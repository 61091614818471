import ListIcon from '@mui/icons-material/List';
import QualificationsList from "./QualificationsList";
import QualificationsCreate from "./QualificationsCreate";
import QualificationsEdit from "./QualificationsEdit";

export default {
  list: QualificationsList,
  edit: QualificationsEdit,
  create: QualificationsCreate,
  icon: ListIcon,
  options: {
    label: 'Qualifications',
  },
};
