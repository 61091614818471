import { DeleteButton, Link, ReferenceField, TextField, useGetIdentity, useRecordContext, useRefresh } from 'react-admin'

import { getRole } from '../common/roles'
import Tooltip from '@mui/material/Tooltip'
import customColor from '../common/customColor'
import { TruncatedTextField } from './CustomTextField'

import CircleIcon from '@mui/icons-material/Circle'
import CancelIcon from '@mui/icons-material/Cancel'
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff'
import { supabaseClient } from '../supabase'

const buttonStyle = {
  padding: '5px',
  borderRadius: '5px',
  minWidth: '100px',
  maxWidth: '150px',
}

export const InfoReferenceField = props => {
  const info = useRecordContext();
  const { bgColor } = customColor(info)

  const style = { color: bgColor }

  return (
    <ReferenceField sx={style} {...props} />
  )
}

export const SourceTextReferenceField = props => {
  const info = useRecordContext();

  if ( info.source_id ) {
    info.lead_source_id = info.source_id
  }

  let sourceIcon
  if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_NOMAD ) {
    sourceIcon = <Tooltip title="NOMAD"><CircleIcon sx={{ color: '#efa87c', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_BROCHURES ) {
    sourceIcon = <Tooltip title="Demande de brochure"><CircleIcon sx={{ color: '#c29cd8', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_WEB ) {
    sourceIcon = <Tooltip title="Contact web"><CircleIcon sx={{ color: '#dfed72', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_MOBILE ) {
    sourceIcon = <Tooltip title="Contact mobile"><CircleIcon sx={{ color: '#19e519', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_CSV_IMPORT ) {
    sourceIcon = <Tooltip title="Import CSV"><CircleIcon sx={{ color: '#51edc7', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_DIPLOMEO ) {
    sourceIcon = <Tooltip title="Contact Diplomeo"><CircleIcon sx={{ color: '#344CBE', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_Q_PARCOURSUP ) {
    sourceIcon = <Tooltip title="Question Parcoursup"><CircleIcon sx={{ color: '#d861cc', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_DEMANDE_RAPPEL ) {
    sourceIcon = <Tooltip title="Demande de rappel"><CircleIcon sx={{ color: '#e33460', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_HIPPOCAST ) {
    sourceIcon = <Tooltip title="Import Hippocast"><CircleIcon sx={{ color: '#c5efac', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_THOTIS ) {
    sourceIcon = <Tooltip title="Import Thotis"><CircleIcon sx={{ color: '#d2aeb7', fontSize: '1rem' }} /></Tooltip>
  } else {
    sourceIcon = <Tooltip title="Origine inconnue"><CircleIcon sx={{ color: '#eee', fontSize: '1rem' }} /></Tooltip>
  }

  return sourceIcon
}

export const ReferenceFieldWithLink = props => {
  const info = useRecordContext()
  const { identity } = useGetIdentity()
  const isRespo = identity?.role?.grade >= getRole('responsable')

  return (
    <ReferenceField
      label={props.label}
      source={props.source}
      reference={props.reference}
      link={props.link}
    >
      { isRespo ?
        <Link to={`/tasks/${info.id}`}>
          <TextField source="name" />
        </Link>
        : <TextField source="name" />
      }
    </ReferenceField>
  )
}

export const InfoTextReferenceField = props => {
  const info = useRecordContext()
  const { textColor, bgColor } = customColor(info)

  const style = {
    ...buttonStyle,
    color: textColor,
    backgroundColor: bgColor,
  }

  return (
    <ReferenceField
      label={props.label}
      source={props.source}
      reference={props.reference}
      link={props.link}
    >
      <TruncatedTextField source={props.textFieldSource} style={style} maxLength={props.truncate} />
    </ReferenceField>
  )
}

export const YellowReferenceField = (props) => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const { textColor, bgColor } = customColor(record)

  const style = {
    ...buttonStyle,
    color: record.momentary_assignation?.isMomentary
      ? '#fefefe'
      : bgColor !== '#e21f1f'
        ? '#111'
        : textColor,
    backgroundColor: record.momentary_assignation?.isMomentary
      ? '#1c94bc'
      : bgColor !== '#e21f1f'
        ? '#ffbd72'
        : bgColor,
    minWidth: '210px',
    display: 'flex',
    alignItems: 'center',
  }

  const removemomentary_assignation = async (e) => {
    e.stopPropagation()

    const { error: errorUpdatingAssignationLog } = await supabaseClient
      .from('assignation_logs')
      .update({ duration: null })
      .eq('id', record.momentary_assignation.assignationLogId)

    if (errorUpdatingAssignationLog) {
      console.error('Error removing momentary assignation:', errorUpdatingAssignationLog)
      return
    }

    const { error: errorUpdatingLead } = await supabaseClient
      .from('leads')
      .update({ assigned_pubeur: record.momentary_assignation.originalPubeur })
      .eq('id', record.id)

    if (errorUpdatingLead) {
      console.error('Error removing momentary assignation:', errorUpdatingLead)
    }

    refresh()
  }

  return (
    <ReferenceField
      label={props.label}
      source={props.source}
      reference={props.reference}
      link={props.link}
    >
      <div style={style}>
        { record.momentary_assignation?.isMomentary && <HistoryToggleOffIcon style={{ fontSize: '1rem', color: '#fefefe', marginRight: '.5rem' }} /> }
        {props.children}
        { record.momentary_assignation?.isMomentary && <CancelIcon sx={{ fontSize: '1rem' }} onClick={(e) => removemomentary_assignation(e)} /> }
      </div>
    </ReferenceField>
  )
}