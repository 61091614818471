import React, { useState } from 'react';
import {
  useDelete,
  useGetOne,
  useNotify,
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceInput,
  TextInput,
  SelectInput,
  useGetIdentity,
} from "react-admin";
import {
  Button,
  Grid,
  Typography,
} from '@mui/material';

import { CommonDialog } from '../common/Dialog';
import { getStatusNameById } from './inboundcontactStatus';
import { btnAction, btnDelete } from '../Design/CustomStyles';
import { classes as optionClasses } from '../common/classes';

export const LeadInput = ({ source, label, record }) => {
  const { data } = useGetOne('leads', { id: record.lead_id })

  return (
    <TextInput source={source} label={label} variant="outlined" defaultValue={data && data[source]} fullWidth />
  )
}

export const LeadClasseInput = ({ source, label, record }) => {
  const { data } = useGetOne('leads', { id: record.lead_id })
  const { identity } = useGetIdentity()
  const localCenter = identity?.center

  return (
    <ReferenceInput
      source={source}
      reference="classes"
    >
      <SelectInput
        source={source}
        variant="outlined"
        choices={optionClasses[localCenter?.id ?? 'default']?.options}
        fullWidth
      />
    </ReferenceInput>
  );
};

export const LeadReferenceInput = ({ source, label, record }) => {
  const { data } = useGetOne('leads', { id: record.lead_id });

  return (
    <ReferenceInput
      source={source}
      reference="schools"
    >
      <AutocompleteInput
        label={label}
        optionText="name"
        variant="outlined"
        filterToQuery={searchText => ({ 'name@ilike': searchText })}
        options={{ type: 'search' }}
        fullWidth
      />
    </ReferenceInput>
  );
};

export const ParentInput = ({ source, label, record }) => {
  const { data } = useGetOne('parents', { id: record.parent_id });

  return (
    <TextInput source={source} label={label} variant="outlined" defaultValue={data && data[source]} fullWidth />
  );
};

export const ContactLogs = ({ record, ...props }) => {
  const [deleteOne, { isLoading, error }] = useDelete();
  const notify = useNotify()
  const [open, setOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  const handleRowClick = (actionRecord) => {
    setSelectedAction(actionRecord);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAction(null);
  };

  const handleDelete = async (record) => {
    try {
      deleteOne('inbound_contact_logs', {
        id: record.id,
        previousData: record
      });

      notify('Suppression réussie', { type: 'success'});
      setOpen(false);
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error);
    }
  }

  return (
    <div>
      <List
        {...props}
        title="Historique des actions"
        filter={{ inbound_contact_id: record.id }}
        sort={{ field: 'timestamp', order: 'DESC' }}
        resource="inbound_contact_logs"
        disableSyncWithLocation={true}
        exporter={false}
        empty={false}
        actions={false}
      >
        <Datagrid bulkActionButtons={false} rowClick={(id, resource, record) => handleRowClick(record)}>
          <FunctionField
            label="Status"
            render={record => {
              const recordAction = JSON.parse(record.action);

              const statusName = recordAction?.actionResult === 'voicemail' || recordAction?.actionResult === 'no_response'
                ? `En attente`
                : `Action terminée`;

              return statusName || 'Statut inconnu';
            }}
          />

          <FunctionField
            label="Action"
            render={record => {
              let statusName;
              const recordAction = JSON.parse(record.action);

              statusName = `
                ${getStatusNameById(recordAction?.originType)} >
                ${getStatusNameById(recordAction?.actionType)} >
                ${getStatusNameById(recordAction?.actionResult)}
              `;

              return statusName || 'Statut inconnu';
            }}
          />

          <DateField
            source="timestamp"
            label="Date"
          />
        </Datagrid>
      </List>

      <CommonDialog
        open={open}
        handleClose={handleClose}
        displayDialogActions={false}
        title="Détails de l'action"
        size="xs"
      >
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={6}>
            <Typography variant="h6">Action</Typography>
            <Typography>{selectedAction && getStatusNameById(JSON.parse(selectedAction.action).originType)}</Typography>
            <Typography>&gt; {selectedAction && getStatusNameById(JSON.parse(selectedAction.action).actionType)}</Typography>
            <Typography>&gt; {selectedAction && getStatusNameById(JSON.parse(selectedAction.action).actionResult)}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6">Status</Typography>
            <Typography>{selectedAction && getStatusNameById(record?.status)}</Typography>

            <Typography variant="h6" style={{ marginTop: '10px' }}>Qualification(s)</Typography>
            <Typography>{selectedAction && getStatusNameById(JSON.parse(selectedAction.action).qualification)}</Typography>
          </Grid>
        </Grid>
        
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={6}>
            <Typography variant="h6">Date de l'action</Typography>

            <DateField
              source="timestamp"
              record={selectedAction}
              showTime
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Commentaire</Typography>
            <Typography>{selectedAction && selectedAction.new_values?.comment || 'Aucun commentaire.'}</Typography>
          </Grid>
        </Grid>
        
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={12}>
            <Button style={btnDelete} onClick={() => handleDelete(selectedAction)} fullWidth>Supprimer</Button>
          </Grid>
        </Grid>
      </CommonDialog>
    </div>
  );
};