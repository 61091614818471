import { EventTypesInputContent } from '.'
import { EditDialog } from '@react-admin/ra-form-layout'

const EventTypeEdit = props => (
  <EditDialog
    title="Catégorie d'événement"
    maxWidth="md"
    fullWidth
  >
    <EventTypesInputContent {...props} />
  </EditDialog>
)

export default EventTypeEdit
