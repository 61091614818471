import { Link, useLogout } from 'react-admin'
import { CircularProgress } from '@mui/material'

const LoadingPage = () => {
  const logout = useLogout()

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        textAlign: 'center',
        fontSize: '1.25rem',
        backgroundColor: '#fafafb',
      }}>
        <h1 style={{ color: '#74a5b5' }}>Chargement en cours...</h1>
      
        <CircularProgress />

        <span style={{ marginTop: '40px', fontSize: '1rem' }}>
          Si la page est trop longue à charger,
          merci de <Link onClick={logout}>vous déconnecter</Link>.
        </span>
      </div>
    </div>
  )
}

export default LoadingPage
