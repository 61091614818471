const centerMapping = {
  'default': 'default',
  'droitCenters': [process.env.REACT_APP_CENTER_CAPAVOCAT, process.env.REACT_APP_CENTER_CAPIRA, process.env.REACT_APP_CENTER_CAPCRC, process.env.REACT_APP_CENTER_CAPTA],
  'commerceCenters': [process.env.REACT_APP_CENTER_MYPREPA]
}

const resolveCenterKey = (center) => {
  if (centerMapping.droitCenters.includes(center)) return 'droit'
  if (centerMapping.commerceCenters.includes(center)) return 'commerce'
  return 'default'
}

export const availableInputs = {
  'default': {
    label: 'Médecine',
    options: [
      { id: 'formation', name: 'Formations proposées' },
      { id: 'classe', name: 'Classe de l\'étudiant' },
      { id: 'civilite', name: 'Civilité' },
      { id: 'demande', name: 'Demandeur' },
      { id: 'nomPrenom', name: 'Nom et prénom de l\'étudiant' },
      { id: 'telephone', name: 'Téléphone' },
      { id: 'codepostal', name: 'Code Postal' },
      { id: 'lycee', name: 'Lycée de l\'étudiant' },
      { id: 'diplome', name: 'Dernier diplôme obtenu ou en cours :' },
    ],
  },
  'droit': {
    label: 'Droit',
    options: [
      { id: 'concours', name: 'Type de concours' },
      { id: 'formation', name: 'Formations proposées' },
      { id: 'classe', name: 'Classe de l\'étudiant' },
      { id: 'civilite', name: 'Civilité' },
      { id: 'demande', name: 'Demandeur' },
      { id: 'nomPrenom', name: 'Nom et prénom de l\'étudiant' },
      { id: 'telephone', name: 'Téléphone' },
      { id: 'codepostal', name: 'Code Postal' },
      { id: 'lycee', name: 'Lycée de l\'étudiant' },
      { id: 'diplome', name: 'Dernier diplôme obtenu ou en cours :' },
    ],
  },
  'commerce': {
    label: 'Commerce',
    options: [
      { id: 'formation', name: 'Formations proposées' },
      { id: 'classe', name: 'Classe de l\'étudiant' },
      { id: 'civilite', name: 'Civilité' },
      { id: 'demande', name: 'Demandeur' },
      { id: 'nomPrenom', name: 'Nom et prénom de l\'étudiant' },
      { id: 'telephone', name: 'Téléphone' },
      { id: 'codepostal', name: 'Code Postal' },
      { id: 'lycee', name: 'Lycée de l\'étudiant' },
      { id: 'diplome', name: 'Dernier diplôme obtenu ou en cours :' },
      { id: 'rappel', name: 'Souhaitez-vous être rappelé par un conseiller pédagogique ou un Alumni de MyPrepa ?' },
    ],
  },
}

export const getFormationIdByName = (center, name) => {
  const resolvedKey = resolveCenterKey(center)
  const inputsChoices = availableInputs[resolvedKey]?.options ?? availableInputs['default']?.options
  const result = inputsChoices?.filter(i => i.name === name)

  return result[0]?.id
}

export const getFormationNameById = (center, ids) => {
  const resolvedKey = resolveCenterKey(center)
  const inputsChoices = availableInputs[resolvedKey]?.options ?? availableInputs['default']?.options

  // return for multiple ids
  if ( Array.isArray(ids) ) {
    return ids.map(id => {
      return inputsChoices.find(i => i.id === id)?.name
    }).join(' - ')
  }

  // return for single id
  return inputsChoices.find(i => i.id === ids)?.name
}