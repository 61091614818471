import baseDataProvider from '../supabase/baseDataProvider'
import { isEqual } from 'lodash'

const schoolsDataProvider = {
  ...baseDataProvider,
  getList: async (resource, params) => {
    resource = 'schools'
    let result = null

    const nbrOfFilters = Object.keys(params?.filter) // because params.filter has current_year_id by default
    
    if ( params.filter && nbrOfFilters?.length > 0 ) {
      let filters = params.filter

      function replaceSpacesWithUnderscores(obj) {
        if ( typeof obj !== 'object' ) return obj

        const result = {}

        for (const key in obj) {
          if ( typeof obj[key] === 'string') {
            const modifiedValue = obj[key].replace(/\s/g, '_')
            result[key] = modifiedValue
          } else {
            result[key] = obj[key]
          }
        }

        return result
      }

      const newFilters = replaceSpacesWithUnderscores(filters)

      params.filter = newFilters
    }

    result = await baseDataProvider.getList(resource, params)

    return result
  },
  update: (resource, params) => {
    // used to make sure that the data has changed before making an update to avoid httpError with ra-many
    const getChanges = (data, previousData) => {
      const changes = Object.keys(data).reduce((changes, key) => {
        if (!isEqual(data[key], previousData[key])) {
          changes[key] = data[key];
        }

        return changes
      }, {})

      return changes
    }

    const changedData = getChanges(params?.data, params?.previousData)

    if ( Object.keys(changedData).length === 0 ) {
      return Promise.resolve({ data: { ...params?.data, id: params?.previousData.id } })
    }

    return baseDataProvider.update(resource, params)
  },
}

export const dataProvider = schoolsDataProvider
