import { useEffect, useState } from 'react'
import {
  useEditContext,
  useGetIdentity,
  FunctionField,
  NumberField,
  ReferenceField,
  TextField,
  useNotify,
} from 'react-admin'
import { Box, Typography, Stack, } from '@mui/material'

import { supabaseClient } from '../supabase'
import { classes } from '../common/classes'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const EventBilan = props => {
  const { record } = useEditContext()
  const { identity } = useGetIdentity()
  const notify = useNotify()
  const localCenter = identity?.center

  const classesList = classes[localCenter?.id] ?? classes['default']
  if ( ! classesList.options.some(option => option.id === 9999) ) {
    classesList.options.push({ id: 9999, name: 'Sans classe' })
  }

  const [qualificationsList, setQualificationsList] = useState([])
  const [leadsCount, setLeadsCount] = useState({})

  const getQualificationChoices = async () => {
    if ( ! record ) return

    const { data, error } = await supabaseClient
      .from('events')
      .select('qualifications_ids')
      .eq('id', record.id)

    if ( error ) {
      console.error('Erreur lors de la récupération des qualifications')
    }

    if ( data[0].qualifications_ids === null ) return

    const { data: qualificationsData, error: qualificationsError } = await supabaseClient
      .from('types_qualifications')
      .select('id, name')
      .in('id', data[0].qualifications_ids)
    
    if ( qualificationsError ) {
      console.error('Erreur lors de la récupération des qualifications')
    }

    setQualificationsList(qualificationsData)
  }

  const getLeadsCountForQualifications = async () => {
    if ( ! record ) return

    const { data, error } = await supabaseClient.rpc('get_qualifications_count_for_event', {
      p_event_id: record.id
    })

    if (error) {
      console.error('Error fetching leads count for qualifications');
      return
    }

    const initCounts = qualificationsList.reduce((acc, qualification) => {
      acc[qualification.id] = {
        name: qualification.name,
        qualification_id: qualification.id,
        total: 0,
        secondes: 0,
        premieres: 0,
        terminales: 0,
        licences: 0,
        autres: 0,
        noclass: 0,
      }

      return acc
    }, {})

    const counts = data.reduce((acc, item) => {
      const { qualification_id, count, qualification_name, class_id } = item;
    
      if ( acc[qualification_id] ) {
        acc[qualification_id].name = qualification_name
        acc[qualification_id].total += count
    
        // Increment count based on class_id
        switch (class_id) {
          case 1:
            acc[qualification_id].secondes += count
            break
          case 2:
            acc[qualification_id].premieres += count
            break
          case 3:
            acc[qualification_id].terminales += count
            break
          case 4:
            acc[qualification_id].licences += count
            break
          case 0:
            acc[qualification_id].autres += count
            break
          default:
            acc[qualification_id].noclass += count
            break
        }
      }
    
      return acc
    }, initCounts)

    setLeadsCount(counts)
  }

  const getTotalQualificationsCount = (leadsCount) => {
    return Object.values(leadsCount).reduce((totalSum, qualification) => {
      // If 'total' is already calculated
      if (qualification.total != null) {
        return totalSum + qualification.total
      }
  
      // If 'total' needs to be calculated
      const qualificationTotal = qualification.secondes + qualification.premieres + qualification.terminales + qualification.licences + qualification.autre
      return totalSum + qualificationTotal
    }, 0)
  }

  useEffect(() => {
    getQualificationChoices()
  }, [])
  
  useEffect(() => {
    if (qualificationsList.length > 0) {
      getLeadsCountForQualifications()
    }
  }, [qualificationsList])

  return (
    <Box display="flex">
      <Box mt={1} flex="1">

        <Typography variant="h5" color="#287791" mt={2} mb={2}>Bilan complet</Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#eee' }}>
                <TableCell></TableCell>

                <TableCell align="right">Secondes</TableCell>
                <TableCell align="right">Premieres</TableCell>
                <TableCell align="right">Terminales</TableCell>
                <TableCell align="right">Licences</TableCell>
                <TableCell align="right">Autres</TableCell>
                <TableCell align="right">Sans classe</TableCell>

                { record.type_id === process.env.REACT_APP_EVENT_TYPE_JPO && <TableCell align="right">Ilots</TableCell> }
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow
                key="rowTotalClasses"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Stack direction="row" spacing={1} alignItems="center" alignContent="space-between">
                    <Typography color="textSecondary" variant="body2">Total Contacts</Typography>

                    <Typography variant="h6">
                      <ReferenceField
                        label="Total"
                        source="id" 
                        reference="event_leads_count"
                        link={false}
                      >
                        <NumberField source="total_leads" />
                      </ReferenceField>
                    </Typography>
                  </Stack>
                </TableCell>

                { classesList?.options.map((classList, key) => 
                  <TableCell align="right" key={key}>
                    <Typography variant="h6">
                      <ReferenceField
                        key={key}
                        label={`${classList?.name}s`}
                        source="id" 
                        reference="event_leads_count"
                        link={false}
                        sx={{ mr: 3 }}
                      >
                        <NumberField source={`class_id_${classList?.id}`} />
                      </ReferenceField>
                    </Typography>
                  </TableCell>
                )}

                { record.type_id === process.env.REACT_APP_EVENT_TYPE_JPO && 
                  <TableCell align="right">
                    <Typography variant="h6">
                      <ReferenceField
                        label="Total"
                        source="id"
                        reference="event_leads_count"
                        link={false}
                      >
                        <NumberField source="total_ilots" />
                      </ReferenceField>
                    </Typography>
                  </TableCell>
                }
              </TableRow>

              { Object.values(leadsCount).length > 0 ? (
                <TableRow>
                  <TableCell>
                    <Stack direction="row" spacing={1} alignItems="center" alignContent="space-between">
                      { Object.values(leadsCount).length > 0 ? (
                        <>
                          <Typography color="textSecondary" variant="body2">Total Qualifications</Typography>

                          <span>{ getTotalQualificationsCount(leadsCount) }</span>
                        </>
                      ) : null }
                    </Stack>
                  </TableCell>

                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>

                  { record.type_id === process.env.REACT_APP_EVENT_TYPE_JPO &&  <TableCell></TableCell> }
                </TableRow>
              ) : null }

              { Object.values(leadsCount).length > 0 ? (
                <>
                  { Object.values(leadsCount).map((qualification, key) => 
                    <TableRow
                      key={qualification.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <ContentCopyIcon
                          sx={{ ml: 1, fontSize: '.9rem', cursor: 'pointer' }}
                          onClick={() => (
                            navigator.clipboard.writeText(qualification.qualification_id),
                            notify('ID de qualification copié dans le presse-papier', { type: 'success' })
                          )}
                        />
                        {` ${qualification.name} (${qualification.total})`}
                      </TableCell>

                      <TableCell align="right"><span style={{ marginRight: '26px' }}>{qualification.secondes ?? 0}</span></TableCell>
                      <TableCell align="right"><span style={{ marginRight: '26px' }}>{qualification.premieres ?? 0}</span></TableCell>
                      <TableCell align="right"><span style={{ marginRight: '26px' }}>{qualification.terminales ?? 0}</span></TableCell>
                      <TableCell align="right"><span style={{ marginRight: '26px' }}>{qualification.licences ?? 0}</span></TableCell>
                      <TableCell align="right"><span style={{ marginRight: '26px' }}>{qualification.autres ?? 0}</span></TableCell>
                      <TableCell align="right"><span style={{ marginRight: '26px' }}>{qualification.noclass ?? 0}</span></TableCell>

                      { record.type_id === process.env.REACT_APP_EVENT_TYPE_JPO && 
                        <TableCell align="right"><span style={{ marginRight: '26px' }}>{qualification.ilots ?? 0}</span></TableCell>
                      }
                    </TableRow>
                  )}
                </>
              ) :
                <>
                  <TableRow>
                    <TableCell colSpan={8} align="center">Aucune qualification n'a été enregistrée dans cet événement</TableCell>
                  </TableRow>
                </>
              }
            </TableBody>
          </Table>
        </TableContainer>
        
        <Typography variant="h5" color="#287791" mt={5} mb={2}>Commentaire</Typography>

        <Box my={2}>
          {record?.comment ? <TextField source="comment" /> : 'Aucun commentaire'}
        </Box>

        { record?.locked_at &&
          <Box my={2}>
            <Typography color="textSecondary" variant="body2">Fermé par</Typography>

            <ReferenceField source="locked_by" reference="profiles" link={false}>
              <FunctionField render={record => (
                <Typography variant="subtitle1">
                  { record?.first_name } { record?.last_name } le { record?.locked_at.format('D/M/yyyy à HH:mm') }
                </Typography>
              )} />
            </ReferenceField>
          </Box>
        }

        { record?.closed_at &&
          <Box my={2}>
            <Typography color="textSecondary" variant="body2">Clôturé par</Typography>

            <ReferenceField source="closed_by" reference="profiles" link={false}>
              <FunctionField render={record => (
                <Typography variant="subtitle1">
                  { record?.first_name } { record?.last_name } le { record?.closed_at.format('D/M/yyyy à HH:mm') }
                </Typography>
              )} />
            </ReferenceField>
          </Box>
        }
      </Box>
    </Box>
  )
}

export default EventBilan