import { useState, useEffect } from 'react'
import { useGetIdentity } from 'react-admin'
import { Grid, Typography, Chip } from '@mui/material'

import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'

import PersonIcon from '@mui/icons-material/Person'

const TaskCard = ({ record }) => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')

  const [taskPubeurs, setTaskPubeurs] = useState(null)

  const options = { year: 'numeric', month: 'long', day: 'numeric', TimeRanges: 'Europe/Paris' }
  const startDate = new Date(record.start_at).toLocaleDateString('fr-FR', options)
  const endDate = record.end_at ? new Date(record.end_at).toLocaleDateString('fr-FR', options) : null

  const taskDates = endDate ? `Du ${startDate} au ${endDate}` : `À partir du ${startDate}`

  useEffect(() => {
    const getLeadTasksPubeurs = async () => {
      const { data, error } = await supabaseClient
        .rpc('rpc_fetch_lead_tasks_pubeurs', { 'lt_id': record?.id })
    
      if (error) {
        console.error('Error fetching tasks leads:', error.message)
        return
      }
    
      setTaskPubeurs(data)
    }
    
    getLeadTasksPubeurs()
  }, [])

  return (
    <>
      <Grid container>
        <Grid item xs={10}>
          <Chip label={record.type_name} sx={{ marginBottom: 1, borderRadius: '5px', backgroundColor: record.type_color }} />
        </Grid>

        <Grid item xs={2}>
          { taskPubeurs?.length > 0 && (
            <div style={{ display: 'flex', alignItems: 'center', color: '#437c79' }}>
              <div style={{
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                fontSize: '.8rem',
                color: '#437c79',
                height: '4vh',
              }}>
                <PersonIcon /> 
                <span style={{ fontWeight: 'bold' }}>{taskPubeurs?.length}</span>
              </div>
            </div>
          )}
        </Grid>
      </Grid>

      <Typography variant="h6">{record.name}</Typography>
      { isAdmin && <Typography variant="caption" gutterBottom>{record.center_name}</Typography> }

      <div style={{ color: '#444', marginTop: '2vh' }}>
        <Typography variant="body2" gutterBottom>
          {taskDates}
        </Typography>
      </div>
    </>
  )
}

export default TaskCard