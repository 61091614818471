import LeadSourcesList from './LeadSourcesList'
import LeadSourceCreate from './leadSourceCreate'
import LeadSourcesEdit from './LeadSourcesEdit'

import WhereToVoteOutlinedIcon from '@mui/icons-material/WhereToVoteOutlined'

export default {
  list: LeadSourcesList,
  edit: LeadSourcesEdit,
  create: LeadSourceCreate,
  icon: WhereToVoteOutlinedIcon,
  options: {
    label: 'Type de provenance',
  },
}
