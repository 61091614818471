const centerMapping = {
  'default': 'default',
  'droitCenters': [process.env.REACT_APP_CENTER_CAPAVOCAT, process.env.REACT_APP_CENTER_CAPIRA, process.env.REACT_APP_CENTER_CAPCRC, process.env.REACT_APP_CENTER_CAPTA],
  'commerceCenters': [process.env.REACT_APP_CENTER_MYPREPA]
}

const resolveCenterKey = (center) => {
  if (centerMapping.droitCenters.includes(center)) return 'droit'
  if (centerMapping.commerceCenters.includes(center)) return 'commerce'
  return 'default'
}

export const availableDocuments = {
  'default': {
    label: 'Médecine',
    options: [
      { id: 'linkcandidature', name: 'Lien vers la candidature en ligne' },
      { id: 'parcoursup', name: 'Dossier Parcoursup' },
      { id: 'bro_complet', name: 'Brochure complète' },
      { id: 'bro_lycee', name: 'Brochure lycée' },
      { id: 'bro_p0', name: 'Brochure P0' },
      { id: 'bro_tss', name: 'Brochure TS Semestrielle' },
      { id: 'bro_man', name: 'MAN Scientifique' },
      { id: 'doss_lycee', name: 'Dossier inscription lycée' },
      { id: 'doss_tss', name: 'Dossier inscription TS Semestrielle' },
      { id: 'doss_complet', name: 'Dossier inscription complet' },
      { id: 'bro_infirmier', name: 'Brochure infirmier' },
      { id: 'bro_psychomotricien', name: 'Brochure Psychomotricien' },
      { id: 'bro_orthophoniste', name: 'Brochure Orthophoniste' },
      { id: 'bro_las2', name: 'Brochure LAS2' },
      { id: 'bro_lsps2', name: 'Brochure LSPS2' },
    ],
  },
  'droit': {
    label: 'Droit',
    options: [
      { id: 'linkcandidature', name: 'Lien vers la candidature en ligne' },
      { id: 'bro_article100', name: 'Brochure Article 100' },
    ],
  },
  'commerce': {
    label: 'Commerce',
    options: [
      { id: 'linkcandidature', name: 'Lien vers la candidature en ligne' },
      { id: 'bro_HEC_ECG', name: 'Cours complémentaires ECG (verte)' },
      { id: 'bro_HEC_enligne', name: 'Nos classes prépa HEC en ligne (bleue)' },
      { id: 'bro_HEC_paris', name: 'Nos classes prépa HEC à Paris (rouge)' },
      { id: 'bro_HEC_orientation', name: 'Brochure Orientation et stages au Lycée (violette)' },
    ],
  },
}


export const getDocumentsIdByName = (center, name) => {
  const resolvedKey = resolveCenterKey(center)
  const documentsChoices = availableDocuments[resolvedKey]?.options ?? availableDocuments['default']?.options
  const result = documentsChoices?.filter(formation => formation.name === name)

  return result[0]?.id
}

export const getDocumentsNameById = (center, ids) => {
  const resolvedKey = resolveCenterKey(center)
  const documentsChoices = availableDocuments[resolvedKey]?.options ?? availableDocuments['default']?.options

  // return for multiple ids
  if ( Array.isArray(ids) ) {
    return ids.map(id => {
      return documentsChoices.find(d => d.id === id)?.name
    }).join(' - ')
  }

  // return for single id
  return documentsChoices.find(d => d.id === ids)?.name
}
