import { List, ListItem, ListItemText } from '@mui/material'

import { CardWithIcon } from '../../Design/Cards'

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'

const TasksList = ({ tasksInfo }) => {
  const numberOfTasks = tasksInfo?.length || 0
  const numberOfTasksCompleted = tasksInfo?.filter(task => task.progress === "100").length || 0
  const numberOfTasksInProgress = tasksInfo?.filter(task => task.progress < "100" && task.progress > "0").length || 0
  const numberOfTasksNotStarted = tasksInfo?.filter(task => task.progress === "0" || task.progress === null).length || 0

  return (
    <div style={{ width: '33.33333%' }}>
      <CardWithIcon
        to="/tasks"
        icon={AssignmentTurnedInIcon}
        title="Tâches assignées"
        subtitle={numberOfTasks}
        color="#fbc410"
      >
        <List>
          <ListItem>
            <ListItemText primary="Pas commencées" secondary={numberOfTasksNotStarted} />
          </ListItem>

          <ListItem>
            <ListItemText primary="En cours" secondary={numberOfTasksInProgress} />
          </ListItem>

          <ListItem>
            <ListItemText primary="Terminées" secondary={numberOfTasksCompleted} />
          </ListItem>
        </List>
      </CardWithIcon>
    </div>
  )
}

export default TasksList