import { useEffect, useState } from 'react'
import { useEditContext, useGetIdentity } from 'react-admin'
import { useMediaQuery, Tooltip, Box, Stack, Chip, Grid, Typography } from '@mui/material'

import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'
import ConversationImport from '../conversations/ConversationImport'

import SellRoundedIcon from '@mui/icons-material/SellRounded'

const LeadConversation = (props) => {
  const record = useEditContext(props)
  const { identity } = useGetIdentity()
  const [messages, setMessages] = useState(null)
  const isResponsable = identity?.role?.grade >= getRole('responsable')
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    { noSsr: true }
  )

  let previousSenderType = null

  const fetchMessages = async () => {
    const { data, error } = await supabaseClient
      .from('conversation_messages')
      .select(`
        *,
        conversations!inner(id, lead_id, pubeur_id, system) 
      `)
      .eq('conversations.lead_id', record?.record?.id)
      .eq('conversations.pubeur_id', identity?.id)
      .order('created_at', { ascending: true })

    if (error) {
      console.error(error)
      return null
    }

    setMessages(data)
  }

  // const updatePaidMessage = async (msgId, status) => {
  //   const { error } = await supabaseClient
  //     .from('conversation_messages')
  //     .update({ paid: status })
  //     .eq('id', msgId)

  //   if (error) {
  //     console.error(error)
  //     return null
  //   }

  //   notify(status ? 'Ce message est passé en "à payer"' : 'Ce message est passé en "ne pas payer"', { type: 'success' })
  //   fetchMessages()
  // }

  useEffect(() => {
    fetchMessages()
  }, [record, identity])

  return messages && messages.length > 0 ? (
    <Box spacing={1} sx={{ maxWidth: isMobile ? '100%' : '70%', margin: '0 auto' }}>
      {messages.map((msg, index) => {
        const isRemunerated = msg.paid
        const showSenderType = msg.conversations?.system?.toLowerCase() === 'iphone' ? false : msg.sender_type !== previousSenderType
        previousSenderType = msg.sender_type

        return !msg.isDate ? (
          <div key={msg.id}>
            <Grid container spacing={1}>
              <Grid item xs={msg.sender_type === 'pubeur' && !isMobile ? 11 : 12}>  
                <Box
                  sx={{
                    padding: msg.isResponse ? '.7em' : '1em',
                    backgroundColor: msg.isResponse ? '#80a0b2' : msg.sender_type === 'pubeur' ? '#56a39f' : '#dcdcdc',
                    color: msg.sender_type === 'pubeur' ? '#fefefe' : '#222',
                    borderRadius: '0.5em',
                    textAlign: msg.sender_type === 'pubeur' ? 'right' : 'left',
                    maxWidth: isMobile
                      ? msg.isResponse ? '70%' : '80%'
                      : msg.isResponse ? '60%' : '70%',
                    marginLeft: msg.sender_type === 'pubeur' ? 'auto' : '0',
                  }}
                  mt={showSenderType ? 2 : 1}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 400,
                      fontSize: isMobile
                        ? msg.isResponse ? '.5rem' : '.8rem'
                        : msg.isResponse ? '.7rem' : undefined
                    }}
                    gutterBottom
                  >
                    {msg.text}
                  </Typography>

                  <Typography variant="subtitle2" sx={{ fontSize: '.7em', fontWeight: 300 }} gutterBottom>
                    {`${new Date(msg.created_at).toLocaleString()}`}
                  </Typography>
                </Box>
              </Grid>

              { isResponsable && !isMobile && (
                <Grid item xs={1} sx={{ display: 'flex', display: 'flex', verticalAlign: 'middle', alignItems: 'center' }}>
                  { isRemunerated && (
                    <Tooltip title="Ne pas compter comme à payer">
                      <SellRoundedIcon
                        sx={{ fontSize: '1.5em', color: 'green', cursor: 'pointer' }}
                        // onClick={() => updatePaidMessage(msg.id, false)}
                      />
                    </Tooltip>
                  )}

                  { ! isRemunerated && (
                    <Tooltip title="Compter comme SMS à payer">
                      <SellRoundedIcon
                        sx={{ fontSize: '1.5em', color: 'lightgrey', cursor: 'pointer' }}
                        // onClick={() => updatePaidMessage(msg.id, true)}
                      />
                    </Tooltip>
                  )}
                </Grid>
              )}
            </Grid>
          </div>
        ) : <div
          key={msg.id}
          style={{ textAlign: 'center', marginTop: '1em', fontWeight: '600' }}
        >
          <Chip label={msg.text} sx={{ backgroundColor: 'transparent', color: '#999', fontSize: '.7rem' }} />
        </div>
      })}

      <ConversationImport
        leadId={record?.record?.id}
        leadName={`${record?.record?.first_name ?? ''} ${record?.record?.last_name ?? ''}`}
        add={true}
      />
    </Box>
  ) : (
    <Stack spacing={4} direction="column" alignItems="center" justifyContent="center" sx={{ width: '95%', mt: 5 }}>
      <Typography variant="h5">Conversation SMS</Typography>

      <Typography variant="body1">
        Vous n'avez pas encore soumis de conversation avec ce contact.
      </Typography>

      <ConversationImport
        leadId={record?.record?.id}
        leadName={`${record?.record?.first_name ?? ''} ${record?.record?.last_name ?? ''}`}
      />

      {/* <Button
        label="Programmer un RDV"
        variant="contained"
        color="primary"
        size="large"
        onClick={() => setOpenRdvDialog(true)}
      /> */}
    </Stack>
  )
}

export default LeadConversation