export const classes = {
  'default': {
    options: [
      { id: 1, name: 'Seconde' },
      { id: 2, name: 'Première' },
      { id: 3, name: 'Terminale' },
      { id: 4, name: 'Licence' },
      { id: 0, name: 'Autre' },
    ],
  },
  [process.env.REACT_APP_CENTER_MYPREPA]: {
    options: [
      { id: 0, name: 'Autre' },
      { id: 1, name: 'Seconde' },
      { id: 2, name: 'Première' },
      { id: 3, name: 'Terminale' },
      { id: 4, name: 'Licence' },
      { id: 5, name: 'Étudiant en prépa HEC 1ère année' },
      { id: 6, name: 'Étudiant en prépa HEC 2ème année' },
      { id: 7, name: 'Étudiant en prépa HEC 3ème année' },
    ],
  },
}

export const getClassIdByName = (name, localCenter) => {
  const result = classes[localCenter?.id]?.options?.filter(classe => classe.name == name) ?? classes['default']?.options?.filter(classe => classe.name == name)
  return result[0]?.id
}

export const getClassNameById = (id, localCenter) => {
  const result = classes[localCenter?.id]?.options.filter(classe => classe.id == id) ?? classes['default']?.options.filter(classe => classe.id == id)
  return result[0]?.name
}
