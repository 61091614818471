import { EditDialog } from '@react-admin/ra-form-layout';

import { ParentInputContent } from './index';

const ParentEdit = () => (
    <EditDialog title="Parents" fullWidth maxWidth="md">
        <ParentInputContent />
    </EditDialog>
);

export default ParentEdit;
