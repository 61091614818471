import { Datagrid, List, TextField } from "react-admin"
import { PostPagination } from '../common/Pagination'
import { TextFieldCopy } from '../Design/CustomTextField'

const LeadSourcesList = props => (
  <List
    {...props}
    pagination={<PostPagination />}
    perPage={50}
    empty={false}
    sx={{ '& .RaList-main': { maxWidth: '70%', margin: '0 auto' }, }}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" label="Nom" />
      <TextFieldCopy source="id" />
    </Datagrid>
  </List>
)

export default LeadSourcesList