import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabaseClient as supabase } from './supabase'
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material'
import './LoginPage.css'
import { Link } from 'react-admin'

const LoginPage = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleLogin = async (event) => {
    event.preventDefault()
    setLoading(true)
    setError('')

    const { user, error } = await supabase.auth.signInWithPassword({ email, password })

    if ( error ) {
      setLoading(false)
      if ( error.message === 'Invalid login credentials') {
        setError('Email ou mot de passe incorrect')
      } else {
        setError(error.message)
      }

      return false
    }

    if ( ! user ) {
      setLoading(false)
      setError('Impossible de vous connecter, veuillez réessayer')
    }

    navigate('/')
  }

  return (
    <div className="background">
      <Container maxWidth="sm" sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backdropFilter: 'blur(6px)'
      }}>
        <Typography variant="h2" sx={{ fontWeight: '900', color: '#fefefe' }} gutterBottom>Forma Contacts</Typography>

        <Typography variant="h4" sx={{ color: '#f5f5f5' }} gutterBottom>
          Connexion
        </Typography>

        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            className="input"
            margin="normal"
            required
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            className="input"
            margin="normal"
            required
            fullWidth
            label="Mot de passe"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? 'Connexion...' : 'Se connecter'}
          </Button>

          <div style={{ textAlign: 'center' }}>
            <Link to="/forgot-password" variant="caption" sx={{ color: '#fefefe' }}>
              J'ai oublié mon mot de passe
            </Link>
          </div>
        </Box>
      </Container>
    </div>
  )
}

export default LoginPage