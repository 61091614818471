import { useGetOne, useCreate, SimpleForm, TextInput, useNotify, SaveButton } from 'react-admin'
import { Box, Typography } from '@mui/material'

import { CardWithIcon } from '../../Design/Cards'

import EuroIcon from '@mui/icons-material/Euro'
import { useEffect, useState } from 'react'
import { supabaseClient } from '../../supabase'

const SettingSmic = ({ value }) => {
  const notify = useNotify()
  const [create, { isPending, error }] = useCreate()
  const [smicHoraire, setSmicHoraire] = useState(null)

  const handleSubmit = (value) => {
    create('current_smic', {
      data: { amount: value.amount }
    })

    notify('Montant du SMIC horaire mis à jour', { type: 'success' })
  }

  useEffect(() => {
    const getSmicAmount = async () => {
      const { data, error } = await supabaseClient
        .from('current_smic')
        .select('amount')
        .order('created_at', { ascending: false })
        .limit(1)

      if (error) {
        console.error('Error fetching event pubeurs:', error.message)
        return
      }

      setSmicHoraire(data[0])
    }

    getSmicAmount()
  }, [])
  
  return (
    <div>
      <CardWithIcon
        to="/leads"
        icon={EuroIcon}
        title="Montant du SMIC horaire"
        subtitle={value}
        color="#ffbe4f"
      >
        <Box p={4}>
          <Typography variant="h4" gutterBottom>
            {smicHoraire?.amount} €
          </Typography>

          <Typography variant="body1" gutterBottom>
            En mettant à jour le montant du SMIC horaire, celui-ci sera automatiquement appliqué aux <i>futures</i> lignes de paie.
          </Typography>

          <SimpleForm
            onSubmit={handleSubmit}
            defaultValues={{ amount: '' }}
            toolbar={false}
          >
            <TextInput source="amount" label="Nouveau montant horaire BRUT" variant="outlined" />
            <SaveButton />
          </SimpleForm>
        </Box>
      </CardWithIcon>
    </div>
  )
}

export default SettingSmic