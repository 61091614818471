import { CreateDialog } from '@react-admin/ra-form-layout';

import { CenterInputContent } from './index';

const CenterCreate = () => (
    <CreateDialog title="Nouveau centre" fullWidth maxWidth="md">
        <CenterInputContent />
    </CreateDialog>
);

export default CenterCreate;
