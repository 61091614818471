import {
  useListContext,
  useGetIdentity,
  Button
} from 'react-admin'

import { getRole } from '../../common/roles'
import AddFilterDialog from './ManageFilters'

import IconButton from '@mui/material/IconButton'
import StarsIcon from '@mui/icons-material/Stars'
import StarRateIcon from '@mui/icons-material/StarRate'
import FilterListIcon from '@mui/icons-material/FilterList'

export const LeadsFilters = () => {
  const { identity } = useGetIdentity();
  const roleUser = identity?.role?.grade
  const { filterValues, setFilters } = useListContext()
  const groupedFilteredValues = JSON.parse(localStorage.getItem('groupedFilteredValues')) ?? null

  const handleAllFavouriteFilter = () => {
    if ( filterValues.check_all_favourites ) {
      delete filterValues.check_all_favourites
      setFilters({ ...filterValues })
      return
    }

    delete filterValues.favourited_by
    setFilters({ ...filterValues, 'check_all_favourites': { 1: { filterId: `{${identity?.id}}`, filterTitle: 'Tous Les Favoris', filterLabel: 'Tous les Favoris', filterMethod: true, filterNumber: 1 } } })
  }

  const handleFavouriteFilter = () => {
    if ( filterValues.favourited_by ) {
      delete filterValues.favourited_by
      setFilters({ ...filterValues })
      return
    }

    delete filterValues.check_all_favourites
    setFilters({ ...filterValues, 'favourited_by': { 1: { filterId: `{${identity?.id}}`, filterTitle: 'Mes Favoris', filterLabel: 'Favoris', filterMethod: true, filterNumber: 1 } } })
  }

  return (
    <>
      { roleUser >= getRole('responsable') && (
        <IconButton
          sx={{
            color: filterValues['check_all_favourites'] ? '#f50057' : 'primary.main',
            marginLeft: '1vw'
          }}
          size="small"
          aria-label="Filtrer tous les favoris"
          title="Filtrer tous les favoris"
          onClick={handleAllFavouriteFilter}
        >
          <StarsIcon />
        </IconButton>
      )}

      <IconButton
        sx={{
          color: filterValues['favourited_by'] ? '#f50057' : 'primary.main',
          marginLeft: '1vw'
        }}
        size="small"
        aria-label="Mes contact favoris"
        title="Mes contact favoris"
        onClick={handleFavouriteFilter}
      >
        <StarRateIcon />
      </IconButton>

      { ( filterValues.groupFilters === 'vide' || ! filterValues.groupFilters || ! groupedFilteredValues ) && (
        <AddFilterDialog
          variant="contained"
          label="Ajouter un filtre"
          icon={<FilterListIcon />}
          styles={{ fontSize: '.75rem', marginLeft: '10px' }}
        />
      )}
    </>
  )
}