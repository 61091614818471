import dataProvider from '../supabase/dataProvider'

const addNewComment = async (leadId, profileId, eventId, comment) => {
  if ( ! leadId ) return
  if ( ! comment || comment === '' ) return

  const response = await dataProvider.create('lead_comments', {
    data: {
      lead_id: leadId,
      profile_id: profileId,
      comment: comment,
      event_id: eventId,
    }
  })

  if (!response || !response.data) {
    console.error('Failed to create lead comment:', response.error)
  }
}

export default addNewComment