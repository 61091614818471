import { supabaseAuthProvider } from 'ra-supabase'
import { supabaseClient } from './'
import { getPermissionsFromRoles } from '@react-admin/ra-rbac'

import permissions from '../permissions'

const baseAuthProvider = supabaseAuthProvider(supabaseClient, {
  getIdentity: async profile => {
    const { data, error } = await supabaseClient
      .from('profiles')
      .select(
        `
          id,
          first_name,
          last_name,
          email,
          phone,
          role: role_id (id, name, reference, grade),
          centers: profile_centers ( centers (id, name) ),
          tours
        `
      )
      .match({ user_auth_id: profile.id })
      .single()

    if (!data || error) {
      throw new Error()
    }

    const profilePermissions = getPermissionsFromRoles({
      roleDefinitions: permissions,
      profileRoles: data.role.grade,
    })

    return {
      id: data.id,
      first_name: data.first_name,
      last_name: data.last_name,
      fullName: `${data.first_name} ${data.last_name}`,
      email: data.email,
      phone: data.phone,
      role: data.role,
      center: data.centers[0].centers,
      permissions: profilePermissions,
      tours: data.tours,
    };
  },
  getPermissions: async () => {
    const profileData = await supabaseClient.auth.getUser()

    if (!profileData?.data?.user) {
      await baseAuthProvider.logout() // Ensure logout if the user isn't found
      return []
    }

    const profile = profileData?.data?.user

    if (!profile) {
      return []
    }

    const { data, error } = await supabaseClient
      .from('profiles')
      .select(`role: role_id (id, name, reference, grade)`)
      .match({ user_auth_id: profile?.id })
      .single()

    if (error || !data) {
      console.error('Error fetching profile roles', error)
      return []
    }

    const profilePermissions = getPermissionsFromRoles({
      roleDefinitions: permissions,
      userRoles: [data?.role?.grade],
    })

    return profilePermissions
  }
})

const authProvider = baseAuthProvider

export default authProvider
