function toTimeZone(date) {
  const formatOptions = {
    timeZone: 'Europe/Paris', // Fixed to Paris/Berlin time zone, handles DST
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };

  const formatter = new Intl.DateTimeFormat('en-US', formatOptions);
  const parts = formatter.formatToParts(date);

  const year = parts.find(p => p.type === 'year').value;
  const month = parts.find(p => p.type === 'month').value;
  const day = parts.find(p => p.type === 'day').value;
  const hour = parts.find(p => p.type === 'hour').value;
  const minute = parts.find(p => p.type === 'minute').value;
  const second = parts.find(p => p.type === 'second').value;

  // Construct the date in ISO format without assuming any fixed GMT offset
  return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`).toISOString();
}

export default toTimeZone