import {
  Datagrid,
  downloadCSV,
  ExportButton,
  TextField,
  TopToolbar,
  useGetIdentity,
} from 'react-admin'
import { List, IfCanAccess } from '@react-admin/ra-rbac'
import jsonExport from 'jsonexport/dist'

import { getRole } from '../common/roles'
import { FormBrochures } from './'
import { PostPagination } from '../common/Pagination'
import BrochuresAsideList from './BrochuresAsideList'

const BrochuresList = props => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')

  const CustomActions = () => (
    <div style={{ minHeight: '50px' }}>
      <TopToolbar sx={{ alignItems: 'left !important' }}>
      </TopToolbar>
    </div>
  )

  return (
    <>
      <List
        {...props}
        empty={false}
        actions={<CustomActions />}
        aside={ isAdmin ? <BrochuresAsideList /> : null}
        pagination={<PostPagination />}
        perPage={50}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <FormBrochures />
      </List>
    </>
  )
}

export default BrochuresList
