import { CreateDialog } from '@react-admin/ra-form-layout'

import { SchoolInputContent } from './index'
import { formatPhoneNumber, getValidEmail, toCamelCase, toLowerCase } from '../common/Formating'

const checkRightTypo = value => {
  return value.replace(/(l|L)ycee/g, (match) => {
    return match.charAt(0) === 'L' ? 'Lycée' : 'lycée'
  })
}

const transform = data => ({
  ...data,
  name: toCamelCase(checkRightTypo(data.name)),
  phone: formatPhoneNumber(data.phone),
  email: getValidEmail(data.email),
  address: {
    street: toLowerCase(data.address?.street),
    postalCode: data.address?.postalCode,
    city: toLowerCase(data.address?.city),
  },
})

const SchoolCreate = () => (
  <CreateDialog title="Ajouter un nouveau lycée" fullWidth maxWidth="md"
    sx={{
      '& .MuiDialogTitle-root': {
        backgroundColor: '#D7EAE8',
      },
    }}
    transform={transform}
  >
    <SchoolInputContent />
  </CreateDialog>
)

export default SchoolCreate
