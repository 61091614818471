import { EditGuesser } from "react-admin";
import LeadYearList from './LeadYearList';
import LeadYearCreate from './LeadYearCreate';

import SchoolIcon from '@mui/icons-material/School'

export default {
  list: LeadYearList,
  edit: EditGuesser,
  create: LeadYearCreate,
  icon: SchoolIcon,
  options: {
    label: 'Années scolaires',
  },
};
