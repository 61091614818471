import { useState, useEffect } from 'react'
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectField,
  SelectInput,
  TextField,
  useGetIdentity,
  WrapperField,
} from 'react-admin'

import { getRole } from '../common/roles'
import { PostPagination } from '../common/Pagination'
import InboundContactEdit from './InboundContactEdit'
import { inboundcontactStatus } from './inboundcontactStatus'
import { SourceTextReferenceField } from '../Design/CustomReferenceField'
import { InboundContactActions, AssignBulkActionButtons } from './InboundContactComponents'

import TrueIcon from '@mui/icons-material/Check'
import FalseIcon from '@mui/icons-material/Close'
import TripOriginIcon from '@mui/icons-material/TripOrigin'

const InboundcontactList = (props) => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')

  return (
    <>
      <List
        {...props}
        empty={false}
        pagination={<PostPagination />}
        perPage={50}
        actions={<InboundContactActions {...props} />}
        sort={{ field: 'requested_date', order: 'DESC' }}
        filterDefaultValues={{ status: 'new' }}
        filter={ !isAdmin
          ? {
            center_id: identity?.center?.id,
            year_id: process.env.REACT_APP_CURRENT_YEAR_ID
          } : { year_id: process.env.REACT_APP_CURRENT_YEAR_ID }}
        filters={[
          <SearchInput placeholder="Nom" source="last_name@ilike" variant="outlined" alwaysOn resettable sx={{ maxWidth: '200px' }} />,
          <SearchInput placeholder="Prénom" source="first_name@ilike" variant="outlined" alwaysOn resettable sx={{ maxWidth: '200px' }} />,
          <ReferenceInput source="source_id" reference="lead_sources" alwaysOn resettable>
            <AutocompleteInput optionText="name" label="Origine" variant="outlined" />
          </ReferenceInput>,
        ]}
        exporter={false}
      >
        <Datagrid
          rowClick="edit"
          bulkActionButtons={<AssignBulkActionButtons />}
        >
          <SourceTextReferenceField textFieldSource="name" label={<TripOriginIcon sx={{ fontSize: '1rem', color: '#999' }} />} source="source_id" reference="lead_sources" />

          <ReferenceField label="Origine" source="source_id" reference="lead_sources" link={false}>
            <TextField source="name" />
          </ReferenceField>

          <DateField label="Reçu le" source="requested_date" />

          <DateField label="Modifié le" source="updated_at" />

          <ReferenceField label="Centre" source="center_id" reference="centers">
            <TextField source="name" />
          </ReferenceField>

          <WrapperField label="Étudiant">
            <ReferenceField source="lead_id" reference="leads">
              <FunctionField render={record => `${record?.first_name || "Non renseigné"} ${record?.last_name || "Non renseigné"}`} />
            </ReferenceField>

            <ReferenceField source="lead_id" reference="leads">
              <FunctionField render={record => ` ${record?.phone || ''}`} />
            </ReferenceField>
          </WrapperField>

          <WrapperField label="Parent">
            <ReferenceField source="parent_id" reference="parents" link={false}>
              <FunctionField render={record => `${record?.first_name || "Non renseigné"} ${record?.last_name || "Non renseigné"}`} />
            </ReferenceField>

            <ReferenceField source="parent_id" reference="parents">
              <FunctionField render={record => ` ${record?.phone ?? ''}`} />
            </ReferenceField>
          </WrapperField>

          <BooleanField
            label="Nouveau"
            source="already_registered"
            valueLabelTrue="Non"
            valueLabelFalse="Oui"
            TrueIcon={FalseIcon}
            FalseIcon={TrueIcon}
          />

          <SelectField label="Action" source="last_action?.actionType" choices={inboundcontactStatus} />
        </Datagrid>
      </List>

      <InboundContactEdit
        source={
          <ReferenceField label="Origine" source="source_id" reference="lead_sources" link={false}>
            <TextField source="name" />
          </ReferenceField>
        }
      />
    </>
  )
};

export default InboundcontactList;
