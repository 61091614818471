import { Edit, SimpleForm, TextInput } from 'react-admin';
import { TextInputCopy } from '../Design/CustomTextInput';

const QualificationsEdit = props => (
  <Edit mutationMode="pessimistic" redirect="list">
      <SimpleForm>
        <TextInputCopy source="id" fullWidth disabled />

        <TextInput label="Titre" fullWidth source="name" />
      </SimpleForm>
  </Edit>
)

export default QualificationsEdit