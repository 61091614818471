import React, { useState, useEffect } from 'react'
import {
  required,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdate,
  AutocompleteInput,
  DateField,
  FunctionField,
  Labeled,
  ReferenceInput,
  SimpleForm,
  Toolbar,
  ReferenceField,
  SaveButton,
  Link,
  TextField,
  useGetIdentity
} from 'react-admin'
import {
  Grid,
  Chip,
  Typography,
  Autocomplete,
  TextField as MuiTextField
} from '@mui/material'

import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'
import lighterColor from '../common/lighterColor'
import { ActionMenu } from './InboundContactActions'
import InboundContactList from './InboundContactList'
import { LeadInput, LeadReferenceInput, ParentInput } from './InboundContactUtils'
import { getItemFromLocalStorage, setItemToLocalStorage } from '../common/LocalStorage'

import CircleIcon from '@mui/icons-material/Circle'
import BookIcon from '@mui/icons-material/MenuBook'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import CircularProgress from '@mui/material/CircularProgress'


export const InboundContactInputContent = props => {
  const [update] = useUpdate()
  const record = useRecordContext()
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const [open, setOpen] = useState(false)

  const centerTags = getItemFromLocalStorage('centerTags')

  const [tagsChoices, setTagsChoices] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [loadingSaveTags, setLoadingSaveTags] = useState(false)

  const saveCurrentTags = async (tagsIds, stopRGPD, isInterested, isNotInterested) => {
    setLoadingSaveTags(true)

    const { data, error } = await supabaseClient
      .from('leads')
      .update({
        tags_ids: tagsIds,
        stop_rgpd: stopRGPD,
        interested: isInterested,
        not_interested: isNotInterested,
      })
      .eq('id', record.id)
      .select()

    if ( error ) {
      console.error('Erreur lors de la mise à jour des tags')
    }

    record.tags_ids = tagsIds
    // refresh()

    setTimeout(() => {
      setLoadingSaveTags(false)
    }, 1000)
  }

  const handleTagChange = (event, newValue) => {
    const lowerCaseTags = newValue.map(tag => tag.name.toLowerCase())

    const stopRGPDSelected = lowerCaseTags.includes('stop rgpd')
    let interestedSelected = lowerCaseTags.includes('intéressé(e) par la prépa')
    let notInterestedSelected = lowerCaseTags.includes('pas intéressé(e) par la prépa')
  
    // If both "Intéressé(e) par la prépa" and "Pas intéressé(e) par la prépa" are selected,
    // keep only the last selected one and unselect the other
    if (interestedSelected && notInterestedSelected) {
      const lastSelectedTag = newValue[newValue.length - 1]
      const unselectedTagName =
        lastSelectedTag.name.toLowerCase() === 'intéressé(e) par la prépa'
          ? 'pas intéressé(e) par la prépa'
          : 'intéressé(e) par la prépa';
      newValue = newValue.filter(tag => tag.name.toLowerCase() !== unselectedTagName)

      if (unselectedTagName === 'intéressé(e) par la prépa') {
        interestedSelected = false
        notInterestedSelected = true
      } else {
        interestedSelected = true
        notInterestedSelected = false
      }
    }
  
    // Filter selected tags to match the structure of options
    const filteredSelectedTags = newValue.filter(tag =>
      tagsChoices.some(option => option.id === tag.id)
    )
  
    setSelectedTags(filteredSelectedTags)

    const newTagsIds = newValue.map(tag => tag.id)

    saveCurrentTags(newTagsIds, stopRGPDSelected, interestedSelected, notInterestedSelected)
  }

  const getTagsChoices = async () => {
    let tagsData = centerTags?.tags || []

    if ( tagsChoices?.length === 0 && ( isAdmin || ! centerTags ) ) {
      console.log('Getting tags choices in the lead...');
      
      const { data, error } = await supabaseClient
        .from('tags')
        .select('*')
        .eq('center_id', record.center_id)

      if ( error ) {
        console.error('Erreur lors de la récupération des qualifications')
      }

      if ( ! data ) return

      const tagChoices = data.map(tag => ({
        id: tag.id,
        name: tag.name,
        color: tag.color ?? '999',
        colorLight: lighterColor(tag.color) ?? 'eee'
      }))
    
      setTagsChoices(tagChoices)
      setItemToLocalStorage('centerTags', { tags: data, updatedAt: Date.now() })

      tagsData = data
    } else {
      setTagsChoices(tagsData)
    }

    if ( record?.tags_ids ) {
      if ( selectedTags ) return

      const leadTags = tagsData?.filter(tag => record.tags_ids.includes(tag.id)).map(tag => ({
        id: tag.id,
        name: tag.name,
        color: tag.color ?? '999',
        colorLight: lighterColor(tag.color) ?? 'eee'
      }))
  
      setSelectedTags(leadTags)
    }
  }

  const onSubmit = async values => {
    try {
      if (values.lead_id) {
        await update('leads', {
          id: values.lead_id,
          data: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone: values.phone,
            school_id: values.school,
          },
          previousData: { record }
        });
      }

      if (values.parent_id) {
        await update('parents', {
          id: values.parent_id,
          data: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone: values.phone,
          },
          previousData: { record }
        });
      }

      await update('inbound_contacts', {
        id: values.id,
        data: {
          center_id: values.center_id,
        },
        previousData: { record }
      });

      refresh()
      notify('Mise à jour réussie', { type: 'success'})
      redirect('/inbound_contacts')
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error)
    }
  }

  const CustomToolbar = () => <Toolbar><SaveButton label="Valider" fullWidth /></Toolbar>

  useEffect(() => {
    getTagsChoices()
  }, [record])

  return (
    <>
      <SimpleForm
        {...props}
        onSubmit={onSubmit}
        toolbar={<CustomToolbar />}
      >
        <Typography variant="body1" mb={2}>
          { record.already_registered
            ? <i><b>Contact { record.lead_id ? `étudiant` : `parent` } déjà enregistré</b></i>
            : <i style={{ color: 'darkgreen' }}>Ce contact est nouveau dans notre base.</i> }
        </Typography>

        {record && record.lead_id && (
          <>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <LeadInput source="first_name" label="Prénom" record={record} />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <LeadInput source="last_name" label="Nom" record={record} />
                  </Grid>
                </Grid>

                <ReferenceField source="lead_id" reference="leads">
                  <FunctionField
                    render={leadRecord => (
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={8}>
                          <LeadInput source="email" label="Email" record={record} />
                        </Grid>
                        
                        <Grid item xs={12} md={4}>
                          <LeadInput source="phone" label="Téléphone" record={record} />
                        </Grid>
                      </Grid>
                    )}
                  />
                </ReferenceField>
                
                <ReferenceField source="lead_id" reference="leads">
                  <FunctionField
                    render={leadRecord => (
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                          <LeadReferenceInput source="school" label="Lycée" record={record} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <ReferenceInput source="center_id" reference="centers" label="Centre" fullWidth>
                            <AutocompleteInput label="Centre" optionText="name" variant="outlined" validate={required()} />
                          </ReferenceInput>
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
                          <LeadClasseInput source="classe" label="Classe" record={record} />
                        </Grid> */}
                      </Grid>
                    )}
                  />
                </ReferenceField>

                <Autocomplete
                  multiple
                  options={tagsChoices}
                  getOptionLabel={option => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  filterSelectedOptions
                  onChange={handleTagChange}
                  sx={{ marginBottom: '1em' }}
                  renderInput={params => (
                    <MuiTextField
                      {...params}
                      key={params.id}
                      variant="outlined"
                      label="Sélectionner un ou plusieurs tags"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingSaveTags ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={`li-tag-${option.id}`} sx={{ padding: '2px', backgroundColor: `#${option.color} !important` }}>
                        <CircleIcon sx={{ fontSize: '1rem', marginRight: '10px', color: `#${option.color}` }} />
                        {option.name}
                      </li>
                    );
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                       {...getTagProps({ index })}
                        variant="outlined"
                        label={option.name}
                        key={`chip-tag-${option.id}`}
                        sx={{
                          color: `#${option.color} !important`,
                          backgroundColor: `#${option.colorLight} !important`,
                          fontWeight: 500,
                          border: 'none',
                          fontSize: '12px',
                          margin: '1px',
                          padding: '1px',
                          height: '24px'
                        }}
                      />
                    ))
                  }
                  value={selectedTags}
                />
              </Grid>
            </Grid>

            <Grid container mb={2} sx={{marginBottom: 2}}>
              <Grid item xs={6} md={6}>
                <Labeled label="Lycée">
                  <TextField source="lycee" emptyText="Non renseigné" />
                </Labeled>
              </Grid>

              <Grid item xs={6} md={6}>
                <Labeled label="Classe">
                  <TextField source="classe" emptyText="Non renseigné" />
                </Labeled>
              </Grid>
            </Grid>

            <Grid container mb={2} sx={{marginBottom: 4}}>
              <Grid item xs={6} md={6}>
                <Labeled label="Reçu le">
                  <DateField source="requested_date" />
                </Labeled>
              </Grid>

              <Grid item xs={6} md={6}>
                <Labeled label="Formation">
                  <TextField source="formation" emptyText='Non renseigné'  />
                </Labeled>
              </Grid>
            </Grid>

            <Typography
              color="textSecondary"
              variant="body1"
              sx={{
                marginBottom: 4,
                textAlign: 'center',
                width: '100%',
                display: 'block'
              }}
            >
              <Link
                to={`/leads/${record.lead_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Accéder à la fiche complète du contact étudiant <OpenInNewIcon sx={{ fontSize: '18px' }} /> 
              </Link>
            </Typography>
          </>
        )}

        {record && record.parent_id && (
          <>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={12}>          
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <ParentInput source="first_name" label="Prénom" record={record} />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <ParentInput source="last_name" label="Nom" record={record} />
                  </Grid>
                </Grid>

                <ReferenceField source="parent_id" reference="parents">
                  <FunctionField
                    render={parentRecord => (
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={8}>
                          <ParentInput source="email" label="Email" record={record} />
                        </Grid>
                        
                        <Grid item xs={12} md={4}>
                          <ParentInput source="phone" label="Téléphone" record={record} />
                        </Grid>
                      </Grid>
                    )}
                  />
                </ReferenceField>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <ReferenceInput source="center_id" reference="centers" label="Centre" fullWidth>
                      <AutocompleteInput label="Centre" optionText="name" variant="outlined" validate={required()} />
                    </ReferenceInput>
                  </Grid>

                  <Grid item xs={14} md={4}>
                    <p>Tags à venir prochainement.</p>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            <Grid container mb={2} sx={{marginBottom: 2}}>
              <Grid item xs={6} md={6}>
                <Labeled label="Lycée">
                  <TextField source="lycee" emptyText="Non renseigné" />
                </Labeled>
              </Grid>

              <Grid item xs={6} md={6}>
                <Labeled label="Classe">
                  <TextField source="classe" emptyText="Non renseigné" />
                </Labeled>
              </Grid>
            </Grid>
            
            <Grid container mb={2} sx={{marginBottom: 4}}>
              <Grid item xs={6} md={6}>
                <Labeled label="Reçu le">
                  <DateField source="requested_date" />
                </Labeled>
              </Grid>

              <Grid item xs={6} md={6}>
                <Labeled label="Formation">
                  <TextField source="formation" emptyText='Non renseigné'  />
                </Labeled>
              </Grid>
            </Grid>

            <Typography
              color="textSecondary"
              variant="body1"
              sx={{
                marginBottom: 4,
                textAlign: 'center',
                width: '100%',
                display: 'block'
              }}
            >
              <Link
                to={`/parents/${record.parent_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Accéder à la fiche complète du contact parent <OpenInNewIcon sx={{ fontSize: '18px' }} /> 
              </Link>
            </Typography>
          </>
        )}

        <ActionMenu record={record} refresh={refresh} openInfo={open} />
      </SimpleForm>
    </>
  );
};

export default {
  name: 'inbound_contacts',
  list: InboundContactList,
  icon: BookIcon,
  options: {
    label: 'Contacts entrants',
  },
};
