import { CreateDialog } from '@react-admin/ra-form-layout'

import { TasksInputContent } from './index'

const TasksCreate = props => (
    <CreateDialog
      title="Nouvelle tâche"
      maxWidth="md"
      fullWidth
    >
      <TasksInputContent action="create" {...props} />
    </CreateDialog>
);

export default TasksCreate;
