import { BulkDeleteButton, Datagrid, DatagridConfigurable, List, TextField, useGetIdentity } from 'react-admin'

import EventTypeEdit from './EventTypeEdit'
import EventTypeCreate from './EventTypeCreate'
import { PostPagination } from '../common/Pagination'
import EventTypeTopToolbar from './EventTypeTopToolbar'
import { TextFieldCopy } from '../Design/CustomTextField'
import { getRole } from '../common/roles'

const EventTypeList = props => {
  const { identity } = useGetIdentity()
  
  return (
    <>
      <List
        {...props}
        empty={false}
        actions={<EventTypeTopToolbar {...props} />}
        sort={{ field: 'created_at', order: 'DESC' }}
        pagination={<PostPagination />}
        perPage={50}
      >
        <DatagridConfigurable rowClick="edit" bulkActionButtons={ identity?.role?.grade === getRole('admin') ? <BulkDeleteButton mutationMode="pessimistic" /> : false }>
          <TextField source="name" label="Nom" />

          <TextFieldCopy source="id" maxWidth={'350px'} />

          <TextField source="created_at" label="Création" />
          
          <TextField source="updated_at" label="Mise à jour" />
        </DatagridConfigurable>
      </List>

      <EventTypeEdit />
      <EventTypeCreate />
    </>
  )
}

export default EventTypeList