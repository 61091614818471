import React, { useState } from 'react'
import { Pagination, useNotify, useListContext } from 'react-admin'

const CustomPostPagination = (props) => {
  const { perPage, setPerPage } = useListContext()
  const [rowsPerPage, setRowsPerPage] = useState(perPage || 50)
  const notify = useNotify()

  const handlePageSizeChange = (event) => {
    const value = parseInt(event.target.value, 10)

    setRowsPerPage(value)
    setPerPage(value)

    // Trigger the warning if the value is greater than 200
    if (value > 200) {
      notify('Attention, vous allez charger un nombre conséquent de contacts. Pour éviter toute latence, il est conseillé de cacher au préalable un maximum de colonnes', { type: 'warning' })
    }
  }

  return (
    <Pagination
      {...props}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[10, 20, 50, 100, 250, 500, 1000, 2000]}
      onRowsPerPageChange={handlePageSizeChange}
    />
  )
}

export default CustomPostPagination
