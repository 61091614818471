import { ImportButton } from "react-admin-import-csv";

import { insertIntoTable } from './profileUtils';
import { getRole } from '../common/roles';
import { getValidEmail, getValidPhone } from '../common/Formating';

export const ImportProfileButton = props => {
    const config = {
        logging: true,
        disableImportOverwrite: true,
        disableCreateMany: true,
        disableUpdateMany: true,
        transformRows: csvRows => {
            const results = [];
            csvRows.map((row, index) => {
                results[index] = {
                    enabled: true,
                    first_name: row.prenom,
                    last_name: row.nom,
                    phone: getValidPhone(row.tel),
                    email: getValidEmail(row.mail),
                    from_school: row['lycee de provenance'],
                    role_id: row.poste ? getRole(row?.poste) : 'c30ae5a9-a25b-42ea-bdfe-42a0338e45e7',
                    centers: (row.centres.replace(/ /g, '')).split(','),
                };
            });

            return results;
        },
        preCommitCallback: async (action, values) => {
            const errors = [];

            const promises = values.map(async profile => {
                const { centers, ...profileWithoutCenters } = profile;
                const { result: insertedProfile, error: profileError } = await insertIntoTable('profiles', profileWithoutCenters);
                
                if (profileError) {
                    errors.push({ error: profileError, rowData: profileWithoutCenters });
                    return;
                }

                const centersPromises = centers.map(async center => {
                    return insertIntoTable('profile_centers', {
                        profile_id: insertedProfile.id,
                        center_id: center
                    });
                });

                await Promise.all(centersPromises);
            });

            await Promise.all(promises);

            if (errors.length > 0) {
                console.error('Errors occurred during import:', errors);
            }

            return [];
        },
        parseConfig: {
            dynamicTyping: true,
            delimiter: "",
            skipEmptyLines: true,
        },
    };

    return <ImportButton {...props} {...config} />;
};
