import * as React from "react";
import { CreateDialog } from '@react-admin/ra-form-layout';

import { ProfileInputContent } from './index';

const ProfileCreate = () => (
    <CreateDialog
      title="Nouvel utilisateur"
      maxWidth="md"
      mutationMode="pessimistic"
      fullWidth
    >
      <ProfileInputContent />
    </CreateDialog>
);

export default ProfileCreate;
