import { SearchInput } from 'react-admin'

const TagsFiltersBar = [
  <SearchInput
    reference="tags"
    source="name"
    variant="outlined"
    alwaysOn
  />
]

export default TagsFiltersBar