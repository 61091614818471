import * as React from 'react';
import {
    useGetList,
    FilterList,
    FilterListItem,
} from 'react-admin';
import { Box, Chip } from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';

export const SchoolListFilter = () => {
    const { data } = useGetList(
        'centers',
        { 
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'name', order: 'ASC' }
        }
    );

    return (
        <Box width="15em" order="-1" marginRight="1em">
            <FilterList label="Centres" icon={<StoreIcon />}>
                {data &&
                    data?.map(center => (
                        <FilterListItem
                            key={center.id}
                            label={
                                <Chip
                                    label={center.name}
                                    size="small"
                                    style={{
                                        backgroundColor: center.color,
                                        border: 0,
                                        cursor: 'pointer',
                                    }}
                                />
                            }
                            value={{ center_id: center.id }}
                        />
                    ))
                }
            </FilterList>
        </Box>
    );
};
