import * as React from 'react'
import { createElement } from 'react'
import { Stack, Card, Box, Typography, Divider, CardContent, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'

export const FormCard = ({ colorTitle = '#287791', title, cardWidth, marginTop = 2, children }) => {
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('sm'),
    { noSsr: true }
  )

  const trueCardWidth = isMobile ? '100%' : cardWidth ?? '100%'

  return (
    <Card variant="outlined" sx={{ mt: marginTop, width: trueCardWidth, mb: 2, backgroundColor: '#f2f2f2' }}>
      <CardContent sx={{ '& .MuiInputBase-root': { backgroundColor: '#fff' } }}>
        {title && (
          <Typography variant="h6" color={colorTitle} mb={2} gutterBottom>
            {title}
          </Typography>
        )}
        {children}
      </CardContent>
    </Card>
  )
}

export const CardWithIcon = ({ icon, title, subtitle, to, children, color }) => (
  <Card
    sx={{
      minHeight: 52,
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      '& a': {
          textDecoration: 'none',
          color: 'inherit',
      },
    }}
  >
    <Link to={to}>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          padding: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& .icon': {
            color: color || 'secondary.main',
          },
          '&:before': {
            position: 'absolute',
            top: '50%',
            left: 0,
            display: 'block',
            content: `''`,
            height: '200%',
            aspectRatio: '1',
            transform: 'translate(-30%, -60%)',
            borderRadius: '50%',
            backgroundColor: 'secondary.main',
            opacity: 0.15,
          },
        }}
      >
        <Box width="3em" className="icon">
          { createElement(icon, { fontSize: 'large' }) }
        </Box>
        
        <Box textAlign="right">
          <Typography color="textSecondary">{title}</Typography>
          
          <Typography variant="h5" component="h2">
            { subtitle ?? '' }
          </Typography>
        </Box>
      </Box>
    </Link>
    
    {children && <Divider />}
    {children}
  </Card>
)

export const PaieCardWithIcon = ({ icon, title, subtitle, color, children }) => (
  <Card
    sx={{
      minHeight: 52,
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      '& a': {
        textDecoration: 'none',
        color: 'inherit',
      },
    }}
  >
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        padding: '16px',
        '& .icon': {
          color: color || 'secondary.main',
        },
        '&:before': {
          position: 'absolute',
          top: '50%',
          left: 0,
          display: 'block',
          content: `''`,
          height: '200%',
          aspectRatio: '1',
          transform: 'translate(-30%, -60%)',
          borderRadius: '50%',
          backgroundColor: 'secondary.main',
          opacity: 0.15,
        },
      }}
    >
      <Stack spacing={2} direction="row" alignContent="center" alignItems="center" justifyContent="space-between">
        <Box width="3em" className="icon">
          { createElement(icon, { fontSize: 'large' }) }
        </Box>
        
        <Box>
          <Typography variant="h5" component="h2">{title}</Typography>
          
          <Typography color="textSecondary">
            { subtitle ?? '' }
          </Typography>
        </Box>
      
        {children}
      </Stack>
    </Box>
  </Card>
)
