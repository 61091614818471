import { CardWithIcon } from '../../Design/Cards'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

const WeeklyLeads = ({ value }) => (
  <div>
    <CardWithIcon
      to="/leads"
      icon={PeopleAltIcon}
      title="Nouveaux contacts"
      subtitle={value}
      color="#83c4c1"
    />
  </div>
)

export default WeeklyLeads