import dataProvider from '../supabase/dataProvider'

async function createNotification (record) {
  if ( ! record.profile_id ) return

  await dataProvider.create('profiles_notifications', {
    data: record
  })

  console.log('Notification created')
}

export default createNotification