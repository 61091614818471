import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

import { supabaseClient } from '../supabase'

import toTimeZone from '../common/toTimeZone'

const columns = [
  { header: 'Prénom', key: 'prenom', width: 10 },
  { header: 'Nom', key: 'nom', width: 10 },
  { header: 'Email', key: 'email', width: 10 },
  { header: 'Téléphone', key: 'telephone', width: 10 },
  { header: 'Centre', key: 'nom_du_centre', width: 10 },
  { header: 'ID du pubeur assigné', key: 'pubeur_assigne', width: 10 },
  { header: 'Créé le', key: 'created_at', width: 10 },
  { header: 'Modifié le', key: 'updated_at', width: 10 },
]

const formatLeadToRow = (lead) => {
  return {
    prenom: lead.prenom,
    nom: lead.nom,
    email: lead.email,
    telephone: lead.telephone,
    nom_du_centre: lead.nom_du_centre,
    pubeur_assigne: lead.pubeur_assigne,
    created_at: toTimeZone(new Date(), 'Europe/Paris') ?? null,
    updated_at: toTimeZone(new Date(), 'Europe/Paris') ?? null,
  }
}

const fetchDataFromRPCWithSelectedParents = async (selectedParentsIds) => {
  let allData = []
  let batch_no = 1
  let hasMore = true

  while (hasMore) {
      const { data, error } = await supabaseClient.rpc('rpc_fetch_selected_parents_data', {
          parents_ids: selectedParentsIds,
          batch_no: batch_no,
      })

      if (error) {
          console.error('Error fetching paginated data from RPC:', error)
          return []
      }

      allData = allData.concat(data)

      if (data.length < 1000) {
        hasMore = false
      } else {
        batch_no += 1
      }
  }

  return allData
}

export const exporter = async (records) => {
  try {
    const leadsData = await fetchDataFromRPCWithSelectedParents(records.map(record => record?.id ?? record))

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Informations parents')
    worksheet.columns = columns

    for (const lead of leadsData) {
      worksheet.addRow(formatLeadToRow(lead))
    }

    const buffer = await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'Export de parents.xlsx')
  } catch (error) {
    console.error("Erreur lors de l'export:", error)
    throw error
  }
}

export const headersOnlyExporter = async () => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Informations parents')
  worksheet.columns = columns
  worksheet.addRow({})

  worksheet.addRow({
    prenom: '',
    nom: '',
    email: '',
    telephone: '',
    pubeur_assigne: '',
  })

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  saveAs(blob, 'Template de parents.xlsx')
}
