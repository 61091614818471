import React, { useEffect, useState } from 'react'
import { CheckboxGroupInput, Loading, SimpleForm, TextInput, useGetIdentity, useListContext, useNotify, useUpdate, FileInput, FileField, Link, useRefresh } from 'react-admin'
import { Typography, Grid } from '@mui/material'

import { FormCard } from '../Design/Cards'
import BrochuresList from './BrochuresList'
import { supabaseClient } from '../supabase/'
import { availableInputs } from './inputsList'
import objectComparison from '../common/objectComparison'
import { availableFormations } from '../common/formations'
import { classes as optionClasses } from '../common/classes'
import { availableDocuments, getDocumentsNameById } from './pdfDocuments'

import FilePresentIcon from '@mui/icons-material/FilePresent'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

export default {
  list: BrochuresList,
  icon: FilePresentIcon,
  options: {
    "label": "Brochures",
  }
}

export const FormBrochures = () => {
  const { data, isLoading, filterValues } = useListContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const { identity } = useGetIdentity()
  const localCenter = identity?.center

  const [update, { isPending, error }] = useUpdate()

  const documentListChoices = availableDocuments[localCenter?.id]?.options ?? availableDocuments['default'].options ?? null
  const [bucketDocuments, setBucketDocuments] = useState([])
  const [uploadStatus, setUploadStatus] = useState({})

  const record = data ? data[0] : null

  useEffect(() => {
    if (record) {
      const getExistingDocs = async () => {
        // get all registered documents in the storage bucket
        const { data, error } = await supabaseClient
          .storage
          .from(record.center_id) // bucket name is the center UUID
          .list()

        if (error) {
          console.error('Error listing folders:', error.message)
          setBucketDocuments([])
          setUploadStatus({})
          return
        }
        
        // const registeredDocuments = record.pdf_docs ?? []
        const registeredDocumentFolders = data ?? []

        const docs = registeredDocumentFolders.map(async (docFolder) => {
          const { data, error } = await supabaseClient
            .storage
            .from(record.center_id)
            .list(`${docFolder.name}/`)

          if (error) {
            console.error(`Error fetching files for doc folder ${docFolder.name}:`, error.message)
            return null
          }

          // If document exists, add to uploadedDocuments
          if (data && data.length > 0) {
            const fileName = data[0].name
            return { docId: docFolder.name, documentName: fileName, documentUrl: `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${record.center_id}/${docFolder.name}/${fileName}` }
          }

          setBucketDocuments([])
          setUploadStatus({})

          return null
        })

        const resolvedDocs = await Promise.all(docs)
        const existingDocs = resolvedDocs.filter(doc => doc !== null)
        setBucketDocuments(existingDocs)
      }

      getExistingDocs()
    } else {
      setBucketDocuments([])
      setUploadStatus({})
    }
  }, [record, filterValues])

  if ( isLoading || !identity ) return <Loading />
  if ( ! data || data?.length > 1 ) return <Typography variant="body1" m={2}>Veuillez sélectionner un formulaire</Typography>

  if ( ! record ) return <Typography variant="body1" m={2}>Ce centre n'a pas encore de formulaire</Typography>

  const sanitizeFileName = (fileName) => {
    // Remove accents/diacritics, replace spaces with underscores, and remove any other disallowed characters
    return fileName
      .normalize('NFD') 
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '_')
      .replace(/[^\w.-]/g, '')
  }

  const removeAllFilesFromFolder = async (folderName) => {
    const { data: files, error: listError } = await supabaseClient
      .storage
      .from(record.center_id)  // The bucket is the center_id
      .list(`${folderName}/`)   // List files in the folder

    if (listError) {
      console.error('Error listing files:', listError);
      setUploadStatus((prev) => ({ ...prev, [document]: 'Erreur lors de la récupération des fichiers', }))
      return false
    }
    
    if (files.length > 0) {
      const filePaths = files.map(file => `${folderName}/${file.name}`)

      const { error: removeAllFiles } = await supabaseClient
        .storage
        .from(record.center_id)
        .remove(filePaths)

      if (removeAllFiles) {
        console.error('Error removing files:', removeAllFiles)
        setUploadStatus((prev) => ({ ...prev, [document]: 'Erreur lors de la suppression des fichiers', }))
        return false
      }
    }

    return true
  }

  const handleSubmit = async (values) => {
    // Save the PDF files to the storage bucket
    const savePDFfiles = async (values) => {
      let loadedDocuments = bucketDocuments.map(doc => doc.docId)
      for (const key of Object.keys(values)) {
        if ( !key.startsWith('pdf_upload_') ) continue // avoid other fields

        const fileObj = values[key]
        const document = key.replace('pdf_upload_', '')
        delete values[key]
        
        if ( fileObj && Object.values(fileObj).length > 0 ) {
          const file = fileObj[0]?.rawFile ?? fileObj.rawFile
          
          if (file) {
            // first remove all files in the folder
            const isRemoved = await removeAllFilesFromFolder(document)

            if ( !isRemoved ) continue

            // then upload the file to Supabase storage
            const fileName = sanitizeFileName(file.name)
            const filePath = `${document}/${fileName}`

            const { error: addNewFolder } = await supabaseClient
              .storage
              .from(record?.center_id) // bucket is the center UUID
              .upload(filePath, file, { upsert: true })

            if (addNewFolder) {
              console.error('Error uploading file:', addNewFolder)
              console.error('Center ID:', record?.center_id)

              setUploadStatus((prev) => ({ ...prev, [document]: 'Erreur lors de l\'envoi du fichier', }))
            } else {
              setUploadStatus((prev) => ({ ...prev, [document]: 'Fichier enregistré avec succès', }))
              loadedDocuments.push(document)
              loadedDocuments = [...new Set(loadedDocuments)]
            }
          }
        }
      }

      values.pdf_docs = loadedDocuments

      return values
    }

    const restValues = await savePDFfiles(values)

    console.log(!objectComparison(restValues, record));

    // Save the rest of the form
    if ( !objectComparison(restValues, record) ) {
      update('brochures', {
        id: record.id,
        data: restValues,
        previousData: record
      })

      notify('Le formulaire a été mis à jour', { type: 'success' })
      refresh()
    }
  }

  const removeDocument = async (docId) => {
    const isRemoved = await removeAllFilesFromFolder(docId)

    if ( !isRemoved ) return

    const updatedDocs = bucketDocuments.filter(doc => doc.docId !== docId)

    setBucketDocuments(updatedDocs)
    notify('Document supprimé', { type: 'info' })
    refresh()
  }
  
  return (
    <SimpleForm
      record={record}
      onSubmit={handleSubmit}
    >
      <TextInput label="Nom du formulaire" source="name" variant="outlined" fullWidth />

      <FormCard title="Champs du formulaire">
        <Typography variant="body2"><i>Sélectionnez les champs qui doivent apparaître dans votre formulaire de demande de brochures.</i></Typography>

        <CheckboxGroupInput
          source="used_inputs"
          label={ availableInputs[localCenter?.id]?.label ?? availableInputs['default'].label }
          choices={ availableInputs[localCenter?.id]?.options ?? availableInputs['default'].options }
          row={false}
        />
      </FormCard>

      <FormCard title="Classes">
        <Typography variant="body2"><i>Sélectionnez les classes qui peuvent être choisies dans votre formulaire de demande de brochures.</i></Typography>

        <CheckboxGroupInput
          source="classes"
          label={ optionClasses[localCenter?.id]?.label ?? optionClasses['default']?.label }
          choices={ optionClasses[localCenter?.id]?.options ?? optionClasses['default']?.options }
          row={false}
        />
      </FormCard>

      <FormCard title="Formations proposées">
        <Typography variant="body2"><i>Sélectionnez les formations qui peuvent être choisies dans votre formulaire de demande de brochures.</i></Typography>

        <CheckboxGroupInput
          source="formations"
          label={ availableFormations[localCenter?.id]?.label ?? availableFormations['default'].label }
          choices={ availableFormations[localCenter?.id]?.options ?? availableFormations['default'].options }
          row={false}
        />
      </FormCard>

      <FormCard title="Brochures et dossiers d'inscription">
        <Typography variant="body2" mb={4}><i>Importez les documents à envoyer à vos prospects.</i></Typography>

        <Grid container spacing={5}>
          {documentListChoices.map(doc => (
            <Grid item sm={4} key={doc.id}>
              <Typography variant="body2"><b>{ getDocumentsNameById(record.center_id, doc.id) }</b> :</Typography>

              { ! uploadStatus[doc.id] ?
                <>
                  <FileInput
                    source={`pdf_upload_${doc.id}`}
                    label="Fichier PDF (max. 15MO)"
                    accept="application/pdf"
                    maxSize={16000000} // 16MO
                  >
                    <FileField source="src" title="title" />
                  </FileInput>

                  { bucketDocuments.length > 0 && bucketDocuments.find(d => d.docId === doc.id) && (
                    <Typography variant="body2" sx={{ display: 'flex', alignContent: 'center' }}>
                      <span style={{ color: 'darkgreen' }}>Document déjà uploadé :</span>
                      <Link to={ bucketDocuments.find(d => d.docId === doc.id).documentUrl } target="_blank" rel="noreferrer" ml={1}>Voir</Link>
                      <HighlightOffIcon
                        color="error"
                        sx={{ marginLeft: 2, fontSize: '1.2rem', cursor: 'pointer' }}
                        onClick={() => removeDocument(doc.id)}
                      />
                    </Typography>
                  ) }
                </>
              : <Typography variant="body2" mt={2}><i>{uploadStatus[doc.id]}</i></Typography> }
            </Grid>
          ))}
        </Grid>
      </FormCard>
    </SimpleForm>
  )
}