import React, { useEffect, useState } from 'react';
import {
  Datagrid, FunctionField, List, ReferenceField, SearchInput,
  useNotify, useRefresh, useUnselectAll,
} from "react-admin";
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { supabaseClient } from '../supabase';
import { CommonDialog } from "../common/Dialog";

const renderFullName = record => `${record.first_name} ${record.last_name} | ${record.email} ${record.phone}`;

const useUnassociatedParents = (leadId, searchText) => {
    const [parents, setParents] = useState([]);

    useEffect(() => {
        const fetchUnassociatedParents = async () => {
            const { data, error } = await supabaseClient.rpc('get_unassociated_parents', { 
                p_lead_id: leadId, 
                p_search_text: searchText
            });

            if (error) {
                console.error('Error fetching parents:', error);
            } else {
                setParents(data);
            }
        };

        fetchUnassociatedParents();
    }, [leadId, searchText]);

    return parents;
}

const handleAction = async (actionFunc, leads, notify, unselectAll, refresh, closeDialog) => {
    try {
        await actionFunc(leads);
        notify('Rattachement réussi', { type: 'success' });
        unselectAll();
        refresh();
        closeDialog();
    } catch {
        notify('Erreur lors de l\'action', 'warning');
    }
}

const ParentListFilter = ({ setSearchText }) => (
    <Box width="15em" order="-1" marginRight="1em">
        <SearchInput source='parents' onChange={e => setSearchText(e.target?.value)} />
    </Box>
);

const ParentAssignationContent = ({ leadId, setOpen }) => {
    const [searchText, setSearchText] = useState("");
    const parents = useUnassociatedParents(leadId, searchText);
  
    return (
        <List
            aside={<ParentListFilter setSearchText={setSearchText} />}
            pagination={false}
            empty={<p>Aucun parent</p>}
            exporter={false}
        >
            <Datagrid
                data={parents}
                ids={parents.map(parent => parent.id)}
                bulkActionButtons={<AssignParentButton leadId={leadId} setOpenDialog={setOpen} />}
            >
                <FunctionField label="Prénom / Nom" render={renderFullName} />
            </Datagrid>
        </List>
    );
};

const assignParentToLead = async (leadId, parentIds) => {
    const { error } = await supabaseClient.from('lead_parents').insert(parentIds.map(parentId => ({
        parent_id: parentId,
        lead_id: leadId
    })));
  
    if (error) {
        console.error('Error inserting parent-lead relation:', error);
    }
}

const unassignParentFromLead = async (parentId, leadId) => {
    const { error } = await supabaseClient.from('lead_parents').delete().match({
      lead_id: leadId,
      parent_id: parentId
    });
  
    if (error) {
      console.error('Error attaching lead from parent:', error);
      throw error;
    }
}

const AssignParentButton = ({ leadId, setOpenDialog, ...props }) => {
    const notify = useNotify();
    const unselectAll = useUnselectAll('leads');
    const refresh = useRefresh();

    return (
        <Button
            startIcon={<AddIcon />}
            size="large"
            color="primary"
            onClick={() => {
                handleAction(() => assignParentToLead(leadId, props.selectedIds), props.selectedIds, notify, unselectAll, refresh, () => setOpenDialog(false))
            }}
        >
            Rattacher
        </Button>
    );
};

const UnassignButton = ({ parentId, leadId, refresh }) => {
    const notify = useNotify();
  
    const handleClick = async () => {
      try {
        await unassignParentFromLead(parentId, leadId);
        notify('Détachement réussi', { type: 'success' });
        refresh();
      } catch {
        notify('Erreur lors du détachement', { type: 'warning' });
      }
    };
  
    return (
      <Button
        size="small"
        color="secondary"
        onClick={handleClick}
      >
        Détacher
      </Button>
    );
};

const AssignationDialog = ({ open, setOpen, parentId, ...props }) => (
    <CommonDialog open={open} handleClose={() => setOpen(false)} title={`Rattachement de parents`}>
      <ParentAssignationContent leadId={parentId} setOpen={setOpen} {...props} />
    </CommonDialog>
);

export const ParentAssignationDialogButton = ({ record, ...props }) => {
    const [open, setOpen] = useState(false);
  
    return (
        <>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Rattacher des parents
            </Button>
            <AssignationDialog open={open} setOpen={setOpen} parentId={record?.id} {...props} />
        </>
    );
}

/*export const AssociatedParentsList = ({ record }) => {
    const refresh = useRefresh();
  
    return (
        <List
            title="Parents rattachés"
            empty={<p>Aucun parent rattaché à ce contact</p>}
            resource="lead_parents"
            filter={{ lead_id: record?.id }}
        >
            <Datagrid>
                <ReferenceField label="Prénom / Nom" source="parent_id" reference="parents">
                    <FunctionField render={renderFullName} />
                </ReferenceField>
                <FunctionField 
                    label="Action"
                    render={parentRecord => (
                        <UnassignButton leadId={record?.id} parentId={parentRecord.parent_id} refresh={refresh} />
                    )}
                />
            </Datagrid>
        </List>
    );
};*/

export const AssociatedParentsList = ({ record }) => {
  const refresh = useRefresh();
  const [listParents, setListParents] = useState([]);
  const [changeAction, setChangeAction] = useState(false);
  
  useEffect(() => {
    const fetchParentsData = async () => {
      try {
        const leadParents = await supabaseClient
        .from('lead_parents')
        .select('parent_id')
        .eq('lead_id', record?.id);

        const parentIds = leadParents.data.map(item => item.parent_id);

        const { data, error } = await supabaseClient
        .from('parents')
        .select('id, first_name, last_name')
        .in('id', parentIds);

        if (error) {
            console.error('Error fetching parents:', error);
        }

        setListParents(data)
      } catch (error) {
        console.error('Error fetching parent data:', error);
      }
    }

    fetchParentsData()
  }, [record?.id, changeAction])
  
  return listParents?.length === 0
    ? ( <p>Aucun parent rattaché à ce contact</p> )
    : ( listParents.map((parent, index) => {
        if ( ! parent.first_name && ! parent.last_name ) return
      
        return (
        <div
          id={`parent${index}`}
          key={index}
          style={{
            padding: '2px 10px',
            borderLeft: '10px solid #ececec',
          }}
        >
          { parent?.first_name +' '+ parent?.last_name }

          <UnassignButton leadId={record?.id} parentId={parent.id} refresh={refresh} />
        </div>
      )}) )
  }
