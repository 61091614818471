import { supabaseDataProvider } from 'ra-supabase-core'
import { supabaseClient } from './'

const baseDataProvider = supabaseDataProvider({
    instanceUrl: process.env.REACT_APP_SUPABASE_URL,
    apiKey: process.env.REACT_APP_SUPABASE_ANON_KEY,
    supabaseClient
})

export default baseDataProvider
