
import { ReferenceManyToManyInput } from '@react-admin/ra-relationships'
import { AutocompleteInput, DateInput, DateTimeInput, ReferenceInput, SimpleForm, TextInput, useGetIdentity, required, useEditContext, FunctionField, AutocompleteArrayInput, BooleanInput, TimeInput } from 'react-admin'
import { Grid, Typography } from '@mui/material'

import { getRole } from '../common/roles'
import CalendarEventsList from './CalendarEventsList'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { supabaseClient } from '../supabase'
import { useEffect, useState } from 'react'

export default {
  list: CalendarEventsList,
  icon: CalendarMonthIcon,
  options: { label: 'Mon calendrier' },
}

export const ContentEventsInput = props => {
  const record = useEditContext(props)
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')
  const isRespo = identity?.role?.grade >= getRole('responsable')
  const [leadInfo, setLeadInfo] = useState(null)

  const getLeadForEvent = (eventId) => {
    if ( ! eventId ) return

    const getLeadInfo = async () => {
      const { data, error } = await supabaseClient
        .from('lead_rdv')
        .select('lead: lead_id(first_name,last_name)')
        .eq('calendar_event_id', eventId)

      if (error) {
        console.error('Error getting lead for event', error)
        return
      }

      if ( ! data || data.length === 0 ) return
      
      setLeadInfo(data[0]?.lead)
    }

    getLeadInfo()
  }

    useEffect(() => {
      if (record) {
        getLeadForEvent(record?.record?.id)
      }
    }, [record])

  return (
    <SimpleForm {...props}>
      { record && isAdmin &&
        <ReferenceInput source="center_id" reference="centers">
          <AutocompleteInput
            label="Centre"
            optionText="name"
            fullWidth
          />
        </ReferenceInput>
      }

      <TextInput source="title" label="Titre" validate={[required()]} fullWidth />

      { record ? (
        <>
          {/* <BooleanInput source="allDay" label="Journée entière" defaultValue={false} fullWidth /> */}
          <DateTimeInput source="start" label="Début" fullWidth />
          <DateTimeInput source="end" label="Fin" fullWidth />
        </>
      ) : (
        <>
          <DateInput
            source="dateRDV"
            label="Date du RDV"
            variant="outlined"
            fullWidth
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TimeInput
                source="timeStart"
                label="Début du RDV"
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <TimeInput
                source="timeEnd"
                label="Fin du RDV"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </>
      )}
      
      { record?.record && isRespo &&
        <ReferenceInput source="profile_id" reference="profiles">
          <AutocompleteInput
            label="Pubeur responsable"
            optionText={(record) => `${record.first_name} ${record.last_name}`}
            filterToQuery={searchText => ({ 'first_name@ilike': `%${searchText}%` })}
            fullWidth
          />
        </ReferenceInput>
      }

      { record?.record?.id
        ? leadInfo
          ? <>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Lead associé</Typography>
              {`${leadInfo.first_name} ${leadInfo.last_name}`}
            </>
          : <ReferenceInput source="contact_id" reference="leads">
              <AutocompleteInput
                label="Contact associé"
                optionText={(record) => `${record.first_name} ${record.last_name}`}
                filterToQuery={searchText => ({ 'first_name@ilike': `%${searchText}%` })}
                filterOptions={{ 'center_id': identity?.center?.id }}
                fullWidth
              />
            </ReferenceInput>
        : <p>Créez votre événement pour pouvoir y associer un contact</p>
      }
    </SimpleForm>
  )
}