import {
  Datagrid,
  FunctionField,
  ReferenceManyField,
  TextField,
  Link,
  useGetList,
  Loading,
  useRecordContext,
} from 'react-admin'
import { Typography, Card } from '@mui/material'

import { FormCard } from '../Design/Cards'

const SchoolOldLeads = () => {
  const record = useRecordContext()
  const schoolId = record?.id

  // Fetch leads from 2023
  const { data: data2023, error: error2023 } = useGetList(
    'leads_2023',
    { page: 1, perPage: 100 }, // Adjust perPage as needed
    { field: 'id', order: 'ASC' },
  )

  // Fetch leads from 2022
  const { data: data2022, error: error2022 } = useGetList(
    'leads_2022',
    { page: 1, perPage: 100 }, // Adjust perPage as needed
    { field: 'id', order: 'ASC' },
  )

  if ( error2023 ) return <Typography variant="body2">Ooops... Impossible de charger les contacts 2023 : {error2023}</Typography>
  if ( error2022 ) return <Typography variant="body2">Ooops... Impossible de charger les contacts 2022 : {error2022}</Typography>

  const filteredData2023 = data2023?.filter (
    record => record.lead_qualification_id !== null &&
    record.lead_qualification_id !== '' &&
    record.school_id === schoolId
  )

  const filteredData2022 = data2022?.filter (
    record => record.lead_qualification_id !== null &&
    record.lead_qualification_id !== '' &&
    record.school_id === schoolId
  )

  let filteredData = [
    { annee: '2023/2024', data: filteredData2023 },
    { annee: '2022', data: filteredData2022 }
  ]

  return filteredData.map ( filteredData => (
    <FormCard title={`Anciens contacts ${filteredData.annee} inscrits du lycée`}>
      <Datagrid
        bulkActionButtons={false}
        data={filteredData.data}
        sort={{ field: 'id', order: 'ASC' }}
        empty={`Aucun contact pour ${filteredData.annee}`}
      >
        <FunctionField
          label="Contact"
          render={record => (
            <Link to={`#/leads/${record.id}`}>{`${record.first_name} ${record.last_name}`}</Link>
          )}
        />
        <TextField label="Téléphone" source="phone" />
        <TextField label="Adresse mail" source="email" />
      </Datagrid>
    </FormCard>
  ) )
}

const SchoolOldStudents = props => (
  <>
    <FormCard title="Anciens pubeurs du lycée">
      <ReferenceManyField
        label={false}
        reference="profiles"
        target="from_school"
      >
        <Datagrid bulkActionButtons={false}>
          <FunctionField
            label="Contact"
            render={record => (
              <Link to={`#/leads/${record.id}`}>{`${record.first_name} ${record.last_name}`}</Link>
            )}
          />

          <TextField label="Téléphone" source="phone" />
          <TextField label="Adresse mail" source="email" />
        </Datagrid>
      </ReferenceManyField>
    </FormCard>
    
    <SchoolOldLeads />
  </>
)

export default SchoolOldStudents