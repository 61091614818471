/*
  * Function to lighten or darken a hex color
  * @param {string} hex - Hex color code
  * @param {number} percent - Percentage to lighten or darken
  * @returns {string} - Lightened or darkened hex color code
  * @example
  * lighterColor('#FF0000', 20) // returns 'ff3333'
*/

import { color, rgbaToHexa } from '@uiw/color-convert'

function lighterColor (hex, percent = 50) {
  if ( ! hex ) return null

  // Convert hex to HSV
  const { rgb } = color(hex);
  const { r, g, b } = rgb

  // Lighten the color by the given percentage
  let colorR = Math.round((r * (100 + percent)) / 100)
  let colorG = Math.round((g * (100 + percent)) / 100)
  let colorB = Math.round((b * (100 + percent)) / 100)

  // Clamp the values to the range 0-255
  colorR = Math.min(colorR, 255)
  colorR = Math.max(colorR, 25)
  colorG = Math.min(colorG, 255)
  colorG = Math.max(colorG, 25)
  colorB = Math.min(colorB, 255)
  colorB = Math.max(colorB, 25)

  const newColor = {
    r: colorR,
    g: colorG,
    b: colorB,
    a: .35
  }

  return rgbaToHexa(newColor).replace('#', '')
}

export default lighterColor