import React, { createContext, useState, useContext } from 'react'

const ProfileContext = createContext()

export const ProfileProvider = ({ children }) => {
  const [roleGrade, setRoleGrade] = useState(null)

  return (
    <ProfileContext.Provider value={{ roleGrade, setRoleGrade }}>
      {children}
    </ProfileContext.Provider>
  )
}

export const useProfile = () => useContext(ProfileContext)