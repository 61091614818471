import {
  CreateButton,
  TopToolbar,
  useListContext,
  Button,
  SelectColumnsButton,
} from 'react-admin'
import { IfCanAccess } from '@react-admin/ra-rbac'

import TasksTypes from './TasksTypes'

import CancelIcon from '@mui/icons-material/Cancel'
import ListIcon from '@mui/icons-material/ViewList'
import GridOnIcon from '@mui/icons-material/GridOn'
import AddCircleIcon from '@mui/icons-material/AddCircle'

const TasksTopToolbar = () => {
  const { sort, filterValues, setSort, setFilters } = useListContext()

  return (
    <TopToolbar sx={{
        minHeight: '60px',
        alignItems: 'center !important',
      }}
    >
      { Object.keys(filterValues).length > 0 && (
        <Button color="error" label="filtres" onClick={() => setFilters({})}><CancelIcon /></Button>
      )}

      { ( sort.field !== 'created_at' || sort.order !== 'DESC' ) && (
        <Button color="error" label="triage" onClick={() => setSort({ field: 'created_at', order: 'DESC' })}><CancelIcon /></Button>
      )}

      <IfCanAccess action="create">
        <CreateButton icon={<AddCircleIcon />} label="Créer une tâche" />
        <TasksTypes />
      </IfCanAccess>

      {/* <SelectColumnsButton /> */}
    </TopToolbar>
  )
}

export default TasksTopToolbar