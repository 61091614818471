import { defaultTheme } from 'react-admin'

const baseColor = '#d7eae8'

const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    RaCreateButton: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .RaDatagrid-headerCell': {
            backgroundColor: '#f2efef',
          },
        }
      }
    },
    /*MuiPaper: {
      styleOverrides: {
        root: {
          // background: 'transparent',
          // boxShadow: 'none',
          // border: 'none',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          margin: '20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
          borderRadius: '8px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexGrow: 1, // Allow the cell to grow to take up available space
          flexShrink: 0, // Prevent the cell from shrinking too much
          flexBasis: '10px', // Set an initial size based on the content
          justifyContent: 'flex-start',
          alignItems: 'center',
          alignContent: 'center',
          borderBottom: 'none',
        },
      },
    },*/
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: baseColor,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          // Default styles for all tabs can go here
          '& .Mui-selected': {
            backgroundColor: '#edf7f6',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === 'customPrimary' && {
            backgroundColor: theme.palette.customPrimary.main,
            color: '#fefefe',
            '&:hover': {
              backgroundColor: theme.palette.customPrimary.dark,
            },
          }),
        }),
      },
    },
  },
  typography: {
    ...defaultTheme.typography,
    fontFamily: 'Poppins, Arial, sans-serif', // Set Poppins as the default font
    h6: { fontWeight: 600, color: '#444' },
    h5: { fontWeight: 600, color: '#444' },
    h4: { fontWeight: 700, color: '#333' },
    h3: { fontWeight: 700, color: '#333' },
    h2: { fontWeight: 800, color: '#222' },
    h1: { fontWeight: 800, color: '#222' },
  },
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#1c94bc',
    },
    secondary: {
      main: '#c5dedd',
    },
    info: {
      main: '#333'
    },
    customPrimary: {
      main: '#80afbc',
      light: '#abcad3',
      dark: '#51a0ba'
    },
  },
}

export default theme