import { CreateDialog } from '@react-admin/ra-form-layout'
import { TagsInputContent } from './index'

const TagsCreate = props => (
  <CreateDialog
    title="Nouveau tag"
    maxWidth="md"
    fullWidth
    {...props}
  >
    <TagsInputContent action="create" {...props} />
  </CreateDialog>
)

export default TagsCreate;
