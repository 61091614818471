import { createContext, useState, useContext } from 'react'

const ServiceWorkerContext = createContext()

export const ServiceWorkerProvider = ({ children }) => {
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false)

  const updateAssets = () => {
    console.log('Updating assets')
    
    if (isUpdateAvailable) {
      window.location.reload()
    }
  }

  return (
    <ServiceWorkerContext.Provider value={{ isUpdateAvailable, setIsUpdateAvailable, updateAssets }}>
      {children}
    </ServiceWorkerContext.Provider>
  )
}

export const useServiceWorker = () => useContext(ServiceWorkerContext)
