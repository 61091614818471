import { DropzoneArea } from 'mui-file-dropzone'
import { Typography, Box } from '@mui/material'

const FileUpload = ({ action = console.log }) => {
  const handleChange = (files) => {
    action(files) // Pass files to the action (handleFileUpload)
  }

  return (
    <Box sx={{ padding: '20px', textAlign: 'left', borderRadius: '5px' }}>
      <Typography variant="body1" style={{ marginBottom: '20px' }}>
        Importer des captures d'écran (iPhone uniquement) ou une conversation PDF (iPhone and Android)
      </Typography>

      <Typography variant="body2" style={{ marginBottom: '10px' }}>
        · Vous n'avez pas besoin de rogner vos captures d'écran. Simplement envoyez-les telles qu'elles.
      </Typography>

      <Typography variant="body2" style={{ marginBottom: '10px' }}>
        · N'envoyez qu'une seule conversation PDF par contact !
      </Typography>

      <DropzoneArea
        onChange={handleChange}
        acceptedFiles={['image/jpeg', 'image/png', 'application/pdf']}
        maxFileSize={5000000} // 5MB limit
        filesLimit={40} // Limit number of files
        showFileNames // Display file names
      />
    </Box>
  )
}

export default FileUpload
