import {
    required,
    Create,
    SimpleForm,
    TextInput,
} from 'react-admin';

const LeadSourceCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <TextInput source="name" validate={[required()]} fullWidth autoFocus />
            </SimpleForm>
        </Create>
    );
};

export default LeadSourceCreate;
