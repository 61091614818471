import {
  useGetIdentity,
  AutocompleteInput,
  DateField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  List,
  TextField,
  DatagridConfigurable,
  Loading,
  FunctionField
} from 'react-admin'

import TagsEdit from './TagsEdit'
import TagsCreate from './TagsCreate'
import { getRole } from '../common/roles'
import TagsTopToolbar from './TagsTopToolbar'
import TagsFiltersBar from './TagsFiltersBar'
import { TagsListFilter } from './TagsSideFilters'
import { PostPagination } from '../common/Pagination'
import { TextFieldCopy } from '../Design/CustomTextField'

import CircleIcon from '@mui/icons-material/Circle';

const TagsList = props => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')

  return identity?.role?.grade ? (
    <>
      <List
        {...props}
        empty={false}
        actions={<TagsTopToolbar {...props} />}
        aside={ isAdmin ? <TagsListFilter /> : null}
        filters={TagsFiltersBar}
        pagination={<PostPagination />}
        perPage={50}
        sort={{ field: 'created_at', order: 'DESC' }}
      >
        <DatagridConfigurable
          rowClick="edit"
          omit={ ['created_at', 'center_id'] }
        >
          <TextField source="name" label="Intitulé" />

          <FunctionField label="Couleur" sx={{ maxWidth: '30px' }} render={record => <CircleIcon sx={{ fontSize: '1rem', color: `#${record?.color ?? 'fefefe'}` }} />} />

          <TextFieldCopy label="ID" source="id" />

          <ReferenceField label="Centre" source="center_id" reference="centers" link={false}>
            <TextField source="name"/>
          </ReferenceField>

          <DateField label="Date de création" source="created_at" />
        </DatagridConfigurable>
      </List>
      
      <TagsEdit />
      <TagsCreate />
    </>
  ) : <Loading />
}

export default TagsList