import baseDataProvider from './baseDataProvider'
import { dataProvider as centersDataProvider } from '../centers/dataProvider'
import { dataProvider as eventsDataProvider } from '../events/dataProvider'
import { dataProvider as leadsDataProvider } from '../leads/dataProvider'
import { dataProvider as profilesDataProvider } from '../profiles/dataProvider'
import { dataProvider as schoolsDataProvider } from '../schools/dataProvider'
import { dataProvider as calendarDataProvider } from '../calendar/dataProvider'
import { dataProvider as tasksDataProvider } from '../tasks/dataProvider'
import { dataProvider as inboundContactsDataProvider } from '../inboundContacts/dataProvider'

const dataProvidersByResource = {
  'centers': centersDataProvider,
  'events': eventsDataProvider,
  'leads': leadsDataProvider,
  'leads_view': leadsDataProvider,
  'profiles': profilesDataProvider,
  'schools': schoolsDataProvider,
  'calendar': calendarDataProvider,
  'tasks': tasksDataProvider,
  'inbound_contacts': inboundContactsDataProvider,
}

const defaultHandler = {
  get: (target, property) => {
    return (resource, params) => {
      const dataProvider = dataProvidersByResource[resource] || baseDataProvider
      const method = dataProvider[property]

      if (!method) {
        throw new Error(`Unknown dataProvider function: ${property}`)
      }

      return method.call(dataProvider, resource, params)
    }
  }
}

export default new Proxy({}, defaultHandler);