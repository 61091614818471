import * as React from 'react'
import {
  Tab,
  TabbedShowLayout,
} from 'react-admin'
import { EditDialog } from '@react-admin/ra-form-layout'

import { TagsInputContent } from './index'


const TagsEdit = props => (
  <EditDialog
    title="Fiche tâche"
    maxWidth="md"
    fullWidth
    {...props}
  >
    <TabbedShowLayout style={{ minHeight: '90vh' }}>
      <Tab label="Informations" name="informations">
        <TagsInputContent />
      </Tab>
    </TabbedShowLayout>
  </EditDialog>
)

export default TagsEdit
