import { useEffect, useState } from 'react'
import { useRecordContext } from 'react-admin'
import { Typography, Table, TableHead, TableRow, TableBody, TableCell, Paper } from '@mui/material'

import { FormCard } from '../Design/Cards'
import { supabaseClient } from '../supabase'

const SchoolMissions = () => {
  const record = useRecordContext()
  const [rapports, setRapports] = useState([])

  useEffect(() => {
    const fetchRaports = async () => {
      const { data, error } = await supabaseClient
        .from('school_reporting')
        .select('*, event: events(*)')
        .eq('school_id', record.id)
        .order('created_at', { ascending: false })
      
      if ( error ) {
        console.error('Error fetching missions:', error.message)
        return
      }

      setRapports(data)
    }

    fetchRaports()
  }, [record])

  return (
    <>
      <Typography color="textSecondary" variant="h6" mt={2}>
        Rapports des missions effectuées
      </Typography>

      <FormCard>
        {rapports?.map(mission => (
          <Paper key={mission.id} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Mission</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>Date</TableCell>
                </TableRow>
              </TableHead>
              
              <TableBody>
                <TableRow key={mission.id}>
                  <TableCell sx={{ verticalAlign: 'top' }}>{mission.event?.name}</TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }} dangerouslySetInnerHTML={{ __html: mission.rapport }} />
                  <TableCell sx={{ verticalAlign: 'top' }}>{new Date(mission.created_at).toLocaleDateString()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        ))}
      </FormCard>
    </>
  );
};

export default SchoolMissions;
