import { useState } from 'react'
import {
  useGetIdentity,
  DateField,
  ReferenceField,
  List,
  TextField,
  DatagridConfigurable,
} from 'react-admin'

import TaskCard from './TaskCard'
import TasksEdit from './TasksEdit'
import TasksCreate from './TasksCreate'
import { getRole } from '../common/roles'
import TasksGridLayout from './TasksGridLayout'
import TasksTopToolbar from './TasksTopToolbar'
import TasksFiltersBar from './TasksFiltersBar'
import { TasksListFilter } from './TasksSideFilters'
import CustomPagination from '../Design/CustomPagination'

const TasksList = props => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')

  const [isGridView, setIsGridView] = useState(true)
  const toggleView = () => {
    setIsGridView((prevView) => !prevView)
  }

  return (
    <>
      <List
        {...props}
        empty={false}
        actions={<TasksTopToolbar />}
        aside={ isAdmin ? <TasksListFilter /> : null}
        filters={TasksFiltersBar}
        pagination={<CustomPagination isGridView={isGridView} toggleView={toggleView} />}
        perPage={50}
        sort={{ field: 'created_at', order: 'DESC' }}
        sx={isGridView && {
          '& .MuiPaper-root': {
            backgroundColor: '#e1edec',
            padding: '1vw',
          },
        }}
      >
        {isGridView ? (
          <TasksGridLayout>
            {record => (
              <TaskCard record={record} />
            )}
          </TasksGridLayout>
        ) : (
          <DatagridConfigurable
          rowClick="edit"
          omit={ ['created_at', 'center_id'] }
        >
          <TextField source="name" labe="Intitulé" />

          <ReferenceField label="Type de tâche" source="type_id" reference="tasks_types">
            <TextField source="name" />
          </ReferenceField>

          <ReferenceField label="Centre" source="center_id" reference="centers" link={false}>
            <TextField source="name"/>
          </ReferenceField>

          <DateField label="Date de début" source="start_at" />
          <DateField label="Date de fin" source="end_at" />
          <DateField label="Date de création" source="created_at" />
        </DatagridConfigurable>
        )}
      </List>
      
      <TasksEdit />
      <TasksCreate />
    </>
  )
}

export default TasksList