import React from 'react'
import { Pagination, Button } from 'react-admin'
import { Box } from '@mui/material'

import GridOnIcon from '@mui/icons-material/GridOn'
import ListIcon from '@mui/icons-material/ViewList'

const CustomPagination = ({ isGridView, toggleView, ...props }) => {
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" paddingTop="16px">
      <Button
        label={isGridView ? 'Liste' : 'Grille'}
        onClick={toggleView}
        startIcon={isGridView ? <ListIcon /> : <GridOnIcon />}
      />
      
      { !isGridView && <Pagination {...props} /> }
    </Box>
  )
}

export default CustomPagination