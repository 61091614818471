import { useEffect, useState } from 'react'
import { useGetIdentity } from 'react-admin'
import { FormControl, Select as MUIselect, InputLabel, MenuItem, Stack, Container, Box, Tab, Tabs, Typography } from '@mui/material'

import TasksList from './TasksList'
import EventsList from './EventsList'
import SettingSmic from './SettingSmic'
import WeeklyLeads from './WeeklyLeads'
import LeadsInscrits from './LeadsInscrits'
import { getRole } from '../../common/roles'
import { supabaseClient } from '../../supabase'
import fetchEvents from '../../common/fetchEvents'
import DashboardRespoPaies from './DashboardRespoPaies'

const CustomTabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default () => {
  const { identity } = useGetIdentity()

  const lastMonday = new Date()
  lastMonday.setHours(0, 0, 0, 0)
  lastMonday.setDate(lastMonday.getDate() - (lastMonday.getDay() + 6) % 7)

  const firstOfTheMonth = new Date()
  firstOfTheMonth.setHours(0, 0, 0, 0)
  firstOfTheMonth.setDate(1)

  const firstDayOfTheYear = new Date()
  firstDayOfTheYear.setHours(0, 0, 0, 0)
  firstDayOfTheYear.setMonth(7, 1)

  const [filteredDate, setFilteredDate] = useState(lastMonday.toISOString())

  const handleChangeDate = (event) => {
    setFilteredDate(event.target.value)
  }

  const [value, setValue] = useState(0)
  const [numberOfNewLeads, setNumberOfNewLeads] = useState(0)
  const [numberOfLeadsInscrits, setNumberOfLeadsInscrits] = useState(0)
  const [tasksInfo, setTasksInfo] = useState(null)
  const [eventsInfo, setEventsInfo] = useState(null)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect (() => {
    const fetchNewLeads = async () => {
      const { data, error } = await supabaseClient
        .from('leads_view')
        .select('id')
        .gte('created_at', filteredDate)

      if (error) {
        console.error(error)
        return null
      }

      setNumberOfNewLeads(data.length)
    }

    const fetchLeadsInscrits = async () => {
      const { data, error } = await supabaseClient
        .from('leads_view')
        .select('id')
        .gte('created_at', filteredDate)
        .neq('status_inscription_id', 'f200316c-8ffc-4a3c-bf01-4efe6aad8d81') // leads inscrits

      if (error) {
        console.error(error)
        return null
      }

      setNumberOfLeadsInscrits(data.length)
    }

    const fetchTasksInfo = async () => {
      let query = supabaseClient
        .from('lead_tasks')
        .select('id, progress, lead: lead_id (center_id, current_year_id), task: task_id (name)')
      
      if ( identity?.role?.grade === getRole('admin') ) {
        query = query.eq('lead.current_year_id', process.env.REACT_APP_CURRENT_YEAR_ID)
      } else {
        query = query.eq('lead.current_year_id', process.env.REACT_APP_CURRENT_YEAR_ID)
        query = query.eq('lead.center_id', identity?.center?.id)
      }

      const { data, error } = await query

      if (error) {
        console.error(error)
        return
      }

      const cleanDate = data.filter(task => task.lead !== null && task.task !== null)
      
      setTasksInfo(cleanDate)
    }

    const fetchAllEvents = async () => {
      // fetchEvents(centerId, identity, dateFilter = false, catId = null, yearId = null)
      const dataEvents = await fetchEvents(identity?.center?.id, identity, filteredDate, null, process.env.REACT_APP_CURRENT_YEAR_ID)

      const filteredData = dataEvents.filter(
        event => event.type_id === process.env.REACT_APP_EVENT_TYPE_MISSIONLYCEE || event.type_id === process.env.REACT_APP_EVENT_TYPE_INTERVENTION
      )

      setEventsInfo(filteredData)
    }

    fetchNewLeads()
    fetchLeadsInscrits()
    fetchTasksInfo()
    fetchAllEvents()
  }, [filteredDate])

  return (
    <Container sx={{ mt: 4, fontSize: '1rem' }}>
      <Stack spacing={2} direction="row" flex="1" alignItems="center" justifyContent="space-between" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Mon tableau de bord
        </Typography>

        <Box>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Vue générale" {...a11yProps(0)} />
            <Tab label="Paies pubeurs" {...a11yProps(1)} />
            <Tab label="Réglages paies" {...a11yProps(2)} />
          </Tabs>
        </Box>
      </Stack>
      
      <CustomTabPanel value={value} index={0}>
        <>
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Box sx={{ width: '20%' }}>
              <FormControl variant="outlined" sx={{ m: .5, minWidth: '100%' }}>
                <InputLabel id="monthLabel">Voir depuis</InputLabel>
                <MUIselect
                  labelId="monthLabel"
                  id="select-monthLabel"
                  value={filteredDate}
                  variant="outlined"
                  onChange={handleChangeDate}
                  label="Voir depuis"
                >
                  <MenuItem value={lastMonday.toISOString()}>Cette semaine</MenuItem>
                  <MenuItem value={firstOfTheMonth.toISOString()}>Mois en cours</MenuItem>
                  <MenuItem value={firstDayOfTheYear.toISOString()}>Année en cours</MenuItem>
                </MUIselect>
              </FormControl>
            </Box>
          </Stack>

          <Stack spacing={2} mt={2} direction="row">
            <Stack spacing={2} mt={2} direction="column" sx={{ width: '33.3333%' }}>
              <WeeklyLeads value={numberOfNewLeads} />
              <LeadsInscrits value={numberOfLeadsInscrits} />
            </Stack>

            <TasksList tasksInfo={tasksInfo} />
            <EventsList eventsInfo={eventsInfo} date={filteredDate} />
          </Stack>
        </>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <DashboardRespoPaies />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <Box sx={{ p: 4 }}>
          <SettingSmic />
        </Box>
      </CustomTabPanel>
    </Container>
  )
}