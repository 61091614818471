import { useEffect, useState } from 'react'
import {
  useRefresh,
  useNotify,
  useUnselectAll,
  useListContext,
} from 'react-admin'

import { supabaseClient } from '../../supabase'
import toTimeZone from '../../common/toTimeZone'
import { CustomSelectInput } from '../../Design/CustomSelectInput'

const StatusAssignation = () => {
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll('leads')
  const { selectedIds: leads } = useListContext()

  const [qualificationsOptions, setQualificationsOptions] = useState([])

  const handleQualificationAction = async (chunk, qualification) => {
    const { data, error } = await supabaseClient
      .from('leads')
      .update({
        status_inscription_id: qualification === '9999' ? null : qualification,
        updated_at: toTimeZone(new Date(), 'Europe/Paris') ?? null
      })
      .in('id', chunk)
  
    if (error) {
      console.error('Error updating qualifications: ', error)
      return
    }
  
    notify(qualification === '9999' ? `Qualification supprimée` :  `Qualification mise à jour`, {'type' : 'success'})
    unselectAll()
    refresh()
  }

  const saveQualifications = (qualification) => {
    if ( ! qualification ) return

    const chunkSize = 100
    const chunks = []

    for ( let i = 0; i < leads?.length; i += chunkSize ) {
      chunks.push(leads.slice(i, i + chunkSize))
    }

    chunks.forEach(chunk => {
      handleQualificationAction(chunk, qualification)
    })
  }

  useEffect(() => {
    const getQualificationsOptions = async () => {
      const { data, error } = await supabaseClient
        .from('qualifications')
        .select('id, name')
        .order('name', { ascending: true })

      if (error) {
        console.error('Error fetching qualifications: ', error)
        return
      }

      let optionsQualifications = []
      data.map(row => {
        const qualification = {
          label: row.name,
          value: row.id,
        }

        optionsQualifications.push(qualification)
      })

      const sansQualification = {
        label: 'Sans statut inscrit',
        value: '9999',
      }

      optionsQualifications.push(sansQualification)

      setQualificationsOptions(optionsQualifications)
    }

    getQualificationsOptions()
  }, [])

  return (
    <CustomSelectInput
      id="selectedQualifications"
      label="Statut inscrit"
      options={qualificationsOptions}
      onChange={(event, newValue) => saveQualifications(newValue?.value ?? null)}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': { border: '1px solid #ccc' },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': { fontSize: '.9rem' },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall.MuiAutocomplete-input': { padding: '0' },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': { padding: '2', width: '200px' },
      }}
    />
  )
}

export default StatusAssignation