import { useState } from 'react'
import {
  useGetIdentity,
  AutocompleteInput,
  CreateButton,
  DateField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  TextField,
  TopToolbar,
  useListContext,
  Button,
  SelectColumnsButton,
  DatagridConfigurable,
  NumberField,
} from 'react-admin'
import { List, IfCanAccess } from '@react-admin/ra-rbac'

import EventCard from './EventCard'
import EventEdit from './EventEdit'
import EventCreate from './EventCreate'
import { getRole } from '../common/roles'
import EventsGridLayout from './EventsGridLayout'
import { EventListFilter } from './EventListFilter'
import { TextFieldCopy } from '../Design/CustomTextField'
// import CustomPagination from '../Design/CustomPagination'
import { filterProfileBlankSpaces } from '../common/customTextFilter'

import CancelIcon from '@mui/icons-material/Cancel'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { PostPagination } from '../common/Pagination'

const EventList = props => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')
  const isRespo = identity?.role?.grade >= getRole('responsable')
  const isPubeur = identity?.role?.grade < getRole('responsable')

  const LeadActions = () => {
    const { sort, filterValues, setSort, setFilters } = useListContext()

    return (
      <TopToolbar sx={{
          minHeight: '60px',
          alignItems: 'center !important',
        }}
      >
        { Object.keys(filterValues).length > 0 && (
          <Button color="error" label="filtres" onClick={() => setFilters({})}><CancelIcon /></Button>
        )}

        { ( sort.field !== 'created_at' || sort.order !== 'DESC' ) && !isPubeur && (
          <Button color="error" label="triage" onClick={() => setSort({ field: 'created_at', order: 'DESC' })}><CancelIcon /></Button>
        )}

        <IfCanAccess action="create">
          <CreateButton icon={<AddCircleIcon />} label="Créer un événement" />
          <SelectColumnsButton />
        </IfCanAccess>
      </TopToolbar>
    )
  }

  const eventFilters = [
    <SearchInput placeholder="Nom" source="name@ilike" variant="outlined" sx={{ maxWidth: '200px' }} alwaysOn resettable />,

    <ReferenceInput
      reference="types"
      source="type_id"
      alwaysOn
    >
      <AutocompleteInput
        label="Type"
        variant="outlined"
        optionText="name"
        filterToQuery={searchText => ({ 'name@ilike': searchText })}
      />
    </ReferenceInput>,

    <ReferenceInput
      reference="lead_years"
      source="current_year_id"
      alwaysOn
    >
      <AutocompleteInput
        label="Année scolaire"
        variant="outlined"
        optionText="name"
        filterToQuery={searchText => ({ 'name@ilike': searchText })}
      />
    </ReferenceInput>,
  ]

  return (
    <>
      <List
        {...props}
        empty={false}
        actions={<LeadActions {...props} />}
        aside={ isAdmin ? <EventListFilter /> : null }
        filters={!isPubeur ? eventFilters : null}
        pagination={<PostPagination />}
        perPage={50}
        sort={ isPubeur ? { field: 'date', order: 'ASC' } : { field: 'created_at', order: 'DESC' }}
        sx={isPubeur && {
          '& .MuiPaper-root': {
            backgroundColor: '#e1edec',
            padding: '1vw',
          },
        }}
      >
        { isPubeur ? (
          <EventsGridLayout>
            {record => (
              <EventCard record={record} />
            )}
          </EventsGridLayout>
        ) : (
          <DatagridConfigurable
            omit={ isRespo ?  ['created_at', 'center_id'] : ['created_at'] }
            rowClick={`${identity?.role.grade <= getRole('pubeur') ? 'show': 'edit'}`}
            bulkActionButtons={false}
          >
            <ReferenceField label="Type" source="type_id" reference="types">
              <TextField source="name" />
            </ReferenceField>

            <TextField label="Nom" source="name" />

            <TextFieldCopy label="ID" source="id" />

            <ReferenceField label="Centre" source="center_id" reference="centers" link={false}>
              <TextField source="name"/>
            </ReferenceField>

            <NumberField label="Pubeurs" source="pubeurs_count" />

            <DateField label="Date de début" source="date" />
            <DateField label="Date de fin" source="end_at" />
            <DateField label="Date de création" source="created_at" showTime={true} />
          </DatagridConfigurable>
        )}
      </List>

      <EventEdit />
      <EventCreate />
    </>
  )
}

export default EventList