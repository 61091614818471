import { CardWithIcon } from '../../Design/Cards'

import PersonAddIcon from '@mui/icons-material/PersonAdd'

const LeadsInscrits = ({ value }) => (
  <div>
    <CardWithIcon
      to="/leads"
      icon={PersonAddIcon}
      title="Contacts inscrits"
      subtitle={value}
      color="#51cc5d"
    />
  </div>
)

export default LeadsInscrits