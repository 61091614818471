import { supabaseClient } from '../supabase'
import { getRole } from './roles'

const fetchEventsQualifications = async (identity, centerId, catId, currentYearId = process.env.REACT_APP_CURRENT_YEAR_ID) => {
  if ( ! centerId ) return []
  
  try {
    let query = supabaseClient
      .from('types_qualifications')
      .select('*, type_id: types(*)')
      // .eq('current_year_id', currentYearId)

    if ( centerId !== '0000' || identity?.role?.grade < getRole('admin') ) {
      query.eq('center_id', centerId)
    }

    if ( catId && ( catId !== '0000' || identity?.role?.grade < getRole('admin') ) ) {
      query.eq('type_id', catId)
    }

    const { data: eventsQualifications, error: eventsQualificationsError } = await query

    if (eventsQualificationsError) {
      console.error('Error: ', eventsQualificationsError)
      return
    }
    
    return eventsQualifications
  } catch (error) {
    console.error('Error: ', error)
    return []
  }
}

export default fetchEventsQualifications