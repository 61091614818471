import React, { useEffect, useState } from 'react'
import { Button, useGetIdentity, useNotify, useRefresh } from 'react-admin'
import { Grid, Typography, Chip } from '@mui/material'

import { supabaseClient } from '../supabase'

import { getRole } from '../common/roles'
import { CommonDialog } from '../common/Dialog'
import TextField from '@mui/material/TextField'

import Wheel from '@uiw/react-color-wheel'
import { hexToHsva, validHex, hsvaToHex } from '@uiw/color-convert'

import SettingsIcon from '@mui/icons-material/Settings'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const TasksTypes = () => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')
  const refresh = useRefresh()
  const notify = useNotify()

  const editColor = null
  const [hsva, setHsva] = useState(editColor ?? { h: 214, s: 43, v: 90, a: 1 })
  const hexColor = hsvaToHex(hsva)
  const [colorSelected, setColorSelected] = useState(false)

  const [openTypesDialog, setOpenTypesDialog] = useState(false)
  const [listOfTaskTypes, setListOfTaskTypes] = useState([])

  const fetchTasks = async () => {
    let query = supabaseClient
      .from('tasks_types')
      .select('*, center: center_id(id, name)')

    if ( ! isAdmin ) {
      query.eq('center_id', identity?.center?.id)
    }

    const { data, error } = await query
    
    if (error) {
      console.error('Error fetching tasks types', error)
      return
    }

    setListOfTaskTypes(data.map(type => (
      <Chip
        key={type.id}
        variant="contained"
        color="success"
        sx={{ mr: 1, mt: 1, backgroundColor: `${type.color}` }}
        label={ isAdmin ? `${type.name} (${type.center.name})` : type.name }
        onDelete={() => deleteTaskType(type.id)}
      />
    )) ?? `Aucun type n'a été créé pour le moment`)
  }

  useEffect(() => {
    if ( ! identity?.center?.id ) return

    fetchTasks()
  }, [identity])

  const deleteTaskType = async (typeId) => {
    const { error } = await supabaseClient
      .from('tasks_types')
      .delete()
      .eq('id', typeId)

    if (error) {
      console.error('Error deleting task type', error)
      notify('Une erreur est survenue', { type: 'error' })
      return
    }

    notify('Type de tâche supprimé', { type: 'success' })
    fetchTasks()
    refresh()
  }

  const addNewTaskType = () => {
    const typesName = document.getElementById('typesName').value

    const saveNewAction = async () => {
      const { error } = await supabaseClient
        .from('tasks_types')
        .insert({
          name: typesName,
          created_by: identity?.id ?? null,
          center_id: identity?.center?.id,
          color: hexColor,
        })
      
      if (error) {
        console.error('Error saving new task type', error)
        return
      }

      notify('Nouveau type de tâche créé', { type: 'success' })
      fetchTasks()
      refresh()
    }

    saveNewAction()
  }

  return (
    <>
      <CommonDialog
        open={openTypesDialog}
        handleClose={() => setOpenTypesDialog(false)}
        title="Gestion des types de tâche"
        size="sm"
      >
        <Typography variant="body1" color="textSecondary" sx={{ mt: 1, fontWeight: 'bold' }}>
          Créer un nouveau type de tâche :
        </Typography>

        <Typography variant="subtitle1" color="textSecondary" sx={{ mt: 2, mb: 1 }}>
          Les types de tâches sont des catégories pour mieux pour mieux organiser les tâches de votre centre.
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={10}>
            <TextField id="typesName" label="Nom du type de tâche" variant="outlined" fullWidth />
          </Grid>

          <Grid item xs={2}>
            <Button
              variant="outlined"
              sx={{ p: 1.1, mt: 1 }}
              onClick={() => addNewTaskType()}
              fullWidth
              label="Créer"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Wheel color={hsva} onChange={(color) => {
              setColorSelected(true)
              setHsva({ ...hsva, ...color.hsva })
            }} width="100" height="100" style={{ width: '100px', height: '100px' }} />
          </Grid>

          <Grid item xs={12} md={4}>
            { colorSelected && <p><CheckCircleIcon sx={{ color: hexColor }} /></p> }
          </Grid>
        </Grid>

        <Typography variant="body1" color="textSecondary" sx={{ mt: 4, mb: 1, fontWeight: 'bold' }}>
          Types de tâche existants :
        </Typography>

        { listOfTaskTypes ?? `Aucun type n'a été créé pour le moment` }
      </CommonDialog>

      <Button
        label="Gestion des types de tâche"
        startIcon={<SettingsIcon />}
        onClick={() => setOpenTypesDialog(true)}
        // variant="contained"
        // color="secondary"
        // sx={{ mt: 1.5 }}
      />
    </>
  )
}

export default TasksTypes