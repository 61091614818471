import * as React from 'react'
import { CreateDialog } from '@react-admin/ra-form-layout'

import { EventInputContent } from './index'

const EventCreate = props => (
  <CreateDialog title="Créer un évènement" fullWidth maxWidth="md">
    <EventInputContent action="create" {...props} />
  </CreateDialog>
)

export default EventCreate
