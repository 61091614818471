import { useEffect, useState } from 'react'
import { Button, useGetIdentity, useNotify, useRefresh } from 'react-admin'
import { Divider, Chip, Stack, Typography, Autocomplete } from '@mui/material'

import { supabaseClient } from '../supabase'

import { getRole } from '../common/roles'
import { CommonDialog } from '../common/Dialog'
import TextField from '@mui/material/TextField'

import SettingsIcon from '@mui/icons-material/Settings'

const TasksActionTypes = ({ label = `Créer une nouvelle action` }) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const { identity } = useGetIdentity()
  const [centers, setCenters] = useState([])
  const [centerChoice, setCenterChoice] = useState(identity?.center?.id)
  const [actionName, setActionName] = useState(null)
  const [actionSuccessName, setActionSuccessName] = useState(null)
  const [actionFailureName, setActionFailureName] = useState(null)
  const [actionNoChoiceName, setActionNoChoiceName] = useState(null)
  const [openActionDialog, setOpenActionDialog] = useState(false)
  const [listOfActions, setListOfActions] = useState([])

  const [actionTypeToEdit, setActionTypeToEdit] = useState(null)
  const [openActionTypeToEditDialog, setOpenActionTypeToEditDialog] = useState(false)

  const deleteActionType = async (actionId) => {
    const { error } = await supabaseClient
      .from('tasks_actions')
      .delete()
      .eq('id', actionId)
    
    if (error) {
      console.error('Error deleting action', error)
      notify('Une erreur est survenue', { type: 'error' })
      return
    }

    notify('Action supprimée', { type: 'success' })
    fetchActions()
    refresh()
  }

  const fetchActions = async () => {
    let query = supabaseClient
      .from('tasks_actions')
      .select('*, centers(*)')

    if ( identity?.role?.grade < getRole('admin') ) {
      query.eq('center_id', identity?.center?.id)
    }

    const { data, error } = await query
    
    if (error) {
      console.error('Error fetching actions', error)
      return
    }

    if ( data.length === 0 ) return

    const organisedDataByCenters = data.reduce((acc, action) => {
      if (action.centers?.name) {
        const centerName = action.centers.name
        // if ( action.name === 'Traité' || action.name === 'En attente' ) return acc

        if (!acc[centerName]) {
          acc[centerName] = []
        }
  
        acc[centerName].push(action)
      }
    
      return acc;
    }, {})

    const isAdmin = identity?.role?.grade === getRole('admin')
    const finalMapping = isAdmin
      ? Object.entries(organisedDataByCenters).map(([centerName, actions]) => (
          <div key={centerName}>
            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 1, ml: 1, textDecoration: 'underline' }}>
              {centerName}
            </Typography>

            { actions.map((action, index) => (
                <Chip
                  key={`${action.id}-${index}-chip`}
                  variant="contained"
                  sx={{ m: 1, fontSize: '0.75rem' }}
                  label={action.name}
                  color={ action.name === 'Traité' ? 'success' : action.name === 'En attente' ? 'warning' : 'primary' }
                  onDelete={ action.name !== 'Traité' && action.name !== 'En attente' ? () => deleteActionType(action.id) : undefined }
                  onClick={() => {
                    if ( action.name !== 'Traité' && action.name !== 'En attente' ) {
                      setCenterChoice(null)
                      setActionName(null)
                      setActionSuccessName(null)
                      setActionFailureName(null)
                      setActionNoChoiceName(null)
                      setActionTypeToEdit(action)
                      setOpenActionTypeToEditDialog(true)
                    }
                  }}
                />
              )
            ) }
          </div>
        ))
      : data.map((action, index) => (
        <Chip
          key={action.id}
          variant="contained"
          sx={{ m: .5, fontSize: '0.75rem' }}
          label={action.name}
          color={ action.name === 'Traité' ? 'success' : action.name === 'En attente' ? 'warning' : 'primary' }
          onDelete={ action.name !== 'Traité' && action.name !== 'En attente' ? () => deleteActionType(action.id) : undefined }
          onClick={() => {
            if ( action.name !== 'Traité' && action.name !== 'En attente' ) {
              setCenterChoice(null)
              setActionName(null)
              setActionSuccessName(null)
              setActionFailureName(null)
              setActionNoChoiceName(null)
              setActionTypeToEdit(action)
              setOpenActionTypeToEditDialog(true)
            }
          }}
        />
      ))
      
    setListOfActions(finalMapping)
  }

  const fetchCenters = async () => {
    const { data, error } = await supabaseClient
      .from('centers')
      .select('id, name')

    if (error) {
      console.error('Error fetching centers', error)
      return
    }

    if ( data.length === 0 ) return

    const centers = data.map(center => ({
      id: center.id,
      name: center.name,
    }))

    setCenters(centers)
  }

  useEffect(() => {
    fetchActions()
    fetchCenters()
  }, [])

  const updateActionTrees = async (actionId, newChoices) => {
    try {
      // Fetch all trees from the database
      const { data: dataAllTrees, error: errorAllTrees } = await supabaseClient
        .from('tasks_parcours')
        .select('task_id, tree')
  
      if (errorAllTrees) {
        console.error('Error fetching all parcours trees:', errorAllTrees)
        return
      }
  
      console.log('dataAllTrees (start)', dataAllTrees)
  
      // Function to recursively update the action in the tree
      const updateActionInTree = (tree) => {
        if (tree.action && tree.action.id === actionId) {
          console.log('Found action with id:', actionId, 'in tree:', tree.id)
          tree.action.choices = newChoices
        }
  
        if (tree.children && tree.children.length > 0) {
          tree.children.forEach(child => updateActionInTree(child))
        }
      }
  
      // Iterate through all trees and update the action if found
      dataAllTrees.forEach(task => {
        if (task.tree) {
          updateActionInTree(task.tree)
        }
      })
  
      console.log('dataAllTrees (end)', dataAllTrees)
  
      // Update the trees in the database
      for (const task of dataAllTrees) {
        const { error } = await supabaseClient
          .from('tasks_parcours')
          .update({ tree: task.tree })
          .eq('task_id', task.task_id)
  
        if (error) {
          console.error('Error updating trees:', error)
          return
        }
      }
  
      console.log('Action updated successfully.')
    } catch (error) {
      console.error('Error updating action in trees:', error)
    }
  }
  
  const editActionType = async (action) => {
    if ( ! action ) return

    const choices = {
      options: [
        { id: 1, name: actionSuccessName || action.choices.options[0].name || 'Succès' },
        { id: -1, name: actionFailureName || action.choices.options[1].name || 'Échec' },
        { id: 2, name: actionNoChoiceName || action.choices.options[2].name || 'Continuer' }
      ]
    }

    const { error } = await supabaseClient
      .from('tasks_actions')
      .update({
        name: actionName || action.name,
        created_by: identity?.id ?? null,
        choices: choices?.options,
        center_id: centerChoice ?? identity?.center_id,
      })
      .eq('id', action.id)
    
    if (error) {
      console.error('Error edit action type', error)
      return
    }

    updateActionTrees(action.id, choices)

    notify('Type d\'action modifié avec succès', { type: 'success' })
    fetchActions()
    refresh()
    setOpenActionTypeToEditDialog(false)
  }

  const addNewActionType = async () => {
    if ( ! actionName ) return

    const choices = {
      options: [
        { id: 1, name: actionSuccessName ?? 'Succès' },
        { id: -1, name: actionFailureName ?? 'Échec' },
        { id: 2, name: actionNoChoiceName ?? 'Continuer' }
      ]
    }

    const { error } = await supabaseClient
      .from('tasks_actions')
      .insert({
        name: actionName,
        created_by: identity?.id ?? null,
        choices: choices,
        center_id: centerChoice ?? identity?.center_id,
      })
    
    if (error) {
      console.error('Error saving new action', error)
      return
    }

    notify('Nouvelle action créée', { type: 'success' })
    fetchActions()
    refresh()
  }

  return (
    <>
      <CommonDialog
        titleBgColor="#ed6c02"
        titleColor="#fefefe"
        widthInPx="800px"
        open={openActionTypeToEditDialog}
        handleClose={() => setOpenActionTypeToEditDialog(false)}
        title="Modifier une action"
        size="md"
      >
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1, mb: 2 }}>
          Vous pourez ensuite utiliser cette action dans votre parcours.
        </Typography>

        { identity?.role?.grade === getRole('admin') && 
          <Autocomplete
            disablePortal
            options={centers}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => setCenterChoice(value?.id ?? null)}
            renderInput={(params) => <TextField {...params} label="Centre" variant="outlined" />}
          />
        }

        <TextField label="Nom de l'action" variant="outlined" defaultValue={actionTypeToEdit?.name} onChange={e => setActionName(e.target.value)} fullWidth />

        <Typography variant="body2" color="textSecondary" sx={{ mt: 4, mb: 2 }}>
          Les intitulés ci-dessous seront visibles par le pubeur, lorsqu'il effectuera l'action.
        </Typography>

        <TextField label="Intitulé si succès (défault : Succès)" variant="outlined" defaultValue={actionTypeToEdit?.choices?.options[0].name} onChange={e => setActionSuccessName(e.target?.value)} fullWidth />
        <TextField label="Intitulé si échec (défault : Échec)" variant="outlined" defaultValue={actionTypeToEdit?.choices?.options[1].name} onChange={e => setActionFailureName(e.target?.value)} fullWidth />
        <TextField label="Intitulé si seul choix possible (défault : Continuer)" variant="outlined" defaultValue={actionTypeToEdit?.choices?.options[2].name} onChange={e => setActionNoChoiceName(e.target?.value)} fullWidth />

        <Typography variant="body2" color="error" sx={{ mt: 4, mb: 2 }}>
          N'oubliez pas de réinitialiser les tâches qui utilisent cette action dans leur parcours !
        </Typography>

        <Button
          variant="contained"
          sx={{ p: 1 }}
          onClick={() => editActionType(actionTypeToEdit ?? null)}
          color="warning"
          fullWidth
          label="Modifier l'action"
        />
      </CommonDialog>

      <CommonDialog
        open={openActionDialog}
        handleClose={() => setOpenActionDialog(false)}
        title="Créer une nouvelle action"
        size="sm"
      >
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1, mb: 1 }}>
          Vous pourez ensuite utiliser cette action dans votre parcours.
        </Typography>

        { identity?.role?.grade === getRole('admin') && 
          <Autocomplete
            disablePortal
            options={centers}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => setCenterChoice(value?.id ?? null)}
            renderInput={(params) => <TextField {...params} label="Centre" variant="outlined" />}
          />
        }

        <TextField label="Nom de l'action" variant="outlined" onChange={e => setActionName(e.target.value)} fullWidth />

        <Typography variant="body2" color="textSecondary" sx={{ mt: 2, mb: .5 }}>
          Les intitulés ci-dessous seront visibles par le pubeur, lorsqu'il effectuera l'action.
        </Typography>

        <TextField label="Intitulé si succès (défault : Succès)" variant="outlined" onChange={e => setActionSuccessName(e.target.value)} fullWidth />
        <TextField label="Intitulé si échec (défault : Échec)" variant="outlined" onChange={e => setActionFailureName(e.target.value)} fullWidth />
        <TextField label="Intitulé si seul choix possible (défault : Continuer)" variant="outlined" onChange={e => setActionNoChoiceName(e.target.value)} fullWidth />

        <Button
          variant="contained"
          sx={{ p: 1, mt: 2 }}
          onClick={() => addNewActionType()}
          color="customPrimary"
          fullWidth
          label="Créer l'action"
        />

        <Divider sx={{ mt: 4, mb: 4 }} />

        <Typography variant="body1" color="textSecondary" sx={{ mb: 1, fontWeight: 'bold' }}>
          Actions existantes :
        </Typography>

        { listOfActions ?? `Aucune action n'a été créée pour le moment` }
      </CommonDialog>

      <Stack spacing={2} display="flex" alignItems="flex-end" sx={{ mt: 2 }}>
        <Button
          startIcon={<SettingsIcon />}
          label={label}
          variant="outlined"
          color="customPrimary"
          onClick={() => setOpenActionDialog(true)}
        />
      </Stack>
    </>
  )
}

export default TasksActionTypes