import { useState, useEffect } from 'react'
import {
  useUpdateMany,
  useRefresh,
  useNotify,
  useGetIdentity,
  useUnselectAll,
  List,
  Datagrid,
  TextField,
  FunctionField,
  Button,
  SearchInput,
  TopToolbar,
  useListContext,
} from 'react-admin'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import sendMail from '../../common/SendMail'
import { getRole } from '../../common/roles'
import { supabaseClient } from '../../supabase'
import { CommonDialog } from '../../common/Dialog'
import dataProvider from '../../supabase/dataProvider'
import addNewComment from '../../common/addLeadComment'
import { PostPagination } from '../../common/Pagination'
import createNotification from '../../common/createNotification'

import ContactPageRoundedIcon from '@mui/icons-material/ContactPageRounded'

const profileFilters = [
  <SearchInput source="first_name@ilike" variant="outlined" sx={{ fontSize: '.8rem', width: '150px' }} placeholder="Prénom" alwaysOn />,
  <SearchInput source="last_name@ilike" variant="outlined" sx={{ fontSize: '.8rem', width: '150px' }} placeholder="Nom" alwaysOn />,
]

const LeadAssignationContent = props => {
  const {
    saveAssignation,
    identity,
    dateOfMomentaryAssignation,
    setDateOfMomentaryAssignation,
    isMomentary,
    setIsMomentary,
    roles,
    params,
  } = props

  const isAdmin = identity?.role?.grade === getRole('admin')
  const isRespoOrAdmin = identity?.role?.grade >= getRole('responsable')

  // get the role id with the name
  // const roleRespo = roles.filter(role => role.reference === 'responsable')
  const roleAdmin = roles.filter(role => role.reference === 'admin')

  return (
    <List
      disableSyncWithLocation
      pagination={<PostPagination />}
      perPage={50}
      resource="profiles"
      filter={
        isAdmin
          ? { 'enabled': true }
          : { 'enabled': true,
              'center_id': identity?.center?.id,
              'role_id@neq': [roleAdmin[0].id] // see profiles dataProvider
            }
      }
      filters={profileFilters}
      actions={
        isRespoOrAdmin ? (
          <TopToolbar sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', minWidth: '60%' }}>
            <FormControlLabel control={<Checkbox />} label="Assignation ponctuelle" onChange={() => setIsMomentary(!isMomentary)} />
            
            { isMomentary ?
              <span>jusqu'au</span>
              : null
            }
            { isMomentary ?
              <input
                type="date"
                value={dateOfMomentaryAssignation ?? ''}
                style={{ fontSize: '1rem' }}
                onChange={(e) => setDateOfMomentaryAssignation(e.target.value)}
              />
              : null
            }
            { isMomentary ?
              <span>inclus</span>
              : null
            }
          </TopToolbar>
        ) : (
          false
        )
      }
      empty={false}
      exporter={false}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="Prénom" source="first_name" />
        <TextField label="Nom" source="last_name" />

        <FunctionField render={
          record => (
            <>
              <Button
                label="Assigner ce pubeur"
                variant="outlined"
                color="primary"
                size="small"
                fullWidth
                onClick={() => saveAssignation(record.id)}
              />
            </>
          )
        }/>
      </Datagrid>
    </List>
  )
}

export const PubeurAssignation = () => {
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll('leads')
  const [updateMany, { error }] = useUpdateMany()
  const { identity } = useGetIdentity()
  const isRespoOrAdmin = identity?.role?.grade >= getRole('responsable')
  const { selectedIds: leads, filterValues, sort, pagination } = useListContext()
  const inProduction = process.env.NODE_ENV === 'production'

  const [openAssignation, setOpenAssignation] = useState(false)
  const [isMomentary, setIsMomentary] = useState(false)
  const [dateOfMomentaryAssignation, setDateOfMomentaryAssignation] = useState(null)
  const [rolesData, setRolesData] = useState([])
  // const leads = Object.values(selectedIds)

  const params = {
    filter: filterValues,
    pagination: {
      page: pagination?.page,
      perPage: pagination?.perPage,
    },
    sort: {
      field: sort?.field,
      order: sort?.order,
    },
  }

  const logAssignation = async (leadId, entries) => {
    entries.current_year_id = process.env.REACT_APP_CURRENT_YEAR_ID
    
    const { error } = await supabaseClient
      .from('assignation_logs')
      .insert(entries)

    if (error) console.error('Error saving assignation log: ', error)
  }

  const saveAssignation = (pubeur) => {
    const chunkSize = 100
    const chunks = []

    for ( let i = 0; i < leads?.length; i += chunkSize ) {
      chunks.push(leads.slice(i, i + chunkSize))
    }

    chunks.forEach(chunk => {
      performAssignation(chunk, pubeur)
    })
  }

  const performAssignation = async (chunk, pubeurId) => {
    // send mail informing of the assignation
    const fetchPubeurInfo = async () => {
      const {data, error} = await supabaseClient
        .from('profiles')
        .select('id, first_name, last_name, email')
        .eq('id', pubeurId)
        .single()

      if (error) {
        console.error('Error fetching pubeur info: ', error)
        return null
      }

      return data
    }

    const fetchLeadInfo = async lead_id => {
      const {data, error} = await supabaseClient
        .from('leads')
        .select('id, first_name, last_name, assigned_pubeur')
        .eq('id', lead_id)
        .single()

      if (error) {
        console.error('Error fetching pubeur info: ', error)
        return null
      }

      return data
    }

    try {
      if ( pubeurId ) {
        const pubeurInfo = await fetchPubeurInfo()

        await Promise.all([pubeurInfo])

        const leadInfoPromises = leads?.map(lead_id => fetchLeadInfo(lead_id))
        const leadsInfo = await Promise.all(leadInfoPromises)
        const allNames = leadsInfo.map(info => info.first_name + ' ' + info.last_name)

        if ( pubeurInfo && allNames.length > 0 ) {
          let messageHTML = `
            <h3>Information FormaContacts</h3>

            <h4>Un ou plusieurs contacts vous ont été assignés aujourd'hui :</h4>

            <ul>`
          messageHTML += allNames.map(leadName => `<li>${leadName}</li>`).join('')
          messageHTML += `
            </ul>

            <p><a href="${process.env.REACT_APP_WEBSITE_URL}/leads">Cliquez ici pour vous rendre sur le CRM</a></p>

            <p><i>Ceci est un message automatique depuis le CRM Forma Contacts, merci de ne pas y répondre.</i></p>
          `

          const messageTEXT = `Un ou plusieurs contacts vous ont été attribués aujourd'hui.\n\nCeci est un message automatique, merci de ne pas y répondre.`
          
          if ( inProduction ) {
            sendMail ({
              identity: pubeurInfo,
              subject: `Nouveaux contacts assignés`,
              messageHTML: messageHTML,
              messageTEXT: messageTEXT,
              sendNow: true
            })
          }

          const listeOfLeads = allNames.map(leadName => `${leadName}`).join(', ')

          /* add comment to lead logs */
          leads?.forEach(lead_id => {
            addNewComment(lead_id, identity?.id, null, `*AUTO* Assignation du contact à ${pubeurInfo.first_name} ${pubeurInfo.last_name}`)
          })

          createNotification ({
            profile_id: pubeurInfo.id,
            type: 'assignation',
            title: `Nouveaux contacts assignés`,
            message: `Votre responsable vous a assigné un ou plusieurs contacts : ${listeOfLeads}.`,
            url: `/leads`,
          })

          /* save assignation log */
          leads?.forEach(leadId => {
            const leadOriginalPubeur = leadsInfo.find(info => info.id === leadId).assigned_pubeur

            // same person assigned momentarily
            let update = true
            if ( isMomentary && pubeurInfo.id === leadOriginalPubeur ) {
              update = false
            }

            if ( update ) {
              const entries = {
                pubeur_id: pubeurInfo.id,
                lead_id: leadId,
                temporary: isMomentary,
                lead_previous_pubeur_id: leadOriginalPubeur ?? null,
                duration: isMomentary ? dateOfMomentaryAssignation + 'T23:59:59.000' : null,
              }
              
              logAssignation(leadId, entries)
            }
          })
        }
      }
    } catch (error) {
      console.error('Error in performAssignation: ', error)
    } finally {
      updateMany(
        'leads',
        {
          ids: chunk,
          data: { assigned_pubeur: pubeurId ?? null  }
        },
        {
          onSuccess: async (data) => {
            console.log('Assignation success: ');
            
            unselectAll()
            notify(
              pubeurId
                ? isMomentary
                  ? 'Assignation ponctuelle effectuée avec succès'
                  : 'Assignation effectuée avec succès'
                : 'Désassignation effectuée avec succès',
              { type: 'success' }
            )
            setIsMomentary(false)
            setDateOfMomentaryAssignation(null)
            setOpenAssignation(false)

            await dataProvider.refetchData('leads_view', params)
            refresh()
          },
          onError: (error) => {
            notify(`Erreur affectation pubeur : ${error.message}`, { type: 'error' })
          },
      })
    }
  }

  useEffect(() => {
    const fetchRoles = async () => {
      const { data, error } = await supabaseClient
        .from('roles')
        .select('id, reference')
      
      if (error) {
        console.error('Error fetching roles: ', error)
        return
      }

      setRolesData(data)
    }

    fetchRoles()
  }, [identity])

  return (
    <>
      <CommonDialog
        open={openAssignation}
        handleClose={() => setOpenAssignation(false)}
        title={ 
          <>
            { leads?.length > 1 ? `Gestion pubeur de ${leads?.length} contacts` : `Gestion pubeur du contact sélectionné` }
            
            { isRespoOrAdmin && (
              <Button
                label="Désassigner le pubeur actuel"
                variant="contained"
                color="warning"
                onClick={() => saveAssignation(null)}
                sx={{ ml: 2, paddingTop: '5px' }}
              />
            )}
         </>
        }
        size="md"
      >
        <LeadAssignationContent
          saveAssignation={saveAssignation}
          isMomentary={isMomentary}
          setIsMomentary={setIsMomentary}
          dateOfMomentaryAssignation={dateOfMomentaryAssignation}
          setDateOfMomentaryAssignation={setDateOfMomentaryAssignation}
          identity={identity}
          roles={rolesData}
          params={params}
        />
      </CommonDialog>

      <Button
        label="Pubeurs"
        startIcon={<ContactPageRoundedIcon style={{ fontSize: '1rem'}} />}
        size="small"
        color="primary"
        onClick={() => setOpenAssignation(true)}
        sx={{ fontSize: '.8rem', minWidth: '100px' }}
      />
    </>
  )
}

export default PubeurAssignation