import React, { useEffect, useMemo } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { Admin, CustomRoutes } from 'react-admin'
import { ForgotPasswordPage } from 'ra-supabase'
import { Resource } from '@react-admin/ra-rbac'

import { fr } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { useProfile } from './contexts/ProfileContext'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ServiceWorkerProvider, useServiceWorker } from './contexts/ServiceWorkerContext'

import theme from './common/theme'
import LoginPage from './LoginPage'
import { MyLayout }  from './Layout'
import { i18nProvider } from './i18nProvider'
import LoadingPage from './Design/LoadingPage'
import SetPasswordPage from './SetPasswordPage'
import ResetPasswordPage from './ResetPasswordPage'
import combinedDataProvider from './supabase/dataProvider'
import { ProfileProvider } from './contexts/ProfileContext'
import customAuthProvider from './supabase/customAuthProvider'

import Dashboard from './dashboards/'
import leads from './leads'
import parents from './parents'
import centers from './centers'
import events from './events'
import schools from './schools'
import sites from './sites'
import tasks from './tasks'
import tags from './tags'
import conversations from './conversations'
import profiles from './profiles'
import inboundContacts from './inboundContacts'
import eventTypes from './eventTypes'
import leadYears from './leadYears'
import leadSources from './leadSources'
import qualifications from './qualifications'
import calendar from './calendar'
import brochures from './brochures'

const ReactAdminApp = () => {
  const { setIsUpdateAvailable, setUpdateAssets } = useServiceWorker()
  const { roleGrade } = useProfile()
  const authProvider = useMemo(() => customAuthProvider(roleGrade), [roleGrade])

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        setIsUpdateAvailable(true)
  
        const refreshCacheAndReload = () => {
          console.log('Starting cache clearing and page reload process')
        
          const clearCache = () => {
            caches.keys().then(cacheNames => {
              cacheNames.forEach(cacheName => {
                console.log(`Deleting cache: ${cacheName}`)
                caches.delete(cacheName)
              })
            })
        
            console.log('Cache cleared, reloading the page')
            window.location.reload(true)
          }
        
          console.log('Unregistering service worker')
          serviceWorkerRegistration.unregister()
          clearCache()
        }
  
        const handleButtonClick = () => {
          if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' })
  
            setIsUpdateAvailable(false)
  
            refreshCacheAndReload()
          }
        }
  
        setUpdateAssets(() => handleButtonClick)
      },
    })
  }, [])
  
  return (
    <Admin
      requireAuth
      loading={LoadingPage}
      dataProvider={combinedDataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={LoginPage}
      layout={MyLayout}
      theme={theme}
      dashboard={Dashboard}
      darkTheme={null}
    >
      <CustomRoutes noLayout>
        <Route path="/set-password" element={<SetPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path={ForgotPasswordPage.path} element={<ForgotPasswordPage />} />
      </CustomRoutes>

      <Resource name="leads" {...leads} recordRepresentation={(record) => `${record.first_name} ${record.last_name}`} />
      <Resource name="parents" {...parents} recordRepresentation={(record) => `${record.first_name} ${record.last_name}`} />
      <Resource name="events" {...events} recordRepresentation="name" />
      <Resource name="inbound_contacts" {...inboundContacts} recordRepresentation="name" />
      <Resource name="tasks" {...tasks} recordRepresentation="name" />
      <Resource name="tags" {...tags} recordRepresentation="name" />
      <Resource name="conversations" {...conversations} recordRepresentation="name" />
      <Resource name="profiles" {...profiles} recordRepresentation="email" />
      <Resource name="centers" {...centers} recordRepresentation="name" />
      <Resource name="types" {...eventTypes} recordRepresentation="name" />
      <Resource name="schools" {...schools} recordRepresentation="name" />
      <Resource name="sites" {...sites} recordRepresentation="name" />
      <Resource name="lead_years" {...leadYears} recordRepresentation="name" />
      <Resource name="lead_sources" {...leadSources} recordRepresentation="name" />
      <Resource name="qualifications" {...qualifications} recordRepresentation="name" />
      <Resource name="calendar" {...calendar} recordRepresentation="name" />
      <Resource name="brochures" {...brochures} />
    </Admin>
  )
}

const App = () => (
  <ServiceWorkerProvider>
    <ProfileProvider>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
          <ReactAdminApp />
        </LocalizationProvider>
      </BrowserRouter>
    </ProfileProvider>
  </ServiceWorkerProvider>
)

export default App