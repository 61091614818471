import { useGetIdentity } from 'react-admin'
import { getRole } from '../common/roles'
import { Container } from '@mui/material'

import DashboardPubeur from './pubeur'
import DashboardRespo from './respo'

export default () => {
  const { identity } = useGetIdentity()

  // return <Container sx={{ mt: 30, textAlign: 'center' }}><b>Maintenance en cours, merci de patienter...</b><br/>Récupération des tâches...</Container>
  
  return identity?.role?.grade >= getRole('responsable')
    ? <DashboardRespo />
    : <DashboardPubeur />
}