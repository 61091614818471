import * as React from 'react'
import {
    useGetList,
    FilterList,
    FilterListItem,
    TextInput,
} from 'react-admin'
import { Box, Chip, Grid, Typography, Input } from '@mui/material'
import HomeWorkRoundedIcon from '@mui/icons-material/HomeWorkRounded'

export const TasksListFilter = () => {
  const { data } = useGetList(
    'centers',
    { 
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'name', order: 'ASC' }
    }
  )

  return (
    <Box width="15em" order="-1" marginRight="1em">
      <FilterList label="Centres" icon={<HomeWorkRoundedIcon />}>
        { data &&
          data?.map(center => (
            <FilterListItem
              key={center.id}
              label={
                <Chip
                  label={center.name}
                  size="small"
                  style={{
                    backgroundColor: center.color,
                    border: 0,
                    cursor: 'pointer',
                  }}
                />
              }
              value={{ center_id: center.id }}
            />
          ))
        }
      </FilterList>
    </Box>
  )
}
