import React, { useEffect, useState } from 'react'
import { useListContext, Loading, useRedirect, useGetIdentity } from 'react-admin'
import { styled } from '@mui/material/styles'
import { supabaseClient } from '../supabase'

const Container = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1.5vw',
  padding: '1vw',
})

const Card = styled('div')(({ color, isVisible, isFullyVisible }) => ({
  backgroundColor: '#fefefe',
  borderBottom: `1vh solid ${color}`,
  borderRadius: '10px',
  boxSizing: 'border-box',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
  cursor: !isVisible && !isFullyVisible ? 'default' : 'pointer',
  '&:hover': (isVisible || isFullyVisible) && {
    boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px',
  },
  width: 'calc(26% - 2vw)', // default for larger screens
  padding: '1vw',

  // Media queries for responsiveness
  '@media (max-width: 1600px)': {
    width: 'calc(33% - 2vw)', // 2 cards when screen < 1200px
    padding: '1.5vw',
  },
  '@media (max-width: 1200px)': {
    width: 'calc(48% - 2vw)', // 2 cards when screen < 1200px
    padding: '2vw',
  },
  '@media (max-width: 700px)': {
    width: 'calc(100% - 2vw)', // 1 card when screen < 700px
    padding: '3vw',
  },
}))

const EventsGridLayout = ({ children, ...props }) => {
  const { data, isLoading } = useListContext()
  const { identity } = useGetIdentity()
  const redirect = useRedirect()

  const handleCardClick = (eventId) => {
    redirect(`/events/${eventId}`)
  }

  const [endedEvents, setEndedEvents] = useState({})

  const getEndDate = async (record) => {
    try {
      const { data: pubeurs, error } = await supabaseClient
        .from('event_pubeurs')
        .select('is_leader, pubeur_id')
        .eq('event_id', record.id)
      
      if (error) {
        console.error('Error fetching pubeurs in events', error)
        return false
      }

      const now = new Date().toISOString()
      const eventLeader = pubeurs?.filter(pubeur => pubeur.is_leader)
      let endOfEvent = record.end_at

      // Add 72h if the identity is a leader
      if (eventLeader?.some(pubeur => pubeur.pubeur_id === identity?.id)) {
        endOfEvent = new Date(
          new Date(record.end_at).setHours(new Date(record.end_at).getHours() + 72)
        ).toISOString()
      }

      return now >= endOfEvent
    } catch (error) {
      console.error('Error calculating end date', error)
      return false
    }
  }

  useEffect(() => {
    const fetchEndedEvents = async () => {
      const results = await Promise.all(
        data.map(async (record) => {
          const ended = await getEndDate(record)
          return { [record.id]: ended }
        })
      )

      const endedEventsMap = results.reduce((acc, curr) => ({ ...acc, ...curr }), {})
      setEndedEvents(endedEventsMap)
    }

    if (data && data.length > 0) {
      fetchEndedEvents()
    }
  }, [data])

  return isLoading ? <Loading /> : (
    <Container {...props}>
      { data
        ? data.map(record => {
            const now = new Date().toISOString()
            const nowMinusOneDay = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
            const isVisible = now >= record.missionStartAff
            const isFullyVisible = nowMinusOneDay >= record.date

            const endedEvent = endedEvents[record.id]

            return !endedEvent ? (
              <Card key={record.id} color={record.color} isVisible={isVisible} isFullyVisible={isFullyVisible} onClick={() => handleCardClick(record.id)}>
                {children(record)}
              </Card>
            ) : null
          })
        : <p>Aucun événement disponible pour le moment.</p>
      }
    </Container>
  )
}

export default EventsGridLayout
