import {
    email,
    required,
    useCreateContext,
    useEditContext,
    useRecordContext,
    SimpleForm,
    TextInput,
} from 'react-admin'
import {
  Grid,
  Typography,
} from '@mui/material'

import CenterList from './CenterList'
import { TextInputCopy } from '../Design/CustomTextInput'
import { formatPhoneNumber, getValidEmail, isFormPhoneValid, toLowerCase } from '../common/Formating'

import StoreIcon from '@mui/icons-material/Store'

export default {
  list: CenterList,
  icon: StoreIcon,
  options: {
    "label": "Centres",
  }
}

const transformData = data => {
    const { name, phone, address, email, email_leaflet, ...rest } = data;

    return {
        name: name,
        phone: formatPhoneNumber(phone),
        email: getValidEmail(email),
        address: {
            street: toLowerCase(address?.street),
            postalCode: address?.postalCode,
            city: toLowerCase(address?.city),
        },
        email_leaflet: getValidEmail(email_leaflet),
        ...rest,
    };
};

export const CenterInputContent = props => {
    const record = useRecordContext();
    const editContext = useEditContext();
    const createContext = useCreateContext();

    const handleSubmit = (values, form) => {
        const transformedValues = transformData(values);

        if (record && record.id) {
            return editContext.save(transformedValues, form);
        }

        return createContext.save(transformedValues, form);
    };

    return (
        <SimpleForm {...props} onSubmit={handleSubmit}>
            { record && record.id && (
              <>
                <TextInputCopy label="CRM id" source="id" fullWidth disabled />
                <TextInputCopy label="MEP id" source="mep_id" fullWidth />
              </>
            )}

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput fullWidth label="Nom" source="name" validate={[required()]} autoFocus />
                </Grid>

                <Grid item xs={6}>
                    <TextInput fullWidth label="Téléphone" source="phone" validate={[required(), isFormPhoneValid]} />
                </Grid>
            </Grid>

            <Typography color="textSecondary" variant="body2">
                Adresse principale
            </Typography>
            
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextInput label="Rue" source="address.street" fullWidth />
                </Grid>
                <Grid item xs={4} md={2}>
                    <TextInput label="Code postal" source="address.postalCode" fullWidth />
                </Grid>
                <Grid item xs={8} md={4}>
                    <TextInput label="Ville" source="address.city" fullWidth />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput label="Email contact" source="email" validate={[required(), email()]} fullWidth />
                </Grid>

                <Grid item xs={6}>
                    <TextInput label="Email brochure" source="email_leaflet" validate={[email()]} fullWidth />
                </Grid>
            </Grid>
        </SimpleForm>
    );
};
