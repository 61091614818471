export const inboundcontactStatus = [
    { id: 'call', name: 'Appel' },
    { id: 'sms', name: 'SMS' },
    { id: 'email', name: 'Email' },
    { id: 'inbound', name: 'On me contacte' },
    { id: 'outbound', name: 'Je contacte' },
    { id: 'voicemail', name: 'Répondeur' },
    { id: 'called', name: 'Appel traité' },
    { id: 'no_response', name: 'Pas de réponse' },
    { id: 'response_received', name: 'Réponse reçue' },
    { id: 'stop_rgpd', name: 'Stop RGPD' },
    { id: 'inscrit_ts', name: 'Inscrit(e) TS' },
    { id: 'inscrit_passlas', name: 'Inscrit(e) PASS/LAS' },
    { id: 'candidat', name: 'Candidat(e)' },
    { id: 'interested', name: 'intéressé(e) par la prépa' },
    { id: 'not_interested', name: 'Pas intéressé(e) par la prépa' },
    { id: 'processing', name: 'En attente' },
    { id: 'done', name: 'Terminé' },
];

export const getStatusByName = name => {
    const result = inboundcontactStatus.filter(status => status.name == name);
    return result[0]?.id;
};

export const getStatusNameById = id => {
    const status = inboundcontactStatus.find(item => item.id === id);
    return status ? status.name : undefined;
};
