import React, { useState } from 'react'
import { useListContext, useRedirect, useRefresh } from 'react-admin'
import { Stack, Box, Button, Tooltip } from '@mui/material'

import truncatedText from '../../common/truncateText'
import createGroupFilters from './groupFilters'

import { alpha } from '@mui/material/styles'
import CancelIcon from '@mui/icons-material/Cancel'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'

const CurrentFilterButton = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const { filterValues, setFilters } = useListContext()

  const filterValuesBeforeGrouped = JSON.parse(localStorage.getItem('filterValuesBeforeGrouped')) ?? null
  const { entryKey, groupIndex, filterOptions, filterInformation, group } = props
  const { filterTitle, filterMethod } = filterOptions
  const { filterNameEncoded, filterIntitule, groupCondition, groupColor, groupUniqueID } = filterInformation

  let filterName = filterNameEncoded.replace('_'+ groupUniqueID, '')
  
  group.groupIndex = groupIndex

  const reverseMethod = {
    ...filterOptions,
    filterMethod: ! filterMethod,
  }

  const handleFilterChange = (filterName, numOption, newOptions) => {
    filterValuesBeforeGrouped[filterName][numOption] = newOptions

    let updatedFilterValuesBeforeGrouped = {
      ...filterValuesBeforeGrouped,
      [filterName]: filterValuesBeforeGrouped[filterName]
    }

    const groupedFilteredValues = createGroupFilters(updatedFilterValuesBeforeGrouped)

    if ( updatedFilterValuesBeforeGrouped.length === 0 ) {
      delete filterValues.groupFilters
      setFilters(filterValues)
      localStorage.removeItem('groupedFilteredValues')
      localStorage.removeItem('filterValuesBeforeGrouped')
    } else {
      localStorage.setItem('groupedFilteredValues', JSON.stringify(groupedFilteredValues))
      localStorage.setItem('filterValuesBeforeGrouped', JSON.stringify(filterValuesBeforeGrouped))
    }

    redirect('/leads')
    refresh()
  }

  const removeFilter = (filterName, numOption) => {
    delete filterValuesBeforeGrouped[filterName][numOption]

    const groupedFilteredValues = createGroupFilters(filterValuesBeforeGrouped)

    if ( groupedFilteredValues.length > 0 ) {
      localStorage.setItem('groupedFilteredValues', JSON.stringify(groupedFilteredValues))
      localStorage.setItem('filterValuesBeforeGrouped', JSON.stringify(filterValuesBeforeGrouped))
    } else {
      filterValues.groupFilters = 'vide'
      localStorage.removeItem('groupedFilteredValues')
      localStorage.removeItem('filterValuesBeforeGrouped')
    }

    refresh()
  }

  return (
    <Stack
      display="flex" flexDirection="row" justifyContent="space-between"
      sx={{
        border: 'none',
        border: groupCondition === 'AND' ? '2px solid darkgreen' : '2px solid #00d8ff',
        // borderRight: '0px',
        // borderRadius: '5px',
        color: '#333',
        backgroundColor: 'transparent',
        fontSize: '.7rem',
        padding: '5px',
        width: '100%',
        marginBottom: '4px',
      }}
    >
      <div>
        <b>{truncatedText(filterIntitule.toUpperCase(), null, 20)}</b><br/>
        <u>
          { filterMethod
            ? 'Inc.'
            : 'Exc.'
          }
        </u>&nbsp;
        {filterTitle ? truncatedText(filterTitle, null, 15) ?? null : null}
      </div>

      <div style={{ width: '25%', textAlign: 'right' }}>
        <Tooltip title="Inclure/Exclure">
          <SwapHorizIcon
            onClick={() => handleFilterChange(filterName, entryKey, reverseMethod)}
            style={{ fontSize: '1.1rem', marginTop: '5px', cursor: 'pointer', color: '#999' }} 
          />
        </Tooltip>

        <Tooltip title="Retirer le filtre">
          <CancelIcon
            onClick={() => removeFilter(filterName, entryKey)}
            style={{ fontSize: '1.1rem', marginTop: '5px', cursor: 'pointer', color: '#bbb' }} 
          />
        </Tooltip>
      </div>
    </Stack>
  )

  return (
    <Stack direction="row" spacing={1} style={{ margin: '0 auto', width: '100%' }}>
      <div
        style={{
          border: 'none',
          borderLeft: groupCondition === 'AND' ? '5px solid darkgreen' : '5px solid #00d8ff',
          borderRight: '0px',
          borderRadius: '0px',
          color: '#333',
          backgroundColor: groupColor,
          fontSize: '.7rem',
          display: 'flex',
          alignItems: 'center',
          padding: '5px',
          width: '100%',
        }}
      >
        { filterMethod
          ? <PlaylistAddCheckIcon style={{ marginRight: '5px', fontSize: '1.2rem' }} />
          : <PlaylistRemoveIcon style={{ marginRight: '5px', fontSize: '1.2rem' }} />
        }
        
        <b>{truncatedText(filterIntitule, null, 10)}</b>
        &nbsp;
        {filterTitle ? truncatedText(filterTitle, null, 20) ?? null : null}
      </div>

      <Tooltip title="Inclure/Exclure">
        <SwapHorizIcon
          onClick={() => handleFilterChange(filterName, entryKey, reverseMethod)}
          style={{ fontSize: '1.1rem', marginTop: '5px', cursor: 'pointer', color: '#999' }} 
        />
      </Tooltip>

      <Tooltip title="Retirer le filtre">
        <CancelIcon
          onClick={() => removeFilter(filterName, entryKey)}
          style={{ fontSize: '1.1rem', marginTop: '5px', cursor: 'pointer', color: '#bbb' }} 
        />
      </Tooltip>
    </Stack>
  )
}

export default CurrentFilterButton