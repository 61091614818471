import { EditDialog } from '@react-admin/ra-form-layout';

import { CenterInputContent } from './index';

const CenterEdit = () => (
    <EditDialog title="Fiche centre" fullWidth maxWidth="sm">
        <CenterInputContent />
    </EditDialog>
);

export default CenterEdit;
