/* Go to LeadTopToolbar to set up the tour ! */
const Tours = {
  'TourImport': {
    steps: [
      {
        target: `[data-tour-id='template-import-button']`,
        title: 'Import des contacts',
        content: "Le template d'import a été mis à jour. Merci de le télécharger à nouveau pour vérifier que votre import est aux normes.",
        joyrideProps: {
          styles: {
            beacon: {
              zIndex: 1000,
            },
          },
          locale: { last: `J'ai compris` }
        },
      },
    ],
    after: ({ profileTours, setProfileTours }) => {
      setProfileTours({ ...profileTours, TourImport: true })
    },
  },
  'TourImportEventQualifications': {
    steps: [
      {
        target: `[data-tour-id='template-import-button']`,
        title: 'Import des contacts (9 avril 2024)',
        content: "Le template d'import a été mis à jour. Merci de le télécharger à nouveau pour vérifier que votre import est aux normes.",
        joyrideProps: {
          styles: {
            beacon: {
              zIndex: 1000,
            },
          },
          locale: { last: `J'ai compris` }
        },
      },
    ],
    after: ({ profileTours, setProfileTours }) => {
      setProfileTours({ ...profileTours, TourImport: true, TourImportEventQualifications: true})
    },
  }
}

export default Tours