import {
  useNotify,
  useUnselectAll,
  useDeleteMany,
  useRedirect,
  useGetIdentity,
} from 'react-admin'
import { Stack } from '@mui/material'

import PubeurAssignation from './PubeurAssignation'
import EventAssignation from './EventAssignation'
import TaskAssignation from './TaskAssignation'
import StatusAssignation from './StatusAssignation'
import TagAssignation from './TagAssignation'
import { confirmationDialog } from '../../common/createConfirmation'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import LeadsFusion from './LeadsFusion'
import { getItemFromLocalStorage } from '../../common/LocalStorage'
import { getRole } from '../../common/roles'

const BulkMenu = props => {
  const { identity } = useGetIdentity()
  const notify = useNotify()
  const redirect = useRedirect()
  const unselectAll = useUnselectAll('leads')
  const [deleteMany, { isLoading, error }] = useDeleteMany()
  
  const localCurrentYear = getItemFromLocalStorage('localCurrentYear')?.id || process.env.REACT_APP_CURRENT_YEAR_ID
  const isPubeur = identity?.role?.grade === getRole('pubeur')
  const isSDR = identity?.role?.grade === getRole('sdr')

  const deleteLeads = async (leadIds) => {
    if ( await confirmationDialog({
      confirmation: 'Êtes-vous sûr(e) de vouloir supprimer ces contacts ?',
    }) ) {
      deleteMany('leads', { ids: leadIds })

      unselectAll()
      notify(`Contact(s) supprimé(s) avec succès`, {'type' : 'success'})
      redirect('/leads')
    }
  }

  return !isPubeur ? (
    <Stack direction="row" alignItems="center" spacing={1}>
      { isSDR && localCurrentYear === process.env.REACT_APP_CURRENT_YEAR_ID && (
        <>
          <PubeurAssignation />
        </>
      )}

      { !isSDR && localCurrentYear === process.env.REACT_APP_CURRENT_YEAR_ID && (
        <>
          <StatusAssignation />
          <EventAssignation />
          <PubeurAssignation />
          <TaskAssignation />
          <TagAssignation />
          <LeadsFusion />
        
          <DeleteForeverIcon onClick={() => deleteLeads(props.selectedIds)} sx={{ color: '#e22d2d', cursor: 'pointer' }} />
        </>
      )}
    </Stack>
  ) : false
}

export default BulkMenu