import { useGetIdentity, useRecordContext, } from 'react-admin'
import { EditDialog } from "@react-admin/ra-form-layout"

import SchoolBilan from './SchoolBilan'
import SchoolInter from './SchoolInter'
import { getRole } from '../common/roles'
import SchoolEvents from './SchoolEvents'
import { SchoolInputContent } from './index'
import SchoolMissions from './SchoolMissions'
import SchoolOldStudents from './SchoolOldStudents'
import SchoolAdministration from './SchoolAdministration'
import { CustomLeadsTab, CustomLeadTabbedShowLayout } from '../Design/CustomTabs'
import { formatPhoneNumber, getValidEmail, toCamelCase, toLowerCase } from '../common/Formating'

const checkRightTypo = value => {
  return value.replace(/(l|L)ycee/g, (match) => {
    return match.charAt(0) === 'L' ? 'Lycée' : 'lycée'
  })
}

const transform = (data, { previousData }) => {
  const transformedData = {
    ...data,
    name: toCamelCase(checkRightTypo(data.name)),
    phone: formatPhoneNumber(data.phone),
    email: getValidEmail(data.email),
    address: {
      street: toLowerCase(data.address?.street),
      postalCode: data.address?.postalCode,
      city: toLowerCase(data.address?.city),
    },
  }

  const copiedData = transformedData
  delete copiedData['@@ra-many/schools/school_exits/school_id']
  delete copiedData['@@ra-many/schools/school_interventions/school_id']
  delete copiedData['@@ra-many/schools/school_administrations/school_id']

  Object.keys(copiedData).forEach(key => {
    if ( key !== 'address' && copiedData[key] === previousData[key] ) {
      delete copiedData[key]
    }
  })

  if (
    copiedData['address'].street === previousData['address'].street &&
    copiedData['address'].postalCode === previousData['address'].postalCode &&
    copiedData['address'].city === previousData['address'].city
  ) {
    delete copiedData['address']
  }

  let finalData = {}
  if ( Object.keys(copiedData).length > 0 ) {
    finalData = {
      ...copiedData,
    }
  }

  finalData = {
    ['@@ra-many/schools/school_exits/school_id']: data['@@ra-many/schools/school_exits/school_id'],
    ['@@ra-many/schools/school_interventions/school_id']: data['@@ra-many/schools/school_interventions/school_id'],
    ['@@ra-many/schools/school_administrations/school_id']: data['@@ra-many/schools/school_administrations/school_id'],
    ...finalData,
  }

  return finalData
}

const EditDialogTitle = () => {
  const record = useRecordContext()

  return record ? `Fiche lycée : ${record.name}` : 'Fiche lycée'
}

const SchoolEdit = () => (
  <EditDialog
    title={<EditDialogTitle />}
    maxWidth="md"
    mutationMode="optimistic"
    fullWidth
    sx={{
      '& .MuiDialogTitle-root': {
        backgroundColor: '#d7Eae8',
      },
    }}
    transform={transform}
  >
    <SchoolEditContent />
  </EditDialog>
)

const SchoolEditContent = () => {
  const { identity } = useGetIdentity()
  const localCenter = identity?.center
  const isPubeur = identity?.role?.grade < getRole('responsable')

  return (
    <CustomLeadTabbedShowLayout style={{ minHeight: '98vh' }}>
      <CustomLeadsTab label="Fiche">
        <SchoolInputContent />
      </CustomLeadsTab>

      { !isPubeur &&
        <CustomLeadsTab label="Événements" path="events">
          <SchoolEvents localCenter={localCenter} />
        </CustomLeadsTab>
      }

      { !isPubeur &&
        <CustomLeadsTab label="Rapports" path="missions">
          <SchoolMissions />
        </CustomLeadsTab>
      } 

      { !isPubeur &&
        <CustomLeadsTab label="Interventions" path="inter">
          <SchoolInter />
        </CustomLeadsTab>
      }

      { !isPubeur && // only for the event leader
        <CustomLeadsTab label="Administration" path="administration">
          <SchoolAdministration />
        </CustomLeadsTab>
      }

      { !isPubeur &&
        <CustomLeadsTab label="Bilan" path="bilan">
          <SchoolBilan />
        </CustomLeadsTab>
      }

      { !isPubeur &&
        <CustomLeadsTab label="Anciens élèves" path="anciens">
          <SchoolOldStudents />
        </CustomLeadsTab>
      }
    </CustomLeadTabbedShowLayout>
  )
}
  
export default SchoolEdit
