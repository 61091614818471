import { CircularProgress } from '@mui/material'

const UpdatingPage = () => (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: '-2', height: '100vh' }}>
    <h1>Mise à jour en cours en cours...</h1>
    
    <CircularProgress />
    
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '20px',
      textAlign: 'center',
      fontSize: '1.25rem',
      marginTop: '50px',
      backgroundColor: '#fafafb',
    }}>
      <span>Cette opération peut prendre quelques minutes...</span>
    </div>
  </div>
)

export default UpdatingPage
