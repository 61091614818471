import * as React from 'react'
import {
  CreateButton,
  Loading,
  TextField,
  TopToolbar,
  useGetIdentity,
} from 'react-admin'
import { List, Datagrid, IfCanAccess } from '@react-admin/ra-rbac'

import SiteShow from './SiteShow'
import SiteEdit from './SiteEdit'
import SiteCreate from './SiteCreate'
import { PostPagination } from '../common/Pagination'

import AddCircleIcon from '@mui/icons-material/AddCircle'
import { getRole } from '../common/roles'

const SiteList = () => {
  const CustomActions = () => (
    <TopToolbar>
      <IfCanAccess action="create">
        <CreateButton icon={<AddCircleIcon />} label="Ajouter un site" />
      </IfCanAccess>
    </TopToolbar>
  )

  return (
    <>
      <List
        pagination={<PostPagination />}
        perPage={50}
        actions={<CustomActions />}
        sx={{ '& .RaList-main': { maxWidth: '70%', margin: '0 auto' }, }}
      >
        <Datagrid rowClick="edit">
          <TextField label="Nom" source="name" />
          <TextField label="Téléphone" source="phone" />
        </Datagrid>
      </List>

      <SiteShow />
      <SiteEdit mutationMode="pessimistic" />
      <SiteCreate />
    </>
  )
}

export default SiteList
