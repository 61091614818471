import * as React from "react";
import { EditDialog } from '@react-admin/ra-form-layout';

import { ProfileInputContent } from './index';

const ProfileEdit = () => (
    <EditDialog
      title="Fiche utilisateur"
      mutationMode="pessimistic"
      maxWidth="sm"
      fullWidth
    >
      <ProfileInputContent />
    </EditDialog>
);

export default ProfileEdit;
