import React from 'react'
import { List } from 'react-admin'
import { Calendar  } from '@react-admin/ra-calendar'
import CalendarEventsCreate from './CalendarEventsCreate'
import CalendarEventsEdit from './CalendarEventsEdit'

const CalendarEventsList = () => (
  <List perPage={1000} pagination={false}>
    <Calendar />
    <CalendarEventsCreate />
    <CalendarEventsEdit />
  </List>
)

export default CalendarEventsList