import { AutocompleteArrayInput, BooleanInput, DateInput, ReferenceArrayInput, ReferenceInput, SimpleForm, SimpleFormIterator, TextInput } from 'react-admin'
import { ReferenceManyInput } from '@react-admin/ra-relationships'
import { Grid } from '@mui/material'
import { FormCard } from '../Design/Cards'

const SchoolInter = props => (
  <SimpleForm {...props}>
    <FormCard title="Interventions dans le lycée">
      <ReferenceManyInput label={false} reference="school_interventions" target="school_id">
        <SimpleFormIterator inline disableReordering>
          <DateInput label="Date" source="intervention_at" variant="outlined" />
          <TextInput label="Statut" source="status" variant="outlined" />
          <BooleanInput label="Avec cours" source="withLesson" variant="outlined" />

          <TextInput label="Commentaire" source="comment" variant="outlined" fullWidth />
          
          <TextInput label="Nom du contact" source="contact_name" variant="outlined" sx={{ maxWidth: '30%' }} />
          <TextInput label="Mail du contact" source="contact_email" variant="outlined" sx={{ maxWidth: '30%' }} />
          <TextInput label="Tel. du contact" source="contact_phone" variant="outlined" sx={{ maxWidth: '30%' }} />

          <ReferenceArrayInput
            source="pubeurs_ids"
            reference="profiles"
          >
            <AutocompleteArrayInput
              label="Pubeurs associés"
              optionText={(choice) => `${choice.first_name} ${choice.last_name}`}
              variant="outlined"
              fullWidth
            />
          </ReferenceArrayInput>
        </SimpleFormIterator>
      </ReferenceManyInput>
    </FormCard>
  </SimpleForm>
)

export default SchoolInter