import { useState, useEffect } from 'react'
import {
  useRecordContext,
  useCreate,
  useNotify,
  useUpdate,
  useRefresh,
  useGetIdentity,
} from 'react-admin'
import { Tooltip } from '@mui/material'
import { supabaseClient } from '../supabase'

import StarRateIcon from '@mui/icons-material/StarRate'
import StarOutlineIcon from '@mui/icons-material/StarOutline'

const CheckIfFavourited = ({currentUser, leadId, notify, refresh}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isFavourited, setIsFavourited] = useState(false)
  const [entryId, setEntryId] = useState(null)

  const handleCreate = async () => {
    try {
      const { data, error } = await supabaseClient
        .from('profiles_favourite')
        .insert({ profile_id: currentUser, lead_id: leadId, is_fav: true })
        .select()
  
  
      if (error) {
        console.error(error)
        notify('Une erreur est survenue lors de l\'ajout aux favoris')
        return
      }
  
      notify('Contact ajouté aux favoris', { type: 'success' })
      setIsFavourited(true)
      setEntryId(data[0].id)
      refresh()
    } catch (error) {
      console.error('Error creating new favourite: ' + error)
    }
  }
  
  const handleUpdate = async () => {
    try {
      const { data, error } = await supabaseClient
        .from('profiles_favourite')
        .update({is_fav: !isFavourited})
        .eq('id', entryId);
  
      if (error) {
        console.error(error)
        notify('Une erreur est survenue lors de la modification du favori')
        return
      }
    
      isFavourited
        ? notify('Contact retiré des favoris', { type: 'success' })
        : notify('Contact ajouté aux favoris', { type: 'success' })
    } catch (error) {
      console.error('Error updating new favourite: ' + error);
    } finally {
      setIsFavourited(isFavourited ? false : true)
      refresh()
    }
  }

  const fetchData = async () => {
    try {
      const { data, error } = await supabaseClient
        .from('profiles_favourite')
        .select()
        .eq('profile_id', currentUser);

      // get all favourites associated to the user
      if ( data.length === 0 ) return // No favourites all all

      // put all user favs in a array
      const favs = [];
      for (let i = 0; i < data.length; i++) {
        const keys = Object.keys(data[i]);
        const values = Object.values(data[i]);
        const result = {};

        for (let j = 0; j < keys.length; j++) {
          result[keys[j]] = values[j];
        }

        favs.push(result);
      }

      // finding association to the current lead
      const result = favs.filter(entry => entry.lead_id === leadId);

      if (result.length > 0) { // Lead is favourited
        setEntryId(result[0]?.id || null) // id of the entry in the table favourite
        setIsFavourited(result[0]?.is_fav || false) // true or false
      }
    } catch (error) {
      console.error('Error fetching user favourites: ' + error);
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (isLoading) return <></>;

  return isFavourited
    ? (
      entryId === null
        ? <Tooltip title="Ajouter ce contact à vos favoris"><StarOutlineIcon style={{ position: 'absolute', top: '18px' }} sx={{ ml: 1.5, cursor: 'pointer' }} onClick={() => handleCreate()} /></Tooltip>
        : <Tooltip title="Retirer contact de vos favoris"><StarRateIcon style={{ position: 'absolute', top: '18px' }} sx={{ ml: 1.5, cursor: 'pointer' }} onClick={() => handleUpdate()} /></Tooltip>
      )
    : (
      entryId === null
        ? <Tooltip title="Ajouter ce contact à vos favoris"><StarOutlineIcon style={{ position: 'absolute', top: '18px' }} sx={{ ml: 1.5, cursor: 'pointer' }} onClick={() => handleCreate()} /></Tooltip>
        : <Tooltip title="Ajouter ce contact à vos favoris"><StarOutlineIcon style={{ position: 'absolute', top: '18px' }} sx={{ ml: 1.5, cursor: 'pointer' }} onClick={() => handleUpdate()} /></Tooltip>
      )
}

export const LeadFavourite = () => {
  const notify = useNotify()
  const refresh = useRefresh()
  const { identity } = useGetIdentity()
  const record = useRecordContext()
  const currentUser = identity?.id
  
  return (
    record?.assigned_pubeur === currentUser && record?.id
      ? <>{`${record?.first_name ?? 'Chargement...'} ${record?.last_name ?? ''}`} <CheckIfFavourited currentUser={currentUser} leadId={record?.id} notify={notify} refresh={refresh} /></>
      : <>{`${record?.first_name ?? 'Chargement...'} ${record?.last_name ?? ''}`}</>
  )
}