import { supabaseClient } from '../supabase'
import { getRole } from './roles'

const fetchEvents = async (centerId, identity, dateFilter = false, catId = null, yearId = null) => {
  if ( ! centerId || ! identity ) return []

  const isAdmin = identity?.role?.grade === getRole('admin')
  const isPubeur = identity?.role?.grade < getRole('responsable')
  
  try {
    let query = supabaseClient
      .from('events')
      .select('id, name, date, end_at, type_id, center_id, qualifications_ids, current_year_id')

    // pubeur
    if ( isPubeur ) {
      query = supabaseClient
        .from('events')
        .select('id, name, date, end_at, type_id, qualifications_ids, event_pubeurs(id, pubeur_id)')
        .eq('event_pubeurs.pubeur_id', identity?.id) // Filter by pubeur_id
        .eq('current_year_id', yearId || process.env.REACT_APP_CURRENT_YEAR_ID)
    }

    // not admin
    if ( ! isAdmin && centerId !== '0000' ) {
      query.eq('center_id', centerId)
    }

    if ( ! isAdmin && catId && catId !== '0000' ) {
      query.eq('type_id', catId)
    }

    // pubeurs cannot get past events
    if ( isPubeur && dateFilter ) {
      query.or(`end_at.gte.${dateFilter},end_at.is.null`)
    } else if ( dateFilter ) {
      query.or(`date.gte.${dateFilter},end_at.is.null`)
    }

    if ( yearId && ! isAdmin ) {
      query.eq('current_year_id', yearId)
    }

    const { data, error } = await query

    if (error) {
      console.error('Error fetching events: ', error)
      return
    }
    
    return ( isPubeur && identity?.id )
      ? data.filter(event => event.event_pubeurs.length)
      : data
  } catch (error) {
    console.error('Error: ', error)
    return []
  }
}

export default fetchEvents