import React from 'react'
import {
  AutocompleteInput,
  ReferenceInput,
  SimpleForm, TextInput, useRecordContext
} from 'react-admin'
import { Grid, Typography } from '@mui/material'

import ParentList from './ParentList'
import ParentCreate from './ParentCreate'
import { isFormPhoneValid } from '../common/Formating'
import { TextInputCopy } from '../Design/CustomTextInput'
import { AssociatedLeadsList, AssignationDialogButton } from './ParentsLeadsAssignation'

import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import { filterBlankSpaces } from '../common/customTextFilter'

const ParentResources = {
  list: ParentList,
  create: ParentCreate,
  icon: FamilyRestroomIcon,
  options: {
    label: 'Parents',
  }
}

export const ParentInputContent = props => {
  const record = useRecordContext()
  const localCurrentYear = JSON.parse(localStorage.getItem('current_year')) ?? process.env.REACT_APP_CURRENT_YEAR_ID

  return (
    <SimpleForm {...props}>
      <TextInputCopy source="id" variant="outlined" fullWidth readOnly />

      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <TextInput source="first_name" label="Prénom" variant="outlined" fullWidth />
        </Grid>

        <Grid item xs={6} md={6}>
          <TextInput source="last_name" label="Nom" variant="outlined" fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <TextInput source="email" label="Adresse email" variant="outlined" fullWidth />
        </Grid>

        <Grid item xs={6} md={6}>
          <TextInput source="phone" label="Téléphone" variant="outlined" validate={isFormPhoneValid} fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4} md={4}>
          <TextInput source="address.street" label="Rue" variant="outlined" fullWidth />
        </Grid>

        <Grid item xs={4} md={4}>
          <TextInput source="address.postalCode" label="Code postal" variant="outlined" fullWidth />
        </Grid>

        <Grid item xs={4} md={4}>
          <TextInput source="address.city" label="Ville" variant="outlined" fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReferenceInput
            source="center_id"
            reference="centers"
          >
            <AutocompleteInput
              label="Centre"
              optionText="name"
              variant="outlined"
              filterToQuery={searchText => filterBlankSpaces(searchText)}
              options={{ type: 'search' }}
              readOnly={ localCurrentYear === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
              fullWidth
            />
          </ReferenceInput>
        </Grid>
      </Grid>

      <Typography variant="body2" color="textSecondary" mb={2}>Contact(s) étudiant(s) rattaché(s)</Typography>
      
      <AssociatedLeadsList record={record} />
      <AssignationDialogButton record={record} />
    </SimpleForm>
  )
}

export default ParentResources