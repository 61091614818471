import React, { useEffect, useState } from 'react'
import { IconButtonWithTooltip, Link, Button, useGetIdentity, useNotify, useRefresh } from 'react-admin'
import { useMediaQuery, Typography, Box, IconButton, Divider, Autocomplete, TextField } from '@mui/material'

import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'
import FileUpload from '../Design/FileUpload'
import { CommonDialog } from '../common/Dialog'
import { processSingleImage } from './imageProcessing'
import {
  truncateConversation,
  classifyMessages,
  mergeEqualMessages,
  mergeSeparatedMessages,
  handleDateMessages,
  handleRemovedUselessText,
  checkIfResponse,
  correctIsSenderMessage,
  handleAndroidMessagesEN,
  handleAndroidMessagesFR,
  getMessagesFromWords,
  reassembleMessages,
  checkAndSeparateDates,
} from './messagesProcessing'
import handleImageUploadWithTesseract from './TesseractExtraction'
import submitImport from './submitConversation'

import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'

import * as pdfjsLib from 'pdfjs-dist'
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.mjs`

let uploadState = {
  isMulti: false,
  lastFile: false,
  messages: [],
}

let convSystem = null
let isPDFiPhone = false
let conversationInfo = null

const ConversationImport = ({ leadId, leadName, refreshConversations, add = false }) => {
  const { identity } = useGetIdentity()
  const refresh = useRefresh()
  const notify = useNotify()
  const isAdmin = identity?.role?.grade === getRole('admin')
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    { noSsr: true }
  )

  convSystem = null
  conversationInfo = null

  const [open, setOpen] = useState(false)
  const [files, setFiles] = useState(null)
  const [listOfProfiles, setListOfProfiles] = useState([])
  const [listOfLeads, setListOfLeads] = useState([])
  const [pubeurInfo, setPubeurInfo] = useState( leadId && leadName ? { id: identity.id, name: `${identity?.first_name} ${identity?.last_name}` } : { id: null, name: null })
  const [leadInfo, setLeadInfo] = useState({ id: leadId ?? null, name: leadName ?? null })

  const [isLoading, setIsLoading] = useState(false)
  const [loadingDisplay, setLoadingDisplay] = useState({ fileName: null, message: null })

  const getOrCreateConversation = async () => {
    console.log('Checking if conversation already exists...')
  
    // Check if conversation already exists
    const { data, error } = await supabaseClient
      .from('conversations')
      .select('id')
      .eq('lead_id', leadInfo?.id)
      .eq('pubeur_id', pubeurInfo?.id)
  
    if (error) {
      console.error(error)
      return null
    }
  
    const convId = data[0]?.id
  
    if (data.length > 0) {
      console.log('Conversation already exists')
  
      // Fetch existing messages for comparison
      const { data: existingMessages, error: messagesError } = await supabaseClient
        .from('conversation_messages')
        .select('*')
        .eq('conversation_id', convId)
        .order('created_at', { ascending: true })
  
      if (messagesError) {
        console.error(messagesError)
        return null
      }
  
      return convId
    }
  
    // Create a new conversation if none exists
    console.log('Creating a new conversation...')
  
    const { data: newConversation, error: newConversationError } = await supabaseClient
      .from('conversations')
      .insert({
        lead_id: leadInfo.id,
        pubeur_id: pubeurInfo.id,
        current_year_id: process.env.REACT_APP_CURRENT_YEAR_ID,
        system: convSystem ?? 'iPhone'
      })
      .select()
  
    if (newConversationError) {
      console.error(newConversationError)
      return null
    }
  
    return newConversation[0].id
  }

  // *******************
  // Image upload
  // *******************
  
  const handleImageUpload = async (file) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // timeout to show loading message
        const fileName = file.name.replace('.jpg', '').replace('.jpeg', '').replace('.png', '')
        convSystem = 'iPhone' // only iPhone screenshots for now

        setLoadingDisplay(
          (prevState) => ({ ...prevState, fileName, message: 'Analyse de la capture d\'écran...' })
        )
      }, 1500)
    
      // Read the file as a data URL
      const reader = new FileReader()
      reader.onload = async (e) => {
        try {
          const originalImageData = e.target.result

          // Process the image for readability
          const processedImage = await processSingleImage(originalImageData)
          const imageBlob = dataURLToBlob(processedImage)

          if (!imageBlob) {
            console.error("Blob creation failed")
            notify('Erreur lors de l\'analyse de la capture d\'écran', { type: 'error' })
            return
          }

          // const conversationId = await getOrCreateConversation()

          // Send the image to the Supabase Edge function
          const formData = new FormData()
          formData.append('image', imageBlob, 'screenshot.png')

          const response = await fetch(`${process.env.REACT_APP_SUPABASE_URL}/functions/v1/conversations_v1`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_ANON_KEY}`,
            },
            body: formData,
          })

          const res = await response.json()

          // const response = await fetch(`${process.env.REACT_APP_SUPABASE_URL}/functions/v1/conversations_v2`, {
          //   method: 'POST',
          //   headers: {
          //     'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_ANON_KEY}`,
          //   },
          //   body: formData,
          // })

          // const res = await response.json()

          if (res?.error) {
            const extractedData = await handleImageUploadWithTesseract(originalImageData)
            processConversation(extractedData)
            resolve()
          }

          // const response2 = await fetch(`${process.env.REACT_APP_SUPABASE_URL}/functions/v1/conversations_processing`, {
          //   method: 'POST',
          //   headers: {
          //     'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_ANON_KEY}`, // If required
          //   },
          //   body: JSON.stringify({
          //     pubeur_id: pubeurInfo?.id,
          //     lead_id: leadInfo?.id,
          //     conversation_id: conversationId,
          //     messages: res.messages
          //   })
          // })

          // const res2 = await response2.json()

          await extractTextFromGoogleJSON(res?.data?.responses)
          resolve()
        } catch (error) {
          reject(error)
        }
      }

      const dataURLToBlob = (dataUrl) => {
        const arr = dataUrl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], { type: mime })
      }

      reader.readAsDataURL(file)
    })
  }

  const extractTextFromGoogleJSON = async (jsonRes) => {
    console.log('Extracting text from Google JSON response...')
    
    let words = jsonRes[0].textAnnotations.slice(1) // full conversation in plain text
    words = words.map(word => {
      const { x, y } = word.boundingPoly.vertices[0]; // Access x and y directly
      const width = word.boundingPoly.vertices[1].x - x; // Calculate width
      const height = word.boundingPoly.vertices[2].y - y; // Calculate height
      
      return { x, y, width, height, text: word.description };
    })
    
    const mess = getMessagesFromWords(words)
    const messages = reassembleMessages(mess)
    const pageData = jsonRes[0].fullTextAnnotation.pages[0]

    // console.log('messages', messages)
    
    const filteredMessagesReceiver = messages.filter(message => message.x < 180)
    const filteredMessagesSender = messages.filter(message => message.x >= 180)

    // console.log('filteredMessagesReceiver', filteredMessagesReceiver)
    // console.log('filteredMessagesSender', filteredMessagesSender)

    // perform first clean for dates
    const messagesReceiverWithCleanDates = checkAndSeparateDates(filteredMessagesReceiver)
    const messagesSenderWithCleanDates = checkAndSeparateDates(filteredMessagesSender)

    // group all messages in the right order
    let allMessages = [...messagesReceiverWithCleanDates, ...messagesSenderWithCleanDates]
    allMessages.sort((a, b) => a.y - b.y)

    // console.log('allMessages', allMessages)

    // perform another quick clean
    allMessages = allMessages.filter(message => {
      const lowerCaseText = message.text.trim().toLowerCase()
      return !['distribu', 'delivered', 'lu le', 'read on'].some(unwanted => lowerCaseText.startsWith(unwanted))
    })

    const truncatedAllMessages = truncateConversation(allMessages) // Filter messages to keep only those before a "+ " message

    // final data (matching with pdf extraction)
    const extractedData = [{
      pageNumber: 1,
      viewport: { width: pageData.width, height: pageData.height },
      textItems: truncatedAllMessages.map(message => ({
        str: message.text,
        transform: [1, 0, 0, 1, message.x, message.y],
        width: message.width,
        height: message.height
      }))
    }]

    processConversation(extractedData)
  }

  // *******************
  // PDF upload
  // *******************

  const handlePDFUpload = async (file) => {
    const extractedData = await extractTextAndPositionFromPDF(file)
    isPDFiPhone = true

    processConversation(extractedData)
  }

  const extractTextAndPositionFromPDF = async (pdfFile) => {
    console.log('Extracting text from PDF file...')
    
    const arrayBuffer = await pdfFile.arrayBuffer()
    const pdf = await pdfjsLib.getDocument(arrayBuffer).promise
  
    const extractedData = []
  
    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      const page = await pdf.getPage(pageNumber)
      const content = await page.getTextContent()
      const viewport = page.getViewport({ scale: 1 })
  
      const filteredTextItems = content.items
        .map(item => ({
          ...item,
          str: item.str.toLowerCase().replace(/^(Message texte • SMS|Message|iMessage|i Message|Lu|Text Message|distribué)\s*/i, '').trim()
        }))
        .filter(item => 
          item.str.length > 0 && // Filter out any empty strings
          !['Distribué', 'Delivered'].includes(item.str) // Exclude specific messages
        )

      const combinedTextItems = []
  
      filteredTextItems.forEach(item => {
        const itemText = item.str
        const previousItem = combinedTextItems.length > 0 ? combinedTextItems[combinedTextItems.length - 1] : null
  
        if (itemText === '?' || itemText === '!') {
          if (previousItem) {
            previousItem.str += ' ' + itemText
          }
        } else if (itemText.startsWith(',') || itemText.startsWith('.') || itemText.startsWith(' ,') || itemText.startsWith(' .') || itemText.startsWith(':')) {
          if (previousItem) {
            previousItem.str += itemText
          }
        } else if (previousItem && previousItem.str.endsWith(',')) {
          previousItem.str += ' ' + itemText
        } else {
          combinedTextItems.push(item)
        }
      })
  
      extractedData.push({
        pageNumber,
        viewport,
        textItems: combinedTextItems.map(item => ({
          str: item.str,
          transform: item.transform,
          width: item.width,
          height: item.height,
        })),
      })
    }
  
    return extractedData
  }

  // *******************
  // Parsing
  // *******************

  const processConversation = async (extractedData) => {
    console.log('Parsing conversation (image and PDF)...')
    
    const classifiedMessages = classifyMessages(extractedData)
    // console.log('classifiedMessages', classifiedMessages)
    const mergedEqualMessages = mergeEqualMessages(JSON.parse(JSON.stringify(classifiedMessages)), isPDFiPhone)
    const mergedSeparatedMessages = mergeSeparatedMessages(JSON.parse(JSON.stringify(mergedEqualMessages)))
    // console.log('mergedSeparatedMessages', mergedSeparatedMessages);
    
    let identifyDateMessages = []
    if ( mergedSeparatedMessages[0].text.toLowerCase().trim().startsWith('conversation with') ) {
      console.log('Analysing Android PDF in English...')
      convSystem = 'android'
      isPDFiPhone = false
      identifyDateMessages = handleAndroidMessagesEN(JSON.parse(JSON.stringify(mergedSeparatedMessages)))
    } else if ( mergedSeparatedMessages[0].text.toLowerCase().trim().startsWith('conversation avec') ) {
      console.log('Analysing Android PDF in French...')
      convSystem = 'android'
      isPDFiPhone = false
      identifyDateMessages = handleAndroidMessagesFR(JSON.parse(JSON.stringify(mergedSeparatedMessages)))
    } else {
      identifyDateMessages = handleDateMessages(JSON.parse(JSON.stringify(mergedSeparatedMessages)))
    }

    // const identifyResponseMessages = checkIfResponse(JSON.parse(JSON.stringify(identifyDateMessages)))
    // const correctedSenderResponseMessages = correctIsSenderMessage(JSON.parse(JSON.stringify(identifyResponseMessages)))
    // const finalMessages = handleRemovedUselessText(JSON.parse(JSON.stringify(correctedSenderResponseMessages)))
    const finalMessages = handleRemovedUselessText(JSON.parse(JSON.stringify(identifyDateMessages)))
    console.log('finalMessages', finalMessages);
    
    // setOriginalMessages(finalMessages) // for resetting purposes
    uploadState.messages.push(finalMessages)

    if ( ( uploadState.isMulti && uploadState?.lastFile ) || !uploadState.isMulti ) {
      // console.log('Full conversation', uploadState.messages)
      // setIsLoading(false)
      // setLeadInfo({ id: null, name: null})
      // setPubeurInfo({ id: null, name: null})
      // setOpen(false)
      // return

      // keep in same order !
      const submit = await submitImport(
        setLoadingDisplay, setOpen,
        identity, refresh, notify,
        convSystem, uploadState, conversationInfo,
        setIsLoading,
        refreshConversations,
        setPubeurInfo, pubeurInfo,
        setLeadInfo, leadInfo, isPDFiPhone
      )

      conversationInfo = submit.conversationInfo
      uploadState = submit.uploadState
    }
  }

  // *******************
  // File upload
  // *******************

  const handleFileUpload = async () => {
    console.log('Importing new files...')

    if ( ! files.length ) return

    if ( files.length > 1 ) {
      uploadState.isMulti = true
    }
    
    // initialize loading state
    setIsLoading(true)
    setLoadingDisplay(
      (prevState) => ({
        ...prevState,
        message: 'Importation de fichiers...'
      })
    )

    // Convert FileList to an array to work with multiple files
    const fileArray = Array.from(files)
    
    // Iterate over the array of files
    for (let i = 0; i < fileArray.length; i++) {
      const file = fileArray[i]
      const fileType = file.type
      const isValidImage = fileType === 'image/jpeg' || fileType === 'image/png'
      const isPdf = fileType === 'application/pdf'
  
      if ( isValidImage ) {
        await handleImageUpload(file) // Upload images
      } else if ( isPdf ) {
        await handlePDFUpload(file) // Upload PDFs
      }

      // Check if this is the last file in the array
      const isLastFile = i === fileArray.length - 2
      if ( isLastFile ) {
        uploadState.lastFile = true
      }
    }

    // used with Matthieu's system
    /*const { error } = await supabaseClient
      .rpc('rpc_update_paie_pubeur_after_bulk', { 'sender': identity?.id })

    if ( error ) {
      console.error('Error updating paie pubeur with rpc', error)
    }

    // Check if this is the last file and last message in the multi-upload process
    if (!uploadState.active || uploadState.lastFile) {
      uploadState = {
        isMulti: false,
        lastFile: false,
        messages: [],
      }
    
      setPubeurInfo({ id: null, name: null })
      setLeadInfo({ id: null, name: null })
      setIsLoading(false)
      conversationInfo = null
      notify('Conversation importée avec succès', { type: 'success' })
      setOpen(false)

      if ( refreshConversations ) {
        // function to refresh the conversations list
        refreshConversations()
      }
    }*/
  }

  // *******************
  // hooks to get pubeurs and leads
  // *******************

  useEffect(() => {
    if ( ! pubeurInfo.id || ( leadId && leadName ) ) return

    const fetchLeads = async () => {
      const { data, error } = await supabaseClient
        .from('leads')
        .select()
        .eq('assigned_pubeur', pubeurInfo.id)
      
      if (error) {
        console.error(error)
        return
      }

      const leads = data.map(lead => ({ id: lead.id, name: `${lead.first_name ?? ''} ${lead.last_name ?? ''}` }))

      setListOfLeads(leads)
    }

    fetchLeads()
  }, [pubeurInfo])

  useEffect(() => {
    const fetchProfiles = async () => {
      if ( isAdmin ) {
        const { data, error } = await supabaseClient
          .from('profiles')
          .select()
        
        if (error) {
          console.error(error)
          return
        }

        const profiles = data.map(profile => ({ id: profile.id, name: `${profile.first_name ?? ''} ${profile.last_name ?? ''}` }))
        setListOfProfiles(profiles)
        return
      }

      const { data, error } = await supabaseClient
        .from('profiles')
        .select()
        .eq('center_id', identity?.center?.id)
      
      if (error) {
        console.error(error)
        return
      }

      const profiles = data.map(profile => ({ id: profile.id, name: profile.first_name + ' ' + profile.last_name }))

      setListOfProfiles(profiles)
    }

    fetchProfiles()
  }, [])

  return (
    <>
      <CommonDialog
        title="Importer une conversation"
        open={open}
        handleClose={() => {
          setPubeurInfo({ id: null, name: null })
          setLeadInfo({ id: null, name: null })
          setOpen(false)
          setIsLoading(false)
          setLoadingDisplay({ fileName: null, message: null })
          
          convSystem = null
          conversationInfo = null
          uploadState = {
            isMulti: false,
            lastFile: false,
            messages: [],
          }
        }}
        size="sm"
        icon={<OpenInNewIcon />}
      >
        { !isLoading && (
          <>
            { !uploadState.isMulti && (
              <>
                {/* <Typography variant="h5">Importer une conversation SMS</Typography> */}

                { !leadId && !leadName && (
                  <Autocomplete
                    disablePortal
                    options={listOfProfiles}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => setPubeurInfo({ id: value?.id ?? null, name: value?.name ?? null })}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => {
                      return (
                        <TextField {...params} label="Pubeur" variant="outlined" />
                      )
                    }}
                  />
                )}

                { !leadId && pubeurInfo?.id && (
                  <Autocomplete
                    disablePortal
                    options={listOfLeads}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => setLeadInfo({ id: value?.id ?? null, name: value?.name ?? null })}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => {
                      return (
                        <TextField {...params} label="Contact étudiant" variant="outlined" />
                      )
                    }}
                  />
                )}
              </>
            )}

            { pubeurInfo.id && leadInfo.id && (
              <>
                <FileUpload action={setFiles} />

                { isMobile ? (
                  <Link
                    to="#"
                    onClick={handleFileUpload}
                    sx={{
                      display: 'block',
                      textAlign: 'center',
                      marginTop: '16px',
                      backgroundColor: '#b3dfea',
                      p: 1,
                      borderRadius: '6px'
                    }}
                  >
                    Continuer
                  </Link>
                ) : (
                  <Button label="Continuer" variant="contained" fullWidth onClick={handleFileUpload} />
                )}
              </>
            )}
          </>
        )}

        { isLoading && (
          <Box
            display="flex"
            flexDirection="column"
            textAlign="center"
          >
            <Typography variant="h6" mt={4} mb={4}>
              { loadingDisplay.fileName && `Chargement de ${loadingDisplay.fileName}` }
            </Typography>

            <Typography variant="body1">
              { loadingDisplay.message }
            </Typography>
          </Box>
        )}
      </CommonDialog>

      { !leadId && !leadName && (
        <Button
          startIcon={<OpenInNewIcon />}
          label="Importer une conversation"
          variant="outlined"
          color="primary"
          onClick={() => setOpen(true)}
        />
      )}

      { leadId && leadName && (
        isMobile ? (
          <Link
            onClick={() => setOpen(true)}
            sx={{ p: 1, backgroundColor: '#9DCDE3', borderRadius: '5px', display: 'flex' }}
          >
            <QuestionAnswerIcon />
            Importer une conversation SMS
          </Link>
        ) : add ? (
          <Link
            onClick={() => setOpen(true)}
            sx={{
              p: 1,
              backgroundColor: '#9DCDE3',
              borderRadius: '5px',
              display: 'flex',
              maxWidth: '60%',
              margin: '20px auto',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <QuestionAnswerIcon />
            Ajouter de nouveaux messages
          </Link>
        ) : (
          <IconButtonWithTooltip
            label="Importer une conversation SMS"
            sx={{ mt: 1, ml: 2, backgroundColor: '#9DCDE3' }}
            onClick={() => setOpen(true)}
          >
            <QuestionAnswerIcon />
          </IconButtonWithTooltip>
        )
      )}
    </>
  )
}

export default ConversationImport