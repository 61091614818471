import { AutocompleteInput, Create, ReferenceInput, SimpleForm, TextInput, useGetIdentity } from 'react-admin'
import { Typography } from '@mui/material'
import { validateAtLeastOneName, validateAtLeastOneContactInfo, validatePhone } from '../common/Validate'
import { getRole } from '../common/roles'
import { filterBlankSpaces } from '../common/customTextFilter'

const ParentCreate = (props) => {
    const { identity } = useGetIdentity()
    const localCenter = identity?.role?.grade === getRole('admin')
        ? { id: '0000', name: 'Tous les centres' }
        : identity?.center
    const localCurrentYear = JSON.parse(localStorage.getItem('localCurrentYear'))?.id || process.env.REACT_APP_CURRENT_YEAR_ID

    return (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="first_name" label="Prénom" validate={validateAtLeastOneName} fullWidth autoFocus />
            <TextInput source="last_name" label="Nom" validate={validateAtLeastOneName} fullWidth />
            <TextInput source="email" label="Email" validate={validateAtLeastOneContactInfo} fullWidth />
            <TextInput source="phone" label="Téléphone" validate={validateAtLeastOneContactInfo} fullWidth />
            <TextInput source="address.street" label="Adresse" fullWidth />
            <TextInput source="address.postalCode" label="Code postal" fullWidth />
            <TextInput source="address.city" label="Ville" fullWidth />
            
            <ReferenceInput
                source="center_id"
                reference="centers"
                filters={ identity?.role?.grade === getRole('admin') ? {} : { id: localCenter?.id }}
            >
                <AutocompleteInput
                label="Centre"
                optionText="name"
                filterToQuery={searchText => filterBlankSpaces(searchText)}
                options={{ type: 'search' }}
                readOnly={ localCurrentYear === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                fullWidth
                />
            </ReferenceInput>
        </SimpleForm>
    </Create>
)}

export default ParentCreate;
