import React from 'react';
import {
    useGetIdentity,
    useGetList,
    FilterList,
    FilterListItem,
} from 'react-admin';

import GetData from '../common/GetData'

import { Box, Chip } from '@mui/material';
import HomeWorkRoundedIcon from '@mui/icons-material/HomeWorkRounded';

const ParentsListFilter = () => {
  const centersData = GetData('centers')

  return (
    <Box
      // width="15em"
      order="-1"
      marginRight="1em"
      // style={{ backgroundColor: '#edf4f4', padding: '0 1em', marginTop: '10px', borderRadius: '5px'}}
    >
      <FilterList label="Centres" icon={<HomeWorkRoundedIcon />}>
        {centersData &&
          centersData?.map(center => (
            <FilterListItem
              key={center.id}
              label={
                <Chip
                  label={center.name}
                  size="small"
                />
              }
              value={{ 'center_id': center.id }}
            />
          ))}
      </FilterList>
    </Box>
  );
};

// export default React.memo(ParentsListFilter);
export default ParentsListFilter