import { useState, useEffect } from 'react'
import { Typography, Stack, Button, Divider, useMediaQuery } from "@mui/material"
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import StarIcon from '@mui/icons-material/Star'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import InterestsIcon from '@mui/icons-material/Interests'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Link, useGetIdentity } from "react-admin"
import { supabaseClient } from "../../supabase"

const DashboardPubeurLeads = ({ isMobile }) => {
  const { identity } = useGetIdentity()

  const [numOfFavouritedLeads, setNumOfFavouritedLeads] = useState(0)
  const [numOfInterestedLeads, setNumOfInterestedLeads] = useState(0)
  const [numOfNewLeads, setNumOfNewLeads] = useState(0)
  const [numOfTotalLeads, setNumOfTotalLeads] = useState(0)

  const getLeadsData = async () => {
    const today = new Date()
    const dayOfWeek = today.getDay()
    const lastMonday = new Date(today)
    lastMonday.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1))
    lastMonday.setHours(0, 0, 0, 0)

    // Fetch all relevant data in a single query
    const { data: leadsData, error } = await supabaseClient
      .from('leads_view')
      .select(`
        id,
        interested,
        assigned_pubeur,
        favourited_by
      `)
      .eq('assigned_pubeur', identity?.id)

    if (error) {
      console.error('Error fetching leads data: ', error)
      return
    }

    // Process the data for each count
    let favouritedCount = 0
    let interestedCount = 0
    let newLeadsCount = 0

    leadsData.forEach(lead => {
      if (lead.favourited_by?.length > 0) {
        const favouritedByPubeur = lead.favourited_by.find(entry => entry.is_fav)
        if (favouritedByPubeur) favouritedCount++
      }
      if (lead.interested) interestedCount++
      if (new Date(lead.created_at) >= lastMonday) newLeadsCount++
    })

    // Fetch assignation logs to count new leads assigned this week
    const { data: assignationData, error: assignationError } = await supabaseClient
      .from('assignation_logs')
      .select('lead_id')
      .eq('pubeur_id', identity?.id)
      .gte('assigned_at', lastMonday.toISOString())

    if (assignationError) {
      console.error('Error fetching assignation logs: ', assignationError)
      return
    }

    // Remove duplicates between leads and assignations
    const assignedLeadIds = assignationData.map(assignation => assignation.lead_id)
    newLeadsCount += assignedLeadIds.filter(id => !leadsData.some(lead => lead.id === id)).length

    setNumOfFavouritedLeads(favouritedCount)
    setNumOfInterestedLeads(interestedCount)
    setNumOfNewLeads(newLeadsCount)
    setNumOfTotalLeads(leadsData.length)
  }

  useEffect(() => {
    getLeadsData()
  }, [])

  return (
    <Card sx={{ width: isMobile ? '100%' : '33%', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px' }}>
      { !isMobile && (
        <CardMedia
          component="img"
          alt="mes contacts"
          height="140"
          image="https://www.leportagesalarial.com/wp-content/uploads/2016/09/consultant-liste-contacts.jpg"
          sx={{ opacity: .75 }}
        />
      )}

      <CardContent>
        <Typography variant="h6" gutterBottom>
          Mes contacts assignés
        </Typography>

        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: '#ede21a' }}>
                <StarIcon sx={{ color: '#fefefe' }} />
              </Avatar>
            </ListItemAvatar>

            <ListItemText primary={`${numOfFavouritedLeads} contacts favoris`} />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: '#52a1ce' }}>
                <InterestsIcon sx={{ color: '#fefefe' }} />
              </Avatar>
            </ListItemAvatar>

            <ListItemText primary={`${numOfInterestedLeads} contacts intéressés`} />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: '#91bcba' }}>
                <PersonAddAlt1Icon sx={{ color: '#fefefe' }} />
              </Avatar>
            </ListItemAvatar>

            <ListItemText primary={`${numOfNewLeads} nouveaux contacts cette semaine`} />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: '#ccc' }}>
                <PeopleAltIcon sx={{ color: '#fefefe' }} />
              </Avatar>
            </ListItemAvatar>

            <ListItemText primary={`${numOfTotalLeads} contacts au total`} />
          </ListItem>
        </List>

        <Divider variant="middle" />

        <CardActions>
          <Link to="leads/" mt={2}>Voir tous mes contacts</Link>
        </CardActions>
      </CardContent>
    </Card>
  )
}

export default DashboardPubeurLeads
