import {
  useEditContext,
  useGetIdentity,
  Datagrid,
  FunctionField,
  ReferenceManyCount,
  TextField,
} from 'react-admin'

import { EditDialog } from '@react-admin/ra-form-layout'

import EventBilan from './EventBilan'
import EventPubeurs from './EventPubeurs'
import { getRole } from '../common/roles'
import { EventInputContent } from './index'
import { CustomLeadsTab, CustomLeadTabbedShowLayout } from '../Design/CustomTabs'

const EventEdit = () => (
  <EditDialog
    title="Consultation événement"
    fullWidth
    maxWidth="md"
    mutationMode="optimistic"
  >
    <EventEditContent />
  </EditDialog>
)

const EventEditContent = () => {
  const { record } = useEditContext()
  const { identity } = useGetIdentity()
  const isRespo = identity?.role?.grade >= getRole('responsable') 
  const localCenter = identity?.center

  return (
    <CustomLeadTabbedShowLayout style={{ minHeight: '65vh' }}>
      <CustomLeadsTab label="Fiche">
        <EventInputContent />
      </CustomLeadsTab>

      { isRespo && (
        <CustomLeadsTab
          path="pubeurs"
          sx={{ root: !record?.pubeurs_selection && { display: 'none' } }}
          label={
            <div style={{ display: 'flex', flexDirection: 'row', contentAlign: 'space-between' }}>
              <ReferenceManyCount
                label="Nb pubeurs"
                reference="event_pubeurs"
                target="event_id"
                sx={{ mr: 1 }}
              />
              {`Pubeur${record?.event_pubeurs ? record?.event_pubeurs?.length < 1 ? '' : 's' : ''}`}
            </div>
          }
        >
          <EventPubeurs record={record} />
        </CustomLeadsTab>
      )}

      { isRespo && (
        <CustomLeadsTab label="Bilan" path="bilan">
          <EventBilan record={record} localCenter={localCenter} />
        </CustomLeadsTab>
      )}
    </CustomLeadTabbedShowLayout>
  )
}

export default EventEdit
