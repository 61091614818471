import { EditDialog } from '@react-admin/ra-form-layout';
import { Typography } from '@mui/material';

import { InboundContactInputContent } from './index';
import { useEditContext, useRecordContext, useRedirect, useRefresh } from 'react-admin';

const CustomEditTitle = () => (
  <span style={{ fontSize: '1.25em', fontWeight: 'bold' }}>
    Fiche contact entrant
  </span>
)
  

const InboundContactEdit = props => {
    const refresh = useRefresh()
    const redirect = useRedirect()

    const handleClose = () => {
      refresh()
      redirect('/inbound_contacts')
    }

    return (
      <EditDialog
        title={<CustomEditTitle />}
        fullWidth maxWidth="md"
        onClose={handleClose}
      >
        <InboundContactInputContent />
      </EditDialog>
    )
}

export default InboundContactEdit