import { useEffect, useState } from "react";
import {
  useListContext,
  useUpdateMany,
  AutocompleteInput,
  Button,
  CreateButton,
  ExportButton,
  FilterButton,
  FilterForm,
  ReferenceArrayInput,
  ReferenceInput,
  SelectColumnsButton,
  SearchInput,
  TopToolbar,
  useNotify,
} from "react-admin";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { Alert, Stack } from '@mui/material';

import ParentsAssignationDialog from "../common/ParentsAssignationDialog";

import { filterBlankSpaces, filterEmailSpaces } from '../common/customTextFilter';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export const ParentsActions = ({ exporter }, ...props) => {
  const { sort, filterValues, setSort, setFilters } = useListContext();

  return (
    <TopToolbar sx={{
        display: 'flex !important',
        justifyContent: 'left !important',
        alignItems: 'center !important',
        position: 'relative !important',
        width: '100%',
        paddingBottom: 0,
        minHeight: '60px',
      }}
    >
      <IfCanAccess action="create">
        <CreateButton icon={<AddCircleIcon />} label="Ajouter un parent" />
      </IfCanAccess>

      <IfCanAccess action="export">
        { exporter &&
          <ExportButton
            maxResults={99999}
            exporter={exporter}
            label="Exporter"
          />
        }
      </IfCanAccess>

      <SelectColumnsButton />

      { Object.keys(filterValues).length > 0 && (
        <Button color="error" label="filtres" onClick={() => setFilters({})}><CancelIcon /></Button>
      )}

      { ( sort.field !== 'created_at' || sort.order !== 'DESC' ) && (
        <Button color="error" label="triage" onClick={() => setSort({ field: 'created_at', order: 'DESC' })}><CancelIcon /></Button>
      )}
    </TopToolbar>
  )
}

export const ListToolbar = ({ isMobile }) => {
  const { selectedIds } = useListContext()

  const inputMaxWidth = isMobile ? { width: '100%' } : { maxWidth: '150px' }

  const ParentsFilter = [
    <SearchInput placeholder="Prénom" source="first_name@ilike" variant="outlined" alwaysOn style={inputMaxWidth} />,
    <SearchInput placeholder="Nom" source="last_name@ilike" variant="outlined" alwaysOn style={inputMaxWidth} />,
    <SearchInput placeholder="Téléphone" source="phone@like" variant="outlined" alwaysOn style={inputMaxWidth} />,

    <ReferenceInput
    source="assigned_pubeur"
    reference="profiles"
    alwaysOn
    >
      <AutocompleteInput
        label="Pubeur"
        variant="outlined"
        optionText={choice => `${choice?.first_name ?? ''} ${choice?.last_name ?? ''}`}
        filterToQuery={searchText => filterEmailSpaces(searchText)}
      />
    </ReferenceInput>,
  ]

  return (
    <Stack direction="row" style={selectedIds.length > 0 ? { padding: '0 10px 50px 10px' } : { padding: '0 10px' }}>
      <FilterForm filters={ParentsFilter} />
    </Stack>
  )
}

export const AssignBulkActionButtons = props => {
  const [updateMany, { error }] = useUpdateMany();

  const performAssignation = (parents, pubeur) => {
    return updateMany(
      'parents',
      {
        ids: parents,
        data: { assigned_pubeur: pubeur ?? null }
    });
  };

  const assign = (parents, pubeur) => {
    return performAssignation(parents, pubeur);
  };

  const unassign = (parents) => {
    return performAssignation(parents, null);
  };

  return (
    <ParentsAssignationDialog
      {...props}
      parents={props.selectedIds}
      assign={assign}
      unassign={unassign}
    />
  );
};
