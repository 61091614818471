import { SimpleForm, SimpleFormIterator, TextInput } from 'react-admin'
import { ReferenceManyInput } from '@react-admin/ra-relationships'

import { FormCard } from '../Design/Cards'

const SchoolAdministration = props => (
  <SimpleForm {...props}>
    <FormCard title="Administration">
      <ReferenceManyInput label={false} reference="school_administrations" target="school_id">
        <SimpleFormIterator inline>
          <TextInput label="Type" source="type" variant="outlined" />
          <TextInput label="Nom" source="name" variant="outlined" />
          <TextInput label="Email" source="email" variant="outlined" />
          <TextInput label="Téléphone" source="phone" variant="outlined" />
          <TextInput label="Commentaire" source="comment" variant="outlined" fullWidth />
        </SimpleFormIterator>
      </ReferenceManyInput>
    </FormCard>
  </SimpleForm>
)

export default SchoolAdministration