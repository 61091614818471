import { useState } from 'react'
import {
  useRefresh,
  useNotify,
  useGetIdentity,
  useUnselectAll,
  List,
  Datagrid,
  TextField,
  FunctionField,
  Button,
  ReferenceField,
  useListContext,
} from 'react-admin'

import { getRole } from '../../common/roles'
import { supabaseClient } from '../../supabase'
import { CommonDialog } from '../../common/Dialog'
import dataProvider from '../../supabase/dataProvider'
import { PostPagination } from '../../common/Pagination'

import LinearProgress from '@mui/material/LinearProgress'

import BookmarkIcon from '@mui/icons-material/Bookmark'

const LeadTagsAssignationContent = ({ saveTagAssignation }) => {
  const { identity } = useGetIdentity()
  const localCenter = identity?.center

  const [isLoading, setIsLoading] = useState(false)

  const handleBeforeAssignation = (action, selectedTagInfo) => {
    setIsLoading(true)
    saveTagAssignation(action, selectedTagInfo)
  }

  return (
    <List
      empty={false}
      disableSyncWithLocation
      pagination={<PostPagination />}
      perPage={50}
      resource="tags"
      exporter={false}
      filter={ identity?.role?.grade === getRole('admin') ? {} : { 'center_id' : localCenter?.id } }
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="Intitulé" source="name" />

        { identity?.role?.grade >= getRole('responsable') && (
          <ReferenceField label="Centre" source="center_id" reference="centers" link={false}>
            <TextField source="name"/>
          </ReferenceField>
        )}

        <FunctionField render={
          record => (
            isLoading
              ? 
                <LinearProgress color="secondary" />
              : 
                <>
                  <Button
                    label="Assigner ce tag"
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => handleBeforeAssignation('assignTag', record)}
                    sx={{ mr: 1 }}
                  />

                  <Button
                    label="Retirer ce tag"
                    variant="outlined"
                    color="warning"
                    size="small"
                    onClick={() => handleBeforeAssignation('unassignTag', record)}
                  />
                </>
          )
        }/>
      </Datagrid>
    </List>
  )
}

export const TagAssignation = () => {
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll('leads')
  const { selectedIds: leads, filterValues, pagination, sort } = useListContext()

  const params = {
    filter: filterValues,
    pagination: {
      page: pagination?.page,
      perPage: pagination?.perPage,
    },
    sort: {
      field: sort?.field,
      order: sort?.order,
    },
  }

  const [openTagAssignation, setOpenTagAssignation] = useState(false)

  const performTagAssignation = async (action, chunk, selectedTag) => {
    const selectedTagInfo = selectedTag
    const tagId = selectedTag.id

    for ( const lead of chunk ) {
      // first check if tag is already assigned
      const { data: existingTags, error: errorExistingTags } = await supabaseClient
        .from('leads')
        .select('tags_ids')
        .eq('id', lead)

      if (errorExistingTags) {
        console.error('Error fetching existing tags: ', errorExistingTags)
        return
      }

      await Promise.all([existingTags])

      if ( action === 'unassignTag' ) {
        const tagLeftAfterRemoval = existingTags[0]?.tags_ids ? existingTags[0].tags_ids.filter(tag => tag !== tagId) : []
        const updateInLead = { tags_ids: tagLeftAfterRemoval }

        // update the lead for specific tags
        if ( selectedTagInfo.name.toLowerCase() === 'stop rgpd' ) {
          updateInLead.stop_rgpd = false
        }

        if ( selectedTagInfo.name.toLowerCase() === 'intéressé(e) par la prépa' ) {
          updateInLead.interested = false
          updateInLead.not_interested = false
        }

        if ( selectedTagInfo.name.toLowerCase() === 'pas intéressé(e) par la prépa' ) {
          updateInLead.interested = false
          updateInLead.not_interested = false
        }

        const { error: errorUpdateLead } = await supabaseClient
          .from('leads')
          .update(updateInLead)
          .eq('id', lead)

        if (errorUpdateLead) {
          console.error('Error updating lead: ', errorUpdateLead)
        }
      } else {
        const newTags = existingTags[0]?.tags_ids ? [...existingTags[0].tags_ids, tagId] : [tagId]
        newTags.filter(tag => tag !== null)

        const updateInLead = { tags_ids: newTags }

        // update the lead for specific tags
        if ( selectedTagInfo.name.toLowerCase() === 'stop rgpd' ) {
          console.log('stop rgpd');
          
          updateInLead.stop_rgpd = true
        }

        if ( selectedTagInfo.name.toLowerCase() === 'intéressé(e) par la prépa' ) {
          updateInLead.interested = true
          updateInLead.not_interested = true
        }

        if ( selectedTagInfo.name.toLowerCase() === 'pas intéressé(e) par la prépa' ) {
          updateInLead.interested = false
          updateInLead.not_interested = true
        }

        const { error: errorUpdateLead } = await supabaseClient
          .from('leads')
          .update(updateInLead)
          .eq('id', lead)

        if (errorUpdateLead) {
          console.error('Error updating lead: ', errorUpdateLead)
        }
      }
    }

    await dataProvider.refetchData('leads_view', params)
    notify(`Tag ${ action === 'unassignTag' ? 'désassigné' : 'assigné' } avec succès`, {'type' : 'success'})
    refresh()
    unselectAll()
    setOpenTagAssignation(false)
    return
  }

  const saveTagAssignation = (action, selectedTagInfo) => {
    const chunkSize = 100
    const chunks = []

    for ( let i = 0; i < leads?.length; i += chunkSize ) {
      chunks.push(leads.slice(i, i + chunkSize))
    }

    if ( ! action ) return

    chunks.forEach(chunk => {
      performTagAssignation(action, chunk, selectedTagInfo)
    })
  }

  return (
    <>
      <CommonDialog
        open={openTagAssignation}
        handleClose={() => setOpenTagAssignation(false)}
        title={ leads?.length > 1 ?
          `Assignation d'une tâche sur ${leads?.length} contacts sélectionnés` :
          `Assignation d'une tâche sur le contact sélectionné`
        }
        size='md'
      >
        <LeadTagsAssignationContent saveTagAssignation={saveTagAssignation} />
      </CommonDialog>

      <Button
        label="Tags"
        startIcon={<BookmarkIcon style={{ fontSize: '1rem'}} />}
        size="small"
        color="primary"
        onClick={() => setOpenTagAssignation(true)}
        sx={{ fontSize: '.8rem', minWidth: '80px' }}
      />
    </>
  )
}

export default TagAssignation