import React, { useEffect, useState } from 'react'
import {
  SimpleForm, TextInput,
  useCreateContext,
  useEditContext,
  useGetIdentity,
  useNotify,
  useRecordContext,
  required,
  Button,
  useRefresh,
  ReferenceInput,
  AutocompleteInput,
  useRedirect,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
} from 'react-admin'
import { Grid, Typography, Chip } from '@mui/material'

import { getRole } from '../common/roles'
import EventTypeList  from './EventTypeList'
import { supabaseClient } from '../supabase'
import { toLowerCase } from '../common/Formating'
import { TextInputCopy } from '../Design/CustomTextInput'

import CancelIcon from '@mui/icons-material/Cancel'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

export default {
  list: EventTypeList,
  icon: TheaterComedyIcon,
  options: {
    label: 'Cat. d\'événements',
  },
}

const transformData = data => {
  const { name, nomQualification, ...rest } = data

  return {
    name: name,
    ...rest,
  }
}

const TypesEditToolbar = props => {
  const record = useRecordContext(props)

  return (
    <Toolbar style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <SaveButton />

      <DeleteWithConfirmButton
        confirmTitle="Supprimer la catégorie ?"
        confirmContent="Attention, cette action est irréversible. Êtes-vous sûr de vouloir supprimer cette catégorie ?"
        confirmColor="warning"
        translateOptions={{ name: record?.name }}
      />
    </Toolbar>
  )
}

export const EventTypesInputContent = props => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const { identity } = useGetIdentity()
  const editContext = useEditContext()
  const createContext = useCreateContext()

  const [listOfEventTypeQualification, setListOfEventTypeQualification] = useState(null)

  const [qualificationName, setQualificationName] = useState(null)
  const [qualificationCenter, setQualificationCenter] = useState(null)

  const fetchEventTypeQualifications = async () => {
    if ( ! record ) return
    
    let query = supabaseClient
      .from('types_qualifications')
      .select('*, centers(*)')
      .eq('type_id', record?.id)
    
    if ( identity?.role?.grade < getRole('admin') ) {
      query.eq('center_id', identity?.center?.id)
    }

    const { data, error } = await query
    
    if (error) {
      console.error('Error fetching tasks types', error)
      return
    }

    if ( data.length === 0 ) return

    const organisedDataByCenters = data.reduce((acc, qualification) => {
      if ( qualification.centers?.name ) {
        const centerName = qualification.centers?.name
        if (!acc[centerName]) {
          acc[centerName] = []
        }

        acc[centerName].push(qualification)

        return acc
      }
    }, {})

    const isAdmin = identity?.role?.grade === getRole('admin')
    const finalMapping = isAdmin
      ? Object.entries(organisedDataByCenters).map(([centerName, qualifications]) => (
          <div key={centerName}>
            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 1, ml: 1, textDecoration: 'underline' }}>
              {centerName}
            </Typography>

            {qualifications.map(qualification => (
              <Chip
                key={qualification.id + '-copy'}
                variant="contained"
                sx={{ m: 0.5, backgroundColor: '#C5DEDD', color: '#222', fontSize: '.8rem' }}
                label={qualification.name}
                onClick={() => {
                  navigator.clipboard.writeText(qualification.id);
                  notify('ID de qualification copié dans le presse-papier', { type: 'success' });
                }}
                icon={<ContentCopyIcon sx={{ fontSize: '1rem' }} />}
                onDelete={() => deleteEventTypeQualifications(qualification.id)}
              />
            )) }
          </div>
        ))
      : data.map(qualification => (
        <Chip
          key={qualification.id +'-copy'}
          variant="contained"
          sx={{ m: .5, backgroundColor: '#C5DEDD', color: '#222', fontSize: '.8rem' }}
          label={qualification.name}
          onClick={() => (
            navigator.clipboard.writeText(qualification.id),
            notify('ID de qualification copié dans le presse-papier', { type: 'success' })
          )}
          icon={<ContentCopyIcon sx={{ fontSize: '1rem' }} />}
          onDelete={() => deleteEventTypeQualifications(qualification.id)}
        />
      ))
    
    setListOfEventTypeQualification(finalMapping)
  }

  useEffect(() => {
    fetchEventTypeQualifications()
  }, [])

  const deleteEventTypeQualifications = async (qualificationId) => {
    const { data, error } = await supabaseClient
      .from('types_qualifications')
      .delete()
      .eq('id', qualificationId)

    if (error) {
      console.error('Error deleting event type qualification', error)
      notify('Une erreur est survenue', { type: 'error' })
      return
    }

    notify('Qualification supprimée', { type: 'success' })
    fetchEventTypeQualifications()
    refresh()
  }

  const saveNewQualification = async () => {
    const { error } = await supabaseClient
      .from('types_qualifications')
      .upsert({
        name: toLowerCase(qualificationName),
        type_id: record?.id,
        created_by: identity?.id ?? null,
        center_id: qualificationCenter ?? identity?.center?.id ?? null,
      })
    
    if (error) {
      console.error('Error saving new event type qualification', error)
      return
    }

    notify('Nouvelle qualification créée', { type: 'success' })
    fetchEventTypeQualifications()
    refresh()
  }

  const addNewEventTypeQualification = () => {
    saveNewQualification()
  }

  const handleSubmit = async (values, form) => {
    if ( identity?.role?.grade === getRole('responsable') ) {
      redirect('/types')
      return
    }

    const { id, ...rest } = transformData(values)

    try {
      return record?.id
        ? editContext.save(rest, form)
        : createContext.save(rest, form)
    } catch (error) {
      console.error('An error occurred:', error);
      notify('Une erreur est survenue', { type: 'error' });
    }
  }

  return (
    <SimpleForm
      {...props}
      shouldUnregister
      onSubmit={handleSubmit}
      toolbar={ identity?.role?.grade === getRole('admin') ? <TypesEditToolbar {...props} /> : null }
    >
      { record && record?.id && identity?.role?.grade === getRole('admin') && (
        <TextInputCopy variant="outlined" label="ID" source="id" fullWidth readOnly />
      )}

      <TextInput
        label="Titre"
        source="name"
        variant="outlined"
        validate={[required()]}
        autoFocus
        fullWidth
        readOnly={ identity?.role?.grade === getRole('responsable') ? true : false }
      />
      
      { record && record?.id && (
        <>
          <Grid container spacing={1}>
            { identity?.role?.grade === getRole('admin') && (
              <Grid item xs={4}>
                <ReferenceInput
                  reference="centers"
                  source="center_id"
                  alwaysOn
                >
                  <AutocompleteInput
                    label="Centre"
                    variant="outlined"
                    optionText="name"
                    filterToQuery={searchText => ({ 'name@ilike': searchText })}
                    onChange={(value, event) => setQualificationCenter(value)}
                  />
                </ReferenceInput>
              </Grid>
            )}

            <Grid item xs={4}>
              <TextInput
                variant="outlined"
                source="nomQualification"
                label="Nom de la qualification"
                onChange={e => setQualificationName(e.target.value)}
                fullWidth
              />
            </Grid>
  
            <Grid item xs={2}>
              <Button
                label="Ajouter"
                variant="outlined"
                sx={{ p: 1.1, mt: 1 }}
                onClick={() => addNewEventTypeQualification()}
                startIcon={<AddCircleOutlineIcon />}
                fullWidth
              />
            </Grid>
          </Grid>
  
          <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}>
            Qualifications existantes :
          </Typography>
  
          <div style={{ display: 'inline' }}>
            { listOfEventTypeQualification ?? `Aucune qualification n'a été créée pour le moment` }
          </div>
        </>
      )}
    </SimpleForm>
  )
}