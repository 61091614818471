import React, { useEffect, useState } from 'react'
import {
  Datagrid, FunctionField, Labeled, List, ReferenceField, SearchInput, SimpleForm, TextInput,
  useNotify, useRecordContext, useRefresh, useUnselectAll, required, useGetIdentity
} from 'react-admin'
import { Box, Button, Grid, Typography } from '@mui/material'

import { supabaseClient } from '../supabase'
import { CommonDialog } from '../common/Dialog'

import AddIcon from '@mui/icons-material/Add'

const renderFullName = record => <>
  <b>{record.first_name} {record.last_name}</b> {record.email} | {record.phone}
</>

const useUnassociatedLeads = (parentId, searchText) => {
  const [leads, setLeads] = useState([])

  const fetchUnassociatedLeads = async () => {
    const { data, error } = await supabaseClient.rpc('get_unassociated_leads', { 
      p_parent_id: parentId, 
      p_search_text: searchText
    })

    if (error) {
      console.error('Error fetching leads:', error)
      return
    }
    
    setLeads(data)
  }

  useEffect(() => {
    fetchUnassociatedLeads()
  }, [parentId, searchText])

  return leads
}

const LeadAssignationContent = ({ parentId, setOpen }) => {
  const [searchText, setSearchText] = useState("");
  const leads = useUnassociatedLeads(parentId, searchText);

  return (
    <List
      actions={<LeadListFilter setSearchText={setSearchText} />}
      pagination={false}
      empty={<p>Aucun contact étudiant à rattacher</p>}
      exporter={false}
    >
      <Datagrid
        data={leads}
        ids={leads.map(lead => lead.id)}
        bulkActionButtons={<AssignButton parentId={parentId} setOpenDialog={setOpen} />}
      >
        <FunctionField label="Prénom / Nom" render={renderFullName} />
      </Datagrid>
    </List>
  );
}

const assignLeadsToParent = async (parentId, leadIds) => {
  const { error } = await supabaseClient.from('lead_parents').insert(leadIds.map(leadId => ({
    lead_id: leadId,
    parent_id: parentId
  })));

  if (error) {
    console.error('Error inserting lead-parent relation:', error);
  }
}

const unassignLeadFromParent = async (parentId, leadId) => {
  const { error } = await supabaseClient.from('lead_parents').delete().match({
    lead_id: leadId,
    parent_id: parentId
  });

  if (error) {
    console.error('Error unassigning lead from parent:', error);
    throw error;
  }
}

const handleAction = async (actionFunc, leads, notify, unselectAll, refresh, closeDialog) => {
  try {
    await actionFunc(leads);
    notify('Assignation réussie', { type: 'success' });
    unselectAll();
    refresh();
    closeDialog();
  } catch {
    notify('Erreur lors de l\'action', 'warning');
  }
}

const AssignButton = ({ parentId, setOpenDialog, ...props }) => {
  const notify = useNotify();
  const unselectAll = useUnselectAll('parents');
  const refresh = useRefresh();

  return (
    <Button
      startIcon={<AddIcon />}
      size="large"
      color="primary"
      onClick={() => {
        handleAction(() => assignLeadsToParent(parentId, props.selectedIds), props.selectedIds, notify, unselectAll, refresh, () => setOpenDialog(false))
      }}
    >
      Rattacher
    </Button>
  )
}

const UnassignButton = ({ parentId, leadId, refresh }) => {
  const notify = useNotify();

  const handleClick = async () => {
    try {
      await unassignLeadFromParent(parentId, leadId);
      notify('Désassignation réussie', { type: 'success' });
      refresh();
    } catch {
      notify('Erreur lors de la désassignation', 'warning');
    }
  };

  return (
    <Button
      size="small"
      color="warning"
      onClick={handleClick}
    >
      Détacher
    </Button>
  );
};

const LeadListFilter = ({ setSearchText }) => (
  <Box width="15em" order="-1" marginRight="1em">
    <SearchInput source="leads" onChange={e => setSearchText(e.target?.value)} />
  </Box>
);

export const AssignationDialogButton = ({ record, ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)} style={{ marginTop: '15px' }}>
        Rattacher des contacts
      </Button>

      <AssignationDialog {...props} open={open} setOpen={setOpen} parentId={record?.id} />
    </>
  );
}

const AssignationDialog = ({ open, setOpen, parentId, ...props }) => (
  <CommonDialog open={open} handleClose={() => setOpen(false)} title={`Assignation de contacts`}>
    <LeadAssignationContent parentId={parentId} setOpen={setOpen} {...props} />
  </CommonDialog>
);

export const AssociatedLeadsList = ({ record, ...props }) => {
  const refresh = useRefresh();

  return (
    <List
      pagination={false}
      title="Contacts étudiants assignés"
      empty={<p>Aucun contact étudiant assigné à ce parent</p>}
      resource="lead_parents"
      filter={{ parent_id: record?.id }}
      exporter={false}
      actions={false}
      {...props}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField label="Prénom / Nom" source="lead_id" reference="leads">
          <FunctionField render={renderFullName} />
        </ReferenceField>
        
        <FunctionField 
          label="Action"
          render={leadRecord => (
            <UnassignButton parentId={record?.id} leadId={leadRecord.lead_id} refresh={refresh} />
          )}
        />
      </Datagrid>
    </List>
  );
};