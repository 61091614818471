import { useGetList, FilterList, FilterListItem, Button, useGetIdentity } from 'react-admin'
import { Chip, Stack } from '@mui/material'

import { Status } from './Status'
import MultiFieldSearch from '../common/MultiFieldSearch'

import FaceIcon from '@mui/icons-material/Face'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { getRole } from '../common/roles'

export const ProfileListFilter = ({ displayEnableSearch = true, displayRoleSearch = true, saveAssignation, props }) => {
  const {identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')

  const { data: roles } = useGetList(
    'roles',
    { 
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'grade', order: 'ASC' }
    }
  )

  const filteredRoles = isAdmin ? roles : roles?.filter(role => role.name !== 'Admin')

  return (
    <Stack spacing={1} mb={0.5} mr={ ! saveAssignation ? 1 : 0 } direction={ saveAssignation ? 'row' : 'column' } order={ ! saveAssignation ? '-1' : '' }>
      <MultiFieldSearch 
        label="Rechercher tout"
        resource="profiles"
        sx={{ minWidth: '300px'}}
        variant="outlined"
        fullWidth
      />

      { displayEnableSearch && (
        <FilterList label="Status" icon={<TrendingUpIcon />}>
          <FilterListItem
            label={
              <>
                Actif <Status status="enabled" />
              </>
            }
            value={{
              enabled: true,
            }}
          />
          
          <FilterListItem
              label={
              <>
                Inactif <Status status="disabled" />
              </>
            }
            value={{
              enabled: false,
            }}
          />
        </FilterList>
      )}

      { displayRoleSearch && (
        <FilterList label="Roles" icon={<FaceIcon />}>
          { filteredRoles?.map(role => (
            <FilterListItem
              key={role.id}
              label={
                <Chip
                  label={role.name}
                  size="small"
                />
              }
              value={{ role_id: role.id }}
            />
          ))}
        </FilterList>
      )}
    </Stack>
  )
}
