import React, { useState, useEffect } from 'react'
import { useDataProvider, useNotify, useUpdate, Link } from 'react-admin'
import { TextField as MuiTextField, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch, IconButton } from '@mui/material'

import { supabaseClient } from '../supabase'

import DeleteIcon from '@mui/icons-material/Delete'
import EventProfileSelect from './EventProfileSelect'

import CircularProgress from '@mui/material/CircularProgress'

const EventPubeurs = ({ record }) => {
  const [eventPubeurs, setEventPubeurs] = useState([])
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [update, { isPending, error }] = useUpdate()

  const [loading, setLoading] = useState({})
  const [paidHoursValues, setPaidHoursValues] = useState({})

  const handlePaiePubeur = async (values) => {
    const eventInfo = record
    const isDriver = values.is_driver_updated !== null ? values.is_driver_updated : values.is_driver
    const paieId = values.paie_id

    if ( eventInfo.type_id !== process.env.REACT_APP_EVENT_TYPE_MISSIONLYCEE ) return null

    const paieName = isDriver
                      ? `Mission lycée : ${eventInfo?.name} (conducteur)`
                      : `Mission lycée : ${eventInfo?.name}`

    const quantityValue = Number(values?.paidHoursValue ?? values?.paid_hours ?? eventInfo?.missionDuration)
    const duration = isDriver ? Number(eventInfo?.missionDurationAller) * 2 : Number(eventInfo?.missionDurationAller)
    const paieQuantity = quantityValue + duration
    
    const { data, error } = await supabaseClient
      .from('paie_pubeur')
      .update([{
        name: paieName,
        quantity: paieQuantity || 0 }])
      .eq('id', paieId)
      .select()

    if (error) {
      console.error('Error adding paie to pubeur:', error)
    }
  }

  const handlePaidHours = async (event, paidHoursValue) => {
    setLoading((prev) => ({ ...prev, [event.id]: true }))

    setTimeout(async () => {
      try {
        await update('event_pubeurs', {
          id: event.id,
          data: { paid_hours: paidHoursValue },
          previousData: { id: event.id }
        })

        // also update the paie
        event.paidHoursValue = paidHoursValue
        await handlePaiePubeur(event)
  
        setEventPubeurs((prevEventPubeurs) =>
          prevEventPubeurs.map((pubeur) =>
            pubeur.id === event.id ? { ...pubeur, paid_hours: paidHoursValue } : pubeur
          )
        )
      } catch (error) {
        console.error('Error updating paid hours:', error.message)
      } finally {
        setLoading((prev) => ({ ...prev, [event.id]: false }))
      }
    }, 500)
  }

  const handleBlur = (event) => {
    if (paidHoursValues[event.id]) {
      handlePaidHours(event, paidHoursValues[event.id])
    }
  }

  const handlePaidHoursChange = (record, value) => {
    const pubeurId = record.id
    setPaidHoursValues((prev) => ({ ...prev, [pubeurId]: value }))
  }

  const handleToggleDriver = (values) => {
    const newIsDriverValue = values.is_driver === null ? true : !values.is_driver

    update(
      'event_pubeurs',
      {
        id: values.id,
        data: { is_driver: newIsDriverValue },
        previousData: values,
      }
    )

    values.is_driver_updated = newIsDriverValue

    // paie pubeur
    handlePaiePubeur(values)

    if ( error ) {
      console.error('Error updating leader status', error.message)
    }

    setEventPubeurs(eventPubeurs.map((e) => e.id === values.id ? { ...e, is_driver: values.is_driver === null ? true : values.is_driver ? false : true } : e))
  }

  const handleToggleLeader = (values) => {
    update(
      'event_pubeurs',
      {
        id: values.id,
        data: { is_leader: values.is_leader === null ? true : values.is_leader ? false : true },
        previousData: values,
      }
    )

    if ( error ) {
      console.error('Error updating leader status', error.message)
    }

    setEventPubeurs(eventPubeurs.map((e) => e.id === values.id ? { ...e, is_leader: values.is_leader === null ? true : values.is_leader ? false : true } : e))
  }

  const handleDeletePubeur = async (values) => {
    try {
      await dataProvider.delete('event_pubeurs', {
        id: values.id,
      })

      if ( values.paie_id ) {
        // also delete the paie line
        await dataProvider.delete('paie_pubeur', {
          id: values.paie_id,
        })
      }

      notify('Ligne de paie retirée', { type: 'info' })
      
      setEventPubeurs(eventPubeurs.filter((e) => e.id !== values.id))
    } catch (error) {
      notify('Error removing pubeur', { type: 'warning' })
    }
  }

  const fetchEventPubeurs = async () => {
    const { data, error } = await supabaseClient
      .from('event_pubeurs')
      .select('id, is_leader, is_driver, paid_hours, paie_id, pubeur_id, pubeurInfo: profiles(*)')
      .eq('event_id', record.id)

    if (error) {
      console.error('Error fetching event pubeurs:', error.message)
      return
    }
    
    setEventPubeurs(data)
    setPaidHoursValues(data.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.paid_hours }), {}))
  }

  const handleRefresh = () => {
    fetchEventPubeurs()
  }

  const isEventReady = record && record.type_id === process.env.REACT_APP_EVENT_TYPE_MISSIONLYCEE
    ? record?.missionStartHidden && record?.missionStartAff && record?.timeStart && record?.missionDuration
    : true

  useEffect(() => {
    if ( ! record ) return

    fetchEventPubeurs()
  }, [record])

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
        { 
          isEventReady
            ? <EventProfileSelect eventInfo={record} listRefresh={handleRefresh} />
            : <p style={{ margin: '40px 90px 0 0', fontWeight: 'bold' }}>Veuillez compléter les informations de la mission lycée pour pouvoir y ajouter des pubeurs (débuts d'affichage, heure de départ et nombre d'heures sur place).</p>
        }
      </Stack>

      { eventPubeurs.length ? (
        <TableContainer sx={{ marginTop: '2vh' }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Pubeur</TableCell>
                <TableCell>Leader du groupe</TableCell>
                <TableCell>Conducteur</TableCell>
                <TableCell>Heures sur place</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              { eventPubeurs?.map((record) => (
                <TableRow key={record.pubeurInfo.id}>
                  <TableCell>
                    <Link to={`/profiles/${record.pubeurInfo.id}`} target="_blank">{record.pubeurInfo.first_name} {record.pubeurInfo.last_name}</Link>
                  </TableCell>

                  <TableCell>
                    <Switch
                      checked={!!record.is_leader}
                      onChange={() => handleToggleLeader(record)}
                    />
                  </TableCell>

                  <TableCell>
                    <Switch
                      checked={!!record.is_driver}
                      onChange={() => handleToggleDriver(record)}
                    />
                  </TableCell>

                  <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <MuiTextField
                        defaultValue={record.paid_hours}
                        onChange={(e) => handlePaidHoursChange(record, e.target.value)}
                        variant="outlined"
                        sx={{ maxWidth: '75px' }}
                        onBlur={() => handleBlur(record)}
                      />
                      {loading[record.id] && <CircularProgress size={20} sx={{ marginLeft: 1 }} />}
                    </div>
                  </TableCell>

                  <TableCell>
                    <IconButton onClick={() => handleDeletePubeur(record)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : <p style={{ textAlign: 'center', marginTop: '10vh' }}>Aucun pubeur n'a encore été assigné à cet événement.</p> }
    </>
  )
}

export default EventPubeurs