import { EditDialog } from '@react-admin/ra-form-layout'
import { useGetIdentity } from 'react-admin'

import { ContentEventsInput } from '.'
import { supabaseClient } from '../supabase'

const CalendarEventsEdit = () => {
  const { identity } = useGetIdentity()

  const handleTransform = (eventData) => {
    const { id, title, start, end, profile_id, contact_id } = eventData
  
    const cleanStart = new Date(start).toISOString()
    const cleanEnd = new Date(end).toISOString()

    if ( contact_id ) {
      const createLeadRdv = async () => {
        const { error } = await supabaseClient
          .from('lead_rdv')
          .insert({
            lead_id: contact_id,
            calendar_event_id: id,
          })
  
        if (error) {
          console.error('Error creating lead_rdv', error)
          return
        }
  
        console.log('RDV lead créé')
      }

      createLeadRdv()
    }
  
    return {
      title,
      id,
      allDay: false,
      start: cleanStart,
      end: cleanEnd,
      profile_id: profile_id ?? identity?.id,
    }
  }

  return (
    <EditDialog transform={handleTransform}>
      <ContentEventsInput />
    </EditDialog>
  )
}

export default CalendarEventsEdit