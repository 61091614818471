import { TabbedShowLayout, Tab } from 'react-admin'
import { styled } from '@mui/material/styles'
import tabClasses from '@mui/material/Tab/tabClasses'

export const CustomLeadsTab = styled(Tab)(({ theme }) => ({
  textTransform: "initial",
  height: 50,
  width: 150,
  color: (theme.vars || theme).palette.common.white,
  opacity: 0.5,
  borderBottom: "2px solid #daedea",
  "&:before": {
    position: "absolute",
    top: 50,
    left: 75,
    content: '" "',
    height: 30,
    width: 30,
    background: "transparent",
    transformOrigin: "0% 0%",
    transform: "rotate(45deg)",
    boxShadow: `0 0 0 150px #6ba39c`,
    zIndex: -1,
    opcaity: 0
  },
  [`&.${tabClasses.selected}`]: {
    opacity: 0.7,
    color: (theme.vars || theme).palette.common.white,
    borderBottom: 'none',
    "&:before": {
      opacity: 1,
      top: 42,
    }
  }
}))

export const CustomLeadTabbedShowLayout = styled(TabbedShowLayout)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-root': {
    borderBottom: 'none !important'
  },
  '& .MuiDivider-root': {
    display: 'none',
  },
}))
