import * as React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Toolbar,
  Grid,
} from '@mui/material'

import { confirmable } from 'react-confirm'
import WarningIcon from '@mui/icons-material/Warning';

const ConfirmationDialog = ({ show, proceed, confirmation, options }) => (
  <Dialog
    open={show}
    onClose={proceed}
    fullWidth
    maxWidth="sm"
  >
    <DialogTitle sx={{ m: 0, p: 0 }}>
      <Toolbar sx={{ color: '222', backgroundColor: '#f7a74c', fontWeight: 500 }}>
        <WarningIcon style={{ color: '#fff', marginRight: '10px' }} />
        Vous allez effectuer une action irréversible
      </Toolbar>
    </DialogTitle>

    <DialogContent  sx={{ m: 2, pt: 4 }}>
      <p>{confirmation}</p>

      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button onClick={() => proceed(true)} color="success" variant="outlined">
            CONFIRMER
          </Button>
        </Grid>

        <Grid item>
          <Button onClick={() => proceed(false)} color="warning" variant="contained">
            ANNULER
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
)

export default confirmable(ConfirmationDialog)