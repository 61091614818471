import {
    email,
    required,
    useCreateContext,
    useEditContext,
    useRecordContext,
    AutocompleteInput,
    Labeled,
    ReferenceInput,
    SimpleForm,
    TextField,
    TextInput,
} from "react-admin";
import {
    Grid,
    Typography,
} from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';

import SiteList from './SiteList'
import { TextInputCopy } from "../Design/CustomTextInput"
import { formatPhoneNumber, isFormPhoneValid, toCamelCase, toLowerCase } from '../common/Formating'

export default {
  list: SiteList,
  icon: RoomIcon,
  options: {
    "label": "Sites",
  }
}

const transformData = data => {
  const { name, phone, address, ...rest } = data

  return {
    name: toCamelCase(name),
    phone: formatPhoneNumber(phone),
    address: {
        street: toLowerCase(address?.street),
        postalCode: address?.postalCode,
        city: toLowerCase(address?.city),
    },
    ...rest,
  }
}

export const SiteInputContent = props => {
    const record = useRecordContext()
    const editContext = useEditContext()
    const createContext = useCreateContext()

    const handleSubmit = (values, form) => {
      const transformedValues = transformData(values)

      return record && record.id
        ? editContext.save(transformedValues, form)
        : createContext.save(transformedValues, form)
    }

    return (
      <SimpleForm {...props} onSubmit={handleSubmit}>
        { record && record.id && (
          <Grid container mb={2}>
            <Grid item xs={12}>
              <TextInputCopy source="id" fullWidth disabled />
            </Grid>
          </Grid>
        )}

        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <TextInput fullWidth label="Nom" source="name" variant="outlined" validate={[required()]} autoFocus />
          </Grid>

          <Grid item xs={6} md={6}>
            <TextInput fullWidth label="Téléphone" source="phone" variant="outlined" validate={[required(), isFormPhoneValid]} />
          </Grid>
        </Grid>

        <Typography color="textSecondary" variant="body2">
          Adresse principale
        </Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <TextInput fullWidth label="Rue" source="address.street" variant="outlined" validate={[required()]} />
          </Grid>

          <Grid item xs={4} md={3}>
            <TextInput fullWidth label="Code postal" source="address.postalCode" variant="outlined" validate={[required()]} />
          </Grid>
          
          <Grid item xs={8} md={4}>
            <TextInput fullWidth label="Ville" source="address.city" variant="outlined" validate={[required()]} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <ReferenceInput
              source="center_id"
              reference="centers"
            >
              <AutocompleteInput
                label="Centre"
                optionText="name"
                variant="outlined"
                validate={[required()]}
                filterToQuery={searchText => ({ 'name@ilike': searchText })}
                fullWidth
              />
            </ReferenceInput>
          </Grid>
        </Grid>
      </SimpleForm>
    )
}
