import React, { useEffect, useState } from 'react'
import { ReferenceField, RichTextField } from 'react-admin'
import { Typography } from '@mui/material'

import { supabaseClient } from '../../supabase'
import TaskActionsButtons from './TaskActionsButtons'

const LeadTaskExpand = ({refresh, setRecordProgress, ...props}) => {
  const [parcoursTree, setParcoursTree] = useState(null)

  const updateTaskProgress = async (progress) => {
    const { error } = await supabaseClient
      .from('lead_tasks')
      .update({ progress })
      .eq('id', props.record?.id)
    
    if (error) {
      console.error('Error updating task progress', error)
      return
    }

    refresh()
    setRecordProgress(progress)
  }

  const calculateProgress = (node) => {
    if ( ! node || ( ! node.children && node.action?.name !== 'Traité' ) ) return 0 // If no children, no progress.

    let unCompletedPaths = 0
    let isCompleted = false
    let steps = 0

    // Recursive function to traverse the tree
    const traverse = (currentNode) => {
      if ( ! currentNode) return false
      if ( ! currentNode.children && currentNode.action?.name !== 'Traité' ) return false // If no children, end of branch

      if ( currentNode.action?.name === 'Traité' && currentNode.done ) {
        isCompleted = true
      } else {
        if ( currentNode.done ) {
          steps++
          unCompletedPaths--
        }

        unCompletedPaths++

        // Iterate through each child to continue the path
        currentNode.children.forEach(child => {
          if ( child.action.name === 'En attente' ) {
            // we jump the child action
            traverse(child.children[0])
          } else {
            traverse(child)
          }
        })
      }
    }

    // Start from the root node's children
    if (node.children) {
      node.children.forEach(child => traverse(child))
    }

    // Calculate the percentage of completed paths
    const finalProgress = isCompleted
      ? 100
      : unCompletedPaths === steps
        ? 0
        : steps > 0
          ? Math.round((steps / unCompletedPaths) * 100)
          : 0
    
    updateTaskProgress(finalProgress)
  }

  useEffect(() => {
    if (parcoursTree) {
      calculateProgress(parcoursTree)
    }
  }, [parcoursTree])

  useEffect(() => {
    const fetchParcoursTree = async () => {
      const { data, error } = await supabaseClient
        .from('lead_tasks')
        .select('*')
        .eq('task_id', props.record?.task_id)
        .eq('lead_id', props.record?.lead_id)
    
      if (error) {
        console.error('Error fetching task actions', error)
        return
      }
    
      if (!data || data.length === 0) return
    
      setParcoursTree(data[0].tree)
    }
    
    fetchParcoursTree()
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '65%' }}>
        { props.leadRecord?.id && (
          <ReferenceField
            label="Description"
            source="task_id"
            reference="tasks"
            link={false}
          >
            <RichTextField source="description" />
          </ReferenceField>
        )}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', width: '35%', padding: '1em' }}>
        <Typography variant="h6" mb={2} color="textSecondary">Actions à effectuer</Typography>

        { parcoursTree ? (
          <TaskActionsButtons parcoursTree={parcoursTree} setParcoursTree={setParcoursTree} refresh={refresh} {...props} />
        ) : <i>Chargement des actions...</i> }
      </div>
    </div>
  )
}

export default LeadTaskExpand