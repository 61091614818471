import { useEffect, useState } from 'react'
import { Button, useNotify, useRedirect } from 'react-admin'
import { Box, Chip, Stack, Grid, Typography, Divider } from '@mui/material'

import Tooltip from '@mui/material/Tooltip'
import { supabaseClient } from '../supabase'
import scrollToTop from '../common/scrollToTop'

import SellRoundedIcon from '@mui/icons-material/SellRounded'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DeleteIcon from '@mui/icons-material/Delete'

const ConversationMessages = ({ convId, convSystem, refreshConversations }) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const [messages, setMessages] = useState(null)
  let previousSenderType = null

  const fetchMessages = async () => {
    const { data, error } = await supabaseClient
      .from('conversation_messages')
      .select('*')
      .eq('conversation_id', convId)
      .order('created_at', { ascending: true })

    if (error) {
      console.error(error)
      return null
    }

    setMessages(data)
  }

  const updatePaidMessage = async (msgId, status) => {
    const { error } = await supabaseClient
      .from('conversation_messages')
      .update({ paid: status })
      .eq('id', msgId)

    if (error) {
      console.error(error)
      return null
    }

    notify(status ? 'Ce message est passé en "à payer"' : 'Ce message est passé en "ne pas payer"', { type: 'success' })
    fetchMessages()
  }

  const deleteAllMessages = async () => {
    const { error } = await supabaseClient
      .from('conversations')
      .delete()
      .eq('id', convId)

    if (error) {
      console.error(error)
      notify('Erreur lors de la suppression des messages', { type: 'warning' })
      return null
    }

    setMessages([])
    notify('Tous les messages ont été supprimés avec succès', { type: 'success' })

    if ( refreshConversations ) {
      refreshConversations()
      return
    }

    redirect(process.env.REACT_APP_WEBSITE_URL +'/conversations')
  }

  useEffect(() => {
    fetchMessages()
  }, [convId])

  return messages ? (
    <Stack spacing={1}>
      {messages.map((msg, index) => {
        const isRemunerated = msg.paid
        const showSenderType = convSystem === 'iphone' ? false : msg.sender_type !== previousSenderType
        previousSenderType = msg.sender_type

        return !msg.isDate ? (
          <div key={msg.id}>
            <Grid container spacing={1}>
              <Grid item xs={msg.sender_type === 'pubeur' ? 11 : 12}>  
                <Box
                  sx={{
                    padding: msg.isResponse ? '.7em' : '1em',
                    backgroundColor: msg.isResponse ? '#80a0b2' : msg.sender_type === 'pubeur' ? '#56a39f' : '#dcdcdc',
                    color: msg.sender_type === 'pubeur' ? '#fefefe' : '#222',
                    borderRadius: '0.5em',
                    textAlign: msg.sender_type === 'pubeur' ? 'right' : 'left',
                    maxWidth: msg.isResponse ? '60%' : '70%',
                    marginLeft: msg.sender_type === 'pubeur' ? 'auto' : '0',
                  }}
                  mt={showSenderType ? 2 : 0}
                >
                  <Typography variant="body2" sx={{ fontWeight: 400, fontSize: msg.isResponse ? '.7rem' : undefined }} gutterBottom>
                    {msg.text}
                  </Typography>

                  <Typography variant="subtitle2" sx={{ fontSize: '.7em', fontWeight: 300 }} gutterBottom>
                    {`${new Intl.DateTimeFormat('fr-FR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                        timeZone: 'UTC'
                    }).format(new Date(msg.created_at))}`}
                  </Typography>
                </Box>
              </Grid>

              { msg.sender_type === 'pubeur' && (
                <Grid item xs={1} sx={{ display: 'flex', display: 'flex', verticalAlign: 'middle', alignItems: 'center' }}>
                  { isRemunerated && (
                    <Tooltip title="Ne pas compter comme à payer">
                      <SellRoundedIcon
                        sx={{ fontSize: '1.5em', color: 'green', cursor: 'pointer' }}
                        onClick={() => updatePaidMessage(msg.id, false)}
                      />
                    </Tooltip>
                  )}

                  { ! isRemunerated && (
                    <Tooltip title="Compter comme SMS à payer">
                      <SellRoundedIcon
                        sx={{ fontSize: '1.5em', color: 'lightgrey', cursor: 'pointer' }}
                        onClick={() => updatePaidMessage(msg.id, true)}
                      />
                    </Tooltip>
                  )}
                </Grid>
              )}
            </Grid>
          </div>
        ) : <div
          key={msg.id}
          style={{ textAlign: 'center', marginTop: '1em', fontWeight: '600' }}
        >
          <Chip label={msg.text} sx={{ backgroundColor: 'transparent', color: '#999', fontSize: '.7rem' }} />
        </div>
      })}

      <br/>

      <Divider />

      <Stack direction="row" spacing={2} justifyContent="center">
        <Button
          label="Haut de la page"
          startIcon={<ArrowUpwardIcon />}
          variant="contained"
          color="secondary"
          onClick={scrollToTop}
          sx={{ mt: 4 }}
        />

        <Button
          label="Supprimer la conversation"
          startIcon={<DeleteIcon />}
          variant="contained"
          color="error"
          onClick={deleteAllMessages}
          sx={{ mt: 4 }}
        />
      </Stack>

    </Stack>
  ) : null
}

export default ConversationMessages
