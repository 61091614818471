import { useState } from 'react'
import {
  useRefresh,
  useNotify,
  useGetIdentity,
  useUnselectAll,
  List,
  Datagrid,
  TextField,
  FunctionField,
  Button,
  ReferenceField,
  useListContext,
} from 'react-admin'

import sendMail from '../../common/SendMail'
import { getRole } from '../../common/roles'
import { supabaseClient } from '../../supabase'
import { CommonDialog } from '../../common/Dialog'
import dataProvider from '../../supabase/dataProvider'
import { PostPagination } from '../../common/Pagination'
import TasksTopToolbar from '../../tasks/TasksTopToolbar'
import TasksFiltersBar from '../../tasks/TasksFiltersBar'
import createNotification from '../../common/createNotification'

import LinearProgress from '@mui/material/LinearProgress'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'


const LeadTasksAssignationContent = ({ saveTaskAssignation }) => {
  const { identity } = useGetIdentity()
  const localCenter = identity?.center
  
  const [isLoading, setIsLoading] = useState(false)

  const handleBeforeAssignation = (action, record) => {
    setIsLoading(true)
    saveTaskAssignation(action, record.id)
  }

  return (
    <List
      empty={false}
      disableSyncWithLocation
      pagination={<PostPagination />}
      perPage={50}
      resource="tasks"
      exporter={false}
      filter={ identity?.role?.grade === getRole('admin') ? {} : { 'center_id' : localCenter?.id } }
      actions={<TasksTopToolbar />}
      filters={TasksFiltersBar}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField label="Type" source="type_id" reference="tasks_types">
          <TextField source="name" />
        </ReferenceField>

        <TextField label="Intitulé" source="name" />

        { identity?.role?.grade >= getRole('responsable') && (
          <ReferenceField label="Centre" source="center_id" reference="centers" link={false}>
            <TextField source="name"/>
          </ReferenceField>
        )}

        <FunctionField source="actions" render={
          record => (
            isLoading
              ? 
                <LinearProgress color="secondary" />
              : 
                <>
                  <Button
                    label="Assigner tâche"
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => handleBeforeAssignation('assignTask', record)}
                    sx={{ mr: 1 }}
                  />

                  <Button
                    label="Désassigner tâche"
                    variant="outlined"
                    color="warning"
                    size="small"
                    onClick={() => handleBeforeAssignation('unassignTask', record)}
                  />
                </>
          )
        }/>
      </Datagrid>
    </List>
  )
}

export const TaskAssignation = () => {
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll('leads')
  const { selectedIds: leads, filterValues, pagination, sort } = useListContext()

  const [openTaskAssignation, setOpenTaskAssignation] = useState(false)

  const params = {
    filter: filterValues,
    pagination: {
      page: pagination?.page,
      perPage: pagination?.perPage,
    },
    sort: {
      field: sort?.field,
      order: sort?.order,
    },
  }

  const handRefetchLeads = () => {
    const refetch = async () => {
      await dataProvider.refetchData('leads_view', params)
      refresh()
    }

    refetch()
  }

  const performTaskAssignation = async (action, chunk, taskId) => {
    console.log(action === 'unassignTask' ? 'Désassignation de la tâche...' : 'Assignation de la tâche...')
  
    if (action === 'unassignTask') {
      for (const lead of chunk) {
        const { error } = await supabaseClient
          .from('lead_tasks')
          .delete()
          .eq('lead_id', lead)
          .eq('task_id', taskId)
  
        if (error) {
          console.error('Error unassigning lead tasks: ', error)
        }
      }
  
      notify(`Tâche désassignée avec succès`, { 'type': 'success' })
      handRefetchLeads()
      refresh()
      unselectAll()
      setOpenTaskAssignation(false)
      return
    }
  
    // get task parcours tree
    const { data: dataTask, error: errorTask } = await supabaseClient
      .from('tasks_parcours')
      .select('tree')
      .eq('task_id', taskId)
  
    if (errorTask) {
      console.error('Error fetching task parcours: ', errorTask)
      return
    }
  
    if (!dataTask) {
      console.error('Task ' + taskId + ' does not exist')
      return
    }
  
    const taskTree = dataTask[0]?.tree
    const pubeurContactsMap = {}
  
    // Process each lead and group by pubeur
    for (const lead of chunk) {
      const { data: dataLead, error: errorLead } = await supabaseClient
        .from('leads')
        .select('*, profile: assigned_pubeur(*)')
        .eq('id', lead)
        .single()
  
      if (errorLead) {
        console.error('Error fetching lead: ', errorLead)
        continue
      }
  
      const leadData = dataLead
      const pubeurInfo = leadData?.profile
  
      if (!pubeurInfo) {
        console.log('Contact sans pubeur assigné : ', leadData?.first_name, leadData?.last_name);
        continue
      }
  
      // verify if the task doesn't already exist
      const { data: leadTasksData, error: errorLeadTasks } = await supabaseClient
        .from('lead_tasks')
        .select()
        .eq('lead_id', lead)
        .eq('task_id', taskId)
  
      if (errorLeadTasks) {
        console.error('Error fetching lead tasks: ', errorLeadTasks)
        continue
      }
  
      // if the task isn't already assigned, create it
      if (!leadTasksData || leadTasksData?.length === 0) {
        const { error } = await supabaseClient
          .from('lead_tasks')
          .insert({
            lead_id: lead,
            task_id: taskId,
            tree: taskTree,
          })
  
        if (error) {
          console.error('Error assigning lead tasks: ', error)
          continue
        }
  
        console.log('Tâche assignée à : ', leadData?.first_name, leadData?.last_name)
  
        // Add contact to the pubeur's contact list
        if (!pubeurContactsMap[pubeurInfo.id]) {
          pubeurContactsMap[pubeurInfo.id] = {
            pubeur: pubeurInfo,
            contacts: [],
          }
        }
  
        pubeurContactsMap[pubeurInfo.id].contacts.push(`${leadData?.first_name} ${leadData?.last_name}`)
      } else {
        console.log('Tâche déjà assignée à : ', leadData?.first_name, leadData?.last_name)
      }
    }
  
    // Send a single email per pubeur
    for (const pubeurId in pubeurContactsMap) {
      const pubeurInfo = pubeurContactsMap[pubeurId].pubeur
      const contactList = pubeurContactsMap[pubeurId].contacts.map(contact => `<li>${contact}</li>`).join('')
  
      let messageHTML = `
        <h3>Nouvelle tâche attribuée</h3>
  
        <h4>Une tâche vient d'être attribuée au(x) contact(s) suivant(s) :</h4>
  
        <ul>
          ${contactList}
        </ul>
  
        <p><a href="${process.env.REACT_APP_WEBSITE_URL}/leads">Cliquez ici pour vous rendre sur le CRM</a></p>
  
        <p><i>Ceci est un message automatique depuis le CRM FormaContact, merci de ne pas y répondre.</i></p>
      `
  
      const messageTEXT = `
        Une tâche vient d'être attribuée au(x) contact(s) suivant(s) :\n
        ${pubeurContactsMap[pubeurId].contacts.join('\n')}\n\n
        Ceci est un message automatique, merci de ne pas y répondre.
      `
  
      sendMail({
        identity: pubeurInfo,
        subject: `[CRM FORMA CONTACTS] Nouvelle tâche attribuée`,
        messageHTML: messageHTML,
        messageTEXT: messageTEXT,
        sendNow: true
      })
  
      createNotification({
        profile_id: pubeurInfo?.id,
        type: 'taskReminder',
        title: `Nouvelle tâche attribuée`,
        message: `Votre responsable a attribué une tâche à plusieurs contacts. Cliquez pour voir le détail.`,
        url: `/leads`,
      })
    }
  
    
    handRefetchLeads()
    unselectAll()
    refresh()
    notify(`Tâche assignée avec succès`, { 'type': 'success' })
    setOpenTaskAssignation(false)
  }

  const saveTaskAssignation = (action, taskId) => {
    const chunkSize = 100
    const chunks = []

    for ( let i = 0; i < leads?.length; i += chunkSize ) {
      chunks.push(leads.slice(i, i + chunkSize))
    }

    if ( ! action ) return

    chunks.forEach(chunk => {
      performTaskAssignation(action, chunk, taskId)
    })
  }

  return (
    <>
      <CommonDialog
        open={openTaskAssignation}
        handleClose={() => setOpenTaskAssignation(false)}
        title={ leads?.length > 1 ?
          `Assignation d'une tâche sur ${leads?.length} contacts sélectionnés` :
          `Assignation d'une tâche sur le contact sélectionné`
        }
        size='md'
      >
        <LeadTasksAssignationContent saveTaskAssignation={saveTaskAssignation} />
      </CommonDialog>

      <Button
        label="Tâches"
        startIcon={<AssignmentTurnedInIcon style={{ fontSize: '1rem'}} />}
        size="small"
        color="primary"
        onClick={() => setOpenTaskAssignation(true)}
        sx={{ fontSize: '.8rem', minWidth: '100px' }}
      />
    </>
  )
}

export default TaskAssignation