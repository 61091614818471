import { useState } from 'react'
import {
  required,
  useCreateContext,
  useEditContext,
  useNotify,
  useRecordContext,
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  TextInput,
  useGetIdentity,
} from 'react-admin'
import { Grid, Typography } from '@mui/material'
import { SimpleForm } from '@react-admin/ra-rbac'
import { RichTextInput } from 'ra-input-rich-text'

import ConversationsList from './ConversationsList'
import { toLowerCase } from '../common/Formating'
import { TextInputCopy } from '../Design/CustomTextInput'
import { filterBlankSpaces } from '../common/customTextFilter'

import BookmarkIcon from '@mui/icons-material/Bookmark'

export default {
  list: ConversationsList,
  icon: BookmarkIcon,
  options: { label: 'Conversations' },
}