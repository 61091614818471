export const btnList = {
  padding: '5px',
  borderRadius: '5px',
  minWidth: '100px',
  maxWidth: '150px',
}

export const btnAction = {
  padding: '10px',
  borderRadius: '5px',
  backgroundColor: '#1976d2',
  color: '#fff',
  minWidth: '150px',
}

export const btnDelete = {
  padding: '10px',
  borderRadius: '5px',
  backgroundColor: '#e01111',
  color: '#fff',
  minWidth: '150px',
}