import { supabaseClient } from '../supabase';

export const insertIntoTable = async (tableName, data) => {
    const { data: result, error } = await supabaseClient
        .from(tableName)
        .upsert(data)
        .single()
        .select('*');

    if (error) {
        return { error: `Error upserting into ${tableName}: ${error}`, rowData: data };
    }

    return { result };
}
