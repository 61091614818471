const centerMapping = {
  'default': 'default',
  'droitCenters': [process.env.REACT_APP_CENTER_CAPAVOCAT, process.env.REACT_APP_CENTER_CAPIRA, process.env.REACT_APP_CENTER_CAPCRC, process.env.REACT_APP_CENTER_CAPTA],
  'commerceCenters': [process.env.REACT_APP_CENTER_MYPREPA]
}

const resolveCenterKey = (center) => {
  if (centerMapping.droitCenters.includes(center)) return 'droit'
  if (centerMapping.commerceCenters.includes(center)) return 'commerce'
  return 'default'
}

export const availableFormations = {
  'default': {
    label: 'Médecine',
    options: [
      { id: 'pre_sante', name: 'Première Santé' },
      { id: 'term_sante', name: 'Terminale Santé' },
      { id: 'term_tempo', name: 'Terminale Santé Semestrielle' },
      { id: 'seconde_chance_l2', name: 'Seconde Chance License 2' },
      { id: 'ann_pre_p0', name: 'Année Préparatoire P0' },
      { id: 'ann_blanche', name: 'Année Blanche P0' },
      { id: 'prep_ann', name: 'Préparation annuelle PluriPASS' },
      { id: 'prep_ann_pluripass', name: 'Préparation annuelle' },
      { id: 'prep_ann_l1sps', name: 'Préparation annuelle L1 SpS' },
      { id: 'prep_ann_l2sps', name: 'Préparation annuelle L2 SpS' },
      { id: 'prep_ann_pass', name: 'Préparation annuelle PASS' },
      { id: 'prep_ann_las', name: 'Préparation annuelle L.AS' },
      { id: 'prep_ann_las2', name: 'Préparation annuelle L.AS 2' },
      { id: 'infirmier', name: 'Infirmier/IFSI' },
      { id: 'psychomotricien', name: 'Psychomotricien' },
      { id: 'orthophoniste', name: 'Orthophoniste' },
      { id: 'man_sci', name: 'MAN Scientifique' },
      { id: 'stage_pre_uni', name: 'Stage pré-universitaire' },
      { id: 'autre', name: 'Autre/je ne sais pas' },
    ],
  },
  'droit': {
    label: 'Droit',
    options: [
      { id: 'form_intensive', name: 'Formation Intensive' },
      { id: 'ann_pre', name: 'Annuelle Présentielle' },
      { id: 'ann_dist', name: 'Annuelle à distance' },
      { id: 'est_pre', name: 'Estivale présentielle' },
      { id: 'est_dist', name: 'Estivale à distance' },
      { id: 'stage_est', name: 'Stage Estival' },
      { id: 'printemps', name: 'Printemps' },
      { id: 'automne', name: 'Automne' },
    ],
  },
  'commerce': {
    label: 'Commerce',
    options: [
      { id: 'HEC_pre_ecg_paris', name: 'Prépa ECG à Paris' },
      { id: 'HEC_pre_ecg_ligne', name: 'Prépa ECG en ligne' },
      { id: 'HEC_stage_pre_ecg', name: 'Cours et stages Prépa ECG' },
      { id: 'HEC_stage_renfo_lycee', name: 'Cours et stages de renforcement au lycée' },
      { id: 'HEC_sol_orientation', name: 'Solutions de conseil en orientation' },
      { id: 'HEC_stage_acces_sesame', name: 'Stages de préparation aux concours ACCES et SESAME' },
    ],
  },
}


export const getFormationIdByName = (center, name) => {
  const resolvedKey = resolveCenterKey(center)
  const formationChoices = availableFormations[resolvedKey]?.options ?? availableFormations['default']?.options
  const result = formationChoices?.filter(formation => formation.name === name)

  return result[0]?.id
}

export const getFormationNameById = (center, ids) => {
  const resolvedKey = resolveCenterKey(center)
  const formationChoices = availableFormations[resolvedKey]?.options ?? availableFormations['default']?.options

  // return for multiple ids
  if ( Array.isArray(ids) ) {
    return ids.map(id => {
      return formationChoices.find(formation => formation.id === id)?.name
    }).join(' - ')
  }

  // return for single id
  return formationChoices.find(formation => formation.id === ids)?.name
}
