import { useMemo } from "react"
import {
  useGetIdentity,
  DatagridConfigurable,
  DateField,
  FunctionField,
  ReferenceField,
  List,
  SimpleList,
  CreateButton,
  useListContext,
} from 'react-admin'
import { useMediaQuery } from '@mui/material'

import LeadEdit from './LeadEdit'
import LeadCreate from './LeadCreate'
import { getRole } from '../common/roles'
import ListToolbar from './LeadsListToolbar'
import { exporter } from './LeadModelExport'
import { LeadActions } from './LeadsTopToolbar'
import BulkMenu from './bulkComponents/BulkMenu'
import LeadsAsideFilters from './filters/LeadsAsideFilters'
import { classes, getClassNameById } from '../common/classes'
import DisplayPhoneNumber from '../Design/DisplayPhoneNumber'
import { InfoSelectField } from '../Design/CustomSelectField'
import { getItemFromLocalStorage } from '../common/LocalStorage'
import CustomPostPagination from '../Design/CustomPostPagination'
import { InfoTextReferenceField, SourceTextReferenceField, YellowReferenceField } from '../Design/CustomReferenceField'
import { InfoTextField, YellowTextField, FavouriteTextField, TasksTextField, TruncatedTextField, TextFieldCopy } from '../Design/CustomTextField'

import TripOriginIcon from '@mui/icons-material/TripOrigin'
import StarPurple500Icon from '@mui/icons-material/StarPurple500'
import customColor from '../common/customColor'

const LeadList = props => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')
  const isResponsable = identity?.role?.grade === getRole('responsable')
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    { noSsr: true }
  )
  const { sort, page, perPage } = useListContext()
  const localCenter = identity?.center
  const localCurrentYear = getItemFromLocalStorage('localCurrentYear')?.id || process.env.REACT_APP_CURRENT_YEAR_ID
  const isCurrentYear = localCurrentYear === process.env.REACT_APP_CURRENT_YEAR_ID

  const exporterProp = useMemo(() => exporter ?? exporter, [props.exporter])

  const postRowSx = (record) => {
    // const { textColor, bgColor } = customColor(record)

    // return {
    //   color: textColor,
    //   backgroundColor: bgColor,
    // }
  }

  return (
    <>
      <List
        {...props}
        pagination={<CustomPostPagination />}
        perPage={50}
        actions={!isMobile && <LeadActions exporter={exporterProp} {...props} />}
        sort={{ field: 'updated_at', order: 'DESC' }}
        empty={false}
        aside={<LeadsAsideFilters />}
      >
        <ListToolbar isMobile={isMobile} />

        { isMobile && (
          <>
            <CreateButton />
            
            <SimpleList
              primaryText={record => `${record.stop_rgpd ? '[STOP RGPD]' : ''} ${record.first_name ?? ''} ${record.last_name ?? ''}`}
              secondaryText={record => {
                const className = getClassNameById(record.class_id, localCenter)
                return record.class_id ? `${className}` : 'Classe non renseignée'
              }}
              tertiaryText={record => record.phone?.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5').replace('+33 0', '0')}
              linkType="edit"
              {...props}
            />
          </>
        )}

        { !isMobile && isAdmin && (
          <DatagridConfigurable
            preferenceKey="admin.datagrid"
            rowClick="edit"
            omit={ ['id', 'created_at', 'email', 'school_id', 'center_id', 'status_inscription_id', 'last_comment', 'favourited_by'] }
            bulkActionButtons={ isCurrentYear ? <BulkMenu /> : false }
            rowSx={postRowSx}
          >
            <SourceTextReferenceField textFieldSource="name" label={<TripOriginIcon sx={{ fontSize: '1rem', color: '#999' }} />} source="lead_source_id" reference="lead_sources" />

            <InfoTextField source="first_name" info="first_name" label="Prénom" />

            <InfoTextField source="last_name" info="last_name" label="Nom" />

            <TextFieldCopy source="id" label="ID" />
            
            <DisplayPhoneNumber source="phone" label="Téléphone"/>
            
            <YellowTextField source="email" info="email" />
            
            <InfoSelectField source="class_id" label="Classe" choices={classes[localCenter?.id]?.options ?? classes['default']?.options} />
            
            <InfoTextReferenceField textFieldSource="name" label="Lycée" source="school_id" reference="schools" truncate="15" />
            
            <ReferenceField label="Centre" source="center_id" reference="centers">
              <FunctionField render={record => (
                record?.name
              )} />
            </ReferenceField>

            <YellowReferenceField label="Pubeur" source="assigned_pubeur" reference="profiles">
              <FunctionField render={record => { return record ? (
                `${record.first_name} ${record.last_name}`
              ) : null } } />
            </YellowReferenceField>

            <TasksTextField label="Tâches" source="tasks_info" />
            
            <DateField label="Date de création" source="created_at" showTime={true} />
            
            <DateField label="Date de modification" source="updated_at" showTime={true} />

            <InfoTextReferenceField source="status_inscription_id" textFieldSource="name" label="Statut inscr."  reference="qualifications" link={false} />

            <TruncatedTextField source="last_comment" label="Commentaire" />

            <FavouriteTextField source="favourited_by" label={<StarPurple500Icon sx={{ fontSize: '1.65rem', color: '#999', ml: -.3 }} />} />
          </DatagridConfigurable>
        )}

        { !isMobile && isResponsable && (
          <DatagridConfigurable
            preferenceKey="respo.datagrid"
            rowClick="edit"
            omit={ ['id', 'created_at', 'updated_at', 'email', 'school_id'] }
            bulkActionButtons={ isCurrentYear ? <BulkMenu /> : false }
            rowSx={postRowSx}
          >
            <SourceTextReferenceField textFieldSource="name" label={<TripOriginIcon sx={{ fontSize: '1rem', color: '#999', width: '5%' }} />} source="lead_source_id" reference="lead_sources" />

            <InfoTextField source="first_name" info="first_name" label="Prénom" sx={{ width: '10%' }} />

            <InfoTextField source="last_name" info="last_name" label="Nom" sx={{ width: '10%' }} />
            
            <TextFieldCopy source="id" label="ID" />

            <DisplayPhoneNumber source="phone" label="Téléphone" sx={{ width: '12%' }}/>
            
            <YellowTextField source="email" info="email" sx={{ width: '15%' }} />
            
            <InfoSelectField source="class_id" label="Classe" choices={classes[localCenter?.id]?.options ?? classes['default']?.options} />
            
            <InfoTextReferenceField textFieldSource="name" label="Lycée" source="school_id" reference="schools" truncate="15" />

            <YellowReferenceField label="Pubeur" source="assigned_pubeur" reference="profiles">
              <FunctionField render={record => { return record ? (
                `${record.first_name} ${record.last_name}`
              ) : null } } />
            </YellowReferenceField>

            <TasksTextField label="Tâches" source="tasks_info" />
            
            <DateField label="Date de création" source="created_at" showTime={true} />
            
            <DateField label="Date de modification" source="updated_at" showTime={true} />

            <InfoTextReferenceField source="status_inscription_id" textFieldSource="name" label="Statut inscr."  reference="qualifications" link={false} />

            <TruncatedTextField source="last_comment" label="Commentaire" />

            <FavouriteTextField source="favourited_by" label={<StarPurple500Icon sx={{ fontSize: '1.65rem', color: '#999', ml: -.3 }} />} />
          </DatagridConfigurable>
        )}

        { !isMobile && !isResponsable && !isAdmin && (
          <DatagridConfigurable
            preferenceKey="pubeur.datagrid"
            rowClick="edit"
            omit={ ['email', 'created_at', 'updated_at'] }
            bulkActionButtons={ isCurrentYear ? <BulkMenu /> : false }
            rowSx={postRowSx}
          >
            <SourceTextReferenceField textFieldSource="name" label={<TripOriginIcon sx={{ fontSize: '1rem', color: '#999' }} />} source="lead_source_id" reference="lead_sources" />

            <InfoTextField source="first_name" info="first_name" label="Prénom" />

            <InfoTextField source="last_name" info="last_name" label="Nom" />
            
            <DisplayPhoneNumber source="phone" label="Téléphone"/>
            
            <YellowTextField source="email" info="email" />
            
            <InfoSelectField source="class_id" label="Classe" choices={classes[localCenter?.id]?.options ?? classes['default']?.options} />
            
            <InfoTextReferenceField label="Lycée" source="school_id" reference="schools" truncate="15" textFieldSource="name" />
            
            <DateField label="Date de création" source="created_at" showTime={true} />
            
            <DateField label="Date de modification" source="updated_at" showTime={true} />

            <InfoTextReferenceField source="status_inscription_id" textFieldSource="name" label="Statut inscr."  reference="qualifications" link={false} />

            <TasksTextField label="Tâches" source="tasks_info" />
            
            <TruncatedTextField source="last_comment" label="Commentaire" />

            <FavouriteTextField source="favourited_by" label={<StarPurple500Icon sx={{ fontSize: '1.65rem', color: '#999', ml: -.3 }} />} />
          </DatagridConfigurable>
        )}
      </List>
      
      <LeadEdit sort={sort} page={page} perPage={perPage} />
      { isCurrentYear && <LeadCreate /> }
    </>
  )
}

export default LeadList
