import { EditDialog } from "@react-admin/ra-form-layout";

import { SiteInputContent } from "./index";

const SiteEdit = () => (
    <EditDialog title="Fiche site" fullWidth maxWidth="sm">
        <SiteInputContent />
    </EditDialog>
);

export default SiteEdit;
