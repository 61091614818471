export function formatDate(date, timestampz = false) {
  const pad = (number) => number < 10 ? '0' + number : number

  let year = date.getFullYear()
  let month = pad(date.getMonth() + 1)
  let day = pad(date.getDate())
  let hour = pad(date.getHours())
  let minute = pad(date.getMinutes())
  let second = pad(date.getSeconds())

  return timestampz // either false is '000', '123', '999'
    ? `${year}-${month}-${day}T${hour}:${minute}:${second}.${timestampz}Z`
    : `${year}-${month}-${day} ${hour}:${minute}:${second}`
}