import { parsePhoneNumberFromString } from 'libphonenumber-js'

export function formatPhoneNumber(originPhoneNumber) {
  if (! originPhoneNumber) return null

  const phoneNumberString = originPhoneNumber?.toString()

  const phoneNumber = parsePhoneNumberFromString(phoneNumberString, {
    defaultCountry: 'FR',
  })

  if (! phoneNumber) {
    return null
  }

  let formatted = phoneNumber.formatInternational()
  formatted = formatted.replace(/\s/g, '')

  if (phoneNumber.country === 'FR') {
    formatted = formatted.replace('+33', '+330')
  }

  return formatted
}

export const getValidPhone = phone => formatPhoneNumber(phone)

export const isFormPhoneValid = phone => {
  return ( ! phone || getValidPhone(phone) === null ) ? 'Veuillez renseigner un numéro de téléphone valide.' : undefined
}

export const isEmailValid = email => /\S+@\S+\.\S+/.test(email)

export const getValidEmail = email => {
  if (!email) return null

  let mail = email.replace('é', 'e')
  mail = mail.replace('è', 'e')
  mail = mail.replace('ê', 'e')
  mail = mail.replace('à', 'a')
  mail = mail.replace('â', 'a')
  mail = mail.replace('ù', 'u')
  mail = mail.replace('û', 'u')
  mail = mail.replace('î', 'i')
  mail = mail.replace('ï', 'i')
  mail = mail.replace('ç', 'c')
  mail = mail.replace('ô', 'o')
  mail = mail.replace('ö', 'o')
    
  const test = /\S+@\S+\.\S+/.test(mail?.toLowerCase())
  return test ? mail : null
}

export const isFormEmailValid = email => {
  if (! email) return 'Veuillez renseigner un email.'
  if (getValidEmail(email) === null) return 'Veuillez renseigner un email valide.'

  return true
}

export function toCapitalCase(str) {
  if (!str) return null

  const he = require('he')
  str = he.decode(str)
  str = str.replace(/\s+$/, '');

  const separator = String(str).includes('-') ? '-' : ' '

  return String(str)
    .split(' ')
    .map(word => word.toUpperCase())
    .join(separator)
    .replace(/&|#[^;]+;/g, '')
}

export function toCamelCase(str) {
  if (! str) return null

  const he = require('he')
  str = he.decode(str)
  str = str.replace(/\s+$/, '');

  const separator = String(str).includes('-') ? '-' : ' '

  return String(str)
    .split(/[\s-]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(separator)
    .replace(/&|#[^;]+;/g, '')
}

export function toLowerCase(str) {
  if (!str) return null;

  const he = require('he')
  str = he.decode(str)
  str = str.replace(/\s+$/, '')

  const words = String(str).split(/[\s-]/);

  if (words.length > 0) {
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase()
  }

  for (let i = 1; i < words.length; i++) {
    words[i] = words[i].toLowerCase()
  }

  const result = words.join(' ')

  return result.replace(/&|#[^;]+;/g, '')
}

export function toBoolean(value) {
  if (! value) return false;

  if (typeof value === 'string') {
    return value.toLowerCase() === 'oui' || value.toLowerCase() === 'true'
  }

  if (typeof value === 'number') {
    return value === 1
  }

  return Boolean(value)
}
