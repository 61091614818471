import { useEffect, useState, useCallback } from 'react'
import {
  useRecordContext,
  useNotify,
} from 'react-admin'
import { Stack, Chip } from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { Tree, TreeNode } from 'react-organizational-chart'

import AddNewActions from './TaskActions'
import { supabaseClient } from '../supabase'
import EditTaskActions from './EditTaskActions'
import TasksActionTypes from './TasksActionTypes'

import EditNoteIcon from '@mui/icons-material/EditNote'

const TasksActionsTrees  = props => {
  const record = useRecordContext(props)
  const taskId = record?.id
  const notify = useNotify()

  const [openEditActionDialog, setOpenEditActionDialog] = useState(false)
  const [branchToEdit, setBranchToEdit] = useState(null)
  const [parcoursTree, setParcoursTree] = useState(null)
  const [parcoursName, setParcoursName] = useState(null)
  const [parcoursId, setParcoursId] = useState(null)
  const [treeState, setTreeState] = useState(null)

  const saveParcoursTree = async () => {
    const { error } = await supabaseClient
      .from('tasks_saved_parcours')
      .insert([{
        center_id: record?.center_id,
        name: parcoursName,
        tree: parcoursTree
      }])

    if (error) {
      console.error('Error saving parcours tree', error)
      return { success: false }
    }

    notify('Parcours d\'action enregistré avec succès', { type: 'success' })
  }

  const isCorrectTree = (branch) => {
    if ( ! branch ) return

    // FALSE: Branch with empty children but not 'Traité' (ie. init branch action)
    if ( branch.children.length === 0 && branch.action?.name !== 'Traité' ) {
      setTreeState(false)
      return false
    }

    // TRUE: If it's a 'Traité' branch, return true
    if ( branch.children.length === 0 && branch.action?.name === 'Traité' ) {
      setTreeState(true)
      return true
    }

    // TRUE: "Traité" as a single option with null condition
    if ( branch.children.length === 1 &&
         branch.children[0].action?.name === 'Traité' &&
         branch.children[0].condition === null
    ) {
      setTreeState(true)
      return true
    }

     // CONTINUE: If it has exactly one child which is NOT a 'Traité' action
     if ( branch.children.length === 1 &&
      branch.children[0].action?.name !== 'Traité'
    ) {
      return isCorrectTree( branch.children[0] )
    }

    // CONTINUE TO 1: If it has exactly two children and [0] is 'Traité', we explore [1]
    if ( branch.children.length === 2 &&
         branch.children[0].action?.name === 'Traité'
    ) {
      return isCorrectTree( branch.children[1] )
    }

    // CONTINUE TO 0: If it has exactly two children and [1] is 'Traité', we explore [0]
    if ( branch.children.length === 2 &&
      branch.children[1].action?.name === 'Traité'
    ) {
      return isCorrectTree( branch.children[0] )
    }

    // CONTINUE TO: 2 children but none of them is 'Traité' -> fork the search
    if ( branch.children.length === 2 &&
      branch.children[0].action?.name !== 'Traité' &&
      branch.children[1].action?.name !== 'Traité'
    ) {
      const leftSubtree = isCorrectTree(branch.children[0])
      const rightSubtree = isCorrectTree(branch.children[1])

      if ( leftSubtree && rightSubtree ) {
        setTreeState(true)
        return true
      }
    }

    setTreeState(false)
    return false
  }

  const findAndDeleteBranch = (tree, branchIdToDelete) => {
    // Base case: if the current branch's id matches the branchIdToDelete, return null to delete it
    if (tree.id === branchIdToDelete) {
      return null
    }
    
    // Recursively search for the branch in children
    const updatedChildren = tree.children
      .map(child => findAndDeleteBranch(child, branchIdToDelete))
      .filter(Boolean) // Filter out null values (deleted branches)
    
    // Return updated tree branch with filtered children
    return { ...tree, children: updatedChildren }
  }
  
  const deleteBranch = async (branchId) => {
    // update the tree
    const updatedFullTree = findAndDeleteBranch(parcoursTree, branchId)
        
    const { error: errorUpdateParcoursTree } = await supabaseClient
      .from('tasks_parcours')
      .update({ tree: updatedFullTree })
      .eq('id', parcoursId)
    
    if (errorUpdateParcoursTree) {
      console.error('Error updating parcours tree', errorUpdateParcoursTree)
      return { success: false }
    }

    // if empty tree, delete parcours
    if (updatedFullTree.children.length === 0) {
      const { error: errorDeleteParcours } = await supabaseClient
        .from('tasks_parcours')
        .delete()
        .eq('id', parcoursId)

      if (errorDeleteParcours) {
        console.error('Error deleting parcours', errorDeleteParcours)
      }
    }

    notify('Action supprimée avec succès', { type: 'success' })
    refreshTree()
  }

  const fetchParcoursTree = async () => {
    const { data, error } = await supabaseClient
      .from('tasks_parcours')
      .select('id, name, tree')
      .eq('task_id', taskId)

    if (error) {
      console.error('Error fetching tasks:', error.message)
      return
    }

    if (!data || data.length === 0) {
      setParcoursTree()
      // if ( parcoursTree ) setParcoursTree()
      return
    }

    setParcoursTree(data[0].tree)
    setParcoursId(data[0].id)
    setParcoursName(data[0].name)
  }

  const memoizedFetchParcoursTree = useCallback(fetchParcoursTree, [])

  const refreshTree = () => {
    memoizedFetchParcoursTree()
    isCorrectTree(parcoursTree)
  }

  useEffect(() => {
    memoizedFetchParcoursTree()
  }, [memoizedFetchParcoursTree])

  useEffect(() => {
    if ( ! parcoursTree ) return

    isCorrectTree(parcoursTree)
  }, [parcoursTree])

  const StyledNode = ({ branch = {}, children, branchTitle }) => (
    <Chip
      label={children === 'Tâche' ? parcoursName : branchTitle}
      sx={{
        padding: '2px',
        maxWidth: '250px',
        height: 'auto',
        '& .MuiChip-label': {
          display: 'block',
          whiteSpace: 'normal',
        },
      }}
      variant="contained"
      color={
        children === 'Tâche'
          ? 'secondary'
          : branch?.action?.name === 'Traité'
            ? 'success'
            : branch?.action?.name === 'En attente'
              ? 'warning'
              : 'primary'
      }
      onDelete={ branch?.action ? () => deleteBranch(branch.id) : undefined }
      icon={
        <>
          { branch?.action?.name !== 'Traité' && branch?.action?.name !== 'En attente' &&
            ( branch?.children ? branch?.children[0]?.condition !== null : null ) &&
            branch?.children?.length < 2 &&
            <AddNewActions refreshTree={refreshTree} taskId={taskId} parcoursId={parcoursId} fullTree={parcoursTree} branch={branch} />
          }
          
          { branch?.action?.name !== 'Traité' && branch?.parentId ?
            <EditNoteIcon
              onClick={
                () => {
                  setBranchToEdit(branch)
                  setOpenEditActionDialog(true)
                }
              }
              sx={{ cursor: 'pointer', fontSize: '1.6rem', ml: 1 }}
            /> : null
            }
        </>
      }
    />
  )

  const ActionNode = ({ branch, parent = null }) => {
    if (! branch) return null

    const callback = branch?.callback ? `(${branch?.callback}h)` : ''
    const condition = parent?.action?.name === 'En attente' ? `Une fois l'attente passée` :
     branch?.condition === null ? `seul choix possible` : branch?.condition ? `si succès` : 'si échec'
    
    const nameToDisplay = branch?.action?.name
      ? `${branch?.action?.name} ${callback}`
      : 'Tâche'
  
    return (
      <TreeNode key={`branch-${branch?.id}`} label={
        <>
          { ! branch?.parentId?.includes('base') && (
            <>
              <Chip
                label={condition}
                variant="outlined"
                color={
                  branch?.condition === null ? 'info' : branch?.condition ? 'success' : 'error'
                }
                sx={{ border: 'none' }}
              /><br/>
            </>
          )}

          <StyledNode 
            branch={branch ?? null}
            branchTitle={nameToDisplay}
            parentId={branch?.id ?? 0}
          />
        </>
      }>
        {branch?.children && branch?.children?.map(child => (
          <ActionNode key={child.id} branch={child} parent={branch} />
        ))}
      </TreeNode>
    )
  }

  return parcoursTree ? (
    <div style={{ marginTop: 0 }}>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={
        { marginTop: '20px', padding: '0 10px', backgroundColor: '#f0f0f0', borderRadius: '10px' }
      }>
        {/* POUR PLUS TARD <Button
          startIcon={<AddCircleOutlineIcon />}
          label="Enregistrer comme modèle à réutiliser"
          variant="outlined"
          onClick={saveParcoursTree}
          style={{ marginBottom: '20px' }}
        /> */}

        <div style={ treeState !== null ? { margin: '20px', maxWidth: '70%' } : {} }>
          { treeState !== null
            ? treeState
              ? <span style={{ color: 'green', marginTop: '20px' }}>Le parcours est fonctionnel. Bravo !</span>
              : <span style={{ color: 'darkorange', marginTop: '20px' }}>
                <b>Attention ! Le parcours n'est pas fonctionnel.</b><br/>
                Chaque branche du parcours doit se terminer par une action <u>"Traité" comme seul choix possible</u>, ou bien une action <u>succès "Traité"
                et une action échec.</u>
              </span>
            : null
          }
        </div>
        
        <TasksActionTypes label="Gestion des actions" />
      </Stack>

      <EditTaskActions
        refreshTree={refreshTree}
        branch={branchToEdit}
        parcoursId={parcoursId}
        fullTree={parcoursTree}
        openEditActionDialog={openEditActionDialog}
        setOpenEditActionDialog={setOpenEditActionDialog}
      />

      <div style={{ marginTop: '40px' }}></div>

      <Tree
        lineWidth="2px"
        lineColor="green"
        lineBorderRadius="20px"
        label={<StyledNode displayHandle={true}>Tâche</StyledNode>}
      >
        { parcoursTree?.children?.map((child) => (
          <ActionNode key={child.id} branch={child} />
        )) }
      </Tree>
    </div>
  ) : <AddNewActions refreshTree={refreshTree} taskId={taskId} />
}

export default TasksActionsTrees