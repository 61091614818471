import { Button, AutocompleteInput, FormDataConsumer, SaveButton, SelectInput, SimpleForm, TextInput, CheckboxGroupInput, required } from 'react-admin'
import { Grid, Typography, Chip, Autocomplete, TextField as MuiTextField, CircularProgress } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { getSpecialitiesNameById } from '../../common/specialities'
import { getClassNameById } from '../../common/classes'
import { getOptionsNameById } from '../../common/options'

const TaskActionForm = ({
  actionToEdit,
  events,
  eventQualifications,
  selectedTags,
  tagsChoices,
  loadingSaveTags,
  handleTagChange,
  handleEventChange,
  allLeadQualifications,
  leadRecord,
  setCommentPubeur,
  commentPubeur,
  handleSubmit,
  updateStopRGPD,
}) => {
  return (
    <SimpleForm
      toolbar={false}
      sx={{ m: 0, p: 0 }}
      onSubmit={handleSubmit}
      defaultValue={{
        event_qualifications: allLeadQualifications.map(qual => qual.id),
      }}
    >
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={6} sx={{ borderRight: '1px solid #ccc', pr: 2, pb: 2 }}>
                <Typography variant="body1" mb={2} color={actionToEdit?.detail ? 'textPrimary' : 'textSecondary'}>
                  Détails : {actionToEdit?.detail ?? 'Pas de précisions pour cette action.'}
                </Typography>

                <SelectInput
                  source="status"
                  label="Statut / Résultat"
                  variant="outlined"
                  choices={
                    (() => {
                      if (!actionToEdit.children || actionToEdit.children.length === 0) {
                        return []
                      }

                      const actionChoices = actionToEdit.action.choices || [
                        { id: 1, name: 'Succès' },
                        { id: -1, name: 'Échec' },
                        { id: 2, name: 'Continuer' }
                      ]

                      return actionToEdit.children.map(child => {
                        if (child.condition === null && child.action.name === 'Traité') {
                          return { id: 1, name: 'Terminer la tâche' }
                        }

                        if (!actionChoices || !Array.isArray(actionChoices)) return []

                        if (child.condition === true) {
                          return actionChoices?.find(choice => choice?.id === 1)
                        } else if (child.condition === false) {
                          return actionChoices?.find(choice => choice?.id === -1)
                        } else {
                          return actionChoices?.find(choice => choice?.id === 2)
                        }
                      }).filter(choice => choice !== undefined)
                    })()
                  }
                  validate={[required()]}
                  fullWidth
                />

                <TextInput
                  source="comment"
                  label="Ajouter un commentaire"
                  variant="outlined"
                  onChange={(e) => setCommentPubeur(e.target.value)}
                  fullWidth
                />

                <AutocompleteInput
                  label="Choisir un événement à mettre à jour"
                  source="event"
                  variant="outlined"
                  choices={events?.map(event => ({ id: event.id, name: `${event.name} du ${event.startDate}` }))}
                  isOptionEqualToValue={(option, value) => option.label === value.name}
                  onChange={handleEventChange}
                />

                {formData.event && eventQualifications && eventQualifications.length > 0 && (
                  <CheckboxGroupInput
                    source="event_qualifications"
                    label="Remplir / mettre à jour"
                    choices={eventQualifications}
                  />
                )}

                <SaveButton variant="contained" label="Valider" fullWidth />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h6" color="textSecondary" mb={2}>Informations contact</Typography>

                {leadRecord ? (
                  <>
                    <p style={{ fontSize: '.9rem', lineHeight: '1.5rem' }}>
                      <b>Contact :</b> {`${leadRecord?.first_name} ${leadRecord?.last_name}`}<br/>
                      <b>Email :</b> {leadRecord?.email ?? 'Non renseigné'}<br/>
                      <b>Téléphone :</b> (+33) {leadRecord?.phone?.replace('+33', '') ?? 'Non renseigné'}<br/>
                      <b>Classe :</b> {getClassNameById(leadRecord?.class_id) ?? 'Non renseignée'}<br/>
                      <b>Formation :</b> {getSpecialitiesNameById(leadRecord?.options) ?? 'Non renseignée'}<br/>
                      <b>Options souhaitées :</b> {getOptionsNameById(leadRecord?.center_id, leadRecord?.desired_options) ?? 'Non renseigné'}
                    </p>

                    <Autocomplete
                      multiple
                      options={tagsChoices}
                      getOptionLabel={option => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      filterSelectedOptions
                      onChange={handleTagChange}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          variant="outlined"
                          label="Sélectionner un ou plusieurs tags"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingSaveTags ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={`li-tag-${option.id}`} sx={{ padding: '2px' }}>
                          <CircleIcon sx={{ fontSize: '1rem', marginRight: '10px', color: `#${option.color}` }} />
                          {option.name}
                        </li>
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            variant="outlined"
                            label={option.name}
                            key={`chip-tag-${option.id}`}
                            sx={{
                              color: `#${option.color} !important`,
                              backgroundColor: `#${option.colorLight} !important`,
                              fontWeight: 500,
                              border: 'none',
                              fontSize: '12px',
                              margin: '1px',
                              padding: '1px',
                              height: '24px'
                            }}
                          />
                        ))
                      }
                      value={selectedTags}
                    />

                    <p style={{ color: 'darkred', textAlign: 'center', fontSize: '.9rem' }}>
                      <Button
                        label="Mettre fin à la tâche"
                        variant="contained"
                        color="error"
                        sx={{ mt: 2, mb: .5 }}
                        onClick={() => {
                          handleSubmit({
                            lead_id: leadRecord.id,
                            result: true,
                            comment: commentPubeur ? `Tâche clôturée : ${commentPubeur}` : 'Tâche clôturée : STOP RGPD / faux numéro',
                            manualClose: true
                          })
                          updateStopRGPD()
                        }}
                      />
                      <br/>Uniquement si le contact ne souhaite plus être contacté / faux numéro
                    </p>
                  </>
                ) : `Chargement...`}
              </Grid>
            </Grid>
          )
        }}
      </FormDataConsumer>
    </SimpleForm>
  )
}

export default TaskActionForm
