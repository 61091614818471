import { useListContext, FilterForm, SearchInput } from 'react-admin'
import { Stack } from '@mui/material'

const SchoolsListToolbar = () => {
  const { selectedIds } = useListContext()

  const schoolsFilters = [
    <SearchInput placeholder="Nom" source="name@ilike" variant="outlined" sx={{ width: '20vw' }} alwaysOn resettable />,
    <SearchInput placeholder="Code postal" source="address->>postalCode@ilike" variant="outlined" sx={{ width: '15vw' }} alwaysOn resettable />,
  ].filter(Boolean)

  return (
    <Stack direction="row" style={selectedIds.length > 0 ? { padding: '0 10px 50px 10px' } : { padding: '0 10px' }}>
      <FilterForm filters={schoolsFilters} />
    </Stack>
  )
}

export default SchoolsListToolbar