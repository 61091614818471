import * as React from "react";
import {
    useRecordContext,
    ChipField,
    FunctionField,
    ReferenceArrayField,
    ReferenceField,
    SelectField,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
} from "react-admin";
import {
    Box,
    Typography,
} from '@mui/material';
import { ShowDialog } from "@react-admin/ra-form-layout";

import roles from '../common/roles';

const ProfileShow = props => (
    <ShowDialog fullWidth maxWidth="lg">
        <ProfileShowContent {...props} />
    </ShowDialog>
);

const ProfileShowContent = () => {
    let profile = useRecordContext();
    if (!profile) return null;

    return (
        <TabbedShowLayout>
            <Tab label={`${profile.firstName} ${profile.lastName}`}>
                <Box display="flex">
                    <Box mb={2} flex="1">
                        <Box display="flex" mt={1}>
                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Email
                                </Typography>
                                <Typography variant="subtitle1">
                                    {profile.email}
                                </Typography>
                            </Box>

                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Téléphone
                                </Typography>
                                <Typography variant="subtitle1">
                                    {profile.phone}
                                </Typography>
                            </Box>

                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Rôle
                                </Typography>
                                <Typography variant="subtitle1">
                                    <SelectField label="Rôle" source="role" choices={roles} />
                                </Typography>
                            </Box>
                        </Box>

                        <Box mt={3}>
                            <Box display="flex" flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Centres
                                </Typography>
                                <Box mt={1}>
                                    <ReferenceArrayField reference="centers" source="centers">
                                        <SingleFieldList>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                                </Box>                         
                            </Box>
                        </Box>

                        <Box mt={3}>
                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Lycée de provenance
                                </Typography>
                                <ReferenceField source="fromSchool" reference="schools" link={false}>
                                    <FunctionField render={record => (
                                        <Typography variant="subtitle1">
                                            { record.name }
                                        </Typography>
                                    )} />
                                </ReferenceField>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Tab>
        </TabbedShowLayout>
    );
};

export default ProfileShow;
