import * as React from "react";
import {
  downloadCSV,
  CreateButton,
  EmailField,
  ExportButton,
  TextField,
  TopToolbar,
  NumberField,
} from "react-admin";
import jsonExport from 'jsonexport/dist';
import { List, Datagrid, IfCanAccess } from "@react-admin/ra-rbac";

import CenterShow from "./CenterShow";
import CenterEdit from "./CenterEdit";
import CenterCreate from "./CenterCreate";
import { PostPagination } from '../common/Pagination';
import { TextFieldCopy } from "../Design/CustomTextField";

import AddCircleIcon from '@mui/icons-material/AddCircle';

const CenterList = props => {
  const CustomActions = () => (
    <div style={{ minHeight: '50px' }}>
      <TopToolbar sx={{ alignItems: 'left !important' }}>
        <IfCanAccess action="create">
          <CreateButton icon={<AddCircleIcon />} label="Créer un centre" />
        </IfCanAccess>

        <IfCanAccess action="export">
          <ExportButton exporter={exporter} />
        </IfCanAccess>
      </TopToolbar>
    </div>
  );

  const exporter = centers => {
    const centersForExport = centers.map(center => {
        const { id, name } = center;
        return { id, name };
    });
    jsonExport(centersForExport, {
        headers: ['id', 'name'],
        rename: ['ID', 'Nom'],
        rowDelimiter: ';',
    }, (err, csv) => {
        downloadCSV(csv, 'Export centres');
    });
  };

  return (
    <>
      <List
        {...props}
        empty={false}
        actions={<CustomActions />}
        pagination={<PostPagination />}
        perPage={50}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <Datagrid rowClick="edit">
          <TextField label="Nom" source="name" />
          <TextFieldCopy label="CRM id" source="id" />
          <TextFieldCopy label="MEP id" source="mep_id" />
          <NumberField label="Contacts" source="totalleads" />
          <NumberField label="Seconde" source="leads_class_id_1" />
          <NumberField label="Première" source="leads_class_id_2" />
          <NumberField label="Terminale" source="leads_class_id_3" />
          <NumberField label="Licence" source="leads_class_id_4" />
          <NumberField label="Autre" source="leads_class_id_0" />
          <NumberField label="Sans classe" source="leads_class_id_999" />
          <NumberField label="Inscrits" source="leads_inscrits" />
          <NumberField label="Inscrits TS" source="leads_inscrits_ts" />
          <NumberField label="Venus en JPO" source="leads_jpo" />
        </Datagrid>
      </List>
      <CenterShow />
      <CenterEdit />
      <CenterCreate />
    </>
  )
};

export default CenterList;
