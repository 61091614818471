import uniqid from '../../common/uniqid'

function createGroupFilters(originalFilterValues) {  
  let filterGroups = {}

  for (const filterLabel in originalFilterValues) {
    for (const key in originalFilterValues[filterLabel]) {
      const filter = originalFilterValues[filterLabel][key]
      const group = filter.filterGroup

      if (!filterGroups[group]) {
        const uniqueGroupID = uniqid()

        filterGroups[group] = {
          groupUniqueID: uniqueGroupID,
          groupFilters: {},
          groupColor: filter.groupColor,
          groupCondition: filter.groupCondition,
          groupNumber: group,
        }
      }

      const uniquefilterLabel = filterLabel + '_' + filterGroups[group].groupUniqueID
      
      if (!filterGroups[group].groupFilters[uniquefilterLabel]) {
        filterGroups[group].groupFilters[uniquefilterLabel] = {}
      }

      filterGroups[group].groupFilters[uniquefilterLabel][key] = {
        filterId: filter.filterId,
        filterTitle: filter.filterTitle,
        filterLabel: filter.filterLabel,
        filterMethod: filter.filterMethod,
        filterGroup: filter.filterGroup,
      }
    }
  }
  
  return Object.values(filterGroups)
}

export default createGroupFilters
