import { Chip, Tooltip } from '@mui/material'
import { DatagridConfigurable, FilterForm, FunctionField, Link, List, SearchInput, TopToolbar, useNotify, useRecordContext, useRedirect, useRefresh } from 'react-admin'
import { Typography } from '@mui/material'

import sendMail from '../common/SendMail'
import { supabaseClient } from '../supabase'
import { PostPagination } from '../common/Pagination'
import createNotification from '../common/createNotification'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove'
import NotificationAddIcon from '@mui/icons-material/NotificationAdd'

const LeadTasksPubeurToolbar = ({ taskId }) => {
  const redirect = useRedirect()
  const notify = useNotify()

  const removeTaskForAllLead = async (taskId) => {
    const { error } = await supabaseClient
      .from('lead_tasks')
      .delete()
      .eq('task_id', taskId)

    if (error) {
      console.error('Error removing task from leads', error)
      return
    }

    console.log('Task removed for all leads')

    redirect('/tasks/'+ taskId, true)
    notify('Tâche retirée pour tous les contacts', { type: 'success' })
  }

  const resetTaskForAllLeads = async (taskId) => {
    try {
      const { data: tasksData, error: tasksError } = await supabaseClient
        .from('tasks_parcours')
        .select('tree')
        .eq('task_id', taskId)

      if (tasksError) {
        console.error('Error fetching tasks parcours', tasksError)
        return
      }

      const { data: leadsData, error: leadsError } = await supabaseClient
        .from('lead_tasks')
        .select('id')
        .eq('task_id', taskId)

      if (leadsError) {
        console.error('Error fetching leads', leadsError)
        return
      }

      for (const lead of leadsData) {
        const { error } = await supabaseClient
          .from('lead_tasks')
          .update({ tree: tasksData[0].tree, progress: null, is_waiting: null, callback: null })
          .eq('id', lead.id)

        if (error) {
          console.error('Error resetting task', error)
          return
        }

        console.log('Task reset for lead', lead.id)
      }

      notify('Tâche réinitialisée pour tous les contacts', { type: 'success' })
    } catch (error) {
      console.error('Error resetting task for all leads', error)
      notify('Erreur lors de la réinitialisation de la tâche pour tous les contacts', { type: 'error' })
    }
  }

  const PubeursListFilter = [
    <SearchInput placeholder="Recherche par pubeur" source="fullname@ilike" variant="outlined" alwaysOn />,
  ].filter(Boolean)

  return (
    <TopToolbar>
      <Tooltip title="Retirer la tâche" placement="top">
        <BookmarkRemoveIcon
        sx={{ color: '#c93838', fontSize: '1.5rem', cursor: 'pointer', mb: 2 }}
        onClick={() => removeTaskForAllLead(taskId)}
        />
      </Tooltip>

      <Tooltip title="Réinitialiser la tâche pour tous les contacts" placement="bottom">
        <RotateLeftIcon
          sx={{ color: '#1c94bc', fontSize: '1.75rem', cursor: 'pointer', mb: 1.75 }}
          onClick={() => resetTaskForAllLeads(taskId)}
        />
      </Tooltip>

      <FilterForm filters={PubeursListFilter} />
    </TopToolbar>
  )
}

const TaskPubeurExpand = ({ taskId }) => {
  const props = useRecordContext() // lead_tasks_pubeurs used as resource in List component parent
  const notify = useNotify()
  const refresh = useRefresh()

  const resetTaskFromLead = async (taskId, leadTaskId) => {
    const { data: tasksData, error: tasksError } = await supabaseClient
      .from('tasks_parcours')
      .select('tree')
      .eq('task_id', taskId)

    if (tasksError) {
      console.error('Error fetching tasks parcours', tasksError)
      return
    }

    const { error } = await supabaseClient
      .from('lead_tasks')
      .update({ tree: tasksData[0].tree, progress: null, is_waiting: null, callback: null })
      .eq('id', leadTaskId)

    if (error) {
      console.error('Error resetting task', error)
      return
    }
  }

  const removeTaskFromLead = async (leadTaskId) => {
    const { error } = await supabaseClient
      .from('lead_tasks')
      .delete()
      .eq('id', leadTaskId)

    if (error) {
      notify('Erreur lors de la suppression de la tâche du contact', { type: 'error' })
      return
    }

    notify('Tâche retirée du contact avec succès', { type: 'success' })
    refresh()
  }

  const sendReminder = async (profile) => {
    const messageHTML = `
      <h3>Rappel de votre responsable</h3>

      <h4><u>Il vous reste des tâches à compléter.</u></h4>

      <p><a href="${process.env.REACT_APP_WEBSITE_URL}/leads">Cliquez ici pour vous rendre sur le CRM</a></p>

      <p><i>Ceci est un message automatique depuis le CRM FormaContact, merci de ne pas y répondre.</i></p>
    `

    const messageTEXT = `Rappel de votre responsable : il vous reste des tâches à compléter.\n\nCeci est un message automatique, merci de ne pas y répondre.`
    
    sendMail ({
      identity: profile,
      subject: `Rappel de tâche`,
      messageHTML: messageHTML,
      messageTEXT: messageTEXT,
      sendNow: true
    })
    
    createNotification ({
      profile_id: profile.id,
      type: 'taskReminder',
      title: `Rappel de tâche`,
      message: `Une ou plusieurs tâches reste à compléter sur des contacts.`,
      url: `/leads`,
    })

    notify('Rappel envoyé avec succès', { type: 'success' })
  }

  return (
    <TableContainer component={Paper} sx={{ margin: '10px 0' }}>
      <Table aria-label="simple table">
        <TableBody sx={{ '& .MuiTableCell-root': { padding: '5px 10px' } }}>
          { // iterate of the lead (record)
            props?.taskcontact?.map((record, index) => (
            taskId === record.taskId && (
              <TableRow
                key={record.id +'-'+ index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                { // clickable link to the contact profile if the lead is from the current year or if it's a Terminale
                  ( record.leadYear === process.env.REACT_APP_CURRENT_YEAR_ID || record.leadClassId === 3 )
                    ? <TableCell><Link to={`${process.env.REACT_APP_WEBSITE_URL}/leads/${record.leadId}/tasks/`}>{record.fullName}</Link></TableCell>
                    : <TableCell><i>{record.fullName}</i></TableCell>
                }

                {/* not necessary since we are in the task already
                <TableCell>{record.taskName}</TableCell> */}

                <TableCell>
                  { record.progress === '100'
                    ? <Chip label="Terminé" color="success" />
                    : record.progress === '0' || record.progress === null
                      ? <Chip label="Non commencée" color="default" />
                      : <Chip label="En cours..." color="warning" />
                  }
                </TableCell>

                <TableCell>
                  { record.dateAssignation }
                </TableCell>

                <TableCell>
                  { ( record.leadYear === process.env.REACT_APP_CURRENT_YEAR_ID || record.leadClassId === 3 ) && record.progress < 100 && props.fullprofile &&
                    <Tooltip title="Envoyer un rappel" placement="top">
                      <NotificationAddIcon
                        onClick={() => sendReminder(props.fullprofile)}
                        sx={{ color: '#e8ba5b', fontSize: '1.25rem', cursor: 'pointer' }}
                      />
                    </Tooltip>
                  }
                </TableCell>

                <TableCell>
                  { ( record.leadYear === process.env.REACT_APP_CURRENT_YEAR_ID || record.leadClassId === 3 ) && ( record.progress === null || record.progress === '0' )
                    ? <Tooltip title="Retirer la tâche" placement="top">
                        <BookmarkRemoveIcon
                        sx={{ color: '#c93838', fontSize: '1.25rem', cursor: 'pointer' }}
                        onClick={() => removeTaskFromLead(record.leadTaskId)}
                        />
                      </Tooltip>
                    : null }
                </TableCell>

                <TableCell>
                  { ( record.leadYear === process.env.REACT_APP_CURRENT_YEAR_ID || record.leadClassId === 3 ) && record.progress === null || record.progress === '0'
                    ? <Tooltip title="Réinitialiser la tâche" placement="top">
                        <RotateLeftIcon
                          sx={{ color: '#1c94bc', fontSize: '1.25rem', cursor: 'pointer' }}
                          onClick={() => resetTaskFromLead(record.taskId, record.leadTaskId)}
                        />
                      </Tooltip>
                    : null }
                </TableCell>
              </TableRow>
            )
          )) }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TasksPubeurs = () => {
  const taskRecord = useRecordContext()

  return (
    <List
      disableSyncWithLocation
      pagination={<PostPagination />}
      perPage={50}
      resource="lead_tasks_pubeurs"
      exporter={false}
      sort={{ field: 'fullname', order: 'ASC' }}
      filter={{ center_id: taskRecord.center_id, task_id: taskRecord.id }}
      actions={<LeadTasksPubeurToolbar taskId={taskRecord.id} />}
      empty={
        <Typography variant="body1" color="textSecondary" mt={3} ml={4}>
          Aucun pubeur n'a été trouvé pour cette tâche, ou bien les pubeurs ne font pas parti de votre centre,
          auquel cas vous ne pouvez pas les voir.
        </Typography>
      }
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        expand={<TaskPubeurExpand taskId={taskRecord.id} />}
        expandSingle
      >
        <FunctionField
          label="Pubeur"
          render={record => <Link to={`${process.env.REACT_APP_WEBSITE_URL}/profiles/${record.fullprofile?.id}/`}>{record.fullname}</Link> }
        />

        <FunctionField
          label="Avancement"
          render={record => <p>{record.taskcontact[0].progresstotal}</p>}
        />
      </DatagridConfigurable>
    </List>
  )
}

export default TasksPubeurs