import { List, ListItem, ListItemText } from '@mui/material'

import { CardWithIcon } from '../../Design/Cards'

import EventIcon from '@mui/icons-material/Event'

const EventsList = ({ eventsInfo, date }) => {
  const dateNow = new Date().toISOString()
  const numberOfEvents = eventsInfo?.length || 0
  const missionsRealisees = eventsInfo?.filter(event => event.type_id === process.env.REACT_APP_EVENT_TYPE_MISSIONLYCEE && event.end_at >= date).length || 0
  const interRealisees = eventsInfo?.filter(event => event.type_id === process.env.REACT_APP_EVENT_TYPE_INTERVENTION && event.end_at >= date).length || 0
  const interEnCours = eventsInfo?.filter(event => event.type_id === process.env.REACT_APP_EVENT_TYPE_INTERVENTION && event.end_at >= date && event.end_at <= dateNow).length || 0
  const interAvenir = eventsInfo?.filter(event => event.type_id === process.env.REACT_APP_EVENT_TYPE_INTERVENTION && event.end_at > dateNow).length || 0

  return (
    <div style={{ width: '33.33333%' }}>
      <CardWithIcon
        to="/events"
        icon={EventIcon}
        title="Événements"
        subtitle={numberOfEvents}
        color="#57aaa6"
      >
        <List>
          <ListItem>
            <ListItemText primary="Missions lycée réalisées" secondary={missionsRealisees} />
          </ListItem>

          <ListItem>
            <ListItemText primary="Interventions réalisées" secondary={interRealisees} />
          </ListItem>

          <ListItem>
            <ListItemText primary="Interventions en cours" secondary={interEnCours} />
          </ListItem>

          <ListItem>
            <ListItemText primary="Interventions à venir" secondary={interAvenir} />
          </ListItem>
        </List>
      </CardWithIcon>
    </div>
  )
}

export default EventsList