import { supabaseClient } from '../supabase'
import baseDataProvider from '../supabase/baseDataProvider'

export const dataProvider = {
  ...baseDataProvider,
  getList: async (resource, params) => {
    if ( params.filter && params.filter.start_gte && params.filter.start_lte ) {
      const start_gte = new Date(params.filter.start_gte).toISOString()
      const start_lte = new Date(params.filter.start_lte).toISOString()

      delete params.filter.start
      delete params.filter.end
      delete params.filter.start_gte
      delete params.filter.start_lte

      const { data, error } = await supabaseClient
        .from(resource)
        .select()
        .lte('start', start_lte)
        .gte('start', start_gte)

      if (error) {
        console.error('Error fetching calendar: ', error)
        return { data: [], total: 0 }
      }

      return {
        data: data,
        total: data.length,
      }
    }

    return baseDataProvider.getList(resource, params)
  }
}