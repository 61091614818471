import { useEffect, useState } from 'react'
import {
  useRefresh,
  useNotify,
  useGetIdentity,
  useUnselectAll,
  useListContext,
} from 'react-admin'

import { getRole } from '../../common/roles'
import { supabaseClient } from '../../supabase'
import addNewComment from '../../common/addLeadComment'
import { CustomSelectInput } from '../../Design/CustomSelectInput'

const EventAssignation = () => {
  const { identity } = useGetIdentity()
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll('leads')
  const { selectedIds: leads } = useListContext()

  const [eventsOptions, setEventsOptions] = useState([])

  const handleEventsAction = async (chunk, eventId ) => {
    chunk.forEach(async lead => {
      const { error } = await supabaseClient
        .from('lead_events')
        .insert({
          lead_id: lead,
          event_id: eventId,
          current_year_id: process.env.REACT_APP_CURRENT_YEAR_ID,
        })
  
      if (error) {
        console.error('Error updating qualifications: ', error)
        return
      }
  
      addNewComment(lead, identity?.id, eventId, '*AUTO* Nouvel événement rattaché')
    })
  
    unselectAll()
    notify(`Événement assigné aux contacts`, {'type' : 'success'})
    refresh()
  }

  const saveEvents = (event) => {
    if ( ! event ) return

    const chunkSize = 100
    const chunks = []

    for ( let i = 0; i < leads?.length; i += chunkSize ) {
      chunks.push(leads.slice(i, i + chunkSize))
    }

    chunks.forEach(chunk => {
      handleEventsAction(chunk, event, notify)
    })
  }

  useEffect(() => {
    const getEventsOptions = async () => {
      let query = supabaseClient
        .from('events')
        .select('id, name')
        .order('name', { ascending: true })
      
      if ( identity?.role?.grade < getRole('admin') ) {
        query.eq('center_id', identity?.center?.id)
      }
      
      const { data, error } = await query

      if (error) {
        console.error('Error fetching qualifications: ', error)
        return
      }

      let optionsEvents = []
      data.map(row => {
        const event = {
          label: row.name,
          value: row.id,
        }

        optionsEvents.push(event)
      })

      setEventsOptions(optionsEvents)
    }

    getEventsOptions()
  }, [])

  return (
    <CustomSelectInput
      id="selectedEvents"
      label="Événements"
      options={eventsOptions}
      onChange={(event, newValue) => saveEvents(newValue?.value ?? null, identity)}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': { border: '1px solid #ccc' },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': { fontSize: '.9rem' },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall.MuiAutocomplete-input': { padding: '0' },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': { padding: '2', width: '250px' },
      }}
    />
  )
}

export default EventAssignation