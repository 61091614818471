import { useState, useEffect } from 'react'
import {
  TextInput,
  ArrayInput,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  useGetIdentity,
  useRecordContext,
  AutocompleteInput,
  DatagridConfigurable,
  SearchInput,
  IconButtonWithTooltip
} from 'react-admin'
import { FormControl, Select as MUIselect, InputLabel, MenuItem, Box, Typography } from '@mui/material'

import { supabaseClient } from '../../supabase'
import { FormCard, PaieCardWithIcon } from '../../Design/Cards'

import RefreshIcon from '@mui/icons-material/Refresh'
import LinearProgress from '@mui/material/LinearProgress'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'

const ExpandPubeur = props => {
  const record = useRecordContext()
  const [paiePubeurs, setPaiePubeurs] = useState(null)
  const [totalPubeur, setTotalPubeur] = useState(0)

  const fetchPaiePubeurs = async () => {
    const { data, error } = await supabaseClient
      .from('paie_pubeur')
      .select('*')
      .eq('profile_id', record.id)

    if (error) {
      console.error('Error fetching data:', error)
    }
    
    setPaiePubeurs(data)
    
    // get total paid
    const total = data.reduce((acc, pubeur) => acc + (pubeur.amount * pubeur.quantity), 0).toFixed(2)

    setTotalPubeur(total)
  }

  // Fetch paie_pubeurs for the current pubeur
  useEffect(() => {
    if (! record ) return

    fetchPaiePubeurs()
  }, [record])

  const handleSubmit = async (values) => {
    const { paie_pubeurs } = values

    const newRows = paie_pubeurs.filter(p => !p.id) // Rows without 'id' are new
    const updatedRows = paie_pubeurs.filter(p => p.id) // Rows with 'id' are updates

    const newValues = newRows.map(pubeur => ({
      ...pubeur,
      profile_id: record.id,
    }))

    const updateValues = updatedRows.map(pubeur => ({
      ...pubeur,
      profile_id: record.id,
    }))

    const deletedValues = paiePubeurs.filter(pubeur => !paie_pubeurs.find(p => p.id === pubeur.id))

    try {
      if (!record) return

      // Insert new rows
      if (newValues.length > 0) {
        const { data, error } = await supabaseClient
          .from('paie_pubeur')
          .insert(newValues)

        if (error) {
          console.error('Error inserting new rows:', error)
        }
      }

      // Update existing rows
      if (updateValues.length > 0) {
        const { error } = await supabaseClient
          .from('paie_pubeur')
          .upsert(updateValues, { onConflict: ['id'] })

        if (error) {
          console.error('Error updating rows:', error)
        }
      }

      // Delete rows
      if (deletedValues.length > 0) {
        const { error } = await supabaseClient
          .from('paie_pubeur')
          .delete()
          .in('id', deletedValues.map(p => p.id))

        if (error) {
          console.error('Error deleting rows:', error)
        }
      }

      // Refetch the data to update the UI
      const { data: updatedData } = await supabaseClient
        .from('paie_pubeur')
        .select('*')
        .eq('profile_id', record.id)

      setPaiePubeurs(updatedData)
    } catch (err) {
      console.error('Error during submission:', err)
    }
  }

  return paiePubeurs ? (
    <FormCard>
      <SimpleForm onSubmit={handleSubmit} {...props} record={{ paie_pubeurs: paiePubeurs }}>
        <ArrayInput source="paie_pubeurs" label={false}>
          <SimpleFormIterator inline>
            <ReferenceInput source="mission_id" reference="paie_missions">
              <AutocompleteInput optionText="name" label="Mission" sx={{ width: '49%' }} />
            </ReferenceInput>
            <ReferenceInput source="unit_id" reference="paie_units">
              <AutocompleteInput optionText="name" label="Unité de mesure" sx={{ width: '20%' }} />
            </ReferenceInput>
            <NumberInput source="amount" label="Montant" sx={{ width: '13%' }} />
            <NumberInput source="quantity" label="Quantité" sx={{ width: '12%' }} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>

      <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'right' }} gutterBottom>
          {`TOTAL BRUT : ${totalPubeur} €`}
          <IconButtonWithTooltip
            label="Rafraîchir"
            onClick={fetchPaiePubeurs}
          >
            <RefreshIcon />
          </IconButtonWithTooltip>
        </Typography>
    </FormCard>
  ) : <LinearProgress color="secondary" />
}

const actionFilters = [
  <SearchInput placeholder="Nom" source="last_name@ilike" variant="outlined" sx={{ maxWidth: '200px' }} alwaysOn resettable />,  
  <SearchInput placeholder="Prénom" source="first_name@ilike" variant="outlined" sx={{ maxWidth: '200px' }} alwaysOn resettable />,
]

const DashboardRespoPaies = () => {
  const { identity } = useGetIdentity()
  const inProduction = process.env.NODE_ENV === 'production'

  const [month, setMonth] = useState('')

  const handleChange = (event) => {
    setMonth(event.target.value)
  }

  let currentMonth = new Date().toLocaleString('fr', { month: 'long', year: 'numeric' })
  // if currentMonth is > 16th, we display the next month
  if (new Date().getDate() > 15) {
    const nextMonth = new Date()
    nextMonth.setMonth(nextMonth.getMonth() + 1)
    currentMonth = nextMonth.toLocaleString('fr', { month: 'long', year: 'numeric' })
  }

  return inProduction ? (
    <Box sx={{ p: 4 }}>
      <Typography variant="h5" gutterBottom>
        Cette fonctionnalité est en cours de développement.
      </Typography>
      
      <Typography variant="body1">
        Elle sera bientôt disponible.
      </Typography>
    </Box>
  ) :
    (
    <Box sx={{ p: 0 }}>
      <PaieCardWithIcon
        icon={AccountBalanceIcon}
        title={currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1)}
        subtitle="Vous pouvez éditer les paies jusqu'au 14 du mois suivant à 23h59."
        colorTitle="#fbc410"
      >
        <div style={{ width: '30%' }}>
          <FormControl variant="outlined" sx={{ m: .5, minWidth: '100%' }}>
            <InputLabel id="monthLabel">Archives des mois précédents</InputLabel>
            <MUIselect
              labelId="monthLabel"
              id="select-monthLabel"
              value={month}
              variant="outlined"
              onChange={handleChange}
              label="Archives des mois précédents"
            >
              <MenuItem value="">
                <em>Sélectionner un mois :</em>
              </MenuItem>

              <MenuItem value={10}>Août 2024</MenuItem>
              <MenuItem value={20}>Juillet 2024</MenuItem>
              <MenuItem value={30}>Juin 2024</MenuItem>
            </MUIselect>
          </FormControl>
        </div>
      </PaieCardWithIcon>

      <List
        resource="profiles"
        basePath="/profiles"
        perPage={20}
        filter={{ enabled: true, center_id: identity?.center?.id,
          'role_id@neq': ['597c594e-c095-4671-a2fa-bce91f2bf920', 'e7d5673e-9f94-4229-a1b4-bb1f4dcd71de']
        }}
        exporter={false}
        actions={false}
        filters={actionFilters}
        sx={{ mt: 2 }}
      >
        <DatagridConfigurable
          bulkActionButtons={false}
          rowClick="expand"
          expand={<ExpandPubeur />}
          expandSingle
        >
          <TextField source="first_name" label="Prénom" />
          <TextField source="last_name" label="Nom" />
          <ReferenceField source="role_id" reference="roles" label="Role" link={false}>
            <TextField source="name" />
          </ReferenceField>
        </DatagridConfigurable>
      </List>
    </Box>
  )
}

export default DashboardRespoPaies
