import { useGetList } from "react-admin";

const GetData = (table, perPage = 50, field = 'name', order = 'ASC', filter) => {
  const { data, error } = useGetList(
    table, { 
      pagination: { page: 1, perPage: perPage },
      sort: { field: field, order: order },
      filter
    }
  )

  if ( error ) throw new Error(error)

  return data ?? []
}

export default GetData