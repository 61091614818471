import { SelectField, useRecordContext } from 'react-admin'
import customColor from '../common/customColor'

const buttonStyle = {
  padding: '5px',
  borderRadius: '5px',
  minWidth: '100px',
  textAlign: 'center',
}

export const InfoSelectField = props => {
  const info = useRecordContext()
  const {textColor, bgColor} = customColor(info)

  const style = {
    ...buttonStyle,
    color: textColor,
    backgroundColor: bgColor
  }

  return <SelectField style={style} {...props} />
}