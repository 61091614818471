import { useEffect, useState, useMemo, useCallback } from 'react'
import { useGetIdentity, useNotify, useRedirect } from 'react-admin'
import { ContainerLayout } from '@react-admin/ra-navigation'
import { TourProvider } from '@react-admin/ra-tour'
import { Stack, useMediaQuery } from '@mui/material'
import { isEqual } from 'lodash'

import Tours from './Tours'
import { getRole } from './common/roles'
import { supabaseClient } from './supabase'
import LoadingPage from './Design/LoadingPage'
import CustomBreadCrumb from './CustomBreadCrumb'
import dataProvider from './supabase/dataProvider'
import CustomUserMenu, { MyMenu } from './UserMenu'
import FrontUpdateAvailable from './Design/FrontUpdateAvailable'
import { useServiceWorker } from './contexts/ServiceWorkerContext'
import { getItemFromLocalStorage, setItemToLocalStorage } from './common/LocalStorage'
import fetchEvents from './common/fetchEvents'

const getLeadYears = async () => {
  const { data, error } = await supabaseClient
    .from('lead_years')
    .select('id, name')
    .order('created_at', { ascending: false })

  if ( error ) {
    console.error('Error getting lead years: ', error)
    return []
  }

  return data
}

export const MyLayout = ({ children, ...rest }) => {
  const { isUpdateAvailable } = useServiceWorker()
  
  const { identity } = useGetIdentity()
  const notify = useNotify()
  const redirect = useRedirect()
  const role = identity?.role?.grade
  const isPubeur = identity?.role?.grade < getRole('responsable')
  const [profileTours, setProfileTours] = useState(null)
  const [maintenanceStyle, setMaintenanceStyle] = useState({display: 'none'})
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    { noSsr: true }
  )

  const currentTimestamp = isPubeur ? new Date().toISOString() : null

  const defaultCurrentYearLocal = getItemFromLocalStorage('localCurrentYear')
  const cachedLeadYears = getItemFromLocalStorage('leadYears') || null
  const localCenter = getItemFromLocalStorage('localCenter')
  const localUserGrade = getItemFromLocalStorage('localUserGrade')
  const localUserId = getItemFromLocalStorage('localUserId')
  const maintenanceStatusCheck = getItemFromLocalStorage('maintenanceStatusCheck')
  const centerTags = getItemFromLocalStorage('centerTags')
  const eventsInfo = getItemFromLocalStorage('eventsInfo')
  const profileToursStatus = getItemFromLocalStorage('profileToursStatus')

  const fetchData = useCallback(async () => {
    if (!identity?.id) return
  
    try {
      if (!defaultCurrentYearLocal) {
        console.log('Init. Fetching local current year...')
        const { data: currentYear } = await dataProvider.getOne('lead_years', { id: process.env.REACT_APP_CURRENT_YEAR_ID })
        setItemToLocalStorage('localCurrentYear', currentYear)
      }
  
      if (!cachedLeadYears) {
        const years = await getLeadYears()
        console.log('Init. Fetching all school years...')
        setItemToLocalStorage('leadYears', years)
      }
  
      if (!localCenter) {
        setItemToLocalStorage('localCenter', identity?.center)
      }
  
      if (!localUserGrade) {
        setItemToLocalStorage('localUserGrade', role)
      }
  
      if (!localUserId) {
        setItemToLocalStorage('localUserId', identity?.id)
      }
    } catch (error) {
      console.error('Error setting up local data for the current user', error)
    }
  }, [identity, dataProvider, cachedLeadYears])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    const getMaintenanceStatus = async () => {
      console.log('Init. Checking maintenance status...')

      const { data, error } = await supabaseClient
        .from('isUnderMaintenance')
        .select('status')
        .single()

      if (error) {
        console.error('Error getting maintenance status: ', error)
      }

      // Update the state based on the maintenance status
      setMaintenanceStyle({
        display: data?.status ? 'flex' : 'none',
      })
    }

    // Check the last check time and update if necessary
    if ( ! maintenanceStatusCheck || maintenanceStatusCheck < Date.now() - 3600000) { // 1 hour check
      getMaintenanceStatus()
      localStorage.setItem('maintenanceStatusCheck', Date.now())
    }
  }, [maintenanceStatusCheck])

  useEffect(() => {
    if ( ! identity ) return

    setProfileTours(identity.tours || {})

    const getCenterTags = async () => {
      console.log('Init. Getting center tags...')

      const { data, error } = await supabaseClient
        .from('tags')
        .select('*')
        .eq('center_id', identity.center.id)

      if (error) {
        console.error('Error getting center tags: ', error)
      }

      setItemToLocalStorage('centerTags', { tags: data, updatedAt: Date.now() })
    }

    const getEventsInfo = async () => {
      // fetch all events
      const events = await fetchEvents(identity?.center?.id, identity, currentTimestamp)

      // fetch all qualifications
      const { data, error } = await supabaseClient
        .from('types_qualifications')
        .select('id,name')

      if (error) {
        console.error('Error getting qualifications for one event: ', error)
      }
      
      for ( const event of events ) {
        // to display in LeadForm
        if ( event.date ) {
          const splitedDate = event.date.split('T')
          event.startDate = splitedDate[0] || null
        }

        // change startDate to dd-mm-yyyy
        if (event.startDate) {
          const date = event.startDate.split('-')
          event.startDate = `${date[2]}/${date[1]}/${date[0]}`
        }

        if ( ! event?.qualifications_ids ) continue
  
        let qualificationInfo = []
        for ( const qualificationId of event.qualifications_ids ) {
          const qual = data.filter(qualification => qualification.id === qualificationId)
  
          qualificationInfo.push(qual)
        }
        
        event.qualificationInfo = qualificationInfo ?? []
      }

      events.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
      
      setItemToLocalStorage('eventsInfo', { events, updatedAt: Date.now() })
    }

    if (!centerTags || Date.now() - centerTags.updatedAt > 600000) { // 10mn
      getCenterTags()
    }

    if (!eventsInfo || Date.now() - eventsInfo.updatedAt > 120000) { // 2mn
      getEventsInfo()
    }
  }, [identity])

  useEffect(() => {
    if ( ! identity || ! profileTours ) return
    
    const updateProfile = async () => {
      console.log('Init. Updating profile tours...')

      setItemToLocalStorage('profileToursStatus', profileTours)

      const { error } = await supabaseClient
        .from('profiles')
        .update({ tours: profileTours })
        .eq('id', identity.id)
        .single()

      if (error) {
        console.error('Error updating profile: ', error)
      }
    }

    if ( ! profileToursStatus || ! isEqual ( profileToursStatus, profileTours ) ) {
      updateProfile()
    }
  }, [profileTours])

  // Memoize the style so that it only updates when the status is changed
  const memoizedStyle = useMemo(
    () => ({
      ...maintenanceStyle,
      backgroundColor: '#f79525',
      padding: '10px',
      fontSize: '.9rem',
      fontWeight: 600,
    }),
    [maintenanceStyle] // Only recalculate if `maintenanceStyle` changes
  )

  return identity ? (
    <>
      { isUpdateAvailable &&
        <FrontUpdateAvailable />
      }

      <TourProvider tours={Tours} tools={{ notify, redirect, profileTours, setProfileTours }}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={memoizedStyle}>
          Une opération de maintenance est en cours. Merci de votre compréhension.
        </Stack>

        <ContainerLayout
          {...rest}
          title={ isMobile ? '' : ! isPubeur ? '' : 'Forma Contacts'}
          maxWidth="xl"
          menu={<MyMenu role={role} {...rest} />}
          userMenu={<CustomUserMenu />}
          sx={ role === getRole('admin') ? {
            '& .RaHeader-toolbar .MuiTab-root': {
              minWidth: '50px',
              typography: { fontSize: '.6rem', fontWeight: 300, padding: '0px 5px' } },
            } : role >= getRole('responsable') ? {
              '& .RaHeader-toolbar .MuiTab-root': {
                minWidth: '55px',
                typography: { fontSize: '.6rem', fontWeight: 300, padding: '0px 5px' } },
            } : {
              '& .RaHeader-toolbar .MuiTab-root': {
                minWidth: '70px',
                typography: { fontSize: '.8rem', fontWeight: 500, padding: '0px 15px' } },
            }
          }
        >
          <CustomBreadCrumb />
          {children}
        </ContainerLayout>
      </TourProvider>
    </>
  ) : <LoadingPage />
}
