import React, { useState } from 'react'
import { Button, useNotify } from 'react-admin'
import { supabaseClient } from '../supabase'

const SendRecoveryLinkButton = ({ email }) => {
  const notify = useNotify()
  const [loading, setLoading] = useState(false)

  const handleSendLink = async () => {
    setLoading(true)

    const { error } = await supabaseClient.auth.resetPasswordForEmail(email)

    setLoading(false)

    if (error) {
      console.error(`Error: ${error.message}`)
      notify('Impossible d\'envoyer le lien de récupération de mot de passe', { type: 'error' })
      setLoading(false)
      return
    }

    notify('Lien de récupération de mot de passe envoyé avec succès', { type: 'success' })
  }

  return (
    <Button
      label={ loading ? 'Envoi en cours...' : 'Envoyer un lien de récupération de mot de passe' }
      variant="contained"
      color="secondary"
      onClick={handleSendLink}
      disabled={loading}
      sx={{ mt: 1.5 }}
      fullWidth
    />
  )
}

export default SendRecoveryLinkButton