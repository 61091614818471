import { NumberField, ReferenceField } from 'react-admin'
import { Typography } from '@mui/material'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { FormCard } from '../Design/Cards'

const SchoolBilanPerYear = ({ cardWidth, title = 'Bilan', tableview, classOptions = [] }) => (
  <FormCard title={title} cardWidth={cardWidth}>
    <TableContainer component={Paper} sx={{ marginTop: '25px' }}>
      <Table>
        <TableHead>
          <TableRow>
            
            {classOptions?.map((classList, key) => (
              <TableCell key={key} align="center">
                <Typography color="textSecondary" variant="body2">
                  Nombre de {classList.name}s
                </Typography>
              </TableCell>
            ))}

            <TableCell align="center">
              <Typography color="textSecondary" variant="body2">
                Total
              </Typography>
            </TableCell>

            <TableCell align="center">
              <Typography color="textSecondary" variant="body2">
                Inscrits à la prépa
              </Typography>
            </TableCell>

          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>

            {classOptions?.map((classList, key) => (
              <TableCell key={key} align="center">
                <Typography variant="h6">
                  <ReferenceField
                    key={key}
                    label={`${classList.name}s`}
                    source="id"
                    reference={tableview}
                    link={false}
                  >
                    <NumberField source={`class_id_${classList.id}`} />
                  </ReferenceField>
                </Typography>
              </TableCell>
            ))}

            <TableCell align="center">
              <Typography variant="h6">
                <ReferenceField
                  label="Total"
                  source="id"
                  reference={tableview}
                  link={false}
                >
                  <NumberField source="total_leads" />
                </ReferenceField>
              </Typography>
            </TableCell>

            <TableCell align="center">
              <Typography variant="h6">
                <ReferenceField
                  label="Inscrits"
                  source="id"
                  reference={tableview}
                  link={false}
                >
                  <NumberField source="qualified_leads" />
                </ReferenceField>
              </Typography>
            </TableCell>

          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </FormCard>
)

export default SchoolBilanPerYear