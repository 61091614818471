export const specialities = [
    { id: '0', name: 'Mathématiques' },
    { id: '1', name: 'Physique – Chimie' },
    { id: '2', name: 'SVT' },
    { id: '3', name: 'Sciences de l’ingénieur (SI)' },
    { id: '4', name: 'Sciences Économiques et Sociales (SES)' },
    { id: '5', name: 'Numérique et sciences informatiques (NSI)' },
    { id: '6', name: 'Biologie – Écologie' },
    { id: '7', name: 'Éducation physique - Pratiques et culture sportives' },
    { id: '8', name: 'Histoire Géographie - Géopolitique et Sciences Politiques' },
    { id: '9', name: 'Langues - Littératures et cultures étrangères et régionales (LLCER)' },
    { id: '10', name: 'Littératures - langues et cultures de l’antiquité' },
    { id: '11', name: 'Humanités - Littérature et Philosophie' },
    { id: '12', name: 'Arts: Histoire des Arts - Théâtre, Arts Plastiques - Arts du Spectacle - etc' },
];

export const getSpecialitiesIdByName = name => {
    const result = specialities.filter(speciality => speciality.name == name)
    return result[0]?.id
}

export const getSpecialitiesNameById = id => {
    if (Array.isArray(id)) {
      return id.map(id => specialities.find(speciality => speciality.id == id)?.name).join(' - ')
    }

    // return for single id
    return specialities.find(speciality => speciality.id == id)?.name
}
