import { useState, useEffect } from 'react'
import { useGetIdentity, Loading, useRedirect, Button, } from 'react-admin'
import { Grid, Typography, Chip } from '@mui/material'

import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'

import PersonIcon from '@mui/icons-material/Person'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const EventCard = ({ record }) => {
  const redirect = useRedirect()
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.reference === getRole('admin')

  let now = new Date().toISOString()
  const nowMinusOneDay = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
  const isNotHidden = now >= record.missionStartHidden
  const isVisible = now >= record.missionStartAff
  const isFullyVisible = nowMinusOneDay >= record.date

  const options = { year: 'numeric', month: 'long', day: 'numeric', TimeRanges: 'Europe/Paris' }
  const startDate = new Date(record.date).toLocaleDateString('fr-FR', options)
  const timeStart = record.timeStart ? 'à '+ new Date(record.timeStart).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }) : null
  const fullDateStart = `Le ${startDate} ${timeStart ?? ''}`
  
  const [loading, setLoading] = useState(true)
  const [typeData, setTypeData] = useState(null)
  const [centerData, setCenterData] = useState(null)
  const [pubeursInEvents, setPubeursInEvents] = useState(null)

  useEffect(() => {
    // fetch the number of pubeurs for the event
    const fetchData = async () => {
      const { data, error } = await supabaseClient
        .from('event_pubeurs')
        .select('id, pubeur_id, is_leader')
        .eq('event_id', record.id)
      
      if (error) {
        console.error('Error fetching pubeurs in events', error)
      }

      setPubeursInEvents(data?.length)
    }

    fetchData()
  }, [])

  useEffect(() => {
    if ( ! record ) return

    const fetchData = async () => {
      try {
        const { data: typeData, error: typeError } = await supabaseClient
          .from('types')
          .select('name')
          .eq('id', record?.type_id)
          .single()

        if (typeError) {
          console.error('Error fetching task type', typeError)
        }

        const { data: centerData, error: centerError } = await supabaseClient
          .from('centers')
          .select('name')
          .eq('id', record?.center_id)
          .single()

        if (centerError) {
          console.error('Error fetching center', centerError)
        }

        setTypeData(typeData?.name)
        setCenterData(centerData?.name)
      } catch (error) {
        console.error('Error fetching data', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [record])

  return loading
    ? ( <Loading />
    ) : (
      isFullyVisible ? (
        <>
          <Grid container>
            <Grid item xs={10}>
              <Chip label={typeData} sx={{ marginBottom: 1, borderRadius: '5px', backgroundColor: record.typeColor }} />
            </Grid>

            <Grid item xs={2}>
              { pubeursInEvents > 0 && (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, color: '#437c79' }}>
                  <div style={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    height: '4vh', 
                    fontSize: '.8rem',
                    color: '#437c79',
                  }}>
                    <PersonIcon /> 
                    <span style={{ fontWeight: 'bold' }}>{pubeursInEvents}</span>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>

          <Typography variant="h6">{record.name}</Typography>
          { isAdmin && <Typography variant="caption" gutterBottom>{centerData}</Typography> }

          <Typography variant="body1" sx={{ color: '#444', mt: 2 }}>{record.description}</Typography>

          <div style={{ color: '#444', marginTop: '2vh' }}>
            <Typography variant="body2" gutterBottom>
              {fullDateStart}
            </Typography>
          </div>
          
          {record?.schools_names?.map((schoolName, index) => (
            <Button
              key={index + '_' + schoolName}
              label={schoolName}
              variant="contained"
              startIcon={<OpenInNewIcon />}
              sx={{ mt: 2, p: 1, backgroundColor: '#c5dedd', color: '#222', '&:hover': { backgroundColor: '#c5dedd' } }}
              onClick={(e) => {
                e.stopPropagation()
                redirect(`/schools/${record?.schools_ids[index]}`)  // Assuming schools_ids is also present
              }}
              fullWidth
            />
          ))}
        </>
      ) : ( isVisible
        ? (
          <>
            <Grid container>
              <Grid item xs={10}>
                <Chip label={typeData} sx={{ marginBottom: 1, borderRadius: '5px', backgroundColor: record.typeColor }} />
              </Grid>

              <Grid item xs={2}>
                { pubeursInEvents > 0 && (
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, color: '#437c79' }}>
                    <div style={{
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center', 
                      height: '4vh', 
                      fontSize: '.8rem',
                      color: '#437c79', // Keeps your color consistent inside the circle
                    }}>
                      <PersonIcon /> 
                      <span style={{ fontWeight: 'bold' }}>{pubeursInEvents}</span>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>

            <Typography variant="h6" gutterBottom>{record.name}</Typography>
            <Typography variant="body1" sx={{ color: '#444', mt: 2 }}>{record.description}</Typography>

            <div style={{ color: '#444', marginTop: '2vh' }}>
              <Typography variant="body2" gutterBottom>
                {fullDateStart}
              </Typography>
            </div>
          </>
        ) : isNotHidden
        ? (
          <Grid container style={{ opacity: .5 }}>
            <Typography variant="subtitle2" mb={2}>{typeData}</Typography>

            <div style={{ color: '#444', marginTop: '2vh' }}>
              <Typography variant="body2" gutterBottom>
                {fullDateStart}
              </Typography>
            </div>
          </Grid>
        ) : null
      )
    )
}

export default EventCard