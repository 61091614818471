import * as React from 'react'
import PeopleIcon from '@mui/icons-material/People'
import LeadList from './LeadList'

// const LeadList = React.lazy(() => import('./LeadList'))

export default {
  list: LeadList,
  icon: PeopleIcon,
  options: {
    label: 'Contacts',
  },
}