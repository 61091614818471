function isObject(object) {
  return object !== null && typeof object === 'object';
}

export default function objectComparison(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  // Check if the objects have the same number of keys
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Loop through the keys in object1
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];

    const areObjects = isObject(val1) && isObject(val2);
    const areArrays = Array.isArray(val1) && Array.isArray(val2);

    // Compare arrays using deep array comparison
    if (areArrays) {
      if (!arraysDeepEqual(val1, val2)) {
        return false;
      }
    } 
    // If both values are objects, perform deep object comparison
    else if (areObjects) {
      if (!objectComparison(val1, val2)) {
        return false;
      }
    } 
    // If they are neither arrays nor objects, directly compare values
    else if (val1 !== val2) {
      return false;
    }
  }

  return true;
}

function arraysDeepEqual(array1, array2) {
  // If the arrays have different lengths, they aren't equal
  if (array1.length !== array2.length) {
    return false;
  }

  // Compare each element in the arrays
  for (let i = 0; i < array1.length; i++) {
    const val1 = array1[i];
    const val2 = array2[i];

    const areObjects = isObject(val1) && isObject(val2);
    const areArrays = Array.isArray(val1) && Array.isArray(val2);

    // Deep compare arrays
    if (areArrays && !arraysDeepEqual(val1, val2)) {
      return false;
    } 
    // Deep compare objects
    else if (areObjects && !objectComparison(val1, val2)) {
      return false;
    } 
    // Directly compare values
    else if (val1 !== val2) {
      return false;
    }
  }

  return true;
}
