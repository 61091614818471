import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import { useRedirect } from 'react-admin'

const SchoolListPubeur = ({ schools }) => {
  const redirect = useRedirect()

  const handleRowClick = (id) => {
    redirect(`/schools/${id}`)
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ backgroundColor: '#f2efef' }}>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell>Code Postal</TableCell>
            <TableCell>Téléphone</TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {schools.map((record, index) => (
            <TableRow 
              key={record.id} 
              hover 
              style={{ cursor: 'pointer' }} 
              onClick={() => handleRowClick(record.id)}
            >
              <TableCell>{record.name}</TableCell>
              <TableCell>{record.address?.postalCode}</TableCell>
              <TableCell>{record.phone?.replace('+33', '')}</TableCell>
              <TableCell>{record.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SchoolListPubeur
