import * as React from 'react';
import { Box } from '@mui/material';

const getColorFromStatus = status =>
  status === 'cold'
      ? '#7dbde8'
      : status === 'warm'
      ? '#e8cb7d'
      : status === 'hot'
      ? '#e88b7d'
      : status === 'in-contract'
      ? '#a4e87d'
      : '#000'

export const Status = status => (
  <Box
    width={10}
    height={10}
    display="inline-block"
    borderRadius={5}
    bgcolor={getColorFromStatus(status)}
    component="span"
  />
)
