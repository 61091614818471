import baseDataProvider from '../supabase/baseDataProvider'

const eventsDataProvider = {
  ...baseDataProvider,
  getList: async (resource, params) => {
    resource = 'events_view'
    
    let result = null

    const nbrOfFilters = Object.keys(params?.filter) // because params.filter has current_year_id by default
    
    if ( params.filter && nbrOfFilters?.length > 0 ) {
      let filters = params.filter

      function replaceSpacesWithUnderscores(obj) {
        if ( typeof obj !== 'object' ) return obj

        const result = {}

        for (const key in obj) {
          if ( typeof obj[key] === 'string') {
            const modifiedValue = obj[key].replace(/\s/g, '_')
            result[key] = modifiedValue
          } else {
            result[key] = obj[key]
          }
        }

        return result
      }

      const newFilters = replaceSpacesWithUnderscores(filters)

      params.filter = newFilters
    }

    result = await baseDataProvider.getList(resource, params)

    return result
  }
}

export const dataProvider = eventsDataProvider