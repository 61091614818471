import * as React from "react";
import {
    useRecordContext,
    ReferenceField,
    Tab,
    TabbedShowLayout,
    TextField,
} from "react-admin";
import {
    Box,
    Typography,
} from '@mui/material';
import { ShowDialog } from "@react-admin/ra-form-layout";

const SiteShow = props => (
    <ShowDialog fullWidth maxWidth="lg">
        <SiteShowContent {...props} />
    </ShowDialog>
);

const SiteShowContent = props => {
    const record = useRecordContext(props);

    return (
        <TabbedShowLayout>
            <Tab label="Fiche">
                <Box display="flex">
                    <Box mt={1} flex="1">
                        <Typography variant="h5">
                            {record.name}
                        </Typography>

                        <Box display="flex" my={2}>
                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Téléphone
                                </Typography>
                                <Typography variant="subtitle1">
                                    <TextField source="phone" />
                                </Typography>
                            </Box>

                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Adresse principale
                                </Typography>
                                <Typography variant="subtitle1">
                                    {record.address?.street}, {record.address?.postalCode}, {record.address?.city}
                                </Typography>
                            </Box>

                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Centre
                                </Typography>
                                <ReferenceField
                                    label="Centre"
                                    source="center"
                                    reference="centers">
                                    <TextField source="name" />
                                </ReferenceField>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Tab>
        </TabbedShowLayout>
    )
};

export default SiteShow;
