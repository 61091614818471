import { useEffect, useState } from 'react'
import { Button, useGetIdentity, useListContext, useNotify, useRefresh, useUnselectAll } from 'react-admin'
import { Typography, Checkbox, FormControlLabel, FormControl, Radio, RadioGroup } from '@mui/material'

import FormCard from '../../Design/FormCard'
import { supabaseClient } from '../../supabase'
import { CommonDialog } from '../../common/Dialog'
import { getClassIdByName, getClassNameById } from '../../common/classes'
import { getOptionsIdByName, getOptionsNameById } from '../../common/options'
import { getSpecialitiesNameById, getSpecialitiesIdByName } from '../../common/specialities'

import BlenderIcon from '@mui/icons-material/Blender'
import toTimeZone from '../../common/toTimeZone'
import addNewComment from '../../common/addLeadComment'

const LeadsFusion = () => {
  const { identity } = useGetIdentity()
  const notify = useNotify()
  const refresh = useRefresh()
  const unselectAll = useUnselectAll('leads')
  const { selectedIds: leads } = useListContext()

  const [openFusionDialog, setOpenFusionDialog] = useState(false)
  const [leadsInfo, setLeadsInfo] = useState([])
  const [uniqueTags, setUniqueTags] = useState([])
  
  const [allIds, setAllIds] = useState([])

  const [equalValues, setEqualValues] = useState({})
  const [selectedValues, setSelectedValues] = useState({})
  const [checkedTags, setCheckedTags] = useState({})

  const gatherLeadInfo = () => {
    const tags = Object.keys(checkedTags).filter(key => checkedTags[key])
    const leadData = { ...selectedValues, ...equalValues }

    const specialTags = {
      'Stop RGPD': { stop_rgpd: true },
      'Intéressé(e) par la prépa': { interested: true },
      'Pas intéressé(e) par la prépa': { not_interested: true }
    }

    const newLead = {
      last_name: leadData.nom,
      first_name: leadData.prenom,
      email: leadData.email,
      phone: leadData.telephone,
      address: {
        postalCode: leadData.code_postal,
        city: leadData.ville,
        street: leadData.adresse
      },
      school_id: leadData.ecole ?? null,
      status_inscription_id: leadData.inscrit ?? null,
      class_id: getClassIdByName(leadData.classe),
      options: getSpecialitiesIdByName(leadData.options) ?? null,
      desired_options: getOptionsIdByName(leadData.center_id, leadData.formations) ?? null,
      center_id: leadData.center_id,
      created_at: toTimeZone(new Date(), 'Europe/Paris') ?? null,
      updated_at: toTimeZone(new Date(), 'Europe/Paris') ?? null,
      current_year_id: process.env.REACT_APP_CURRENT_YEAR_ID,
      // lead_source_id: leadData.origine,
      lead_source_id: process.env.REACT_APP_SOURCE_WEB,
      tags_ids: tags ? tags.map(tag => uniqueTags[parseInt(tag.split('.')[1])].id) : null,
    }

    tags.forEach(tag => {
      const tagName = uniqueTags[parseInt(tag.split('.')[1])].name

      if (specialTags[tagName]) {
        Object.assign(newLead, specialTags[tagName])
      }
    })
  
    return newLead
  }

  const handleSubmit = async () => {
    const removeOldLead = async (leadId) => {
      // instead of deleting the lead, we remove the year so it doesn't appear in the list
      const { error } = await supabaseClient
        .from('leads')
        .update({ current_year_id: null })
        .eq('id', leadId)

      if (error) {
        console.error('Error removing old lead: ', error)
      }
    }

    const getLeadParcours = async (leadId) => {
      const { data, error } = await supabaseClient
        .from('lead_events')
        .select('*')
        .eq('lead_id', leadId)

      if (error) {
        console.error('Error fetching lead events: ', error)
        return []
      }

      return data ? data : []
    }

    const getLeadComments = async (leadId) => {
      const { data, error } = await supabaseClient
        .from('lead_comments')
        .select()
        .eq('lead_id', leadId)

      if (error) {
        console.error('Error fetching lead comments: ', error)
        return []
      }

      return data ? data : []
    }

    const newLead = gatherLeadInfo()

    // gather all parcours and comments from the original leads
    const allParcoursPromises = allIds.map(async leadId => {
      const leadParcours = await getLeadParcours(leadId)
      return leadParcours
    })
  
    const allCommentsPromises = allIds.map(async leadId => {
      const leadComments = await getLeadComments(leadId)
      return leadComments
    })

    const allParcours = await Promise.all(allParcoursPromises)
    const allComments = await Promise.all(allCommentsPromises)

    const newLeadEvents = allParcours.flat()
    const newLeadComments = allComments.flat()
  
    // create new lead
    const { data, error } = await supabaseClient
      .from('leads')
      .insert(newLead)
      .select('id')
  
    if (error || !data) {
      console.error('Error creating new lead: ', error)
      notify('Ooops... La fusion a échouée. Merci de contacter le support.', { type: 'warning' })
      return
    }

    const newLeadId = data[0]?.id

    // copy all events to the new lead
    newLeadEvents.forEach(async parcours => {
      delete parcours.id

      const { error } = await supabaseClient
        .from('lead_events')
        .insert({
          ...parcours,
          lead_id: newLeadId
        })

      if (error) {
        console.error('Error creating new lead parcours: ', error)
        notify('Ooops... La création du parcours du contact fusionné a échoué. Merci de contacter le support.', { type: 'warning' })
      }
    })

    // copy all comments to the new lead
    newLeadComments.forEach(async comment => {
      delete comment.id

      const { error } = await supabaseClient
        .from('lead_comments')
        .insert({
          ...comment,
          lead_id: newLeadId
        })

      if (error) {
        console.error('Error creating new lead comment: ', error)
        notify('Ooops... La création du commentaire du contact fusionné a échoué. Merci de contacter le support.', { type: 'warning' })
      }
    })

    // add comment to new lead
    addNewComment(newLeadId, identity, null, `Fusion de ${allIds.length} contacts`)

    // remove the original leads
    allIds.map(async leadId => {
      await removeOldLead(leadId)
    })

    setSelectedValues({})
    setCheckedTags({})
    setEqualValues({})
    setLeadsInfo([])
    notify('Fusion effectuée avec succès', { type: 'success' })
    refresh()
    setOpenFusionDialog(false)
    unselectAll()
  }

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target
  
    if (type === 'checkbox') {
      setCheckedTags(prevState => ({
        ...prevState,
        [name]: checked
      }))
    } else {
      const [field, index] = name.split('.')
      setSelectedValues(prevState => ({
        ...prevState,
        [field]: value
      }))
    }
  }

  const capitalizeFirstLetter = (input) => {
    if (typeof input !== 'string') {
      return String(input)
    }

    return input.charAt(0).toUpperCase() + input.slice(1)
  }

  const renderCheckboxes = (column, values, handleChange) => (
    values.map((value, index) => (
      value !== null && (
        <FormControlLabel
          key={`${column}-${index}`}
          control={
            <Checkbox
              name={`${column}.${index}`}
              onChange={handleChange}
              checked={checkedTags[`${column}.${index}`] || false}
            />
          }
          label={value.name}
          sx={{ width: '100%' }}
        />
      )
    ))
  )
  
  const renderRadioButtons = (key, values, handleChange) => (
    <FormControl component="fieldset">
      <RadioGroup name={key} onChange={handleChange}>
        {values.map((value, index) => (
          <FormControlLabel
            key={`${key}-${index}`}
            value={value}  // Here, use the actual value
            control={<Radio />}
            checked={selectedValues[key] === value}
            label={value !== null ? value.toString() : 'null'}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )

  const getLeadInfo = async () => {
    const allIds = []
    const getInfo = async (leadId) => {
      allIds.push(leadId)
  
      const { data, error } = await supabaseClient
        .from('leads')
        .select('*, pubeur: assigned_pubeur (*), status: status_inscription_id(*), source: lead_source_id(*), tags_ids')
        .eq('id', leadId)
  
      if (error) {
        console.error('Error fetching lead info: ', error)
        return
      }
  
      if (!data) return null
  
      const getTagNames = async (tagIds) => {
        if (!Array.isArray(tagIds)) return []
  
        const tagNames = await Promise.all(tagIds.map(async tagId => {
          const { data, error } = await supabaseClient
            .from('tags')
            .select('id, name')
            .eq('id', tagId)
  
          if (error) {
            console.error('Error fetching tag name: ', error)
            return
          }
  
          return { id: tagId, name: data[0]?.name }
        }))
  
        return tagNames.filter(tag => tag !== undefined)
      }
  
      const cleanData = await Promise.all(data.map(async lead => {
        const tagNames = await getTagNames(lead.tags_ids)
  
        const newLead = {
          id: lead.id,
          center_id: lead.center_id,
          nom: lead.last_name,
          prenom: lead.first_name,
          email: lead.email,
          telephone: lead.phone,
          adresse: lead.address.street,
          code_postal: lead.address.postalCode,
          ville: lead.address.city,
          ecole: lead.school_id,
          inscrit: lead.status_inscription_id,
          classe: getClassNameById(lead.class_id),
          options: lead.options ? getSpecialitiesNameById(lead.options) : null,
          formations: lead.desired_options ? getOptionsNameById(lead.center_id, lead.desired_options) : null,
          pubeur: lead.pubeur ? `${lead.pubeur.first_name} ${lead.pubeur.last_name}` : null,
          tags: tagNames,
          status_inscrit: lead.status ? lead.status.name : null,
          // origine: lead.source ? lead.source.name : null,
        }
  
        Object.keys(newLead).forEach(key => {
          if (newLead[key] === null || newLead[key] === '' || (Array.isArray(newLead[key]) && newLead[key].length === 0)) {
            delete newLead[key]
          }
        })
  
        return newLead
      }))
  
      return cleanData
    }
  
    const leadsInfo = await Promise.all(leads.map(lead => getInfo(lead)))
  
    let leadsInfoColumns
    if (leadsInfo) {
      if ( ! leadsInfo[0] ) return null
      
      const columns = Object.keys(leadsInfo[0][0])
  
      leadsInfoColumns = columns.map(column => {
        const columnValues = leadsInfo.map(lead => lead[0][column])
  
        return {
          [column]: columnValues.filter(value => value !== null && value !== undefined && value !== '')
        }
      })
  
      // Remove columns where keys are equal
      const uniqueColumns = []
      leadsInfoColumns.forEach(columnObj => {
        const columnValues = Object.values(columnObj)[0]
        const allValuesEqual = columnValues.every(val => val === columnValues[0])
  
        if (!allValuesEqual && Object.keys(columnObj)[0] !== 'id') {
          uniqueColumns.push(columnObj)
        } else {
          if (Object.keys(columnObj)[0] !== 'id') {
            setEqualValues(prevState => ({
              ...prevState,
              [Object.keys(columnObj)[0]]: columnValues[0]
            }))
          }
        }
      })
  
      leadsInfoColumns = uniqueColumns
    }
  
    // Collect unique tags
    const allTags = leadsInfo.flatMap(lead => lead[0].tags)
    const uniqueTags = [...new Set(allTags)].filter(tag => tag !== undefined && tag !== null)
  
    setLeadsInfo(leadsInfoColumns)
    setUniqueTags(uniqueTags)
    setAllIds(allIds)
  
    // then remove tags from leadsInfo
    const leadsInfoWithoutTags = leadsInfoColumns.filter(column => Object.keys(column)[0] !== 'tags')
    setLeadsInfo(leadsInfoWithoutTags)
  }

  useEffect(() => {
    if ( !leads || leads?.length < 2 || leads?.length > 5 ) return

    getLeadInfo()
  }, [leads])

  return leads?.length > 1 && leads?.length < 6 ? (
    <>
      <CommonDialog
        open={openFusionDialog}
        setOpen={setOpenFusionDialog}
        handleClose={() => setOpenFusionDialog(false)}
        title="Fusionner les contacts"
        size="md"
      >
        <Typography variant="body1">
          Pour chaque contact, sélectionnez les informations à garder dans le contact final.
        </Typography>

        <div>
          { leadsInfo.map((field, fieldIndex) =>
            Object.keys(field).map((key) => {
              let name = capitalizeFirstLetter(key)

              if ( name === 'Prenom' ) name = 'Prénom'
              if ( name === 'Nom' ) name = 'Nom de famille'
              if ( name === 'Telephone' ) name = 'Téléphone'
              if ( name === 'Code_postal' ) name = 'Code Postal'
              if ( name === 'Status_inscrit' ) name = 'Statut d\'inscritpion'

              return key === 'id' || key === 'center_id'
                ? null
                : ( key === 'tags'
                  ? (
                    <FormCard key={key} title={name}>
                      {renderCheckboxes(key, field[key], handleChange)}
                    </FormCard>
                  )
                  : (
                    <FormCard key={key} title={name}>
                      {renderRadioButtons(key, field[key], handleChange)}
                    </FormCard>
                  )
                )
              }
            )
          ) }
        </div>
      
        { uniqueTags.length > 0 && <FormCard title="Tags">
          {renderCheckboxes('tags', uniqueTags, handleChange)}
        </FormCard> }

        <Button
          label="Fusionner"
          onClick={handleSubmit}
          variant="contained"
          fullWidth
        />
      </CommonDialog>

      <Button
        label="Fusionner"
        startIcon={<BlenderIcon />}
        onClick={() => {
          setOpenFusionDialog(true)
        }}
      />
    </>
  ) : null
}

export default LeadsFusion
