import { useEffect, useState } from 'react'
import { TextField, useGetIdentity, useNotify, useRecordContext, Button, useRedirect } from 'react-admin'
import { IconButton } from '@mui/material'

import { getRole } from '../common/roles'
import Tooltip from '@mui/material/Tooltip'
import { supabaseClient } from '../supabase'
import customColor from '../common/customColor'

import PendingIcon from '@mui/icons-material/Pending'
import StarRateIcon from '@mui/icons-material/StarRate'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'

const buttonStyle = {
  padding: '5px',
  borderRadius: '5px',
  minWidth: '100px',
}

export const TruncatedTextField = ({ source, style = {}, maxLength = 25 }) => {
  const record = useRecordContext()
  const redirect = useRedirect()
  
  if (!record) return null
  
  const text = record[source]
  const truncatedText = text?.length > maxLength ? `${text?.substring(0, maxLength)}...` : text ?? null

  const handleClickComment = (e) => {
    e.stopPropagation()
    redirect(`/leads/${record.id}/comments`)
  }


  return source === 'last_comment'
    ? <Tooltip title={text} placement="top-start" onClick={(e) => handleClickComment(e)}><span style={style}>{ truncatedText }</span></Tooltip>
    : <span style={style}>{ truncatedText }</span>
}

export const TasksTextField = () => {
  const info = useRecordContext()
  const redirect = useRedirect()

  const tasks = info?.tasks_info

  if ( ! tasks ) return null

  let countNotStarted = 0
  let countInProgress = 0
  let countIsWaiting = 0
  let countIsReady = 0

  tasks.forEach(task => {
    if ( task?.progress === '0' || task?.progress === null ) {
      countNotStarted++
    } else if ( task?.is_waiting !== null && task?.callback ) {
      const isWaiting = task?.is_waiting

      if ( isWaiting ) {
        countIsWaiting++
      } else {
        countInProgress++
      }
    } else if ( task?.progress > '0' && task?.is_waiting !== true && task?.progress !== '100' ) {
      countInProgress++
    } 
  })

  return (
    <>
      { tasks && tasks.length > 0 ? (
        <>
          {countNotStarted > 0 && (
            <Button
              label={`${countNotStarted}`}
              startIcon={<AssignmentTurnedInIcon />}
              onClick={(e) => {
                e.stopPropagation()
                redirect('/leads/' + info?.id + '/tasks')
              }}
            />
          )}

          {countInProgress > 0 && (
            <Button
              label={`${countInProgress}`}
              startIcon={<AssignmentTurnedInIcon color="warning" />}
              sx={{ color: 'darkred' }}
              onClick={(e) => {
                e.stopPropagation()
                redirect('/leads/' + info?.id + '/tasks')
              }}
            />
          )}
          
          {countIsWaiting > 0 && (
            <Button
              label={`${countIsWaiting}`}
              startIcon={<PendingIcon sx={{ color: '#FFCF9D' }} />}
              disabled
            />
          )}

          {countIsReady > 0 && (
            <Button
              label={`${countIsReady}`}
              sx={{ color: '#e58e32' }}
              startIcon={<PublishedWithChangesIcon color="warning" />}
              onClick={(e) => {
                e.stopPropagation()
                redirect('/leads/' + info?.id + '/tasks')
              }}
            />
          )}
        </>
      ) : null }
    </>
  )
}

export const InfoTextField = record => {
  const info = useRecordContext()
  const {textColor, bgColor} = customColor(info)

  const style = {
    ...buttonStyle,
    color: textColor,
    backgroundColor: bgColor,
  }

  return <TextField source={record.source} sx={style} />
}

export const WarningTextField = record => {
  const style = {
    ...buttonStyle,
    color: '#fff',
    backgroundColor: 'darkorange',
  }

  return <TextField style={style} {...record} />
}

export const DangerTextField = record => {
  const style = {
    ...buttonStyle,
    color: '#fff',
    backgroundColor: 'darkred',
  }

  return <TextField source={record.source} style={style} />
}

export const YellowTextField = record => {
  const info = useRecordContext()
  const {textColor, bgColor} = customColor(info)
  
  // const style = {
  //   ...buttonStyle,
  //   color: '#222',
  //   backgroundColor: '#f9dfbb',
  // }

  const style = {
    ...buttonStyle,
    color: textColor,
    backgroundColor: '#f9dfbb',
  }

  return info.email ? <TextField source={record.source} style={style} /> : null
}

export const GreyTextField = record => {
  const style = {
    ...buttonStyle,
    color: '#fff',
    backgroundColor: '#92a4af',
  }

  return <TextField source={record.source} style={style} />
}

export const DarkTextField = record => {
  const style = {
    ...buttonStyle,
    color: '#fff',
    backgroundColor: '#424242'
  }

  return <TextField source={record.source} style={style} />
}

export const TextFieldCopy = ({ source, maxWidth, ...rest }) => {
  const record = useRecordContext()
  const notify = useNotify()
  
  const copyField = () => {
    if (record && record[source]) {
      navigator.clipboard.writeText(record[source])
      notify('ID copiée dans le presse-papier', { type: 'success' })
    }
  }

  return record ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField source={source} style={{ flex: 1, maxWidth: maxWidth }} {...rest} />
      
      <IconButton
        size="small"
        onClick={e => {
          copyField()
          e.stopPropagation()
        }}
        sx={{ marginLeft: '5px' }}
      >
        <ContentCopyIcon />
      </IconButton>
    </div>
  ) : null
}

export const CenterTextField = record => {
  const info = useRecordContext()
  const [isLoading, setIsLoading] = useState(true)
  const [getCenter, setGetCenter] = useState(null)

  const fetchCenterName = async () => {
    try {
      const { data, error } = await supabaseClient
        .from('centers')
        .select('id, name')
        .eq('id', info?.center_ids[0])

      if ( ! data ) return <></>

      setGetCenter(data[0].name)
    } catch (error) {
      console.error('Error fetching lead center: ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchCenterName()
  }, [])

  if (isLoading) return null

  return <>{getCenter}</>
}

export const FavouriteTextField = () => {
  const info = useRecordContext()
  const { identity } = useGetIdentity()
  const currentUser = identity?.id
  const isPubeur = identity?.role?.grade < getRole('responsable')

  if (!info?.favourited_by) return <StarOutlineIcon />

  if (info?.assigned_pubeur === null || info?.favourited_by?.length === 0) return null

  let userEntry = null
  if ( ! isPubeur ) {
    userEntry = info?.favourited_by?.find(entry => entry?.id === info?.assigned_pubeur)
  } else {
    userEntry = info?.favourited_by?.find(entry => entry?.id === currentUser)
  }

  if (!userEntry) return <StarOutlineIcon />

  return userEntry.is_fav ? <StarRateIcon /> : <StarOutlineIcon />
}