import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { classes } from '../common/classes'
import { supabaseClient } from '../supabase'
import toTimeZone from '../common/toTimeZone'
import { specialities } from '../common/specialities'
import { availableOptions as formations } from '../common/options'
import { getItemFromLocalStorage } from '../common/LocalStorage'
import { format } from 'libphonenumber-js'

const columnsForDataExport  = [
  { header: 'Prénom', key: 'prenom', width: 10 },
  { header: 'Nom', key: 'nom', width: 10 },
  { header: 'Email', key: 'email', width: 10 },
  { header: 'Téléphone', key: 'telephone', width: 10 },
  { header: 'ID du centre', key: 'id_du_centre', width: 10 },
  { header: 'ID Événement(s)', key: 'evenements', width: 10 },
  { header: 'ID de la qualification événement', key: 'events_qualifications', width: 10 },
  { header: 'ID du statut d\'inscription', key: 'statut_inscription', width: 10 },
  { header: 'Stop RGPD', key: 'stop_rgpd', width: 10 },
  { header: 'Parents', key: 'parents', width: 10 },
  { header: 'Rue', key: 'rue', width: 10 },
  { header: 'Code postal', key: 'code_postal', width: 10 },
  { header: 'Ville', key: 'ville', width: 10 },
  { header: 'Classe', key: 'classe', width: 10 },
  { header: 'Classe personnalisée', key: 'classe_personnalisee', width: 10 },
  { header: 'ID Lycée', key: 'nom_ecole', width: 10 },
  { header: 'ID Options', key: 'options', width: 10 },
  { header: 'ID du pubeur assigné', key: 'pubeur_assigne', width: 10 },
  { header: 'ID Formation(s) souhaitée(s)', key: 'options_souhaitees', width: 10 },
  { header: 'Tags', key: 'tags', width: 10 },
  { header: 'Commentaire', key: 'commentaire', width: 10 },
  { header: 'Intéressé', key: 'interesse', width: 10 },
  { header: 'Pas intéressé', key: 'pas_interesse', width: 10 },
  { header: 'ID de l\'année en cours', key: 'annee_en_cours', width: 10 },
  { header: 'ID Provenance du lead', key: 'source_lead', width: 10 },
  { header: 'Créé le', key: 'created_at', width: 10 },
  { header: 'Modifié le', key: 'updated_at', width: 10 },
]

const columnsForTemplateExport = [
  { header: 'Prénom', key: 'prenom', width: 10 },
  { header: 'Nom', key: 'nom', width: 10 },
  { header: 'Email', key: 'email', width: 10 },
  { header: 'Téléphone', key: 'telephone', width: 10 },
  { header: 'ID Événement(s)', key: 'evenements', width: 10 },
  { header: 'ID de la qualification événement', key: 'events_qualifications', width: 10 },
  { header: 'ID du statut d\'inscription', key: 'statut_inscription', width: 10 },
  { header: 'Stop RGPD', key: 'stop_rgpd', width: 10 },
  { header: 'Rue', key: 'rue', width: 10 },
  { header: 'Code postal', key: 'code_postal', width: 10 },
  { header: 'Ville', key: 'ville', width: 10 },
  { header: 'Classe', key: 'classe', width: 10 },
  { header: 'Classe personnalisée', key: 'classe_personnalisee', width: 10 },
  { header: 'ID Lycée', key: 'nom_ecole', width: 10 },
  { header: 'ID du pubeur assigné', key: 'pubeur_assigne', width: 10 },
  { header: 'ID Formation(s) souhaitée(s)', key: 'options_souhaitees', width: 10 },
]

const formatLeadToRow = (lead) => {
  return {
    prenom: lead.prenom,
    nom: lead.nom,
    email: lead.email,
    telephone: lead.telephone,
    id_du_centre: lead.id_du_centre,
    evenements: lead.events,
    events_qualifications: lead.events_qualifications,
    statut_inscription: lead.statut_inscription,
    nom_ecole: lead.nom_ecole,
    options: lead.options,
    stop_rgpd: lead.stop_rgpd,
    parents: lead.parents,
    rue: lead.rue,
    code_postal: lead.code_postal,
    ville: lead.ville,
    classe: lead.classe,
    classe_personnalisee: lead.classe_personnalisee,
    pubeur_assigne: lead.pubeur_assigne,
    options_souhaitees: lead.options_souhaitees,
    tags: lead.tags,
    commentaire: lead.commentaire,
    interesse: lead.interesse,
    pas_interesse: lead.pas_interesse,
    annee_en_cours: lead.annee_en_cours,
    source_lead: lead.source_lead,
    created_at: toTimeZone(new Date(), 'Europe/Paris') ?? null,
    updated_at: toTimeZone(new Date(), 'Europe/Paris') ?? null,
  }
}

const addLog = async (logData) => {
  // log the batchLeadIds and the number of rows fetched from the RPC
  const { error } = await supabaseClient
    .from('logs_exports')
    .insert(logData)

  if (error) {
    console.error('Error inserting log data:', error)
  }
}

const fetchDataFromRPCWithSelectedLeads = async (selectedLeadIds, localCenter, localUserId) => {
  let allData = []
  let batch_no = 1
  const batchSize = 500

  const logData = {
    profile_id: localUserId,
    center_id: localCenter?.id,
    table_name: 'leads',
    rows_number: selectedLeadIds?.length,
  }

  if ( selectedLeadIds?.length >= 100 ) {
    addLog(logData)
  }

  // Function to process a single batch of lead IDs
  const processBatch = async (batchLeadIds) => {
    let batchData = []
    let hasMore = true
    let batchOffset = 1

    while (hasMore) {
      const { data, error } = await supabaseClient.rpc('fetch_selected_lead_data', {
        lead_ids: batchLeadIds,
        batch_no: batchOffset,
        batch_size: batchSize,
      })

      if (error) {
        console.error('Error fetching paginated data from RPC:', error)
        return []
      }

      batchData = batchData.concat(data)

      if (data.length < batchSize) {
        hasMore = false
      } else {
        batchOffset += 1
      }
    }

    return batchData
  }

  // Split the selectedLeadIds into smaller batches and process each batch
  for (let i = 0; i < selectedLeadIds.length; i += batchSize) {
    const batchLeadIds = selectedLeadIds.slice(i, i + batchSize)
    
    console.log(`Processing batch ${batch_no} with ${batchLeadIds.length} leads`, batchLeadIds)

    const batchData = await processBatch(batchLeadIds)
    allData = allData.concat(batchData)
    batch_no += 1
  }

  return allData
}

export const exporter = async (records) => {
  const localCenter = getItemFromLocalStorage('localCenter')
  const localUserId = getItemFromLocalStorage('localUserId')
  const selectedLeadIds = records.map(record => record?.id ?? record)

  try {
    const leadsData = await fetchDataFromRPCWithSelectedLeads(selectedLeadIds, localCenter, localUserId)

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Informations contacts')
    worksheet.columns = columnsForDataExport

    for (const lead of leadsData) {
      let leadOptions = lead.options
      let leadOptionsSouhaitees = lead.options_souhaitees

      if ( ! Array.isArray(leadOptions) ) {
        leadOptions = null
      }

      if ( ! Array.isArray(leadOptionsSouhaitees) ) {
        leadOptionsSouhaitees = null
      }

      lead.classe = (classes[localCenter?.id] ?? classes['default'])?.options.filter(currentClass => lead.classe == currentClass.id)[0]?.name

      lead.options = leadOptions?.map(option => {
        const speciality = specialities.find(speciality => option === speciality.id);
        return speciality ? speciality.name : null;
      })

      lead.options_souhaitees = leadOptionsSouhaitees?.map(desiredOption => {
        for (let key in formations) {
          let foundOption = formations[key].options.find(option => desiredOption == option.id)

          if (foundOption) {
            return foundOption.name
          }
        }
      })

      worksheet.addRow(formatLeadToRow(lead))
    }

    const buffer = await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'Export de leads.xlsx')
  } catch (error) {
    console.error("Erreur lors de l'export:", error)
    throw error
  }
}

export const headersOnlyExporter = async () => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Informations contacts')
  worksheet.columns = columnsForTemplateExport
  worksheet.addRow({})

  const localCenter = JSON.parse(localStorage.getItem('localCenter')) ?? null

  // empty rows to populate the boolean list
  worksheet.addRow({
    prenom: '',
    nom: '',
    email: '',
    telephone: '',
    evenements: '',
    events_qualifications: '',
    statut_inscription: '',
    stop_rgpd: '',
    parents: '',
    rue: '',
    code_postal: '',
    ville: '',
    classe: '',
    classe_personnalisee: '',
    nom_ecole: '',
    pubeur_assigne: '',
    options: ''
  })

  const optionsWorksheet = workbook.addWorksheet('Options')
  const classesWorksheet = workbook.addWorksheet('Classes')
  // const formationsWorksheet = workbook.addWorksheet('Formations')

  const booleanList = ['"Oui, Non"']
  specialities.forEach(option => optionsWorksheet.addRow([option.name]))

  const choiceOfClassOptions = classes[localCenter?.id]?.options ?? classes['default']?.options
  choiceOfClassOptions.forEach(classe => classesWorksheet.addRow([classe.name]))
  // formations.forEach(formation => formationsWorksheet.addRow([formation.default.options]))
  
  worksheet.getCell('H2').dataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: booleanList
  }

  worksheet.getCell('P2').dataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: [`Options!A1:A${specialities.length}`],
  }

  worksheet.getCell('L2').dataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: [`Classes!A1:A${classes[localCenter?.id]?.options?.length ?? classes['default']?.options?.length}`],
  }

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  saveAs(blob, 'Template de leads.xlsx')
}
