import { Image as ImageJS } from 'image-js'
import { detect } from 'tesseract.js'

const createCanvas = (img) => {
  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height
  return canvas
}

const adjustContrast = (data, sender = false, value = 40) => {
  const contrast = value
  const factor = (259 * (contrast + 255)) / (255 * (259 - contrast))

  if ( sender ) {
    for (let i = 0; i < data.length; i += 4) {
      // Since the image is grayscale, all channels (R, G, B) have the same value.
      const gray = data[i] // Red channel (same as green and blue in grayscale)
      
      // Adjust the brightness more aggressively by lowering the range
      const newGray = clamp(factor * (gray - 128) + 128, 0, 180) // Darken values
  
      // Apply the new darker gray value to all channels (R, G, B)
      data[i] = data[i + 1] = data[i + 2] = newGray
    }

    return
  }

  for (let i = 0; i < data.length; i += 4) {
    data[i] = clamp(factor * (data[i] - 128) + 128, 5, 250)
    data[i + 1] = clamp(factor * (data[i + 1] - 128) + 128, 5, 250)
    data[i + 2] = clamp(factor * (data[i + 2] - 128) + 128, 5, 250)
  }
}

// const applyThresholdAndInversion = (data) => {
//   for (let i = 0; i < data.length; i += 4) {
//     const r = data[i]
//     const g = data[i + 1]
//     const b = data[i + 2]
//     const brightness = (r + g + b) / 3

//     // Apply threshold
//     if (brightness > 100) {
//       data[i] = data[i + 1] = data[i + 2] = 255 // Set to white
//     } else {
//       data[i] = data[i + 1] = data[i + 2] = 0 // Set to black
//     }

//     // Apply inversion after threshold
//     if (brightness > 160) {
//       data[i] = 255 - data[i] // Invert red
//       data[i + 1] = 255 - data[i + 1] // Invert green
//       data[i + 2] = 255 - data[i + 2] // Invert blue
//     }
//   }
// }

const applyThresholdAndInversion = (data) => {
  for (let i = 0; i < data.length; i += 4) {
    const r = data[i]
    const g = data[i + 1]
    const b = data[i + 2]
    const brightness = (r + g + b) / 3

    const mildThreshold = brightness > 100 ? 255 : 0
    data[i] = data[i + 1] = data[i + 2] = mildThreshold

    if (brightness > 160) {
      data[i] = 255 - r
      data[i + 1] = 255 - g
      data[i + 2] = 255 - b
    } else if (brightness < 100) {
      data[i] = r
      data[i + 1] = g
      data[i + 2] = b
    }
  }
}

const addRectangles = (ctx, width, height, aspectRatio) => {
  const aspectRatioThreshold = 9 / 15

  ctx.fillStyle = 'black'

  if (aspectRatio > aspectRatioThreshold) {
    ctx.fillRect(width - (width * 0.025), 0, width * 0.025, height) // right
  }

  if (aspectRatio <= aspectRatioThreshold) {
    ctx.fillRect(width - (width * 0.05), 0, width * 0.05, height) // right
    ctx.fillRect(0, 0, width, height * 0.16) // top
    ctx.fillRect(0, height - (height * 0.05), width, height * 0.05) // bottom
  }
}

const clamp = (value, min, max) => {
  return Math.min(max, Math.max(min, value))
}

export const detectDarkMode = (imageData) => {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      try {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height

        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        const data = imageData.data

        let totalBrightness = 0
        const sampleSize = 100

        for (let i = 0; i < sampleSize * 4; i += 4) {
          const r = data[i]
          const g = data[i + 1]
          const b = data[i + 2]
          const brightness = (r + g + b) / 3
          totalBrightness += brightness
        }

        const avgBrightness = totalBrightness / sampleSize
        const isDarkMode = avgBrightness < 128 // Dark mode threshold

        resolve(isDarkMode)
      } catch (error) {
        reject('Error detecting dark mode: ' + error)
      }
    }

    img.onerror = (error) => {
      reject('Failed to load image for dark mode detection: ' + error)
    }

    img.src = imageData
  })
}

export const processImageForSender = async (imageData) => {
  // const isDarkMode = await detectDarkMode(imageData)

  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      try {
        const canvas = createCanvas(img)
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        const data = imageData.data

        /*if (!isDarkMode) {
          adjustContrast(data, true, 100)
          applyThresholdAndInversion(data)
        } else {
          for (let i = 0; i < data.length; i += 4) {
            data[i] = 255 - data[i] // Invert red
            data[i + 1] = 255 - data[i + 1] // Invert green
            data[i + 2] = 255 - data[i + 2] // Invert blue
          }
        }*/

        // adjustContrast(data, true, 100) // actually makes the whole image readable
        adjustContrast(data) // actually makes the whole image readable
        applyThresholdAndInversion(data)

        ctx.putImageData(imageData, 0, 0)
        addRectangles(ctx, canvas.width, canvas.height, img.width / img.height)

        resolve(canvas.toDataURL('image/jpeg'))
      } catch (error) {
        reject('Error processing image for sender: ' + error)
      }
    }

    img.onerror = (error) => {
      reject('Failed to load image for sender: ' + error)
    }

    img.src = imageData
  })
}

export const processImageForReceiver = (imageData) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const isDarkMode = detectDarkMode(imageData)

    img.onload = () => {
      try {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height

        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)

        if ( isDarkMode ) {
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
          const data = imageData.data

          adjustContrast(data)

          ctx.putImageData(imageData, 0, 0)
        }

        // Add a rectangle to the right side to hide potential artifacts
        ctx.fillStyle = 'black'
        ctx.fillRect(canvas.width - (canvas.width * 0.05), 0, canvas.width * 0.05, canvas.height)

        // Hide the top part of the image to remove screen info (e.g., battery, time)
        ctx.fillRect(0, 0, canvas.width, canvas.height * 0.16)

        // Hide the bottom part to remove keyboard
        ctx.fillRect(0, canvas.height - (canvas.height * 0.05), canvas.width, canvas.height * 0.05)

        resolve(canvas.toDataURL('image/jpeg'))
      } catch (error) {
        console.error('Error processing image for receiver:', error)
        reject('Error processing image for receiver: ' + error)
      }
    }

    img.onerror = (error) => {
      console.error('Failed to load image for receiver:', error)
      reject('Failed to load image for receiver: ' + error)
    }

    img.src = imageData
  })
}

export const processSingleImage = async (imageData) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = imageData

    img.onload = () => {
      try {
        const canvas = createCanvas(img) // Assuming createCanvas is defined
        const ctx = canvas.getContext('2d')
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0)

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)

        // Put the modified image data back on the canvas
        ctx.putImageData(imageData, 0, 0)

        // Add any additional rectangles or modifications as needed
        addRectangles(ctx, canvas.width, canvas.height, img.width / img.height)

        // Resolve with the modified image as a Data URL
        resolve(canvas.toDataURL('image/jpeg'))
      } catch (error) {
        reject(error)
      }
    }

    img.onerror = (error) => {
      reject(new Error('Failed to load image: ' + error))
    }
  })
}

export const changeImageToGrey = async (imageData) => {
  let image = await ImageJS.load(imageData)
  let grey = image.grey().level()

  return grey.toDataURL()
}
