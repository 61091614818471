import { useState } from 'react'
import { useMediaQuery, Box, Button } from '@mui/material'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'

const CollapsibleFilterBox = ({ children }) => {
  const isTablet = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    { noSsr: true }
  )
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('sm'),
    { noSsr: true }
  )
  const [isCollapsed, setIsCollapsed] = useState(false)

  const toggleBox = () => {
    setIsCollapsed(!isCollapsed)
  }

  return !isMobile && (
    <Box
      width={isCollapsed ? '4vw' : '20vw'}  // Adjust the width based on the collapsed state
      minWidth={isCollapsed ? '50px' : '250px'} 
      backgroundColor="#e6f2f1"
      padding={isCollapsed ? '10px 15px 0 5px' : '10px 20px 10px 15px'}
      marginTop={!isTablet && '8vh' }
      borderRadius="10px 0 10px 10px"
      color="#333"
      order="-1"
      transition="min-width 0.3s ease"  // Smooth transition when toggling
    >
      <Button onClick={toggleBox} sx={{ marginBottom: '10px', minWidth: isCollapsed && '0' }}>
        {isCollapsed ? <DoubleArrowIcon size="small" /> : 'Réduire'}
      </Button>
      
      {!isCollapsed && children}
    </Box>
  )
}

export default CollapsibleFilterBox
