import { useRecordContext } from 'react-admin'
import customColor from '../common/customColor'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

const DisplayPhoneNumber = () => {
    const record = useRecordContext()
    const {textColor, bgColor} = customColor(record)

    if ( ! record.phone ) return

    const actualPhoneNumber = record.phone

    let newPhoneDisplay = actualPhoneNumber.replace('+33', '')
    const phoneOrga1 = new RegExp(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/)
    const phoneOrga2 = new RegExp(/(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/)
    newPhoneDisplay = newPhoneDisplay.replace(phoneOrga1, '$1 $2 $3 $4 $5')
    newPhoneDisplay = newPhoneDisplay.replace(phoneOrga2, '0$1 $2 $3 $4 $5')

    const style = {
      padding: '2px 5px',
      color: textColor,
      backgroundColor: bgColor,
      borderRadius: '5px',
      minWidth: '140px'
    }

    return (
      <div style={style}>
          <LocalPhoneIcon sx={{ fontSize: '.8rem', verticalAlign: 'middle' }} />  {newPhoneDisplay}
      </div>
    )
};

export default DisplayPhoneNumber
