import React, { useEffect, useState } from 'react'
import { useRecordContext } from 'react-admin'
import { Chip } from '@mui/material'

import { supabaseClient } from '../../supabase'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

const ProgressView = ({ recordProgress, leadId }) => {
  const record = useRecordContext()

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const fetchProgress = async () => {
      const { data, error } = await supabaseClient
        .from('lead_tasks')
        .select('progress')
        .eq('task_id', record?.id)
        .eq('lead_id', leadId)
      
      if (error) {
        console.error('Error fetching task progress', error)
        return
      }

      if ( data.length > 0 ) {
        setProgress(data[0].progress)
      }
    }

    if ( leadId ) {
      fetchProgress()
    }
  }, [recordProgress])

  return (
    <Chip
      icon={progress === '100' ? <CheckCircleOutlineIcon sx={{ fontSize: '1rem', color: 'green !important' }} /> : null}
      label={progress === '100' ? 'Terminé' : `${progress ?? '0'}%`}
      sx={{ backgroundColor: progress === '100' ? 'lightgreen' : progress > 0 ? '#ffbe4f' : null }}
    />
  )
}

export default ProgressView