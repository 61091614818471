import { CreateDialog } from "@react-admin/ra-form-layout";

import { SiteInputContent } from "./index";

const SiteCreate = () => (
    <CreateDialog title="Nouveau site" fullWidth maxWidth="md">
        <SiteInputContent />
    </CreateDialog>
);

export default SiteCreate;
