import { supabaseClient } from '../supabase';

async function sendMail (mailInfo) {
  if ( ! mailInfo?.identity ) return

  const payload = {
    profile_id: mailInfo?.identity?.id,
    toEmail: mailInfo?.identity?.email,
    toName: mailInfo?.identity?.fullName ?? `${mailInfo?.identity?.first_name} ${mailInfo?.identity?.last_name}` ?? '',
    subject: mailInfo?.subject ?? 'Erreur FormaContacts',
    textPart: mailInfo?.messageTEXT ?? `Une erreur FormaContacts vient de se produire pour l'utilisateur ${mailInfo?.identity.email}`,
    htmlPart: mailInfo?.messageHTML ?? `<h3>Ooops...</h3><p>Une erreur FormaContacts vient de se produire pour l'utilisateur ${mailInfo?.identity.email}.</p>`,
    date: mailInfo?.date,
    send_now: mailInfo?.sendNow ?? true,
  }

  try {
    const { error } = await supabaseClient
      .from('send_mail')
      .upsert([payload])

    if (error) throw error

    return console.log('Email sent successfully!')
  } catch (error) {
    console.error('Error sending email:', error.message)
    
    return console.log('Impossible d\'envoyer un mail:', error.message)
  }
}

export default sendMail