import { React, useEffect, useState } from 'react'
import {
  ReferenceInput,
  AutocompleteInput,
  Button,
  TextInput,
  useGetIdentity,
  useNotify,
  SimpleForm,
  SelectInput,
} from 'react-admin'
import { Grid, Stack, Typography } from '@mui/material'

import { v4 as uuidv4 } from 'uuid'
import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'
import { CommonDialog } from '../common/Dialog'
import TasksActionTypes from './TasksActionTypes'
import { filterBlankSpaces } from '../common/customTextFilter'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import StartIcon from '@mui/icons-material/Start'

const NewActionLine = ({ refreshTree, fullTree, branch = '', parcoursId, taskId, setOpenActionDialog }) => {
  const notify = useNotify()
  const { identity } = useGetIdentity()

  const [addAction, setAddAction] = useState(false)
  const [treeName, setTreeName] = useState(null)
  const [actionInfo, setActionInfo] = useState(null)
  const [actionCondition, setActionCondition] = useState(null)
  const [actionDescription, setActionDescription] = useState(null)
  const [waitingTime, setWaitingTime] = useState(null) // in hours
  const [followingActionInfo, setFollowingActionInfo] = useState(null) // if waiting time, the action that will follow

  const getActionChoices = () => {
    // if action is En attente, only one possibility
    if ( branch.children.length === 1 && branch.children[0].condition === null ) return []

    // already a child so only one choice is left
    if ( branch.children.length > 0 ) {
      if ( branch.children[0].condition === true ) return [ { id: -1, name: 'Si échec' } ]
      if ( branch.children[0].condition === false ) return [ { id: 1, name: 'Si succès' } ]
    }

    // no children yet
    return [
      { id: 1, name: 'Si succès' },
      { id: -1, name: 'Si échec' },
      { id: 2, name: 'Seul choix possible' }
    ]
  }

  const findAndUpdateBranch = (tree, branchId, newBranch) => {
    if ( tree.id === branchId ) {
      if ( tree.children.length === 0 ) {
        return { ...tree, children: [newBranch] }
      }

      // If the current node has children, find the index of the current branch
      const index = tree.children.findIndex(child => child.id === branchId)

      if ( index !== -1 ) {
        // If the current branch exists in children, insert the new branch after it
        const updatedChildren = [
          ...tree.children.slice(0, index + 1),
          newBranch,
          ...tree.children.slice(index + 1)
        ]

        return { ...tree, children: updatedChildren }
      }

      // If the current branch does not exist in children, add the new branch at the end
      return { ...tree, children: [...tree.children, newBranch] };
    }
    
    // Recursively search for the branch in children
    const updatedChildren = tree.children.map(child => {
      return findAndUpdateBranch(child, branchId, newBranch)
    })
    
    // If branch not found, return the original tree
    return { ...tree, children: updatedChildren }
  }
  
  const saveNewAction = async () => {
    if ( ! actionInfo ) return { success: false }

    // 1. check if tree exists
    let dataInitParcoursTree
    if ( ! branch ) {
      console.log('Creating new parcours tree...')
      
      if ( actionInfo?.name === 'En attente' || actionInfo?.name === 'Traité' ) {
        return { wrongStart: true }
      }

      const parcoursTreeBase = {
        'id': 'base-'+ uuidv4(),
        'children': [],
      }

      const { data, error } = await supabaseClient
        .from('tasks_parcours')
        .insert({
          task_id: taskId,
          name: ( treeName !== '' && treeName !== null ) ? treeName : `Mon parcours d'actions`,
          created_by: identity?.id,
          tree: parcoursTreeBase,
        })
        .select('*')

      if (error) {
        console.error('Error initiating parcours tree', error)
        return { success: false }
      }

      await Promise.all([data])

      if ( data[0].id ) {
        dataInitParcoursTree = data[0]

        // update task with parcours tree id
        const { error: errorUpdateTask } = await supabaseClient
          .from('tasks')
          .update({ parcours_id: dataInitParcoursTree.id })
          .eq('id', taskId)

        if (errorUpdateTask) {
          console.error('Error updating task with parcours tree id', errorUpdateTask)
          return { success: false }
        }
      }

      // console.log('Parcours tree created (dataInitParcoursTree)', dataInitParcoursTree)
      console.log('Parcours tree created.')
    }

    // 2. create new branch and update tree
    if ( branch || dataInitParcoursTree?.id ) {
      console.log('Creating new branch...')
      
      // create new branch
      const conditon = actionCondition === 1 ? true : actionCondition === -1 ? false : null // succès = true, échec = false, sans rapport = null

      // new branch registered
      if ( branch && ! branch?.id ) branch.id = fullTree?.id

      //  add new branch to tree
      const newAction = {
        id: uuidv4(),
        parentId: branch ? branch?.id : dataInitParcoursTree?.tree?.id, // '' so that the key appears in the tree
        action: { 'id': actionInfo?.id, 'name': actionInfo?.name, 'choices': actionInfo?.choices?.options },
        callback: waitingTime,
        condition: conditon,
        result: null,
        comment: null,
        detail: actionDescription,
        done: false,
        dateAction: null,
        children: [],
      }

      console.log('newAction :', newAction)

      // add following action if en attente
      if ( followingActionInfo ) {
        newAction.children.push({
          id: uuidv4(),
          parentId: newAction.id,
          action: { 'id': followingActionInfo?.id, 'name': followingActionInfo?.name, 'choices': actionInfo?.choices?.options },
          callback: null,
          condition: null,
          result: null,
          comment: null,
          detail: null,
          done: false,
          dateAction: null,
          children: [],
        })
      }

      const treeToUpdate = branch ? fullTree : dataInitParcoursTree?.tree
      const currentBranch = branch ? branch?.id : dataInitParcoursTree?.tree?.id

      const updatedFullTree = findAndUpdateBranch(treeToUpdate, currentBranch, newAction)

      console.log('Updating parcours tree...');
  
      // update parcours tree
      const { error: errorUpdateParcoursTree } = await supabaseClient
        .from('tasks_parcours')
        .update({ tree: updatedFullTree })
        .eq('id', parcoursId ?? dataInitParcoursTree.id)
      
      if (errorUpdateParcoursTree) {
        console.error('Error updating parcours tree', errorUpdateParcoursTree)
        return { success: false }
      }

      return { success: true }
    }

    return { success: true }
  }

  const handleSubmit = async (formData) => {
    const saveResult = await saveNewAction(formData)

    if ( saveResult.wrongStart ) {
      notify('Vous ne pouvez pas initialiser un parcours avec cette action', { type: 'error' })
      return
    }

    setTreeName(null)
    setActionCondition(null)
    setActionDescription(null)
    setActionInfo(null)
    setWaitingTime(null)
    setFollowingActionInfo(null)

    if ( ! saveResult.success ) {
      notify('Erreur lors de l\'enregistrement de la nouvelle action de tâche', { type: 'error' })
      return
    }
    
    notify('Nouvelle branche ajoutée au parcours', { type: 'success' })
    refreshTree()
    setOpenActionDialog(false)
  }

  return (
    <SimpleForm
      toolbar={false}
      onSubmit={handleSubmit}
    >
      { ! branch && (
        <>
          <Typography variant="body1" style={{ marginBottom: '15px' }}><b>Initialisation du parcours d'actions</b></Typography>

          <TextInput
            source="treeName"
            variant="outlined"
            label="Nom du parcours (pour pouvoir le réutiliser plus tard)"
            onChange={(event) => setTreeName(event?.target?.value)}
            fullWidth
          />
        </>
      )}

      { branch && parcoursId && branch.parentId && (
        <>
          <Typography variant="body1" style={{ marginBottom: '15px' }}>Action parente: {branch?.action?.name}</Typography>

          <AutocompleteInput
            source="condition"
            variant="outlined"
            choices={getActionChoices()}
            label="Rapport à cette action"
            filterToQuery={searchText => filterBlankSpaces(searchText)}
            onChange={(value, event) => {
              if ( event ) {
                setActionCondition(value)
              }
            }}
            fullWidth
          />
        </>
      )}

      { ( ! branch.parentId || ( branch && parcoursId && branch.parentId && actionCondition ) ) && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <ReferenceInput
              source="actionInfo"
              reference="tasks_actions"
              filter={{ center_id: identity?.center?.id }}
              fullWidth
            >
              <AutocompleteInput
                optionText="name"
                variant="outlined"
                source="tasks_actions"
                label="Choisir une action"
                filterToQuery={searchText => filterBlankSpaces(searchText)}
                onChange={(value, event) => {
                  const selectedAction = event
                  
                  setActionInfo(event)

                  if ( ! branch || selectedAction?.id !== 'a113bba4-8949-4d19-975c-434a9c0539b3' ) {
                    setWaitingTime(null)
                    setFollowingActionInfo(null)
                    setAddAction(true)
                  }

                  if ( ! selectedAction ) setAddAction(false)
                }}
                fullWidth
              />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12} md={4}>
            { identity?.role?.grade >= getRole('responsable') && (
              <TasksActionTypes label="Créer action" />
            )}
          </Grid>
        </Grid>
      )}

      { branch && parcoursId && branch.parentId && actionInfo && actionInfo?.name === 'En attente' && (
        <SelectInput
          source="waitingTime"
          variant="outlined"
          label="Combien de temps avant de pouvoir continuer la tâche ?"
          choices={[
            { name: '12 heures', id: 12 },
            { name: '24 heures', id: 24 },
            { name: '48 heures', id: 48 },
            { name: '3 jours', id: 72 },
            { name: '5 jours', id: 120 },
            { name: '6 jours', id: 144 },
            { name: '8 jours', id: 188 },
            { name: '10 jours', id: 240 },
            { name: '12 jours', id: 288 },
            { name: '1 semaine', id: 168 },
            { name: '2 semaines', id: 336 },
            { name: '1 mois', id: 720 },
            { name: '2 mois', id: 1440 },
            { name: '3 mois', id: 2160 },
            { name: '6 mois', id: 4320 },
            { name: '1 an', id: 8760 },
          ]}
          onChange={(event) => setWaitingTime(event?.target?.value)}
          resettable
          fullWidth
        />
      )}

      { branch && parcoursId && branch.parentId && actionInfo && waitingTime && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <ReferenceInput
              source="followingActionInfo"
              reference="tasks_actions"
              filter={{ center_id: identity?.center?.id }}
              fullWidth
            >
              <AutocompleteInput
                variant="outlined"
                source="tasks_actions"
                label="Choisir l'action qui suivra"
                filterToQuery={searchText => filterBlankSpaces(searchText)}
                optionText={(choice) => choice.name !== 'En attente' ? choice.name : `${choice.name} (Ne pas sélectionner !)` }
                onChange={(value, event) => {
                  setFollowingActionInfo(event)

                  if ( ! event ) setAddAction(false)
                  else setAddAction(true)
                }}
                fullWidth
              />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12} md={4}>
            { identity?.role?.grade === getRole('admin') && (
              <TasksActionTypes />
            )}
          </Grid>
        </Grid>
      )}

      { ( ( actionInfo?.name !== 'Traité' && actionInfo?.name !== 'En attente' && actionInfo ) || ( branch && parcoursId && branch.parentId && actionInfo && waitingTime && followingActionInfo ) ) && (
        <TextInput
          multiline
          source="detail"
          label="Précisions pour le pubeur"
          variant="outlined"
          onChange={(event) => setActionDescription(event?.target?.value ?? null)}
          value={actionDescription}
          defaultValue={actionDescription}
          sx={{ '& .MuiInputBase-input' : { minHeight: '100px' }} }
          fullWidth
        />
      )}

      { addAction && actionInfo &&
        <Button
          label="Ajouter"
          variant="contained"
          startIcon={<AddCircleOutlineIcon style={{ fontSize: '1.5rem' }} />}
          style={{ marginTop: '15px', width: '100%' }}
          type="submit"
        />
      }
    </SimpleForm>
  )
}

const AddNewActions = ({ parcoursId, fullTree, branch, taskId, refreshTree }) => {
  const [openActionDialog, setOpenActionDialog] = useState(false)

  return (
    <>
      <CommonDialog
        open={openActionDialog}
        handleClose={() => setOpenActionDialog(false)}
        title="Ajouter une action"
        size="sm"
      >
        <NewActionLine refreshTree={refreshTree} taskId={taskId} parcoursId={parcoursId} fullTree={fullTree} branch={branch} setOpenActionDialog={setOpenActionDialog} />
      </CommonDialog>

      { branch
        ? <AddCircleOutlineIcon onClick={() => setOpenActionDialog(true)} style={{ fontSize: '1.5rem', cursor: 'pointer', marginLeft: '5px' }} />
        : <>
            <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
              <Button
                variant="contained"
                startIcon={<StartIcon />}
                color="primary"
                sx={{ fontSize: '1rem' }}
                onClick={() => setOpenActionDialog(true)} label="Initialiser le parcours"
              />
            </Stack>

            <Typography variant="body2" mt={2} sx={{ textAlign: 'center' }}>
              Vous ne pourrez pas assigner cette tâche sans un lui avoir attribué un parcours d'actions
            </Typography>
        </>
      }
    </>
  )
}

export default AddNewActions