import { CreateDialog } from '@react-admin/ra-form-layout'

import { EventTypesInputContent } from './index'

const EventTypeCreate = props => (
    <CreateDialog
      title="Nouvelle catégorie d'événement"
      maxWidth="md"
      fullWidth
    >
      <EventTypesInputContent action="create" {...props} />
    </CreateDialog>
);

export default EventTypeCreate

