import React, { useEffect, useState } from "react";
import {
    useNotify,
    useRefresh,
    useUnselectAll,
    AutocompleteInput,
    BulkDeleteButton,
    ChipField,
    Datagrid,
    FunctionField,
    List,
    ReferenceInput,
    SimpleForm,
    SingleFieldList,
    TextField,
    useRedirect,
    useRecordContext,
} from "react-admin";
import {
    Button,
    Grid,
    Autocomplete,
} from '@mui/material';
import { ReferenceManyToManyField } from '@react-admin/ra-relationships';

import toTimeZone from '../common/toTimeZone'
import { CommonDialog } from '../common/Dialog';
import { PostPagination } from '../common/Pagination';
import { ProfileListFilter } from "../profiles/ProfileListFilter";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { supabaseClient } from "../supabase";
import { CustomSelectInput } from "../Design/CustomSelectInput";

const profileFilters = [
  <ReferenceInput
    source="from_school"
    reference="schools"
    alwaysOn
  >
    <AutocompleteInput
      label="Lycée"
      filterToQuery={searchText => ({ 'name@ilike': `%${searchText}%` })}
      optionText="name"
      sx={{ width: 300, marginBottom: -.5 }}
    />
  </ReferenceInput>,
]

const LeadAssignationContent = ({ saveAssignation, ...props }) => (
  <List
    {...props}
    empty={false}
    disableSyncWithLocation
    pagination={<PostPagination />}
    perPage={50}
    resource="profiles"
    filter={{ 'enabled': true }}
    filters={profileFilters}
    actions={
      <ProfileListFilter displayEnableSearch={false} displayRoleSearch={false} />
    }
  >
    <Datagrid bulkActionButtons={false}>
      <TextField label="Prénom" source="first_name" />
      <TextField label="Nom" source="last_name" />
      <ReferenceManyToManyField
          reference="centers"
          through="profile_centers"
          using="profile_id,center_id"
          >
          <SingleFieldList>
              <ChipField source="name" />
          </SingleFieldList>
      </ReferenceManyToManyField>

      <FunctionField render={
          record => (
              <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => saveAssignation('assign', props.leads, record.id)}>
                  Assigner
              </Button>
          )
      }/>
    </Datagrid>
  </List>
)

const LeadQualificationsContent = ({ saveQualifications, ...props }) => {
  const [qualificationsOptions, setQualificationsOptions] = useState([])

  useEffect(() => {
    const getQualificationsOptions = async () => {
      const { data, error } = await supabaseClient
        .from('qualifications')
        .select('id, name')
        .order('name', { ascending: true })

      if (error) {
        console.error('Error fetching qualifications: ', error)
        return
      }

      let optionsQualifications = []
      data.map(row => {
        const qualification = {
          label: row.name,
          value: row.id,
        }

        optionsQualifications.push(qualification)
      })

      const sansQualification = {
        label: 'Sans qualification',
        value: '9999',
      }

      optionsQualifications.push(sansQualification)

      setQualificationsOptions(optionsQualifications)
    }

    getQualificationsOptions()
  }, [])

  return (
    <CustomSelectInput
      id="selectedQualifications"
      label="Qualifier"
      options={qualificationsOptions}
      minWidth="100%"
      onChange={(event, newValue) => saveQualifications(newValue?.value ?? null)}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': { fontSize: '.9rem' },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall.MuiAutocomplete-input': { padding: '0' },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': { padding: '0' },
      }}
    />
  )
}

const BulkAssignationButtons = props => (
  <SimpleForm style={{ marginTop: '10px' }} toolbar={false}>
    <Grid container spacing={2}>
      <Grid item style={{ minWidth: '200px' }}>
        {/*<Button
          startIcon={<CheckIcon style={{ fontSize: '1rem'}} />}
          size="large"
          color="primary"
          onClick={props.handleOpenQualifications}
          style={{ fontSize: '.8rem'}}
        >
          Qualification
        </Button>*/}

        <LeadQualificationsContent saveQualifications={props.saveQualifications} {...props} />
      </Grid>

      <Grid item>
        <Button
          startIcon={<AddIcon style={{ fontSize: '1rem'}} />}
          size="large"
          color="primary"
          onClick={props.handleOpenAssignation}
          style={{ fontSize: '.8rem'}}
        >
          Assigner
        </Button>
      </Grid>

      <Grid item>
        <Button
          startIcon={<RemoveIcon style={{ fontSize: '1rem'}} />}
          size="large"
          color="primary"
          onClick={() => {
              const action = 'unassign';
              const leads = props.leads;
              props.saveAssignation(action, leads, null);
          }}
          style={{ fontSize: '.8rem'}}
        >
          Désassigner
        </Button>
      </Grid>

      <Grid item>
          <BulkDeleteButton size="medium" />
      </Grid>
    </Grid>
  </SimpleForm>
)

const handleAssignationAction = (actionFunc, leads, pubeur, notify, unselectAll, refresh, redirect, setOpenAssignation) => {
  if ( ! actionFunc ) return

  actionFunc(leads, pubeur)
  .then(() => {
    unselectAll()
    notify(`Pubeur ${pubeur ? 'assigné' : 'désassigné'}`, {'type' : 'success'})
    refresh();
    redirect('/leads')
    setOpenAssignation(false)
  })
  .catch(error => {
    console.error('KO', error)
    notify('Une erreur est survenue', {'type' : 'error'})
  })
}

const handleQualificationAction = async (leads, qualification, notify, unselectAll, refresh, redirect, setOpenQualification) => {
  const { data, error } = await supabaseClient
    .from('leads')
    .update({
      status_inscription_id: qualification === '9999' ? null : qualification,
      updated_at: toTimeZone(new Date(), 'Europe/Paris') ?? null
    })
    .in('id', leads)

  if (error) {
    console.error('Error updating qualifications: ', error)
    return
  }

  unselectAll()
  notify(qualification === '9999' ? `Qualification supprimée` :  `Qualification mise à jour`, {'type' : 'success'})
  refresh();
  redirect('/leads')
  setOpenQualification(false)
}

const AssignationDialog = props => {
    const notify = useNotify()
    const unselectAll = useUnselectAll('leads')
    const refresh = useRefresh()
    const redirect = useRedirect()

    const [openAssignation, setOpenAssignation] = useState(false)
    const [openQualifications, setOpenQualifications] = useState(false)

    const leads = props.leads

    const handleOpenAssignation = () => {
      setOpenAssignation(true)
    }

    const saveAssignation = (action, leads, pubeur) => {
      const chunkSize = 100
      const chunks = []

      for ( let i = 0; i < leads.length; i += chunkSize ) {
        chunks.push(leads.slice(i, i + chunkSize))
      }
      
      const actionHandler = ( action === 'assign' ? props.assign : props.unassign ) ?? null

      if ( ! actionHandler ) return

      chunks.forEach(chunk => {
        handleAssignationAction(actionHandler, chunk, pubeur, notify, unselectAll, refresh, redirect, setOpenAssignation)
      })
    }

    const handleOpenQualifications = () => {
      setOpenQualifications(true)
    }

    const saveQualifications = (qualification) => {
      if ( ! qualification ) return

      const chunkSize = 100
      const chunks = []

      for ( let i = 0; i < leads.length; i += chunkSize ) {
        chunks.push(leads.slice(i, i + chunkSize))
      }

      chunks.forEach(chunk => {
        handleQualificationAction(chunk, qualification, notify, unselectAll, refresh, redirect, setOpenQualifications)
      })
    }

    return (
      <>
        <BulkAssignationButtons
          {...props}
          handleOpenAssignation={handleOpenAssignation}
          saveAssignation={saveAssignation}
          handleOpenQualifications={handleOpenQualifications}
          saveQualifications={saveQualifications}
        />

        <CommonDialog
          open={openAssignation}
          handleClose={() => setOpenAssignation(false)}
          title={ props.leads.length > 1 ?
              `Assignation des ${props.leads.length} contacts sélectionnés` :
              `Assignation du contact sélectionné`
          }
        >
          <LeadAssignationContent saveAssignation={saveAssignation} {...props} />
        </CommonDialog>

        <CommonDialog
          open={openQualifications}
          handleClose={() => setOpenQualifications(false)}
          title={ props.leads.length > 1 ?
              `Qualification des ${props.leads.length} contacts sélectionnés` :
              `Qualification du contact sélectionné`
          }
          size="sm"
          minHeight="100px"
        >
          <LeadQualificationsContent saveQualifications={saveQualifications} {...props} />
        </CommonDialog>
      </>
    );
};

export default AssignationDialog
