import {
  email,
  minLength,
  required,
  useCreateContext,
  useEditContext,
  useRecordContext,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  Labeled,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
  useGetIdentity,
} from 'react-admin'
import { Grid } from '@mui/material'

import ProfileList from './ProfileList'
import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'
// import SendMagicLink from './SendMagicLink'
import SendRecoveryLink from './SendRecoveryLink'
import { filterBlankSpaces } from '../common/customTextFilter'
import { getValidEmail, getValidPhone, isFormPhoneValid, toBoolean, toCamelCase, toCapitalCase } from '../common/Formating'

import FaceIcon from '@mui/icons-material/Face'
import toTimeZone from '../common/toTimeZone'
import { TextFieldCopy } from '../Design/CustomTextField'

export default {
  list: ProfileList,
  icon: FaceIcon,
  options: {
    'label': 'Utilisateurs',
  }
}

export const ProfileInputContent = props => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')

  const transformData = data => {
    const { enabled, first_name, last_name, email, phone, updated_at, role_id, ...rest } = data

    if ( role_id === '597c594e-c095-4671-a2fa-bce91f2bf920' && !isAdmin ) {
      notify('Seul un administrateur peut ajouter un autre administrateur.', { type: 'warning' })
      return
    }
  
    return {
      enabled: toBoolean(enabled),
      first_name: toCamelCase(first_name),
      last_name: toCapitalCase(last_name),
      email: getValidEmail(email),
      phone: getValidPhone(phone),
      updated_at: toTimeZone(new Date(), 'Europe/Paris') ?? null,
      created_by: identity?.id,
      role_id,
      ...rest,
    }
  }

  const handleSubmit = async (values, form) => {
    const transformedValues = transformData(values)

    if (record && record?.id) {
      // editContext.save(transformedValues, form)
      const { data, error } = await supabaseClient
        .from('profiles')
        .update(transformedValues)
        .eq('id', record.id)
        .select()
      
      if (error) {
        console.error(error)
      }

      /* obsolet but we keep it just in case */
      if (data) {
        const { error: error2 } = await supabaseClient
          .from('profile_centers')
          .update({ center_id: transformedValues.center_id })
          .eq('profile_id', record.id)

        if (error2) {
          console.error(error2)
        }
      }

      notify('Profil mis à jour.', { 'type' : 'success' })
      redirect('/profiles')
      return
    }

    // createContext.save(transformedValues, form)
    const { data, error } = await supabaseClient
      .from('profiles')
      .insert(transformedValues)
      .select()
    
    if (error) {
      console.error(error)

      if ( error.code === '23505' ) {
        notify('Ce profile est déjà enregistré.', { 'type' : 'warning' })
        return
      }
    }

    /* obsolet but we keep it just in case */
    if (data) {
      const { error: error2 } = await supabaseClient
        .from('profile_centers')
        .insert({ profile_id: data[0].id, center_id: data[0].center_id })
        .select()
      
      if (error2) {
        console.error(error2)

        notify('Une erreur est survenue. Merci de contacter le support.', { 'type' : 'error' })
        return
      }

      notify('Profil créé avec succès.', { 'type' : 'success' })
      // redirect('/profiles')
      refresh()
    }
  }

  return (
    <SimpleForm
      {...props}
      onSubmit={handleSubmit}
      defaultValues={{
        from_school: null,
        enabled: true,
      }}
    >
      <Grid container spacing={1} mb={1}>
        <Grid item xs={3} mt={1}>
          <BooleanInput label="Actif" source="enabled" />
        </Grid>

        <Grid item xs={9}>
          { record && record.id && <SendRecoveryLink email={record.email} /> }
          {/* { record && record.id && <SendMagicLink email={record.email} /> } */}
        </Grid>
      </Grid>
      
      {record && record.id && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <b>ID Utilisateur</b>
              </Grid>

              <Grid item xs={8}>
                <TextFieldCopy source="id" />
              </Grid>
            </Grid>

            { identity?.role?.grade === getRole('admin') && (
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <b>ID Supabase</b>
                </Grid>

                <Grid item xs={8}>
                    <TextFieldCopy source="user_auth_id" emptyText={ record.enabled === false ? `Non renseigné. Veuillez activer pour générer.` : `Non renseigné. Veuillez désactiver puis réactiver pour générer.` } />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      <Grid container mt={3} spacing={1}>
        <Grid item xs={6}>
          <TextInput fullWidth label="Prénom" source="first_name" variant="outlined" validate={[required(), minLength(2)]} autoFocus />
        </Grid>
        
        <Grid item xs={6}>
          <TextInput fullWidth label="Nom" source="last_name" variant="outlined" validate={[required(), minLength(2)]} />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextInput fullWidth label="Email" source="email" variant="outlined" validate={[required(), email()]} />
        </Grid>

        <Grid item xs={6}>
          <TextInput fullWidth label="Téléphone" source="phone" variant="outlined" validate={[required(), isFormPhoneValid]} />
        </Grid>
      </Grid>

      <FormDataConsumer>
        {({ formData }) => (
          <>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <ReferenceInput
                  source="role_id"
                  reference="roles"
                  sort={{ field: 'grade', order: 'DESC' }}
                >
                  <SelectInput optionText="name" variant="outlined" validate={required()} fullWidth />
                </ReferenceInput>
              </Grid>

              {/* <Grid item xs={6}>
                <ReferenceManyToManyInput
                  reference="centers"
                  through="profile_centers"
                  using="profile_id,center_id"
                >
                  <SelectArrayInput
                    label="Centres"
                    validate={required()}
                    optionText="name"
                    fullWidth
                  />
                </ReferenceManyToManyInput>
              </Grid> */}

              <Grid item xs={6} md={6}>
                <ReferenceInput
                  source="center_id"
                  reference="centers"
                >
                  <AutocompleteInput
                    label="Centre"
                    variant="outlined"
                    validate={required()}
                    optionText="name"
                    filterToQuery={searchText => ({ 'name@ilike': `%${searchText}%` })}
                    fullWidth
                  />
                </ReferenceInput>
              </Grid>
            </Grid>
            
            <FormDataConsumer>
              {({ formData, ...rest }) => formData.center_id && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <SchoolFilter formData={formData} />
                  </Grid>
                </Grid>
              )}
            </FormDataConsumer>
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  )
}

const SchoolFilter = ({ formData }) => {
  // const selectedCenters = formData['@@ra-many-to-many/profiles/profile_centers/centers'] || []
  const selectedCenters = formData.center_id || []

  return (
    <ReferenceInput
      key={selectedCenters}
      source="from_school"
      reference="schools"
      filter={{ 'center_id@in': `(${selectedCenters})` }}
    >
      <AutocompleteInput
        readOnly={ ! selectedCenters || selectedCenters.length === 0 }
        variant="outlined"
        fullWidth
        label="Lycée de provenance"
        optionText="name"
        filterToQuery={(searchText) => filterBlankSpaces(searchText)}
      />
    </ReferenceInput>
  )
}
