import React, { useEffect, useState } from 'react'
import { Datagrid, ReferenceField, ReferenceManyField, TextField, useEditContext, useGetIdentity, useRefresh } from 'react-admin'
import { Typography } from '@mui/material'

import ProgressView from './ProgressView'
import { getRole } from '../../common/roles'
import LeadTaskExpand from './LeadTaskExpand'
import { ReferenceFieldWithLink } from '../../Design/CustomReferenceField'
import { TextFieldCopy } from '../../Design/CustomTextField'

const LeadTasks = props => {
  const { record } = useEditContext(props)
  const { identity } = useGetIdentity()
  const refresh = useRefresh()

  const [recordProgress, setRecordProgress] = useState(0)

  useEffect(() => {
    if ( !record ) return
    
    refresh()
  }, [record])
  
  return (
    <>
      <ReferenceManyField
        label={false}
        reference="lead_tasks"
        target="lead_id"
        sort={{ field: 'created_at', order: 'DESC' }}
      >
        <Datagrid
          expand={<LeadTaskExpand leadRecord={record} identity={identity} refresh={refresh} setRecordProgress={setRecordProgress} />}
          bulkActionButtons={identity?.role?.grade >= getRole('responsable')}
          empty={<Typography variant="body1" color="textSecondary" mt={3} ml={4}>Aucune tâche n'a été attribuée à ce contact.</Typography>}
          expandSingle
        >
          <ReferenceField
            label="Tâche"
            source="task_id"
            reference="tasks"
            link={false}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ReferenceFieldWithLink label="Type" source="type_id" reference="tasks_types" />
          </ReferenceField>

          <ReferenceField
            label="Intitulé"
            source="task_id"
            reference="tasks"
            link={false}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TextField source="name" />
          </ReferenceField>

          { identity?.role?.grade === getRole('admin') && <TextFieldCopy source="id" label="ID" /> }

          <ReferenceField
            label="Avancement"
            source="task_id"
            reference="tasks"
            link={false}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ProgressView recordProgress={recordProgress} leadId={record?.id} />
          </ReferenceField>
        </Datagrid>
      </ReferenceManyField>
    </>
  )
}

export default LeadTasks