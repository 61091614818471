import { useEffect, useState } from 'react'
import { useEditContext, useGetIdentity, useRefresh } from 'react-admin'
import { Grid, IconButton } from '@mui/material'

import stripos from '../common/stripos'
import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'
import { getClassNameById } from '../common/classes'
import { confirmationDialog } from '../common/createConfirmation'

import CancelIcon from '@mui/icons-material/Cancel'
import AutoModeIcon from '@mui/icons-material/AutoMode'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'

const LeadComments = props => {
  const { record } = useEditContext(props)
  const { identity } = useGetIdentity()
  const isRespo = identity?.role?.grade >= getRole('responsable')

  const [leadComments, setLeadComments] = useState([])

  const getCommentsForLead = async () => {
    const { data, error } = await supabaseClient
      .from('lead_comments')
      .select('*, profile: profile_id (*)')
      .eq('lead_id', record.id)
      .order('created_at', { ascending: false })

    if (error) {
      console.error('error', error)
      return
    }    

    setLeadComments(data)
  }

  const deleteComment = async (id) => {
    if ( await confirmationDialog({
      confirmation: 'Êtes-vous sûr(e) de vouloir supprimer ce commentaire ?',
    }) ) {
      const { error } = await supabaseClient
        .from('lead_comments')
        .delete()
        .eq('id', id)

      if (error) {
        console.error('error', error)
        return
      }

      getCommentsForLead()
    }
  }

  useEffect(() => {
    if ( ! record ) return
    
    getCommentsForLead()
  }, [record])
  
  return leadComments?.length > 0 ?
    leadComments.map( comment => {
      // transform comment.created_at to French format
      const date = new Date(comment.created_at)
      const options = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' }
      const dateComment = date.toLocaleDateString('fr-FR', options)
      
      let commentaire = stripos(comment.comment, '*AUTO*')
        ? comment.comment.split('*AUTO* ')[1] ?? comment.comment
        : stripos(comment.comment, '*TACHE*')
          ? comment.comment.split('*TACHE* ')[1] ?? comment.comment
          : stripos(comment.comment, '*QUESTION PARCOURSUP*')
            ? comment.comment.split('*QUESTION PARCOURSUP* ')[1] ?? comment.comment
            : comment.comment

      commentaire = commentaire.replace('+33', '')
      commentaire = commentaire.replace('Adresse : , ', ' Adresse : nulle')

      const classMatch = commentaire.match(/Classe\s*:\s*(\d+)/);
      if (classMatch) {
        const classId = parseInt(classMatch[1], 10); // Convert the extracted ID to an integer
        const className = getClassNameById(classId, { id: process.env.REACT_APP_CENTER_MYPREPA }); // Or another local center
        
        if (className) {
          commentaire = commentaire.replace(`Classe : ${classId}`, `Classe : ${className}`);
        }
      }
        
      return (
        <Grid container spacing={1} key={comment.id} mt={1.25}>
          <Grid item xs={1}>
            { stripos ( comment.comment, 'Assignation' )
              ? <PersonAddAltIcon sx={{ fontSize: '2.25rem', mt: .5, ml: 1.8, color: '#1bba9d' }} />
              : stripos ( comment.comment, '*AUTO*' ) && ! stripos ( comment.comment, 'Contact récupéré' )
                ? <AutoModeIcon sx={{ fontSize: '2rem', mt: .5, ml: 2, color: '#60A395' }} />
                : stripos ( comment.comment, '*TACHE*' )
                  ? <TaskAltRoundedIcon sx={{ fontSize: '2.25rem', mt: .5, ml: 1.8, color: '#fbc410' }} />
                  : stripos ( comment.comment, '*QUESTION PARCOURSUP*' )
                    ? <ContactSupportIcon sx={{ fontSize: '2.5rem', mt: .5, ml: 1.8, color: '#d861cc' }} />
                      : stripos ( comment.comment, 'Contact récupéré' )
                        ? <GroupAddIcon sx={{ fontSize: '2.25rem', mt: .5, ml: 1.8, color: '#1bba9d' }} />
                        : <AccountCircleIcon sx={{ fontSize: '2.25rem', mt: .5, ml: 1.8, color: '#2f7991' }} />
            }
          </Grid>

          <Grid item xs={ isRespo ? 10 : 11 }>
            <b style={{ color: '#353535' }}>{
              stripos ( comment.comment, '*AUTO*' )
                ? `CRM`
                : stripos ( comment.comment, '*QUESTION PARCOURSUP*' )
                  ? `Question Parcoursup`
                  : `${comment.profile?.first_name} ${comment.profile?.last_name}` }</b>
            <span style={{ marginLeft: '10px', fontSize: '.9rem' }}>{commentaire}</span><br/>
            <small style={{ color: '#999' }}>le {dateComment}</small>
            <hr style={{ borderBottom: '0px', borderLeft: '0px', borderRight: '0px', borderTop: '1px dotted #ccc' }} />
          </Grid>

          { isRespo ?
            <Grid item xs={1}>
              <IconButton onClick={() => deleteComment(comment.id)}>
                <CancelIcon size="small" color="secondary" />
              </IconButton>
            </Grid>
            : null
          }
        </Grid>
      )
    }
  ) : <p>Aucun commentaire n'est présent sur cette fiche</p>
}

export default LeadComments