import * as React from "react";
import {
    useRecordContext,
    EmailField,
    Tab,
    TabbedShowLayout,
    TextField,
} from "react-admin";
import {
    Box,
    Typography,
} from '@mui/material';
import { ShowDialog } from "@react-admin/ra-form-layout";

const CenterShow = props => (
    <ShowDialog fullWidth maxWidth="lg">
        <CenterShowContent {...props} />
    </ShowDialog>
);

const CenterShowContent = props => {
    const record = useRecordContext(props);

    return (
        <TabbedShowLayout>
            <Tab label="Fiche">
                <Box display="flex">
                    <Box mt={1} flex="1">
                        <Typography variant="h5">
                            {record.id}
                        </Typography>

                        <Typography variant="h5">
                            {record.name}
                        </Typography>

                        <Box display="flex" my={2}>
                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Téléphone principal
                                </Typography>
                                <Typography variant="subtitle1">
                                    <TextField source="phone" />
                                </Typography>
                            </Box>

                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Adresse
                                </Typography>
                                <Typography variant="subtitle1">
                                    {record.address?.street}, {record.address?.postalCode}, {record.address?.city}
                                </Typography>
                            </Box>
                        </Box>

                        <Box display="flex" my={2}>
                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Email principal
                                </Typography>
                                <Typography variant="subtitle1">
                                    <EmailField source="email" />
                                </Typography>
                            </Box>

                            <Box display="flex" mr={5} flexDirection="column">
                                <Typography color="textSecondary" variant="body2">
                                    Email brochure
                                </Typography>
                                <Typography variant="subtitle1">
                                    <EmailField source="emailLeaflet" />
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Tab>
        </TabbedShowLayout>
    )
};

export default CenterShow;
