import * as React from "react"
import { useState } from "react"
import {
  Button,
  useListContext,
  TopToolbar,
  useRefresh,
  useNotify,
  useUpdateMany,
} from "react-admin"
import AssignationDialog from "../common/AssignationDialog"

import sendMail from "../common/SendMail"
import createNotification from "../common/createNotification"
import { useEffect } from "react"
import { supabaseClient } from '../supabase'
import { ImportBrochuresButton } from "./importContacts"
import toTimeZone from "../common/toTimeZone"

export const InboundContactActions = (props) => {
  const [status, setStatus] = useState('new')
  const { selectedIds, sort, filterValues, setSort, setFilters } = useListContext()

  const handleStatusNew = () => {
    setStatus('new')
    const newFilters = { status: 'new' }
    setFilters(newFilters, { ...filterValues }, false)
    setSort({field: 'requested_date', order: 'DESC'})
  }

  const handleStatusProcessing = () => {
    setStatus('processing')
    const newFilters = { status: 'processing' }
    setFilters(newFilters, { ...filterValues }, false)
    if ( sort.field !== 'updated_at' ) setSort({field: 'updated_at', order: 'DESC'})
  }

  const handleStatusDone = () => {
    setStatus('done')
    const newFilters = { status: 'done' }
    setFilters(newFilters, { ...filterValues }, false)
    if ( sort.field !== 'updated_at' ) setSort({field: 'updated_at', order: 'DESC'})
  }

  return (
    <TopToolbar sx={{ 
        display: 'flex !important',
        justifyContent: 'right !important',
        alignItems: 'center !important',
        position: 'relative !important',
        width: '100%',
        paddingBottom: 0,
        minHeight: '60px',
        padding: selectedIds.length > 0 ? '20px 0 70px 5px !important' : null
      }}
    >
      <Button
        label="Nouvelles demandes"
        variant={status === 'new' ? 'contained' : 'outlined'}
        onClick={() => handleStatusNew()}
      />

      <Button
        label="En attente"
        variant={status === 'processing' ? 'contained' : 'outlined'}
        onClick={() => handleStatusProcessing()}
      />

      <Button
        label="Traitées"
        variant={status === 'done' ? 'contained' : 'outlined'}
        onClick={() => handleStatusDone()}
      />

      { status === 'new' && <ImportBrochuresButton /> }
    </TopToolbar>
  )
}

export const AssignBulkActionButtons = props => {
  const refresh = useRefresh()
  const notify = useNotify()
  const [updateMany, { error }] = useUpdateMany()
  const [pubeurInfo, setPubeurInfo] = useState(null)

  const performAssignation = async (leads, pubeurId) => {
    updateMany(
      'leads',
      {
        ids: leads,
        data: {
          assigned_pubeur: pubeurId ?? null,
          updated_at: toTimeZone(new Date(), 'Europe/Paris') ?? null
        },
      },
      {
        onSuccess: (data) => {
          refresh()
          notify('Modification pubeur affectuée', { type: 'success' })
        },
        onError: (error) => {
          notify(`Erreur affectation pubeur : ${error.message}`, { type: 'error' })
        },
    })
    
    // send mail informing of the assignation
    if ( pubeurId ) {
      const fetchPubeurInfo = async () => {
        const {data, error} = await supabaseClient
          .from('profiles')
          .select()
          .eq('id', pubeurId)
          .single()

        if (error) {
          console.error('Error fetching pubeur info: ', error)
          return null
        }

        return await data
      }

      const fetchLeadInfo = async lead_id => {
        const {data, error} = await supabaseClient
          .from('leads')
          .select()
          .eq('id', lead_id)
          .single()

        if (error) {
          console.error('Error fetching pubeur info: ', error)
          return null
        }

        return await data
      }

      try {
        const pubeurInfo = await fetchPubeurInfo()

        const leadInfoPromises = leads.map(lead_id => fetchLeadInfo(lead_id))
        const leadsInfo = await Promise.all(leadInfoPromises)
        const allNames = leadsInfo.map(info => info.first_name + ' ' + info.last_name)

        if ( pubeurInfo && allNames.length > 0 ) {
          let messageHTML = `
            <h3>Information FormaContacts</h3>

            <h4>Un ou plusieurs contacts vous ont été assignés aujourd'hui :</h4>

            <ul>`

          messageHTML += allNames.map(leadName => `<li>${leadName}</li>`).join('')

          messageHTML += `
            </ul>

            <p><a href="${process.env.REACT_APP_WEBSITE_URL}/leads">Cliquez ici pour vous rendre sur le CRM</a></p>

            <p><i>Ceci est un message automatique depuis le CRM FormaContact, merci de ne pas y répondre.</i></p>
          `

          const messageTEXT = `Un ou plusieurs contacts vous ont été attribué aujourd'hui.\n\nCeci est un message automatique, merci de ne pas y répondre.`
          
          sendMail ({
            identity: pubeurInfo,
            subject: `Nouveaux contacts assignés`,
            messageHTML: messageHTML,
            messageTEXT: messageTEXT,
            sendNow: true
          })

          const listeOfLeads = allNames.map(leadName => `${leadName}`).join(', ')
          
          createNotification ({
            profile_id: pubeurInfo.id,
            type: 'assignation',
            title: `Nouveaux contacts assignés`,
            message: `Votre responsable vous a assigné un ou plusieurs contacts : ${listeOfLeads}. Cliquez pour vous voir tous vos contacts.`,
            url: `/leads`,
          })
        }
      } catch (error) {
        console.error('Error in performAssignation: ', error)
      }
    }

    /*if ( pubeurId ) {
      const fetchInfo = async () => {
        const {data, error} = await supabaseClient
          .from('profiles')
          .select()
          .eq('id', pubeurId)
          .single()

        if (error) {
          console.error('Error fetching pubeur info: ', error)
          return null
        }

        // setPubeurInfo(data)
        return await data
      }

      fetchInfo()

      if ( pubeurInfo ) {
        const messageHTML = `
          <h3>Information FormaContacts</h3>

          <h4>Un ou plusieurs contacts vous ont été assignés aujourd'hui.</h4>

          <p><a href="${process.env.REACT_APP_WEBSITE_URL}/leads">Cliquez ici pour vous rendre sur le CRM</a></p>

          <p><i>Ceci est un message automatique depuis le CRM FormaContact, merci de ne pas y répondre.</i></p>
        `

        const messageTEXT = `Un ou plusieurs contacts vous ont été attribué aujourd'hui.\n\nCeci est un message automatique, merci de ne pas y répondre.`
        
        sendMail ({
          identity: pubeurInfo,
          subject: `Nouveaux contacts assignés`,
          messageHTML: messageHTML,
          messageTEXT: messageTEXT,
          sendNow: true
        })

        createNotification ({
          profile_id: pubeurInfo.id,
          type: 'assignation',
          title: `Nouveaux contacts assignés`,
          message: `Votre responsable vous a assigné un ou plusieurs contacts. Cliquez pour vous voir tous vos contacts.`,
          url: `/leads`,
        })
      }
    }*/
  }

  const assign = (leads, pubeur) => {
    return performAssignation(leads, pubeur)
  }

  const unassign = (leads) => {
    return performAssignation(leads, null)
  } 

  const [selectedLeadIds, setSelectedLeadIds] = useState([])

  const getLeadId = async (inboundContactId) => {
    try {
      const { data, error } = await supabaseClient.from('inbound_contacts')
        .select()
        .eq('id', inboundContactId)
      
      if (error) {
        console.error('Error fetching lead id: ', error)
        return null
      }

      return data[0].lead_id
    } catch (error) {
      console.error('Error fetching lead id: ', error)
      return null
    }
  }

  useEffect(() => {
    const fetchLeadIds = async () => {
      const ids = props.selectedIds
      const promises = []
      
      for (let i = 0; i < ids?.length; i++) {
        const inboundContactId = ids[i]
        promises.push(getLeadId(inboundContactId))
      }
      
      const leadIds = await Promise.all(promises)
      setSelectedLeadIds(leadIds.filter(id => id !== null))
    }

    fetchLeadIds()
  }, [props.selectedIds])

  return (
    <AssignationDialog
      {...props}
      leads={selectedLeadIds}
      assign={assign}
      unassign={unassign}
    />
  )
}