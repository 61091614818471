import { AutocompleteInput, ReferenceInput } from 'react-admin'

const TasksFiltersBar = [
  <ReferenceInput
    reference="tasks_types"
    source="type_id"
    alwaysOn
  >
    <AutocompleteInput
      label="Type de tâche"
      variant="outlined"
      optionText="name"
      style={{ minWidth: '250px' }}
      filterToQuery={searchText => ({ 'name@ilike': searchText })}
    />
  </ReferenceInput>,
]

export default TasksFiltersBar