const customColor = record => {
  let textColor = '#121212'
  let bgColor = 'transparent'
  
  if ( record?.status_inscription_id !== undefined ) {
    switch (record?.status_inscription_id) {
      case 'ae180b6a-6711-421f-9344-5991dd678f50':
        textColor = 'blue'
        bgColor = ''
        break
      case null:
        textColor = '#111'
        bgColor = 'transparent'
        break
      default:
        textColor = '#fefefe'
        bgColor = '#008000'
        break
    }

    if ( record?.status_inscription_id === null || record?.status_inscription_id === '' ) {
      switch (true) {
        case record?.not_interested:
          textColor = '#e21f1f'
          bgColor = 'transparent'
          break
        case record?.interested:
          textColor = '#008000'
          bgColor = '#fefefe'
          break
      }
    }

    if ( record?.interested && record?.status_inscription_id !== 'ae180b6a-6711-421f-9344-5991dd678f50' && record?.status_inscription_id !== null && record?.status_inscription_id !== '' ) {
      textColor = '#fff'
      bgColor = '#46a846'
    } else if ( record?.interested && record?.status_inscription_id !== 'ae180b6a-6711-421f-9344-5991dd678f50' && record?.status_inscription_id !== null && record?.status_inscription_id !== '' ) {
      textColor = '#46a846'
      bgColor = 'transparent'
    }
  }

  if ( record?.stop_rgpd ) {
    textColor = '#fff'
    bgColor = '#e21f1f'
  }

  return {textColor, bgColor}
}

export default customColor