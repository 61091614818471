import React, { useState, useEffect } from 'react';
import { useListContext } from 'react-admin';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const MultiFieldSearch = ({label, resource, ...props}) => {
    const { filterValues, setFilters } = useListContext();
    const [search, setSearch] = useState(filterValues.multi_search || '');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSearch(filterValues.multi_search || '');
    }, [filterValues.multi_search]);

    const handleSearch = (event) => {
        setSearch(event.target.value);
        setLoading(true);
        setFilters({ ...filterValues, multi_search: event.target.value }, () => setLoading(false));
    };

    const handleClearSearch = () => {
        setSearch('');
        setFilters({ ...filterValues, multi_search: '' });
    };

    return (
        <TextField
            {...props}
            label={label || "Rechercher"}
            value={search}
            onChange={handleSearch}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {search && (
                                <IconButton onClick={handleClearSearch}>
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            )
                        }
                    </InputAdornment>
                )
            }}
        />
    );
};

export default MultiFieldSearch;
