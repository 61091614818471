export const availableOptions = {
  'default': {
    label: 'PASS - L.AS',
    options: [
      { id: '100', name: 'Première santé' },
      { id: '101', name: 'Terminale santé' },
      { id: '102', name: 'P0' },
      { id: '103', name: 'P1 - PASS' },
      { id: '104', name: 'P1 - L.AS' },
      { id: '105', name: 'MANS' },
      { id: '106', name: 'Orthophonie' },
    ],
  },
  [process.env.REACT_APP_CENTER_CEPSUP]: {
    label: 'L1 - SPS',
    options: [
      { id: '201', name: 'Première santé' },
      { id: '202', name: 'Terminale santé' },
      { id: '203', name: 'P0' },
      { id: '204', name: 'P1 - LSPS' },
    ],
  },
  [process.env.REACT_APP_CENTER_PREPADEFI]: {
    label: 'PLURIPASS / L-PASS',
    options: [
      { id: '301', name: 'Première santé' },
      { id: '302', name: 'Terminale santé' },
      { id: '303', name: 'P1' },
      { id: '304', name: 'P1 - PluriPASS' },
      { id: '305', name: 'P1 - L-PASS' },
    ],
  },
  [process.env.REACT_APP_CENTER_PREPAVESALE]: {
    label: 'SPS',
    options: [
      { id: '401', name: 'Première santé' },
      { id: '402', name: 'Terminale santé' },
      { id: '403', name: 'P1 - LSPS' },
    ],
  },
  [process.env.REACT_APP_CENTER_CAPPEC]: {
    label: 'PASS - L.AS',
    options: [
      { id: '501', name: 'Première santé' },
      { id: '502', name: 'Terminale santé' },
      { id: '503', name: 'P0' },
      { id: '504', name: 'P1' },
    ],
  },
  [process.env.REACT_APP_CENTER_SEGMO]: {
    label: 'PASS - L.AS',
    options: [
      { id: '601', name: 'Première santé' },
      { id: '602', name: 'Terminale santé' },
      { id: '603', name: 'P0' },
      { id: '604', name: 'P1' },
    ],
  },
  [process.env.REACT_APP_CENTER_IPESANTE]: {
    label: 'PASS - L.AS',
    options: [
      { id: '701', name: 'Terminale santé' },
      { id: '702', name: 'P1 - L-PASS' },
    ],
  },
  [process.env.REACT_APP_CENTER_SUPORTHO]: {
    label: 'Paramédical',
    options: [
      { id: '801', name: 'MANS' },
      { id: '802', name: 'Orthophonie' },
    ],
  },
  [process.env.REACT_APP_CENTER_MYPREPA]: {
    label: 'Commerce',
    options: [
      { id: '901', name: 'Classes préparatoires ECG' },
      { id: '902', name: 'Cours complémentaires ECG' },
      { id: '903', name: 'Cours complémentaires Lycées' },
      { id: '904', name: 'Préparation ACCES/SESAME' },
      { id: '905', name: 'Khûbe' },
      { id: '906', name: 'École Post-BAC' },
    ],
  }
}

export const getOptionsIdByName = (center, name) => {
  const optionsChoices = availableOptions[center]?.options ?? availableOptions['default']?.options
  const result = optionsChoices?.filter(option => option.name === name)

  return result[0]?.id
}

export const getOptionsNameById = (center, ids) => {
  // return for multiple ids
  if (Array.isArray(ids)) {
    return ids.map(id => {
      const optionsChoices = availableOptions[center]?.options ?? availableOptions['default']?.options
      return optionsChoices.find(option => option.id === id)?.name
    }).join(' - ')
  }

  // return for single id
  const optionsChoices = availableOptions[center]?.options ?? availableOptions['default']?.options
  return optionsChoices.find(option => option.id === ids)?.name
}