import { Card, CardContent, Typography } from '@mui/material'

const FormCard = ({ title, children }) => (
  <Card variant="outlined" sx={{ mt: 2, width: '100%', mb: 2, backgroundColor: '#f2f2f2' }}>
    <CardContent sx={{ '& .MuiInputBase-input': { backgroundColor: '#fff' } }}>
      { title && <Typography variant="h6" color="#287791" mb={2} gutterBottom>
        {title}
      </Typography> }
      
      {children}
    </CardContent>
  </Card>
)

export default FormCard