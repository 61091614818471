import { useEffect, useState } from 'react'
import { useGetIdentity } from 'react-admin'
import { Typography } from '@mui/material'

import { supabaseClient } from '../../supabase'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import SchoolIcon from '@mui/icons-material/School'

const DashboardPubeurFrais = ({ isMobile }) => {
  const { identity } = useGetIdentity()
  const [paidSMS, setPaidSMS] = useState({ totalAmount: 0, totalMessages: 0 })
  const [paidEvents, setPaidEvents] = useState({ totalAmount: 0, totalHours: 0 })

  const getPaidSMS = async () => {
    const getSMSList = async () => {
      const { data, error } = await supabaseClient
        .from('conversation_messages')
        .select('sender_id, sender_type, paid')
        .eq('sender_type', 'pubeur')
        .eq('sender_id', identity?.id)
      
      if (error) {
        console.error('Error fetching paid events:', error)
        return
      }

      if ( ! data || data.length === 0 ) return

      let totalMessages = 0
      const totalAmount = data.reduce((acc, sms) => {
        if (sms.paid) {
          acc += 0.2
          totalMessages++
        }

        return Math.round(acc * 10) / 10 // 1 decimal
      }, 0)

      setPaidSMS({
        totalAmount,
        totalMessages
      })
    }

    await getSMSList()
  }

  const getPaidEvents = async () => {
    const calculatePaidEvents = async (eventsList) => {
      // we calculate the number given to the pubeur
      let totalHours = 0
      const totalAmount = eventsList.reduce((acc, e) => {
        const event = e.eventInfo
        const isDriver = e.is_driver
        const effectiveHours = e.paid_hours || event.missionDuration
        const montantHoraire = event.missionAmountHor
        const trajetAller = event.missionDurationAller

        if (event.missionDuration) {
          // console.log('Montant pour la durée de la mission sur place')
          
          totalHours += effectiveHours
          const amount = effectiveHours * montantHoraire
          acc += amount

          // console.log(montantHoraire +' eur/h for ', effectiveHours + ' hours = ', amount);
        }

        if (trajetAller) {
          // console.log('Montant pour la durée du déplacement aller')
          
          totalHours += trajetAller

          let amount = 0
          if (isDriver) {
            amount = montantHoraire * ( trajetAller * 2 )
          } else {
            amount = montantHoraire * trajetAller
          }
          
          acc += amount

          // console.log(montantHoraire +' eur/h for ', trajetAller + ' hours =', amount, ' total = '+ acc);
        }

        // console.log('__________________')

        return acc
      }, 0)

      setPaidEvents({
        totalAmount,
        totalHours
      })
    }

    const getEventsList = async () => {
      const { data, error } = await supabaseClient
        .from('event_pubeurs')
        .select('is_leader, is_driver, paid_hours, eventInfo: events(id, missionDuration, missionAmountHor, missionDurationAller, totalKm)')
        .eq('pubeur_id', identity?.id)
      
      if (error) {
        console.error('Error fetching paid events:', error)
        return
      }

      return data ?? []
    }

    const eventsList = await getEventsList()
    calculatePaidEvents(eventsList)
  }

  useEffect(() => {
    if ( ! identity ) return

    // getPaidEvents()
    getPaidSMS()
  }, [identity])

  return (
    <Card sx={{ width: isMobile ? '100%' : '33%', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px' }}>
      { !isMobile && (
        <CardMedia
          component="img"
          alt="Frais remboursés"
          height="140"
          image="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fassets.intia.fr%2Fapp%2Fuploads%2F2021%2F01%2Ffacture-scaled.jpg&f=1&nofb=1&ipt=cfac797c585d9a62db54dbd4dfaae07a57e610b85d38d688c4f78b013a9c4404&ipo=imagesg"
          sx={{ opacity: .75 }}
        />
      )}

      <CardContent>
        <Typography variant="h5" gutterBottom>
          Déclarations des frais
        </Typography>

        <Typography variant="body1" gutterBottom>
          C'est pour bientôt !
        </Typography>

        <List dense={false}>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: '#91bcba' }}>
                <QuestionAnswerIcon />
              </Avatar>
            </ListItemAvatar>

            <ListItemText primary={`SMS phoning : ${paidSMS?.totalAmount} €`} secondary={`${paidSMS?.totalMessages} messages comptabilisés`} />
          </ListItem>

          {/* <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: '#91bcba' }}>
                <SchoolIcon />
              </Avatar>
            </ListItemAvatar>

            <ListItemText primary={`Missions lycée : ${paidEvents?.totalAmount} €`} secondary={`${paidEvents?.totalHours} heures comptabilisées`} />
          </ListItem>*/}
        </List>

        <Typography variant="body2" mt={2} gutterBottom>
          <i>Montants actuels estimés pour le mois de {new Date().toLocaleString('fr-FR', { month: 'long' })}, sous réserve de validation par votre responsable.</i>
        </Typography>

      </CardContent>
    </Card>
  )
}

export default DashboardPubeurFrais